import BatteryIconSmall from "./BatteryIconSmall";
import AppState from "state/AppState";

import { observer } from "mobx-react-lite";
import React from "react";

const BatteryIconSmallController = observer((props) => {
    const { batteryLevel, unlimitedBatteryActive } = AppState;

    return (
        <BatteryIconSmall
            batteryLevel={unlimitedBatteryActive ? 100 : batteryLevel}
            showPercent={!unlimitedBatteryActive}
            unlimited={unlimitedBatteryActive}
            {...props}
        />
    );
});

export default BatteryIconSmallController;

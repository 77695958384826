import styles from "./Studio.module.css";
import StudioEditor from "./editors/StudioEditor";
import StudioPreview from "./StudioPreview";
import APP_CONVERTERS from "./StudioAppConverters";
import { StudioStoryContext } from "./StudioContext";
import StudioWelcomeScreen from "./StudioWelcomeScreen";
import { EllipsisLoader } from "../Loaders";
import Modal, {
    ModalTitle,
    ModalText,
    ModalTextbox,
    ModalButton,
} from "../common/Modal";
import AppState from "state/AppState";
import UiState from "state/UiState";
import * as StudioApi from "server/studioApi";

import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useRef } from "react";
import { Route, Switch, Link, useRouteMatch } from "react-router-dom";
import update from "immutability-helper";
import { isEqual } from "lodash";
import store from "store";
import {
    X as IconX,
    CornerUpLeft as IconCornerUpLeft,
    Pencil as IconPencil,
    View as IconView,
} from "lucide-react";
import useWindowSize from "react-use/lib/useWindowSize";
import copyToClipboard from "copy-text-to-clipboard";
import urljoin from "url-join";

const MIN_SCREEN_WIDTH = 1024;
const MIN_SCREEN_HEIGHT = 600;

// TODOS:
// - design
// - events
// - custom app icons
// - clone mission
// - multiple photo selection and reorder by drag
// - image library (unsplash?)
// - resize images
// - group conversations (social app)
// - contacts app: sections
// - find friends: make time last seen optional
// - find friends: title
// - option to edit the credits list

export default function StudioRouter() {
    const { path, url } = useRouteMatch();

    useEffect(() => {
        AppState.configure({
            shouldSendStateToServer: false,
            shouldShowIntroNotification: false,
        });
    }, []);

    return (
        <>
            <ScreenSizeWarning />
            <Switch>
                <Route exact path={path}>
                    <StudioWelcomeScreen />
                </Route>
                <Route
                    exact
                    path={urljoin(path, "edit/:storyId")}
                    render={({ match }) => (
                        <Studio storyId={match.params.storyId} rootUrl={url} />
                    )}
                />
            </Switch>
        </>
    );
}

function ScreenSizeWarning() {
    const storeKey = "dismissScreenSizeWarning";
    const isSmallScreen =
        window.innerWidth < MIN_SCREEN_WIDTH ||
        window.innerHeight < MIN_SCREEN_HEIGHT;
    const [visible, toggle] = useState(isSmallScreen && !store.get(storeKey));

    const onDismiss = () => {
        toggle(false);
        store.set(storeKey, true);
    };

    if (!visible) {
        return null;
    }

    return (
        <div className={styles.screenSizeWarning}>
            <div className={styles.screenSizeWarning__text}>
                {
                    "We recommend using Peek a Phone Studio on a large screen device such as a laptop or a tablet."
                }
            </div>
            <IconX
                className={styles.screenSizeWarning__dismiss}
                onClick={onDismiss}
                size="1em"
            />
        </div>
    );
}

const Studio = observer(({ storyId, rootUrl }) => {
    const [studioStory, setStudioStory] = useState(null);
    const { playerInfoLoaded, story } = AppState;
    const [dirty, toggleDirty] = useState(false);
    const pendingUpdate = useRef(null);

    const [selectedTab, setSelectedTab] = useState("edit");
    const { width: windowWidth } = useWindowSize();
    const isSmallScreen = windowWidth <= MIN_SCREEN_WIDTH;

    const [publishedUrl, setPublishedUrl] = useState(null);

    useEffect(() => {
        StudioApi.loadStory(storyId)
            .then(({ data }) => setStudioStory(data.story.data || {}))
            .catch(() => {
                window.location.replace(rootUrl);
            });
    }, [storyId, rootUrl]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (pendingUpdate.current) {
                const updating = pendingUpdate.current;
                StudioApi.updateStory(storyId, updating.data)
                    .then(() => {
                        if (updating.time === pendingUpdate.current.time) {
                            pendingUpdate.current = null;
                            toggleDirty(false);
                        }
                    })
                    .catch(() => {
                        // nothing
                    });
            }
        }, 3 * 1000);

        return () => clearInterval(interval);
    }, [storyId]);

    useEffect(() => {
        if (playerInfoLoaded && studioStory) {
            reloadStory(studioStory);
        }
    }, [playerInfoLoaded, studioStory]);

    const handleStoryUpdate = (updateSpec) => {
        const updated = update(studioStory, updateSpec);
        setStudioStory(updated);
        pendingUpdate.current = { time: Date.now(), data: updated };
        toggleDirty(true);
    };

    const handleResetProgress = () => {
        reloadStory(studioStory, { resetState: true });
    };

    const handlePublish = () => {
        StudioApi.publishStory(storyId, convertStory(studioStory))
            .then(({ data }) => {
                if (data.success) {
                    setPublishedUrl(data.url);
                } else {
                    UiState.showAlert(
                        data.error ||
                            "Failed publishing missing. Please try again or contact support."
                    );
                }
            })
            .catch(() => {
                UiState.showAlert(
                    "Failed publishing missing. Please try again or contact support."
                );
            });
    };

    if (!studioStory) {
        return (
            <div className={styles.studio__loading}>
                <EllipsisLoader color="black" />
                <div className={styles.studio__loading__text}>
                    {"Loading mission"}
                </div>
            </div>
        );
    }

    return (
        <StudioStoryContext.Provider value={studioStory}>
            <div className={styles.studio}>
                <StudioBar
                    storyName={studioStory.name || "[Untitled Mission]"}
                    dirty={dirty}
                    isSmallScreen={isSmallScreen}
                    rootUrl={rootUrl}
                    onPublish={handlePublish}
                />
                <div className={styles.studio__editArea}>
                    {isSmallScreen && selectedTab !== "preview" ? null : (
                        <StudioPreview
                            story={story}
                            onResetProgress={handleResetProgress}
                        />
                    )}
                    {isSmallScreen && selectedTab !== "edit" ? null : (
                        <StudioEditor onStoryUpdate={handleStoryUpdate} />
                    )}
                </div>
                {isSmallScreen ? (
                    <StudioBottomNavigation
                        value={selectedTab}
                        onChange={setSelectedTab}
                    />
                ) : null}
                {publishedUrl ? (
                    <PublishedModal
                        url={publishedUrl}
                        onClose={() => setPublishedUrl(null)}
                    />
                ) : null}
            </div>
        </StudioStoryContext.Provider>
    );
});

const StudioBar = ({ storyName, dirty, isSmallScreen, rootUrl, onPublish }) => (
    <div className={styles.studio__bar}>
        <AppBar position="static">
            <Toolbar>
                <Link to={rootUrl}>
                    <IconButton edge="start" color="inherit" aria-label="menu">
                        <IconCornerUpLeft
                            className={styles.studio__bar__icon}
                        />
                    </IconButton>
                </Link>
                <Typography variant="h6" className={styles.studio__bar__title}>
                    {storyName}
                </Typography>
                {dirty ? (
                    <Typography className={styles.studio__bar__saving}>
                        {"Saving..."}
                    </Typography>
                ) : null}
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={onPublish}
                    size={isSmallScreen ? "small" : "medium"}
                >
                    {isSmallScreen ? "Publish" : "Publish Mission"}
                </Button>
            </Toolbar>
        </AppBar>
    </div>
);

const StudioBottomNavigation = ({ value, onChange }) => (
    <BottomNavigation
        className={styles.studio__bottomNav}
        value={value}
        onChange={(event, newValue) => onChange(newValue)}
        showLabels
    >
        <BottomNavigationAction
            value="edit"
            label="Edit"
            icon={<IconPencil />}
        />
        <BottomNavigationAction
            value="preview"
            label="Preview"
            icon={<IconView />}
        />
    </BottomNavigation>
);

function reloadStory(studioStory, options) {
    const { resetState } = options || {};

    const newStory = convertStory(studioStory);
    const keepAppStores =
        AppState.story &&
        !resetState &&
        isEqual(
            newStory.apps.map((x) => x.name).sort(),
            AppState.story.apps.map((x) => x.name).sort()
        );

    AppState.loadStoryFromData(newStory, {
        noEvents: true,
        keepAppStores,
        ...options,
    });
}

const PublishedModal = ({ url, onClose }) => {
    const [copied, setCopied] = useState(false);
    const textboxRef = useRef();

    const handleCopy = () => {
        copyToClipboard(url);
        setCopied(true);
    };

    return (
        <Modal visible alignItems="left" onClose={onClose}>
            <ModalTitle>{"Hurray!!"}</ModalTitle>
            <ModalText>
                {"Your mission is now live and ready to play!"}
            </ModalText>
            <ModalText>
                {"Share it with the world using the link below."}
            </ModalText>
            <ModalTextbox
                ref={textboxRef}
                value={url}
                readOnly
                onClick={() => textboxRef.current.select()}
                style={{ textAlign: "center" }}
            />
            <ModalButton primary displayFont onClick={handleCopy}>
                {copied ? "Link Copied" : "Copy Link"}
            </ModalButton>
        </Modal>
    );
};

function convertStory(story) {
    return update(story, {
        apps: (apps) => (apps || []).map((app) => convertApp(app, story)),
    });
}

function convertApp(app, story) {
    const converter = APP_CONVERTERS[app.type || app.name];
    return update(app, { data: (x) => converter(x || {}, story) });
}

import Studio from "./components/studio/Studio";
import Alert from "./components/Alert";
import Hotjar from "./components/utils/Hotjar";
import { PageViewReporter } from "./analytics";

import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

export default function StudioApp() {
    return (
        <Router>
            <PageViewReporter />
            <Route path="/">
                <Studio />
            </Route>
            <Alert />
            <TawkTo />
            <Hotjar hjid={2577799} hjsv={6} />
        </Router>
    );
}

function TawkTo() {
    useEffect(() => {
        if (process.env.NODE_ENV !== "production") {
            return;
        }

        /* eslint-disable */
        window.Tawk_API = window.Tawk_API || {};
        window.Tawk_LoadStart = new Date();
        (function () {
            var s1 = document.createElement("script"),
                s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = "https://embed.tawk.to/612a167dd6e7610a49b26deb/1fe66fmr3";
            s1.charset = "UTF-8";
            s1.setAttribute("crossorigin", "*");
            s0.parentNode.insertBefore(s1, s0);
        })();
        /* eslint-enable */
    }, []);

    return null;
}

import styles from "./PhoneNumberField.module.css";

import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";

const PhoneNumberField = ({ name, formData, allowCustom, onChange }) => {
    const { type, number } = formData;

    const showTextbox = ["autoAreaCode", "custom"].includes(type);
    const textboxPlaceholder =
        type === "autoAreaCode"
            ? "Area code"
            : type === "custom"
            ? "Number"
            : null;

    return (
        <div className={styles.phoneNumberWidget}>
            <FormControl>
                <InputLabel>{name}</InputLabel>
                <Select
                    value={type || "none"}
                    onChange={(evt) =>
                        onChange({ type: evt.target.value, number })
                    }
                >
                    <MenuItem value="none">
                        <em>{"None"}</em>
                    </MenuItem>
                    <MenuItem value="auto">{"Auto"}</MenuItem>
                    <MenuItem value="autoAreaCode">
                        {"Auto (custom area code)"}
                    </MenuItem>
                    {allowCustom ? (
                        <MenuItem value="custom">{"Custom"}</MenuItem>
                    ) : null}
                </Select>
            </FormControl>
            {showTextbox ? (
                <TextField
                    value={number}
                    placeholder={textboxPlaceholder}
                    className="data-hj-allow"
                    onChange={(evt) =>
                        onChange({ type, number: evt.target.value })
                    }
                />
            ) : null}
        </div>
    );
};

export default PhoneNumberField;

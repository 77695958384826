import { weightedChoice } from "./randomUtils";

import store from "store";

export const SPLIT_TEST_VALUES = {
    storyOrder: splitTestValue("storyOrder", [
        // ["default", 3],
        ["b", 1],
        // ["a", 1]
    ]),
    storeItems: splitTestValue("storeItems", [
        // ["default", 9],
        ["a", 1],
    ]),
    storyTimeLock: splitTestValue("storyTimeLock", [
        ["off", 1],
        ["on", 1],
    ]),
    noFeedback: splitTestValue("noFeedback", [
        ["off", 1],
        // ["on", 1],
    ]),
};

function splitTestValue(name, choices) {
    const storeKey = `test__${name}`;
    let value = store.get(storeKey);
    if (value) {
        return value;
    }

    value = weightedChoice(choices);
    store.set(storeKey, value);
    return value;
}

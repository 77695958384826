import "./LockScreen.css";
import GestureDetector from "../utils/GestureDetector";
import PinKeyboard from "../common/PinKeyboard";
import DotPatternKeyboard from "../common/DotPatternKeyboard";
import DotPatternHoverable from "../common/DotPatternHoverable";
import PasswordScreenLocker from "components/common/PasswordScreenLocker";
import AppState from "state/AppState";
import { selectImageSource } from "utils/browserUtils";

import React, { useState } from "react";
import classnames from "classnames";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";

function LockScreen({ background, unlockMethod, code, showDate, unlockEvent }) {
    const [value, setValue] = useState("");
    const [showKeyboard, setShowKeyboard] = useState(false);
    const unlocked = value === code || AppState.storyState.phoneUnlocked;

    const unlockHandler = () => {
        AppState.unlockPhone();
        if (unlockEvent) {
            AppState.fireEvent(unlockEvent);
        }
    };

    const toggleKeyboard = (show) => {
        if (show) {
            setValue("");
        }
        setShowKeyboard(show);
    };

    return (
        <div
            className={classnames("LockScreen", { unlocked })}
            style={{ backgroundImage: `url(${selectImageSource(background)})` }}
        >
            <GestureDetector
                className={classnames("LockScreen-default", {
                    visible: !showKeyboard,
                })}
                onSwipe={() => toggleKeyboard(true)}
            >
                {showDate ? <LockScreenDate /> : null}
                <div className="LockScreen-default-cta">
                    {"Swipe to unlock"}
                </div>
            </GestureDetector>
            <div
                className={classnames("LockScreen-keyboard-wrapper", {
                    visible: showKeyboard,
                })}
            >
                <Locker
                    unlockMethod={unlockMethod || "code"}
                    code={code}
                    onUnlock={unlockHandler}
                    onCancel={() => toggleKeyboard(false)}
                />
            </div>
        </div>
    );
}

export default observer(LockScreen);

function Locker({ unlockMethod, code, onUnlock, onCancel }) {
    switch (unlockMethod) {
        case "code":
            return (
                <PinKeyboard
                    title="Enter Passcode"
                    password={code}
                    darkMode
                    onUnlock={onUnlock}
                    onCancel={onCancel}
                    appName="lockScreenPin"
                />
            );
        case "dotPattern":
            return (
                <DotPatternKeyboard
                    title="Enter Pattern"
                    pattern={code}
                    darkMode
                    onUnlock={onUnlock}
                    onCancel={onCancel}
                />
            );
        case "dotHoverPattern":
            return (
                <DotPatternHoverable
                    title="Enter Pattern"
                    pattern={code}
                    darkMode
                    onUnlock={onUnlock}
                    onCancel={onCancel}
                    appName="lockScreenPatternHoverable"
                />
            );
        case "password":
            return (
                <PasswordScreenLocker
                    title="Enter Password"
                    password={code}
                    onUnlock={onUnlock}
                    onCancel={onCancel}
                />
            );
        default:
            return null;
    }
}

function LockScreenDate() {
    return (
        <div>
            <div className="LockScreen-default-time">
                {dayjs().format("hh:mm A")}
            </div>
            <div className="LockScreen-default-date">
                {dayjs().format("dddd[, ]MMMM D")}
            </div>
        </div>
    );
}

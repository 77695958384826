import styles from "./MarkdownContent.module.css";
import AppState from "../../state/AppState";
import { markdownToHtml } from "utils/markdownUtils";
import { replaceEmojis, unicodeToCodepoint } from "utils/stringUtils";
import { getEmojiImageUrl } from "components/utils/EmojiText";

import * as Native from "@faintlines/native-bridge";

import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";

const PHONE_SIM_PROTOCOL = "app://";

export default function MarkdownContent({ markdown, className, style }) {
    const root = useRef(null);
    const history = useHistory();

    const handleLinkClick = (evt) => {
        const { href } = evt.target;
        if (!href) {
            return;
        }

        if (href.startsWith(PHONE_SIM_PROTOCOL)) {
            evt.preventDefault();
            AppState.navigateToApp(
                history,
                href.substring(PHONE_SIM_PROTOCOL.length)
            );
            return;
        }

        if (Native.CAPABILITIES.openUrlInBrowser) {
            evt.preventDefault();
            Native.openUrlInBrowser(href);
        }
    };

    useEffect(() => {
        const links = root.current?.getElementsByTagName("a") || [];
        for (let i = 0; i < links.length; i += 1) {
            links[i].addEventListener("click", handleLinkClick);
        }

        return () => {
            for (let i = 0; i < links.length; i += 1) {
                links[i].removeEventListener("click", handleLinkClick);
            }
        };
    });

    return (
        <div
            ref={root}
            className={classnames(styles.markdown, className)}
            style={style}
            dangerouslySetInnerHTML={{
                __html: markdownToHtml(normalizeEmojis(markdown)),
            }}
        />
    );
}

function normalizeEmojis(text) {
    return replaceEmojis(text, emojiToMarkdownImage);
}

function emojiToMarkdownImage(match) {
    const codepoint = unicodeToCodepoint(match);
    const url = getEmojiImageUrl(codepoint);
    return `![${match}](${url})`;
}

import showdown from "showdown";

let markdownConverter = null;

export function markdownToHtml(markdown) {
    return getMarkdownConverter()
        .makeHtml(markdown || "")
        .replace(/…/g, "...");
}

export function markdownToText(markdown) {
    const elem = document.createElement("DIV");
    elem.innerHTML = markdownToHtml(markdown);
    return elem.textContent || elem.innerText || markdown;
}

function getMarkdownConverter() {
    if (!markdownConverter) {
        markdownConverter = new showdown.Converter();
        markdownConverter.setFlavor("github");
        markdownConverter.setOption("simplifiedAutoLink", false);
        markdownConverter.setOption("underline", true);
        markdownConverter.setOption("openLinksInNewWindow", true);
    }

    return markdownConverter;
}

import React from "react";

export default function DaysRuler() {
    const days = ["M", "T", "W", "T", "F", "S", "S"];
    return (
        <div className="AppCalendar-daysRuler">
            {days.map((day, i) => (
                <DaysRulerCell value={day} key={i} />
            ))}
        </div>
    );
}

function DaysRulerCell({ value }) {
    return (
        <div className="AppCalendar-daysRulerCell-container">
            <div className="AppCalendar-daysRulerCell-text">{value}</div>
        </div>
    );
}

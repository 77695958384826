import "./LoginScreen.css";
import PasswordInput from "../utils/PasswordInput";
import AppState from "../../state/AppState";

import { stringsInclude } from "@faintlines/string-utils";

import React, { useState } from "react";
import classnames from "classnames";
import { observer } from "mobx-react-lite";

function LoginScreen({
    username,
    password,
    allowTypos,
    className,
    usernameText,
    usernameType,
    usernamePrefilled,
    submitText,
    errorText,
    backgroundColor,
    appName,
    children,
    onLogin,
}) {
    const appNameStr = appName || "";
    const storageKeyPrefix = `LoginScreen_${appNameStr}_`;
    const usernameValue =
        AppState.storyState.sessionStorage[`${storageKeyPrefix}username`] ||
        (usernamePrefilled ? username : "");
    const passwordValue =
        AppState.storyState.sessionStorage[`${storageKeyPrefix}password`] || "";

    const [showError, setShowError] = useState(false);
    const [errorAnimation, setErrorAnimation] = useState(false);

    usernameText = usernameText || "Username";

    const submitEnabled = usernameValue && passwordValue;

    function onSubmit() {
        const usernameMatch = stringsInclude(username, usernameValue, {
            allowTypos,
        });
        const passwordMatch = stringsInclude(password, passwordValue, {
            allowTypos,
        });
        const passwordStr = `${usernameValue}|${passwordValue}`;

        if (usernameMatch && passwordMatch) {
            if (onLogin) {
                onLogin();
            }
            AppState.trackPasswordEvent(appName, passwordStr, true);
        } else {
            setShowError(true);

            setErrorAnimation(true);
            setTimeout(() => {
                setErrorAnimation(false);
            }, 300);
            AppState.trackPasswordEvent(appName, passwordStr, false);
        }
    }

    const handleUsernameChange = (evt) => {
        AppState.setSessionStorage(
            `${storageKeyPrefix}username`,
            evt.target.value
        );
    };

    const handlePasswordChange = (evt) => {
        AppState.setSessionStorage(
            `${storageKeyPrefix}password`,
            evt.target.value
        );
    };

    return (
        <div
            className={classnames("LoginScreen", className)}
            style={{ backgroundColor }}
        >
            {children}
            <input
                className={classnames(
                    "LoginScreen-textbox username data-hj-allow",
                    usernamePrefilled ? "disabled" : ""
                )}
                value={usernameValue}
                onChange={usernamePrefilled ? null : handleUsernameChange}
                placeholder={usernameText}
                disabled={usernamePrefilled}
                type={usernameType || "text"}
                data-lpignore="true"
            />
            <PasswordInput
                className="LoginScreen-textbox password"
                formClassName="LoginScreen-textboxForm"
                value={passwordValue}
                onChange={handlePasswordChange}
                placeholder="Password"
            />
            <div
                className={classnames("LoginScreen-submit-button", {
                    disabled: !submitEnabled,
                    error: errorAnimation,
                })}
                onClick={onSubmit}
            >
                {submitText || "Log In"}
            </div>
            <div
                className={classnames("LoginScreen-error", {
                    visible: showError,
                })}
            >
                {errorText ||
                    `Please check your ${usernameText.toLowerCase()} and password.`}
            </div>
        </div>
    );
}

export default observer(LoginScreen);

import ContactSelector from "./widgets/ContactSelector";
import ImageUploader from "./widgets/ImageUploader";
import AudoIdHiddenWidget from "./widgets/AudoIdHiddenWidget";
import TextWidget from "./widgets/TextWidget";
import TextareaWidget from "./widgets/TextAreaWidget";
import RelativeDateSelector from "./fields/RelativeDateSelector";
import {
    RelativeDateTimeDaysSelector,
    RelativeDateTimeWeeksSelector,
} from "./fields/RelativeDateTimeSelector";
import {
    TextConversationSingeContactField,
    TextConversationMultipleContactsField,
} from "./fields/TextConversationField";
import PhoneNumberField from "./fields/PhoneNumberField";

import Form from "@rjsf/material-ui";
import React from "react";

export default function StudioSchemaForm({ schema, uiSchema, data, onChange }) {
    return (
        <Form
            schema={schema}
            uiSchema={uiSchema}
            onChange={(evt) => onChange(evt.formData)}
            formData={data}
            widgets={{
                AudoIdHiddenWidget,
                ImageUploader,
                ContactSelector,
                TextWidget,
                TextareaWidget,
            }}
            fields={{
                TextConversationSingeContactField,
                TextConversationMultipleContactsField,
                PhoneNumberField,
                RelativeDateSelector,
                RelativeDateTimeDaysSelector,
                RelativeDateTimeWeeksSelector,
            }}
        >
            <></>
        </Form>
    );
}

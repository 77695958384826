import { toJS } from "mobx";
import { has } from "lodash";

export function pickToJs(obj, keys) {
    const result = {};
    keys.forEach((key) => {
        if (has(obj, key)) {
            result[key] = toJS(obj[key]);
        }
    });
    return result;
}

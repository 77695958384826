import styles from "./AppsEditor.module.css";
import AppSelector from "../StudioAppSelector";
import StudioSchemaForm from "../StudioSchemaForm";
import APP_SCHEMAS from "../schemas/appSchemas";
import { useStudioStory } from "../StudioContext";
import { LOCKERS } from "../StudioConstants";
import { HOTJAR_ALLOW_CLASS_NAME } from "../utils/StudioUtils";
import { APP_ICONS } from "../../Phone/Apps/common/AppIcons";
import EditorTab from "../common/EditorTab";
import UiState from "state/UiState";

import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";

import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { toPairs, findIndex } from "lodash";

const AppsEditor = observer(({ onStoryUpdate }) => {
    const [selectedApp, setSelectedApp] = useState(null);

    const handleAppDelete = (appName) => {
        UiState.confirm({
            title: "Delete app?",
            yesText: "Delete App",
            noText: "Cancel",
            onYes: () => {
                onStoryUpdate({
                    apps: (apps) => apps.filter((x) => x.name !== appName),
                });
                if (selectedApp === appName) {
                    setSelectedApp(null);
                }
            },
        });
    };

    return (
        <EditorTab>
            <AppSelector
                selectedApp={selectedApp}
                onSelected={setSelectedApp}
                onStoryUpdate={onStoryUpdate}
                onDelete={handleAppDelete}
            />
            <AppEditor
                key={selectedApp}
                selectedApp={selectedApp}
                onStoryUpdate={onStoryUpdate}
            />
        </EditorTab>
    );
});

export default AppsEditor;

const AppEditor = observer(({ selectedApp, onStoryUpdate }) => {
    const story = useStudioStory();
    const apps = story.apps || [];

    if (!selectedApp) {
        return (
            <div className={styles.appEditor__empty}>
                {apps.length > 0
                    ? "⇧ Select an app to customize"
                    : "⇧ Start by adding an app"}
            </div>
        );
    }

    const selectedAppIndex = findIndex(apps, (x) => x.name === selectedApp);
    const app = apps[selectedAppIndex];
    if (!app) {
        // this could happen if the app was deleted but selectedApp was not updated yet.
        // A possible fix is to use a Mobx store for all state, as it has action transaction.
        return null;
    }

    const appType = app.type || app.name;

    return (
        <div className={styles.appEditor}>
            <Typography variant="h5" className={styles.appEditor__title}>
                {`Customize ${app.title || app.type || app.name} app`}
            </Typography>
            <AppEditorSettings
                app={app}
                selectedAppIndex={selectedAppIndex}
                onStoryUpdate={onStoryUpdate}
            />
            <StudioSchemaForm
                schema={APP_SCHEMAS[appType]}
                uiSchema={APP_SCHEMAS[`${appType}Ui`]}
                data={app.data}
                onChange={(formData) =>
                    onStoryUpdate({
                        apps: {
                            [selectedAppIndex]: { data: { $set: formData } },
                        },
                    })
                }
            />
        </div>
    );
});

const AppEditorSettings = observer(
    ({ app, selectedAppIndex, onStoryUpdate }) => {
        const lockType = app.passwordLockType || "none";
        const lockerProps = LOCKERS[lockType] || {};

        function updateApp(appUpdateSpec) {
            onStoryUpdate({
                apps: {
                    [selectedAppIndex]: appUpdateSpec,
                },
            });
        }

        return (
            <div className={styles.appEditorSettings}>
                <Select
                    labelId="app-editor-icon"
                    value={app.icon || app.type || app.name}
                    onChange={(evt) =>
                        updateApp({
                            icon: { $set: evt.target.value },
                        })
                    }
                >
                    {toPairs(APP_ICONS).map(([icon, url]) => (
                        <MenuItem key={icon} value={icon}>
                            <img
                                className={styles.appEditorSettings__icon}
                                src={url}
                                alt={icon}
                            />
                        </MenuItem>
                    ))}
                </Select>
                <TextField
                    label="Title"
                    value={app.title}
                    inputProps={{
                        maxLength: 12,
                        className: HOTJAR_ALLOW_CLASS_NAME,
                    }}
                    onChange={(evt) =>
                        updateApp({
                            title: { $set: evt.target.value },
                        })
                    }
                />
                <FormControl>
                    <InputLabel id="app-editor-lock-type">
                        {"Lock type"}
                    </InputLabel>
                    <Select
                        labelId="app-editor-lock-type"
                        value={lockType}
                        autoWidth
                        onChange={(evt) =>
                            updateApp({
                                passwordLockType: { $set: evt.target.value },
                            })
                        }
                    >
                        <MenuItem value="none">
                            <em>{"[Unlocked]"}</em>
                        </MenuItem>
                        <MenuItem value="pin">{"PIN"}</MenuItem>
                        <MenuItem value="password">{"Password"}</MenuItem>
                        <MenuItem value="userPassword">
                            {"Username + password"}
                        </MenuItem>
                        <MenuItem value="dotPattern">{"Dot pattern"}</MenuItem>
                        <MenuItem value="dotHoverPattern">
                            {"Dot hover pattern"}
                        </MenuItem>
                        <MenuItem value="calculator">
                            {"Disguise as Calculator"}
                        </MenuItem>
                        <MenuItem value="converter">
                            {"Disguise as Converter"}
                        </MenuItem>
                    </Select>
                </FormControl>
                {lockType !== "none" ? (
                    <TextField
                        label={lockerProps.label}
                        helperText={lockerProps.helperText}
                        value={app.password || ""}
                        inputProps={{ className: HOTJAR_ALLOW_CLASS_NAME }}
                        onChange={(evt) =>
                            updateApp({
                                password: { $set: evt.target.value },
                            })
                        }
                    />
                ) : null}
                {lockerProps.hasHint ? (
                    <TextField
                        label="Password hint"
                        value={app.passwordHint || ""}
                        inputProps={{
                            maxLength: 30,
                            className: HOTJAR_ALLOW_CLASS_NAME,
                        }}
                        onChange={(evt) =>
                            updateApp({
                                passwordHint: { $set: evt.target.value },
                            })
                        }
                    />
                ) : null}
            </div>
        );
    }
);

import "./Phone.css";

import PhoneScreen from "./PhoneScreen";
import StorySideMenu from "./StorySideMenu";
import { useHashMenu } from "../common/SideMenu";

import React from "react";
import classnames from "classnames";

export default function Phone({ story, onExit }) {
    const [menuOpen, toggleMenu] = useHashMenu("#menu");
    const closeMenu = () => menuOpen && toggleMenu(false);

    return (
        <PhoneView menuOpen={menuOpen}>
            <StorySideMenu
                story={story}
                visible={menuOpen}
                onClose={closeMenu}
            />
            <PhoneScreen
                story={story}
                menuOpen={menuOpen}
                onClick={closeMenu}
                onMenuClick={() => toggleMenu(true)}
                onExit={onExit}
            />
        </PhoneView>
    );
}

export function PhoneView({ children, menuOpen }) {
    return <div className={classnames("Phone", { menuOpen })}>{children}</div>;
}

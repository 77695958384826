import { makeAutoObservable } from "mobx";

class UiState {
    alert = null;
    introNotificationVisible = false;
    introNotificationType = null;
    noteVisible = false;
    subscribeModal = null;
    signupPopupVisible = false;
    hintsModalVisible = false;
    settingsModalVisible = false;
    batteryModalVisible = false;
    ecoIncentiveModalVisible = false;
    dailyRewardModalVisible = false;
    deleteAccountModalVisible = false;
    customOfferModal = null;
    referralModalVisible = false;
    referralModelError = null;
    credits = null;
    successModal = null;
    reward = null;

    // Native apps only - indicates if the app's activity was paused.
    isAppPaused = false;

    get successModalVisible() {
        return this.successModal !== null;
    }

    /// / Actions

    constructor() {
        makeAutoObservable(this);

        window.addEventListener("apppause", () => {
            this.toggleAppIsPaused(true);
        });
        window.addEventListener("appresume", () => {
            this.toggleAppIsPaused(false);
        });
    }

    showAlert(text, title, options) {
        const { onClose, yesText } = options || {};

        this.alert = {
            title,
            text,
            buttons: [
                {
                    text: yesText || "OK",
                    event: () => {
                        this.hideAlert();
                        if (onClose) {
                            onClose();
                        }
                    },
                },
            ],
        };
    }

    confirm({ text, title, yesText, onYes, yesHref, noText, noPrimary, onNo }) {
        this.alert = {
            title,
            text,
            buttons: [
                {
                    text: noText || "No",
                    secondary: !noPrimary,
                    event: () => {
                        this.hideAlert();
                        if (onNo) {
                            onNo();
                        }
                    },
                },
                {
                    text: yesText || "Yes",
                    href: yesHref,
                    event: () => {
                        this.hideAlert();
                        if (onYes) {
                            onYes();
                        }
                    },
                },
            ],
        };
    }

    hideAlert() {
        this.alert = null;
    }

    toggleIntroNotification(toggle, type) {
        if (type) {
            this.introNotificationType = type;
        }
        this.introNotificationVisible = toggle;
    }

    toggleNote(toggle) {
        this.noteVisible = toggle;
    }

    showReward(rewardType, rewardAmount) {
        this.reward = { type: rewardType, amount: rewardAmount };
    }

    hideReward() {
        this.reward = null;
    }

    showSubscribeForUpdatesModal() {
        this.subscribeModal = "new_missions";
    }

    showSubscribeForBetaTestingModal() {
        this.subscribeModal = "beta_testing";
    }

    hideSubscribeModal() {
        this.subscribeModal = null;
    }

    toggleSignupPopup(toggle) {
        this.signupPopupVisible = toggle;
    }

    showCredits(credits) {
        this.credits = credits;
    }

    hideCredits() {
        this.credits = null;
    }

    showSuccessModal({ title, text, image, trophy, awards }) {
        this.successModal = { title, text, image, trophy, awards };
    }

    hideSuccessModal() {
        this.successModal = null;
    }

    showHintUnlockError() {
        this.showAlert("Failed unlocking hint, please try again.");
    }

    showSkipStoryError() {
        this.showAlert("Failed skipping mission, please try again.");
    }

    handleOpenUrlInBrowserFailedEvent(url) {
        this.showAlert(
            `Failed to launch link in your phone's browser. Please try again or visit this link directly: ${url}`
        );
    }

    promptStoryProgress({ onContinue, onStartOver }) {
        this.confirm({
            text: "You have made some progress in this mission.",
            noText: "Continue where I left off",
            yesText: "Start over",
            onNo: onContinue,
            onYes: onStartOver,
            noPrimary: true,
        });
    }

    confirmExitStory({ onExit }) {
        this.confirm({
            text: "Are you sure you want to leave this mission?",
            noText: "Leave mission",
            yesText: "I'd rather stay",
            onNo: onExit,
            noPrimary: true,
        });
    }

    toggleDeleteAccountModal(toggle) {
        this.deleteAccountModalVisible = toggle;
    }

    toggleHintsModal(toggle) {
        this.hintsModalVisible = toggle;
    }

    toggleSettingsModal(toggle) {
        this.settingsModalVisible = toggle;
    }

    toggleBatteryModal(toggle) {
        this.batteryModalVisible = toggle;
    }

    toggleEcoIncentiveModal(toggle) {
        this.ecoIncentiveModalVisible = toggle;
    }

    toggleDailyRewardModal(toggle) {
        this.dailyRewardModalVisible = toggle;
    }

    showCustomOfferModal({ offerId, iapItemId, endDate }) {
        this.customOfferModal = { offerId, iapItemId, endDate };
    }

    hideCustomOfferModal() {
        this.customOfferModal = null;
    }

    toggleReferralModal(toggle) {
        this.referralModalVisible = toggle;
    }

    setReferralModelError(error) {
        this.referralModelError = error;
    }

    toggleAppIsPaused(toggle) {
        this.isAppPaused = toggle;
    }
}

const state = new UiState();

window.addEventListener("openurlinbrowserfailed", (evt) =>
    state.handleOpenUrlInBrowserFailedEvent(evt.detail.url)
);

export default state;

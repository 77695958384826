import { useStudioStory } from "../StudioContext";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import { observer } from "mobx-react-lite";
import React from "react";

export default function ContactSelector(props) {
    // Proxy objects can't serve as Widgets, so we wrap with a non-observable component.
    return <ContactSelectorInner {...props} />;
}

const ContactSelectorInner = observer(
    ({ value, label, className, onChange }) => {
        const story = useStudioStory();
        const contacts = story.contacts || [];

        return (
            <FormControl className={className}>
                {label ? <InputLabel>{label}</InputLabel> : null}
                <Select
                    value={value || ""}
                    onChange={(evt) => onChange(evt.target.value)}
                >
                    <MenuItem value="">{"[None]"}</MenuItem>
                    {contacts.map((cnt) => (
                        <MenuItem key={cnt.id} value={cnt.id}>
                            {cnt.name}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                    {"Add/edit contacts using the Contacts tab above."}
                </FormHelperText>
            </FormControl>
        );
    }
);

const contactsSchema = {
    type: "array",
    default: [],
    items: {
        type: "object",
        properties: {
            id: { type: "string" },
            name: { type: "string", title: "Name" },
            photo: { type: "string", title: "Photo" },
            number: {
                type: "object",
                properties: {
                    type: {
                        enum: ["none", "auto", "autoAreaCode"], // custom
                        default: "none",
                    },
                    number: { type: "string", default: "" },
                },
            },
            // email: { type: "string", title: "Email" },
            unlisted: {
                type: "boolean",
                title: "Hide in other apps (Contacts, etc.)",
            },
        },
    },
};
export default contactsSchema;

export const CONTACTS_UI_SCHEMA = {
    items: {
        id: { "ui:widget": "AudoIdHiddenWidget" },
        photo: { "ui:widget": "ImageUploader" },
        number: { "ui:field": "PhoneNumberField" },
    },
};

import styles from "./ChatAudioPlayer.module.css";

import { useAppStore } from "@faintlines/phone-sim-common";

import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import React from "react";
import { observer } from "mobx-react-lite";

const ChatAudioPlayer = observer(({ src }) => {
    const store = useAppStore();
    const handlePlayingChange = (toggle) => {
        store.toggleAudioPlaying(toggle);
    };

    return (
        <div className={styles.player}>
            <AudioPlayer
                src={src}
                style={{
                    width: "250px",
                    backgroundColor: "transparent",
                    border: "none",
                    boxShadow: "none",
                }}
                showJumpControls={false}
                autoPlayAfterSrcChange={false}
                layout="horizontal-reverse"
                customProgressBarSection={[
                    RHAP_UI.PROGRESS_BAR,
                    RHAP_UI.DURATION,
                ]}
                customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                customAdditionalControls={[]}
                onPlay={() => handlePlayingChange(true)}
                onPlaying={() => handlePlayingChange(true)}
                onPause={() => handlePlayingChange(false)}
                onEnded={() => handlePlayingChange(false)}
                onPlayError={() => handlePlayingChange(false)}
            />
        </div>
    );
});

export default ChatAudioPlayer;

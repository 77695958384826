import "./AppEmail.css";
import MarkdownContent from "../../utils/MarkdownContent";
import PasswordInput from "../../utils/PasswordInput";
import { escapeLunrQueryString } from "../../../utils/stringUtils";
import { reportException } from "../../../utils/errorReporting";
import AppState from "../../../state/AppState";
import ZoomableImage from "../../common/ZoomableImage";
import { formatDate } from "utils/dateUtils";

import { limitString, stringsInclude } from "@faintlines/string-utils";
import { useAppStore } from "@faintlines/phone-sim-common";

import React, { useState, useEffect, useRef } from "react";
import { Switch, Route, Link, useParams, Redirect } from "react-router-dom";
import urljoin from "url-join";
import classnames from "classnames";
import lunr from "lunr";
import { cloneDeep, forEach, last, find } from "lodash";
import { observer } from "mobx-react-lite";

const MAX_THREADS = 5;

function AppEmail({ threads, appUrl, appPath }) {
    const threadsWithIds = cloneDeep(threads || []);
    forEach(threadsWithIds, (x, i) => {
        x.id = x.id || i.toString();
    });

    return (
        <div className="AppEmail">
            <Switch>
                <Route exact path={appPath}>
                    <Inbox threads={threadsWithIds} appUrl={appUrl} />
                </Route>
                <Route exact path={urljoin(appPath, "thread", ":threadId")}>
                    <EmailThread appUrl={appUrl} threads={threadsWithIds} />
                </Route>
                <Route
                    exact
                    path={urljoin(
                        appPath,
                        "thread",
                        ":threadId",
                        "attachment",
                        ":emailId"
                    )}
                    render={(props) => (
                        <Redirect
                            to={urljoin(
                                appUrl,
                                "thread",
                                props.match.params.threadId
                            )}
                        />
                    )}
                />
                <Route
                    exact
                    path={urljoin(
                        appPath,
                        "thread",
                        ":threadId",
                        "attachment",
                        ":emailId",
                        ":attchId"
                    )}
                >
                    <Attachment appUrl={appUrl} threads={threadsWithIds} />
                </Route>
                <Route>
                    <Redirect to={appUrl} />
                </Route>
            </Switch>
        </div>
    );
}

export default observer(AppEmail);

const Inbox = observer(({ threads, appUrl }) => {
    const State = useAppStore();
    const [searchQuery, setSearchQuery] = useState("");

    let filteredThreads = threads.filter(
        (x) => x.visible !== false || State.visibleThreads[x.id]
    );
    filteredThreads = searchQuery
        ? searchThreads(filteredThreads, searchQuery)
        : filteredThreads;

    const firstThreads = filteredThreads.slice(0, MAX_THREADS);
    const totalThreads =
        filteredThreads.length === threads.length
            ? threads.length <= MAX_THREADS
                ? threads.length
                : "many"
            : filteredThreads.length;

    return (
        <div className="AppEmail-inbox-scroller">
            <div className="AppEmail-inbox">
                <div className="AppEmail-inbox-header">
                    <div>
                        <div className="AppEmail-inbox-title">{"Inbox"}</div>
                        <SearchBox onSearch={(q) => setSearchQuery(q)} />
                        {firstThreads.length > 0 ? (
                            <div className="AppEmail-inbox-subtitle">
                                {`Showing ${firstThreads.length} of ${totalThreads}`}
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="AppEmail-inbox-emails">
                    {firstThreads.length === 0 ? (
                        <div className="AppEmail-inbox-no-results">
                            {searchQuery
                                ? "No messages matched your search."
                                : "No messages."}
                        </div>
                    ) : null}
                    {firstThreads.map((thread) => (
                        <InboxThread
                            key={thread.id}
                            thread={thread}
                            appUrl={appUrl}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
});

function SearchBox({ onSearch }) {
    const input = useRef();
    const [searchBoxValue, setSearchBoxValue] = useState("");

    function searchHandler(evt) {
        evt.preventDefault();
        setSearchBoxValue(searchBoxValue);
        onSearch(searchBoxValue);
    }

    function clearHandler() {
        setSearchBoxValue("");
        onSearch("");
    }

    return (
        <form
            className="AppEmail-inbox-search-bar"
            action="#"
            onSubmit={searchHandler}
        >
            <input
                type="search"
                ref={input}
                className="AppEmail-inbox-search-bar-input data-hj-allow"
                placeholder="Search"
                value={searchBoxValue}
                onChange={(evt) => setSearchBoxValue(evt.target.value)}
            />
            <div
                className={classnames(
                    "AppEmail-inbox-search-bar-clear icon-cancel",
                    { visible: searchBoxValue !== "" }
                )}
                onClick={clearHandler}
            />
        </form>
    );
}

const InboxThread = observer(({ thread, appUrl }) => {
    const State = useAppStore();
    const { emails, subject, contacts } = thread;
    const lastEmail = last(thread.emails) || { body: "" };

    const contactsStr =
        contacts ||
        emails
            .map((x) => x.from.name || "")
            .filter((x) => !!x)
            .filter(onlyUnique)
            .map((x) => x.split(" ")[0])
            .join(", ");

    return (
        <Link
            className="AppEmail-inbox-email-link"
            key={thread.id}
            to={urljoin(appUrl, "thread", thread.id.toString())}
        >
            <div className="AppEmail-inbox-email">
                <div className="AppEmail-inbox-email-date">
                    {emailShortDate(lastEmail, State.visibleThreads[thread.id])}
                    <i className="AppEmail-inbox-email-open-icon icon-right-open-big" />
                </div>
                <div className="AppEmail-inbox-email-from">
                    {contactsStr}
                    {emails.length > 1 ? (
                        <span className="AppEmail-inbox-email-count">
                            {emails.length}
                        </span>
                    ) : null}
                </div>
                <div className="AppEmail-inbox-email-subject">{subject}</div>
                <MarkdownContent
                    className="AppEmail-inbox-email-body"
                    markdown={limitString(lastEmail.body.split("\n")[0], 100)}
                />
            </div>
        </Link>
    );
});

const EmailThread = ({ threads, appUrl }) => {
    const { threadId } = useParams();
    const thread = find(threads, (x) => x.id === threadId);

    return (
        <div className="AppEmail-thread">
            <div className="AppEmail-thread-top-bar">
                <Link className="AppEmail-thread-back-button" to={appUrl}>
                    <i className="icon-left-open-big" />
                </Link>
            </div>
            <div className="AppEmail-thread-content">
                <div className="AppEmail-thread-subject">{thread.subject}</div>
                <div className="AppEmail-thread-emails">
                    {thread.emails.map((email, emailId) => (
                        <ThreadEmail
                            key={emailId}
                            threadId={threadId}
                            emailId={emailId}
                            email={email}
                            appUrl={appUrl}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

const ThreadEmail = observer(({ email, threadId, emailId, appUrl }) => {
    const State = useAppStore();
    const [showDetails, toggleDetails] = useState(false);

    return (
        <div className="AppEmail-email">
            <div className="AppEmail-email-date">
                {emailShortDate(email, State.visibleThreads[threadId])}
            </div>
            <div className="AppEmail-email-from-name">
                {email.from.name || email.from.email}
            </div>
            <div className="AppEmail-email-to">
                {"To: "}
                {email.to.map((x) => x.name || x.email).join(", ")}
                <span
                    className={classnames(
                        "AppEmail-email-details-button icon-angle-down",
                        { open: showDetails }
                    )}
                    onClick={() => toggleDetails(!showDetails)}
                />
            </div>
            <EmailDetails email={email} visible={showDetails} />
            <MarkdownContent
                className="AppEmail-email-body"
                markdown={email.body}
            />
            {(email.attachments || []).map((attch, attchId) => (
                <Link
                    className="AppEmail-email-attachment-link"
                    key={attchId}
                    to={urljoin(
                        appUrl,
                        "thread",
                        threadId.toString(),
                        "attachment",
                        emailId.toString(),
                        attchId.toString()
                    )}
                >
                    <div className="AppEmail-email-attachment">
                        <div className="AppEmail-email-attachment-type">
                            {attch.type}
                        </div>
                        <div className="AppEmail-email-attachment-details">
                            <div className="AppEmail-email-attachment-name">
                                {attch.name}
                            </div>
                            <div className="AppEmail-email-attachment-size">
                                {attch.size}
                            </div>
                        </div>
                        <div className="AppEmail-email-attachment-download icon-download-alt" />
                    </div>
                </Link>
            ))}
        </div>
    );
});

const EmailDetails = observer(({ email, threadId, visible }) => {
    const State = useAppStore();

    return (
        <div
            className={classnames("AppEmail-email-details", {
                visible,
            })}
        >
            <EmailDetailsItem
                title="From"
                value={`${email.from.name} <${email.from.email}>`}
            />
            <EmailDetailsItem
                title="To"
                value={email.to.map((x) => `${x.name} <${x.email}>`).join(", ")}
            />
            <EmailDetailsItem
                title="Date"
                value={emailLongDate(email, State.visibleThreads[threadId])}
            />
        </div>
    );
});

function EmailDetailsItem({ title, value }) {
    return (
        <>
            <div className="AppEmail-email-details-item-title">{title}</div>
            <div className="AppEmail-email-details-item-value">{value}</div>
        </>
    );
}

const Attachment = observer(({ threads, appUrl }) => {
    const { threadId, emailId, attchId } = useParams();
    const State = useAppStore();

    const email = threads[threadId].emails[emailId];
    const attch = email.attachments[attchId];

    const locked = !!attch.password && !State.unlockedAttachments[attch.id];
    const downloading = !State.downloadedAttachments[attch.id];

    const [password, setPassword] = useState("");
    const [wrongPassword, setWrongPassword] = useState(false);

    useEffect(() => {
        if (downloading) {
            const timer = setTimeout(
                () => State.downloadAttachment(attch.id),
                2000
            );
            return () => clearTimeout(timer);
        }

        if (wrongPassword) {
            const timer = setTimeout(() => setWrongPassword(false), 300);
            return () => clearTimeout(timer);
        }

        return undefined;
    }, [State, attch.id, downloading, wrongPassword]);

    function onPasswordChange(newPassword) {
        setPassword(newPassword);
    }

    function onPasswordSubmit() {
        if (stringsInclude(attch.password, password)) {
            State.unlockAttachment(attch.id);
            if (attch.unlockEvent) {
                AppState.fireEvent(attch.unlockEvent, {
                    delayMs: attch.unlockEventDelay,
                });
            }
        } else {
            setWrongPassword(true);
        }
    }

    return (
        <div className="AppEmail-thread">
            <div className="AppEmail-thread-top-bar">
                <Link
                    className="AppEmail-thread-back-button"
                    to={urljoin(appUrl, "thread", threadId.toString())}
                >
                    <i className="icon-left-open-big" />
                </Link>
            </div>
            <AttachmentContent
                {...{
                    attch,
                    downloading,
                    locked,
                    password,
                    wrongPassword,
                    onPasswordChange,
                    onPasswordSubmit,
                }}
            />
        </div>
    );
});

function AttachmentContent({
    attch,
    downloading,
    locked,
    password,
    wrongPassword,
    onPasswordChange,
    onPasswordSubmit,
}) {
    if (downloading) {
        return (
            <div className="AppEmail-attachment-downloading">
                {"Downloading "}
                <b>{`${attch.name}.${attch.type}`}</b>
                {"..."}
            </div>
        );
    }

    if (locked) {
        return (
            <div className="AppEmail-attachment-password-protector">
                <div className="AppEmail-attachment-password-text">
                    {"This document is password protected."}
                </div>
                <PasswordInput
                    className={classnames(
                        "AppEmail-attachment-password-textbox",
                        { error: wrongPassword }
                    )}
                    formClassName="AppEmail-attachment-password-textbox-form"
                    value={password}
                    onChange={(evt) => onPasswordChange(evt.target.value)}
                    placeholder="Enter password"
                />
                <div
                    className="AppEmail-attachment-password-submit"
                    onClick={onPasswordSubmit}
                >
                    {"Unlock"}
                </div>
            </div>
        );
    }
    return <AttachmentImage attachment={attch} />;
}

function AttachmentImage({ attachment }) {
    return (
        <div className="AppEmail-attachment-image-wrapper">
            <ZoomableImage
                src={attachment.image}
                className="AppEmail-attachment-image"
                resetOnDoubleClick
                noCenter
                limitToBounds={false}
            />
        </div>
    );
}

function emailShortDate(email, threadDate) {
    return (
        email.dateShort || formatDate(threadDate || email.date, "MMMM D, YYYY")
    );
}

function emailLongDate(email, threadDate) {
    return (
        email.dateLong ||
        formatDate(threadDate || email.date, "MMMM D, YYYY h:mm A")
    );
}

export function searchThreads(threads, q) {
    // Note: an arrow function won't work here, as we need lunr's "this"
    // eslint-disable-next-line
    const idx = lunr(function () {
        this.ref("id");
        this.field("subject");
        this.field("body");
        this.field("fromName");
        this.field("fromEmail");

        forEach(threads, (thread, threadId) => {
            forEach(thread.emails, (email, emailId) => {
                this.add({
                    id: `${threadId}|${emailId}`,
                    subject: thread.subject,
                    body: email.body,
                    fromName: email.from.name,
                    fromEmail: email.from.email,
                });
            });
        });
    });

    let results = [];
    try {
        results = idx.search(escapeLunrQueryString(q));
    } catch (err) {
        reportException(err);
    }

    const threadIds = [];
    forEach(results, (result) => {
        const threadId = result.ref.split("|")[0];
        if (!threadIds.includes(threadId)) {
            threadIds.push(threadId);
        }
    });

    return threadIds.map((threadId) => threads[threadId]);
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

import "./AppFolder.css";
import { APP_ICONS } from "./common/AppIcons";
import MarkdownContent from "../../utils/MarkdownContent";
import ZoomableImage from "components/common/ZoomableImage";
import Image from "components/common/Image";
import EmojiText from "../../utils/EmojiText";

import IconDocument from "../../../images/icons/file-document.png";
import IconPhoto from "../../../images/icons/file-photo.png";

import React from "react";
import { Switch, Route, Link, Redirect, useParams } from "react-router-dom";
import urljoin from "url-join";
import classnames from "classnames";

const FILE_ICONS = {
    document: IconDocument,
    photo: IconPhoto,
};

export default function AppFolder({
    items,
    folderName,
    bgImage,
    layout,
    appUrl,
    appPath,
    rootUrl,
}) {
    return (
        <div
            className={classnames("AppFolder", { bgImage: !!bgImage })}
            style={{ backgroundImage: bgImage ? `url(${bgImage})` : null }}
        >
            <Switch>
                <Route exact path={appPath}>
                    <Folder
                        title={folderName}
                        items={items}
                        layout={layout}
                        appUrl={appUrl}
                        rootUrl={rootUrl}
                    />
                </Route>
                <Route exact path={urljoin(appPath, ":itemId")}>
                    <ItemContent appUrl={appUrl} items={items} />
                </Route>
                <Route>
                    <Redirect to={appUrl} />
                </Route>
            </Switch>
        </div>
    );
}
AppFolder.defaultProps = {
    layout: "grid", // supported: grid, list
};

function Folder({ title, items, layout, appUrl, rootUrl }) {
    layout = layout || "grid";

    return (
        <div className="AppFolder-folder">
            <div className="AppFolder-folder-title">{title}</div>
            <div className={classnames("AppFolder-folder-items", layout)}>
                {(items || []).map((item, i) => (
                    <FolderItem
                        key={i}
                        item={item}
                        layout={layout}
                        itemIndex={i}
                        appUrl={appUrl}
                        rootUrl={rootUrl}
                    />
                ))}
            </div>
        </div>
    );
}

function FolderItem({ item, itemIndex, layout, appUrl, rootUrl }) {
    const linkTo =
        item.type === "app"
            ? urljoin(rootUrl, item.appName)
            : urljoin(appUrl, itemIndex.toString());

    const icon =
        item.iconUrl ||
        (item.type === "app"
            ? APP_ICONS[item.icon || item.appName]
            : FILE_ICONS[item.icon]);

    return (
        <Link to={linkTo}>
            <div className={classnames("AppFolder-folder-item", layout)}>
                <div className="AppFolder-folder-item-icon-wrapper">
                    <Image src={icon} className="AppFolder-folder-item-icon" />
                </div>
                <div className="AppFolder-folder-item-name">
                    <EmojiText text={item.title} />
                </div>
            </div>
        </Link>
    );
}

function ItemContent({ appUrl, items }) {
    const { itemId } = useParams();
    const item = items[itemId];
    const { title, image, text } = item;

    return (
        <div className="AppFolder-file">
            <div className="AppFolder-file-top-bar">
                <Link className="AppFolder-file-back-button" to={appUrl}>
                    <i className="icon-left-open-big" />
                </Link>
                <div className="AppFolder-file-top-bar-title">
                    <EmojiText text={title} />
                </div>
            </div>
            {image ? (
                <ZoomableImage
                    src={image}
                    className="AppFolder-file-image"
                    showSpinner
                />
            ) : text ? (
                <MarkdownContent
                    markdown={text}
                    className="AppFolder-file-text"
                />
            ) : null}
        </div>
    );
}

import styles from "./PhoneBrokenScreen.module.css";
// import ImageBackground1 from "../../images/broken-screen-1.png";
import ImageBackground2 from "../../images/broken-screen-2.png";
import ImageBackground3 from "../../images/broken-screen-3.png";

import { HAVE_IOS } from "@faintlines/native-bridge";

import React from "react";

const BACKGROUND_IMAGES = {
    // 1: ImageBackground1,
    2: ImageBackground2,
    3: ImageBackground3,
};

export default function PhoneBrokenScreen({ type }) {
    // Apple review guidelines do not allow a cracked screen.
    if (HAVE_IOS || !type) {
        return null;
    }

    const bgImg = BACKGROUND_IMAGES[type];
    return (
        <div
            className={styles.brokenScreen}
            style={{
                backgroundImage: `url(${bgImg})`,
            }}
        />
    );
}

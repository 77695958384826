import ErrorBoundary from "components/utils/ErrorBoundary";

import ReactConfetti from "react-confetti";
import React from "react";

// A wrapper for react-confetti with an ErrorBoundry

export default function Confetti(props) {
    return (
        <ErrorBoundary>
            <ReactConfetti {...props} />
        </ErrorBoundary>
    );
}

import styles from "./WordleLocker.module.css";
import { usePhoneSessionStorage } from "state/stateHooks";
import { randomChoice } from "utils/randomUtils";

import { stringsInclude } from "@faintlines/string-utils";

import React from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";
import { range } from "lodash";

const LENGTH = 5;
const TRIES = 6;

const WordleLocker = observer(({ password, appName, onUnlock }) => {
    const [answer, setAnswer] = usePhoneSessionStorage(
        `wordle__${appName}__word`,
        randomWord()
    );
    const [value, setValue] = usePhoneSessionStorage(
        `wordle__${appName}__value`,
        ""
    );
    const [history, setHistory] = usePhoneSessionStorage(
        `wordle__${appName}__history`,
        []
    );

    const [correct, present, disabled] = summary(history, answer);

    const success = history.includes(answer);
    const finished = success || history.length === TRIES;

    function add(letter) {
        if (value.length < LENGTH) {
            const newValue = value + letter;
            setValue(newValue);

            if (
                onUnlock &&
                stringsInclude(password, newValue, { allowTypos: false })
            ) {
                onUnlock();
            }
        }
    }

    const handleClear = () => {
        if (value.length > 0) {
            setValue(value.slice(0, value.length - 1));
        }
    };

    const handleEnter = () => {
        if (value.length !== LENGTH) {
            return;
        }

        if (history.length < TRIES) {
            setHistory([...history, value]);
            setValue("");
        }
    };

    const handleRestart = () => {
        setAnswer(randomWord());
        setValue("");
        setHistory([]);
    };

    const getWord = (index) => {
        if (index === history.length) {
            return value;
        }
        if (index < history.length) {
            return history[index];
        }
        return "";
    };

    const CharKey = ({ ch }) => (
        <Key
            label={ch}
            correct={correct[ch]}
            present={present[ch]}
            disabled={disabled[ch]}
            onClick={() => add(ch)}
        />
    );

    return (
        <div className={styles.wordle}>
            <div className={styles.title}>{"Wordle"}</div>
            <div className={styles.words}>
                {range(TRIES).map((i) => (
                    <Word
                        key={i}
                        word={getWord(i)}
                        answer={i < history.length ? answer : null}
                    />
                ))}
            </div>
            <div className={styles.keyboard}>
                <div className={styles.keyboard__row}>
                    {["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"].map(
                        (ch) => (
                            <CharKey key={ch} ch={ch} />
                        )
                    )}
                </div>

                <div className={styles.keyboard__row}>
                    {["A", "S", "D", "F", "G", "H", "J", "K", "L"].map((ch) => (
                        <CharKey key={ch} ch={ch} />
                    ))}
                </div>

                <div className={styles.keyboard__row}>
                    <Key
                        label="ENTER"
                        className={styles.wide}
                        onClick={handleEnter}
                    />
                    {["Z", "X", "C", "V", "B", "N", "M"].map((ch) => (
                        <CharKey key={ch} ch={ch} />
                    ))}
                    <Key
                        label="⌫"
                        className={styles.wide}
                        onClick={handleClear}
                    />
                </div>
            </div>
            {finished ? (
                <div
                    className={classnames(
                        styles.finished,
                        success ? styles.success : null
                    )}
                >
                    <div
                        className={classnames(
                            styles.finished__title,
                            success ? styles.success : styles.failed
                        )}
                    >
                        {success ? "Success!!" : "Game Over"}
                    </div>
                    <div
                        className={styles.finished__restart}
                        onClick={handleRestart}
                    >
                        {"Start Over"}
                    </div>
                </div>
            ) : null}
        </div>
    );
});

export default WordleLocker;

const Word = ({ word, answer }) => (
    <div className={styles.word}>
        {range(LENGTH).map((i) => (
            <div
                key={i}
                className={classnames(
                    styles.word__letter,
                    answer ? styles.done : null,
                    answer && word[i] === answer[i] ? styles.correct : null,
                    answer && answer.includes(word[i]) ? styles.present : null
                )}
            >
                {word[i] || ""}
            </div>
        ))}
    </div>
);

function Key({ label, correct, present, disabled, className, onClick }) {
    return (
        <div
            className={classnames(
                styles.keyboard__key,
                className,
                disabled ? styles.disabled : null,
                present ? styles.present : null,
                correct ? styles.correct : null
            )}
            onClick={onClick}
        >
            {label}
        </div>
    );
}

function summary(history, answer) {
    const correct = {};
    const present = {};
    const disabled = {};

    history.forEach((word) => {
        for (let i = 0; i < LENGTH; i += 1) {
            if (word[i] === answer[i]) {
                correct[answer[i]] = true;
                disabled[answer[i]] = false;
            } else if (word.includes(answer[i])) {
                present[answer[i]] = true;
                disabled[answer[i]] = false;
            } else {
                disabled[word[i]] = true;
            }
        }
    });

    return [correct, present, disabled];
}

function randomWord() {
    return randomChoice([
        "wisps",
        "scart",
        "aleye",
        "swede",
        "opine",
        "furth",
        "merch",
        "preen",
        "tetri",
        "zigan",
        "heath",
        "morse",
        "puree",
        "roach",
        "medic",
        "lyard",
        "yeads",
        "gaols",
        "strae",
        "moten",
        "wicky",
        "hello",
        "sadza",
        "gulph",
        "cadis",
        "saucy",
        "where",
        "sooey",
        "sophy",
        "ronts",
        "largo",
        "deary",
        "anted",
        "mardy",
        "fumer",
        "riant",
        "gauje",
        "cunts",
        "arbor",
        "treks",
        "mneme",
        "erode",
        "warre",
        "fifed",
        "fusts",
        "ghast",
        "canes",
        "forth",
        "fritt",
        "witan",
        "wicks",
        "demur",
        "noxal",
        "gyeld",
        "conge",
        "absit",
        "asura",
        "debur",
        "tenny",
        "ranks",
        "court",
        "mends",
        "boygs",
        "slags",
        "worst",
        "jaggy",
        "noses",
        "mulct",
        "kohen",
        "mozos",
        "bekah",
        "voila",
        "argue",
        "pules",
        "kedge",
        "skive",
        "shoyu",
        "inker",
        "renal",
        "jobes",
        "waler",
        "teugh",
        "reiki",
        "jelab",
        "carns",
        "mossy",
        "watch",
        "pulks",
        "fibro",
        "azido",
        "batts",
        "chaos",
        "byrls",
        "hippy",
        "skyfs",
        "debye",
        "loups",
        "quino",
        "zorro",
        "rifer",
    ]).toUpperCase();
}

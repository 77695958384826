import TimePicker from "./TimePicker";
import RelativeDateSelector from "./RelativeDateSelector";
import { RelativeDateTime, relativeDate } from "utils/dateUtils";

import React from "react";

function RelativeDateTimeSelector({
    formData,
    onChange,
    daysWeeksOnly,
    daysOnly,
}) {
    const { amount, units, time } = formData || {};

    const handleTimeChange = (newTime) => {
        onChange(new RelativeDateTime(relativeDate(amount, units), newTime));
    };

    const handleDateChange = (newDate) => {
        onChange(new RelativeDateTime(newDate, time));
    };

    return (
        <div>
            <RelativeDateSelector
                formData={{ amount, units }}
                onChange={handleDateChange}
                schema={{ title: "Start date" }}
                daysWeeksOnly={daysWeeksOnly}
                daysOnly={daysOnly}
            />
            <TimePicker value={time} onChange={handleTimeChange} label="Time" />
        </div>
    );
}

export const RelativeDateTimeDaysSelector = ({ ...props }) => (
    <RelativeDateTimeSelector {...props} daysOnly />
);

export const RelativeDateTimeWeeksSelector = ({ ...props }) => (
    <RelativeDateTimeSelector {...props} daysWeeksOnly />
);

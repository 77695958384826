import styles from "./SideMenu.module.css";
import ExternalLink from "../utils/ExternalLink";

import { HAVE_IOS } from "@faintlines/native-bridge";

import React, { useState, useCallback, useEffect } from "react";
import classnames from "classnames";
import { useMeasure } from "react-use";

export function SideMenu({ children, smallHeight }) {
    const [menuRef, { height }] = useMeasure();
    const isSmall = height && smallHeight && height < smallHeight;

    return (
        <div
            ref={menuRef}
            className={classnames(styles.menu, isSmall ? styles.small : null)}
        >
            {children}
        </div>
    );
}

export function SideMenuItems({ bottom, children }) {
    return (
        <div
            className={classnames(styles.items, bottom ? styles.bottom : null)}
        >
            {children}
        </div>
    );
}

export function SideMenuItem({
    title,
    icon,
    image,
    disabled,
    className,
    href,
    newTag,
    onClick,
}) {
    const props = {
        className: classnames(
            styles.item,
            disabled ? styles.disabled : null,
            className
        ),
        onClick,
    };

    const children = (
        <>
            <Icon icon={icon} image={image} />
            <div className={styles.item__title}>{title}</div>
            {newTag ? <NewTag /> : null}
        </>
    );

    if (href) {
        return (
            <ExternalLink href={href} {...props}>
                {children}
            </ExternalLink>
        );
    }

    return <div {...props}>{children}</div>;
}

export function useHashMenu(menuHash) {
    // always start closed
    const [isOpen, setIsOpen] = useState(false);

    if (HAVE_IOS) {
        // On iOS the app is served from local files, so a hash doesn't work very well.
        // On the other hand, we don't have a back button, so useState alone is fine.
        return [isOpen, setIsOpen];
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const onHashChange = useCallback(() => {
        setIsOpen(window.location.hash === menuHash);
    }, [menuHash]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const toggleCallback = useCallback(
        (toggle) => {
            if (isOpen !== toggle) {
                const newMenuHash = toggle ? menuHash : "";
                if (window.location.hash !== newMenuHash) {
                    window.location.hash = toggle ? menuHash : "";
                } else {
                    // In rare cases, there's a mismatch between the menu hash and the internal state
                    // that prevents the player from closing the menu. To overcome this, update the
                    // internal state here.
                    setIsOpen(toggle);
                }
            }
        },
        [menuHash, isOpen]
    );

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        window.addEventListener("hashchange", onHashChange);
        return () => window.removeEventListener("hashchange", onHashChange);
    });

    return [isOpen, toggleCallback];
}

function Icon({ icon, image }) {
    if (typeof icon === "string") {
        return <i className={classnames(styles.item__icon, `icon-${icon}`)} />;
    }
    if (typeof icon === "object" || typeof icon === "function") {
        const IconComp = icon;
        return (
            <IconComp
                className={classnames(styles.item__icon, styles.svg)}
                size="1.1em"
            />
        );
    }
    if (image) {
        return (
            <img
                className={classnames(styles.item__icon, styles.item__image)}
                src={image}
                alt=""
            />
        );
    }
    return null;
}

const NewTag = () => <div className={styles.item__newTag}>{"NEW"}</div>;

import StudioSchemaForm from "../StudioSchemaForm";
import { useStudioStory } from "../StudioContext";
import CONTACTS_SCHEMA, { CONTACTS_UI_SCHEMA } from "../schemas/contactsSchema";
import EditorTab from "../common/EditorTab";

import { observer } from "mobx-react-lite";
import React from "react";

const ContactsEditor = observer(({ onStoryUpdate }) => {
    const story = useStudioStory();

    return (
        <EditorTab scrollable>
            <StudioSchemaForm
                schema={CONTACTS_SCHEMA}
                uiSchema={CONTACTS_UI_SCHEMA}
                data={story.contacts}
                onChange={(formData) =>
                    onStoryUpdate({ contacts: { $set: formData } })
                }
            />
        </EditorTab>
    );
});

export default ContactsEditor;

import styles from "./ChatMessage.module.css";
import ChatAudioPlayer from "./ChatAudioPlayer";
import Image from "../../../common/Image";
import MarkdownContent from "components/utils/MarkdownContent";
import EmojiText from "components/utils/EmojiText";
import { isEmoji } from "utils/stringUtils";
import { formatTime } from "utils/timeUtils";

import React from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";

function ChatMessage({
    message,
    onImageClick,
    previousMessage,
    onImagesLoaded,
}) {
    const { direction, text, time, type, image, status } = message;
    const extraSpace = shouldExtraSpace(message, previousMessage);

    if (type === "system") {
        return <SystemMessage message={message} extraSpace={extraSpace} />;
    }
    if (type === "audio") {
        return <AudioMessage message={message} extraSpace={extraSpace} />;
    }

    const emoji = text && isEmoji(text);
    const deleted = type === "deleted";

    // TODO: consider enabling markdown for outgoing messages. Need to ensure it is safe.
    const allowMarkdown = direction === "in";

    return (
        <div
            className={classnames(
                styles.message,
                extraSpace ? styles.extraSpace : null,
                status ? styles[status] : null,
                styles[direction]
            )}
        >
            {image ? (
                <Image
                    className={styles.image}
                    src={image}
                    showSpinner
                    onLoad={onImagesLoaded}
                    onClick={() => onImageClick(image, text)}
                />
            ) : null}
            <div
                className={classnames(
                    styles.text,
                    emoji ? styles.emoji : null,
                    deleted ? styles.deleted : null
                )}
            >
                {deleted ? (
                    "You deleted this message"
                ) : allowMarkdown ? (
                    <MarkdownContent
                        markdown={text}
                        className={styles.text__contents}
                    />
                ) : (
                    <EmojiText text={text} />
                )}
                <span className={styles.text__spacer} />
            </div>
            <StatusLine direction={direction} time={time} status={status} />
            {status === "error" ? (
                <div className={styles.notDelivered}>{"Not delivered"}</div>
            ) : null}
        </div>
    );
}

export default observer(ChatMessage);

function StatusLine({ direction, time, status }) {
    const icon =
        status === "pending"
            ? "clock"
            : status === "error"
            ? "warning"
            : "check";

    return (
        <div className={styles.time}>
            {formatTime(time)}
            {direction === "out" ? (
                <span
                    className={classnames(
                        styles.status__icon,
                        status ? styles[status] : null,
                        `icon-${icon}`
                    )}
                />
            ) : null}
        </div>
    );
}

function AudioMessage({ message, extraSpace }) {
    const { direction, time, status, audio } = message;

    return (
        <div
            className={classnames(
                styles.message,
                extraSpace ? styles.extraSpace : null,
                styles.audio,
                status ? styles[status] : null,
                styles[direction]
            )}
        >
            <ChatAudioPlayer src={audio} />
            <StatusLine direction={direction} time={time} status={status} />
        </div>
    );
}

function SystemMessage({ message, extraSpace }) {
    return (
        <div
            className={classnames(
                styles.message,
                styles.system,
                extraSpace ? styles.extraSpace : null
            )}
        >
            {message.text}
        </div>
    );
}

function shouldExtraSpace(message, previousMessage) {
    if (!previousMessage) {
        return true;
    }

    if (
        message.type === "system" ||
        message.direction !== previousMessage.direction
    ) {
        return true;
    }

    return false;
}

import * as Server from "../server/api";
// import LiveChat from "../server/LiveChat";
import { decodeStory } from "../stories";
import { markdownToText } from "../utils/markdownUtils";
import AudioMessageIn from "../audio/message-in.mp3";
import { pickToJs } from "utils/mobxUtils";
import { reportException } from "utils/errorReporting";

import { makeAutoObservable, set, runInAction } from "mobx";
import dayjs from "dayjs";
import { Howl } from "howler";
import { pick, reverse, sortBy, last, remove, find } from "lodash";
import { v4 as uuid } from "uuid";

const SEND_RETRIES = 2;

export default class ChatAppState {
    constructor({ storyId, existingState, app, mainState }) {
        makeAutoObservable(this);

        this._storyId = storyId;
        this._mainState = mainState;
        this._app = app;
        // this._liveChats = {};

        this._ticker = setInterval(() => this._tick(), 100);
        this._messagesInTransit = {};

        this._soundMessageIn = new Howl({
            src: [app.data.notifSoundUrl || AudioMessageIn],
        });

        this.typeCharacterEventName = `chat_type_message_${app.name}`;
        this._typeCharacterEvent = new Event(this.typeCharacterEventName);

        if (ChatAppState.hasProgress(existingState)) {
            set(this, existingState);
            // this.initLiveChats();
        } else {
            this.init();
        }
    }

    dispose() {
        if (this._ticker) {
            clearInterval(this._ticker);
        }
        // values(this._liveChats).forEach((x) => x.disconnect());
    }

    isAppActive = false;
    chats = null;
    activeChatId = null;
    pendingMessages = {};
    chatStates = {};
    _pendingActions = [];
    _outgoingMessageQueue = [];
    isAudioPlaying = false;

    static hasProgress(state) {
        if (!state || !state.chats) {
            return false;
        }
        // eslint-disable-next-line no-underscore-dangle
        if (state._pendingActions?.length > 0) {
            return true;
        }
        // eslint-disable-next-line no-underscore-dangle
        if (state._outgoingMessageQueue?.length > 0) {
            return true;
        }

        for (let i = 0; i < state.chats.length; i += 1) {
            const chat = state.chats[i];
            if (typeof chat.hasProgress === "boolean") {
                if (chat.hasProgress) {
                    return true;
                }
            } else if (chat.messages && chat.messages.length > 0) {
                // Note: this case causes a bug in case initialMessages exists.
                // TODO: remove this case after chat.hasProgress exist for a while.
                return true;
            }
        }

        return false;
    }

    get asJson() {
        return pickToJs(this, [
            "chats",
            "chatStates",
            "_pendingActions",
            "_outgoingMessageQueue",
        ]);
    }

    get sortedChatList() {
        const chats = (this.chats || []).filter(
            (x) => x.showEmpty || x.messages.length > 0
        );
        return reverse(sortBy(chats, (x) => last(x.messages)?.time));
    }

    get hasActiveChat() {
        return this.activeChatId !== null;
    }

    get activeChat() {
        if (this.activeChatId === null) {
            return null;
        }
        return this.getChatById(this.activeChatId);
    }

    get activePendingMessage() {
        if (this.activeChatId === null) {
            return "";
        }
        return this.pendingMessages[this.activeChatId] || "";
    }

    get canSendMessage() {
        return (
            !!this.activePendingMessage &&
            this.activePendingMessage.trim() !== ""
        );
    }

    get notificationCounter() {
        return (this.chats || []).reduce(
            (sum, chat) => sum + chat.unreadCount,
            0
        );
    }

    init() {
        Server.chatAppGetInitialChats(this._storyId, this._app.name).then(
            ({ data }) => {
                this.handleInitResponse(data);
            }
        );
        // TODO: handle errors (.catch)
    }

    // initLiveChats() {
    //     const chats = this.chats || [];
    //     chats
    //         .filter((chat) => chat.live)
    //         .forEach((chat) => {
    //             const context = `Story: ${this._storyId}, Chat: ${chat.id}`;
    //             this._liveChats[chat.id] = new LiveChat({
    //                 groupId: chat.liveSettings?.groupId || 0,
    //                 initialMessages: [{ id: uuid(), text: context }],
    //                 onMessage: (message) =>
    //                     this._handleLiveChatMessage(chat.id, message),
    //                 onToggleTyping: (isTyping) =>
    //                     this.executeAction(chat.id, {
    //                         type: "toggleTyping",
    //                         isTyping,
    //                     }),
    //             });
    //         });
    // }

    handleInitResponse({ chats }) {
        chats = decodeStory(chats);
        this.chats = chats.map(this.chatDataToChatState);
        chats.forEach((chat) =>
            this.processActions({
                chatId: chat.id,
                nextStateId: "start",
                actions: chat.actions,
            })
        );

        // this.initLiveChats();
    }

    chatDataToChatState(chat) {
        return {
            messages: chat.initialMessages || [],
            hasProgress: false,
            typing: false,
            connected: false,
            unreadCount: 0,
            ...pick(chat, [
                "id",
                "title",
                "picture",
                "live",
                "liveSettings",
                "showEmpty",
            ]),
        };
    }

    toggleIsAppActive(isAppActive) {
        this.isAppActive = isAppActive;
    }

    setActiveChat(chatId) {
        const chat = this.getChatById(chatId);
        if (chat) {
            this.activeChatId = chatId;
            chat.unreadCount = 0;
        } else {
            this.activeChatId = null;
        }
    }

    unsetActiveChat() {
        this.activeChatId = null;
    }

    setActivePendingMessage(message) {
        this.pendingMessages[this.activeChatId] = message;
    }

    sendMessage() {
        if (!this.canSendMessage) {
            return;
        }

        this.sendMessageForChat(this.activeChat.id);
    }

    sendMessageForChat(chatId) {
        const messageText = (this.pendingMessages[chatId] || "").trim();
        if (messageText === "") {
            return;
        }

        const message = {
            id: uuid(),
            type: "text",
            direction: "out",
            time: dayjs(),
            text: messageText,
            status: "pending",
        };
        this.pushMessage(chatId, message);

        this.enqueueOutgoingMessage({
            chatId,
            id: message.id,
            messageText,
        });
        this.pendingMessages[chatId] = "";
        this._expireActionsByType(chatId, "message");
    }

    sendHiddenMessage(chatId, messageText) {
        this.enqueueOutgoingMessage({
            chatId,
            stateId: this.chatStates[chatId],
            messageText,
        });
    }

    typeAndSendOutgoingMessage(chatId, messageText, typingDelay = 300) {
        this.pendingMessages[chatId] = "";
        for (let i = 0; i < messageText.length; i += 1) {
            const nextChar = messageText.charAt(i);
            setTimeout(
                () => this.typeCharacter(chatId, nextChar),
                typingDelay * i
            );
        }
        setTimeout(
            () => this.sendMessageForChat(chatId),
            typingDelay * (messageText.length + 1)
        );
    }

    sendOutgoingImageMessage(chatId, imageUrl) {
        this.pushMessage(chatId, {
            id: uuid(),
            type: "text",
            direction: "out",
            time: dayjs(),
            image: imageUrl,
            status: "check",
        });
    }

    typeCharacter(chatId, char) {
        this.pendingMessages[chatId] =
            this.pendingMessages[chatId].concat(char);
        window.dispatchEvent(this._typeCharacterEvent);
    }

    toggleAudioPlaying(toggle) {
        this.isAudioPlaying = toggle;
    }

    enqueueOutgoingMessage({ chatId, id, messageText }) {
        this._outgoingMessageQueue.push({
            id: id || uuid(),
            storyId: this._storyId,
            appName: this._app.name,
            chatId,
            stateId: this.chatStates[chatId],
            messageText,
            retriesLeft: SEND_RETRIES,
        });
        this._purgeOutgoingMessagesQueue();
    }

    processActions({ chatId, nextStateId, actions }) {
        if (nextStateId) {
            this._setState(chatId, nextStateId);
        }
        this.enqueueActions(chatId, actions);
    }

    enqueueActions(chatId, actions) {
        actions.forEach((action) => {
            this._pendingActions.push({
                time: new Date().getTime() + action.delay * 1000,
                chatId,
                action,
            });
        });
    }

    executeReadyActions() {
        const now = new Date().getTime();
        const remainingActions = [];
        this._pendingActions.forEach((action) => {
            if (action.time > now || !this.getChatById(action.chatId)) {
                remainingActions.push(action);
                return;
            }

            this.executeAction(action.chatId, action.action);
        });
        this._pendingActions = remainingActions;
    }

    executeAction(chatId, action) {
        switch (action.type) {
            case "setState":
                this._setState(chatId, action.state);
                break;
            case "toggleConnected":
                this.getChatById(chatId).connected = action.isConnected;
                break;
            case "toggleTyping":
                this.getChatById(chatId).typing = action.isTyping;
                break;
            case "message":
                this.pushMessage(chatId, {
                    type: action.messageType,
                    direction: "in",
                    time: dayjs(),
                    text: action.messageText,
                    image: action.messageImgUrl,
                    audio: action.messageAudioUrl,
                });
                break;
            case "event":
                this._mainState.fireEvent(action.eventName);
                break;
            // TODO: add support
            // case "triggerChat":
            //  Server.startChat(action.chatId, action.stateId).then((resp) =>
            //      this.processActions(resp)
            //  );
            //  break;
            default:
                // console.error("unknown action type", action.type);
                break;
        }
    }

    _setState(chatId, nextStateId) {
        this.chatStates[chatId] = nextStateId;
    }

    pushMessage(chatId, message) {
        const chat = this.getChatById(chatId);
        if (!chat) {
            reportException(
                new Error(`pushMessage: no chat with id "${chatId}"`)
            );
            return;
        }

        chat.messages.push(message);
        chat.hasProgress = true;

        if (message.direction === "in") {
            if (this._soundMessageIn && !this.isAudioPlaying) {
                this._soundMessageIn.play();
            }

            if (chatId !== this.activeChatId) {
                chat.unreadCount += 1;

                this._pushNewMessageNotification(chat, message);
            }
        }
    }

    _expireActionsByType(chatId, actionType) {
        remove(
            this._pendingActions,
            (item) =>
                item.chatId === chatId && item.action.expireOn === actionType
        );
    }

    _purgeOutgoingMessagesQueue() {
        this._outgoingMessageQueue.forEach((message) => {
            if (this._messagesInTransit[message.id]) {
                return;
            }

            const chat = this.getChatById(message.chatId);
            if (!chat) {
                // TODO: debug why this happens
                return;
            }

            this._messagesInTransit[message.id] = true;

            // if (chat.live) {
            //     const promise = this._liveChats[chat.id].sendMessage(
            //         message.id,
            //         message.messageText
            //     );
            //     if (promise) {
            //         promise
            //             .then(() => {
            //                 this._dequeueOutgoingMessage(chat, message.id);
            //             })
            //             .finally(() => {
            //                 delete this._messagesInTransit[message.id];
            //             });
            //     } else {
            //         delete this._messagesInTransit[message.id];
            //         this._dequeueOutgoingMessage(chat, message.id);
            //     }
            //     return;
            // }

            Server.chatAppPostMessage(
                message.storyId,
                message.appName,
                message.chatId,
                message.stateId,
                message.messageText
            )
                .then(({ data }) => {
                    this._dequeueOutgoingMessage(chat, message.id);
                    runInAction(() => {
                        data.actions = decodeStory(data.actions);
                        this.processActions(data);
                    });
                })
                .catch(() => {
                    runInAction(() => {
                        message.retriesLeft -= 1;
                        if (message.retriesLeft <= 0) {
                            this._dequeueOutgoingMessage(
                                chat,
                                message.id,
                                "error"
                            );
                        }
                    });
                })
                .finally(() => {
                    delete this._messagesInTransit[message.id];
                });
        });
    }

    _dequeueOutgoingMessage(chat, messageId, status) {
        this._updateMessageStatus(chat, messageId, status);

        this._outgoingMessageQueue = this._outgoingMessageQueue.filter(
            (x) => x.id !== messageId
        );
    }

    // _handleLiveChatMessage(chatId, message) {
    //     const { id, text } = message;
    //     this.pushMessage(chatId, {
    //         id,
    //         type: "text",
    //         direction: "in",
    //         time: dayjs(),
    //         text,
    //     });
    // }

    /// / Private functions

    _pushNewMessageNotification(chat, message) {
        if (this.isAppActive) {
            return;
        }

        const messageText =
            message.type === "audio" ? "🎙️" : message.text || "📷";
        const text =
            this.notificationCounter > 1
                ? `${this.notificationCounter} unread messages`
                : `${chat.title}: ${messageText}`;

        this._mainState.pushNotification(this._app, "newMessage", {
            text: markdownToText(text),
        });
    }

    _updateMessageStatus(chat, messageId, newStatus) {
        if (!messageId) {
            return;
        }

        const message = find(chat.messages, (x) => x.id === messageId);
        if (!message) {
            return;
        }

        if (!newStatus) {
            delete message.status;
        } else {
            message.status = newStatus;
        }
    }

    _tick = () => {
        this.executeReadyActions();
        this._purgeOutgoingMessagesQueue();
    };

    getChatById(chatId) {
        return find(this.chats, (x) => x.id === chatId);
    }
}

import "./ChatList.css";
import EmojiText from "components/utils/EmojiText";
import { markdownToText } from "utils/markdownUtils";
import { formatTime } from "utils/timeUtils";

import { useAppStore } from "@faintlines/phone-sim-common";

import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import classnames from "classnames";
import urljoin from "url-join";
import { last } from "lodash";
import { Image as IconImage, Mic as IconMic } from "lucide-react";

function ChatList({ appTitle, appUrl }) {
    const State = useAppStore();
    const { sortedChatList, activeChatId } = State;

    return (
        <div className="ChatList">
            <div className="ChatList-title">{appTitle || "Chat App"}</div>
            {sortedChatList.length === 0 ? (
                <div className="ChatList-no-chats">{"No Chats"}</div>
            ) : null}
            {sortedChatList.map((chat) => (
                <Link key={chat.id} to={urljoin(appUrl, chat.id)}>
                    <ChatListItem
                        chat={chat}
                        active={chat.id === activeChatId}
                    />
                </Link>
            ))}
        </div>
    );
}

export default observer(ChatList);

const ChatListItem = observer(({ chat, active, onClick }) => {
    const lastMessage = last(chat.messages);

    return (
        <ChatListItemView
            picture={chat.picture}
            title={chat.title}
            time={lastMessage ? lastMessage.time : null}
            status={getStatus(chat, lastMessage)}
            typing={chat.typing}
            deleted={lastMessage && lastMessage.type === "deleted"}
            active={active}
            unreadCount={chat.unreadCount}
            onClick={onClick}
        />
    );
});

function getStatus(chat, lastMessage) {
    if (chat.typing) {
        return "Typing...";
    }
    if (!lastMessage) {
        return null;
    }
    if (lastMessage.type === "deleted") {
        return "You deleted this message";
    }
    if (lastMessage.type === "audio") {
        return (
            <div className="ChatListItem-status-audio">
                <IconMic className="ChatListItem-status-icon" size="1.2em" />
                {"Recording"}
            </div>
        );
    }
    if (lastMessage.image) {
        return (
            <div className="ChatListItem-status-picture">
                <IconImage className="ChatListItem-status-icon" size="1.2em" />
                {lastMessage.text ? (
                    <EmojiText text={markdownToText(lastMessage.text)} />
                ) : (
                    "Picture"
                )}
            </div>
        );
    }
    return <EmojiText text={markdownToText(lastMessage.text)} />;
}

const ChatListItemView = React.memo(
    ({
        picture,
        title,
        time,
        status,
        typing,
        deleted,
        active,
        unreadCount,
        onClick,
    }) => (
        <div
            className={classnames("ChatListItem", { active })}
            onClick={onClick}
        >
            {picture ? (
                <div
                    className="ChatListItem-picture"
                    style={{ backgroundImage: `url(${picture})` }}
                />
            ) : (
                <div className="ChatListItem-icon icon-user" />
            )}
            <div className="ChatListItem-details">
                <div className="ChatListItem-title">
                    {title}
                    {time ? (
                        <div className="ChatListItem-time">
                            {formatTime(time)}
                        </div>
                    ) : null}
                </div>
                <div
                    className={classnames("ChatListItem-status", {
                        typing,
                        deleted,
                    })}
                >
                    {status}
                    {unreadCount ? (
                        <div className="ChatListItem-unread">{unreadCount}</div>
                    ) : null}
                </div>
            </div>
        </div>
    )
);

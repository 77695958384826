import styles from "./PhoneScreen.module.css";

import Apps from "./Apps";
import PhoneHeader from "./PhoneHeader";
import PhoneFooter from "./PhoneFooter";
import HomeScreen from "./HomeScreen";
import PhoneBrokenScreen from "./PhoneBrokenScreen";
import FloatingNote from "./FloatingNote";
import LockScreen from "./LockScreen";
import VideoCall from "./VideoCall";
import ConfettiBlast from "./ConfettiBlast";
import ShutDown from "./ShutDown";
import { StoryTutorial } from "./Tutorial";
import PhoneNotifications from "./PhoneNotifications";
import AppLocker from "./Apps/common/AppLocker";
import { getAppIconUrl } from "./Apps/common/AppIcons";
import AppState from "state/AppState";

import { AppStoreProvider } from "@faintlines/phone-sim-common";

import { Switch, Route, useRouteMatch } from "react-router-dom";
import React, { useEffect, useCallback } from "react";
import useMeasure from "react-use/lib/useMeasure";
import urljoin from "url-join";
import classnames from "classnames";

// Custom fonts
import "@fontsource/mali";
import "@fontsource/roboto-mono";

export default function PhoneScreen({
    story,
    menuOpen,
    onClick,
    onMenuClick,
    onExit,
}) {
    const { path, url } = useRouteMatch();
    const { settings, apps } = story;
    const {
        home,
        brokenScreen,
        lockScreen,
        font_family: fontFamily,
    } = settings || {};
    const [screenRef, { width, height }] = useMeasure();

    return (
        <div
            className={styles.screen}
            style={fontFamilyToStyle(fontFamily)}
            onClick={onClick}
            ref={screenRef}
        >
            <div
                className={classnames(
                    styles.content,
                    menuOpen ? styles.menuOpen : null
                )}
            >
                <PhoneBrokenScreen type={brokenScreen} />
                <PhoneHeader />

                <Switch>
                    <Route exact path={path}>
                        <HomeScreen
                            backgroundImage={home?.background}
                            appsPosition={home?.appsPosition}
                            apps={apps}
                            rootUrl={url}
                        />
                        {lockScreen && lockScreen.code ? (
                            <LockScreen {...settings.lockScreen} />
                        ) : null}
                    </Route>

                    {(apps || []).map((app) => {
                        const appPath = urljoin(path, app.name);
                        const appUrl = urljoin(url, app.name);

                        return (
                            <Route key={app.name} path={appPath}>
                                <PhoneApp
                                    app={app}
                                    rootUrl={url}
                                    appPath={appPath}
                                    appUrl={appUrl}
                                />
                            </Route>
                        );
                    })}
                </Switch>

                <PhoneFooter
                    rootUrl={url}
                    onMenuClick={onMenuClick}
                    onExit={onExit}
                />

                <PhoneNotifications rootUrl={url} />

                <FloatingNote screenWidth={width} screenHeight={height} />

                <StoryTutorial />

                <VideoCall />

                <ConfettiBlast />

                <ShutDown />
            </div>
        </div>
    );
}

function PhoneApp({ app, rootUrl, appPath, appUrl }) {
    const AppComponent = Apps[app.type || app.name];

    useEffect(() => {
        if (app.launchEvent) {
            AppState.fireEvent(app.launchEvent, {
                delayMs: app.launchEventDelay,
            });
        }
    }, [app.launchEvent, app.launchEventDelay]);

    const handlePasswordAttempt = ({ appName, password, success }) => {
        AppState.trackPasswordEvent(appName, password, success);
    };

    const handleEvent = useCallback((eventName) => {
        AppState.fireEvent(eventName);
    }, []);

    const appStore = AppState.getAppStore(app.name);

    return (
        <AppStoreProvider store={appStore}>
            <AppComponent
                appName={app.name}
                appPath={appPath}
                appUrl={appUrl}
                rootUrl={rootUrl}
                password={app.password}
                onPasswordAttempt={handlePasswordAttempt}
                onEvent={handleEvent}
                {...app.data}
            />
            <AppLocker
                appName={app.name}
                appTitle={app.title}
                appIcon={getAppIconUrl(app)}
                lockType={app.passwordLockType}
                password={app.password}
                passwordHint={app.passwordHint}
                lockerProps={app.locker}
                unlockEvent={app.unlockEvent}
                unlockEventDelay={app.unlockEventDelay}
            />
        </AppStoreProvider>
    );
}

function fontFamilyToStyle(fontFamily) {
    if (!fontFamily) {
        return undefined;
    }
    return { fontFamily: `"${fontFamily}", "Roboto", sans-serif` };
}

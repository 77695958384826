import "./AppNews.css";
import MarkdownContent from "../../utils/MarkdownContent";
import Image from "components/common/Image";

import { useAppStore } from "@faintlines/phone-sim-common";

import React, { useEffect } from "react";
import { Switch, Route, Link, useParams, Redirect } from "react-router-dom";
import urljoin from "url-join";
import classnames from "classnames";
import { find } from "lodash";
import { observer } from "mobx-react-lite";

function AppNews({ title, items, appUrl, appPath, onEvent }) {
    const State = useAppStore();
    const filteredItems = filterItems(items, State.visibleArticles);

    return (
        <div className="AppNews">
            <Switch>
                <Route exact path={appPath}>
                    <NewsIndex
                        title={title}
                        items={filteredItems}
                        appUrl={appUrl}
                    />
                </Route>
                <Route exact path={urljoin(appPath, "item", ":itemId")}>
                    <NewsItem appUrl={appUrl} items={items} onEvent={onEvent} />
                </Route>
                <Route>
                    <Redirect to={appUrl} />
                </Route>
            </Switch>
        </div>
    );
}

export default observer(AppNews);

function NewsIndex({ title, items, appUrl }) {
    return (
        <div className="AppNews-scroller">
            <div className="AppNews-index">
                <div className="AppNews-index-title">
                    {title || "Breaking News"}
                </div>
                {items.length > 0 ? (
                    <NewsIndexItem featured item={items[0]} appUrl={appUrl} />
                ) : null}
                <div className="AppNews-index-items">
                    {items.slice(1).map((item, itemId) => (
                        <NewsIndexItem
                            key={itemId}
                            item={item}
                            appUrl={appUrl}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

const NewsIndexItem = observer(({ item, featured, appUrl }) => {
    const State = useAppStore();
    const unread = State.unreadArticles[item.id];

    return (
        <Link
            to={urljoin(appUrl, "item", item.id)}
            className={classnames("NewsIndexItem", {
                featured,
                list: !featured,
            })}
        >
            <div
                className="NewsIndexItem-image"
                style={{ backgroundImage: `url("${item.image}")` }}
            />
            <div className="NewsIndexItem-contents">
                <div className="NewsIndexItem-title">
                    {unread ? (
                        <span className="NewsIndexItem-unread">{"New"}</span>
                    ) : null}
                    {item.title}
                </div>
                {item.subtitle ? (
                    <div className="NewsIndexItem-subtitle">
                        {item.subtitle}
                    </div>
                ) : null}
            </div>
        </Link>
    );
});

function NewsItem({ items, appUrl, onEvent }) {
    const { itemId } = useParams();
    const item = find(items, (x) => x.id === itemId);

    if (!item) {
        return <Redirect to={appUrl} />;
    }

    return <NewsItemContents item={item} onEvent={onEvent} />;
}

const NewsItemContents = observer(({ item, onEvent }) => {
    const { id, readEvent, image, title, subtitle, contents } = item;
    const store = useAppStore();

    useEffect(() => {
        store.markArticleAsRead(id);
    }, [store, id]);

    useEffect(() => {
        if (onEvent && readEvent) {
            onEvent(readEvent);
        }
    }, [readEvent, onEvent]);

    return (
        <div className="AppNews-scroller">
            <div className="NewsItem">
                <Image className="NewsItem-image" src={image} />
                <div className="NewsItem-contents">
                    <div className="NewsItem-title">{title}</div>
                    <div className="NewsItem-subtitle">{subtitle}</div>
                    <MarkdownContent
                        className="NewsItem-body"
                        markdown={contents}
                    />
                </div>
            </div>
        </div>
    );
});

function filterItems(items, visibleItems) {
    return (items || []).filter(
        (x) => x.id && (x.visible || visibleItems[x.id])
    );
}

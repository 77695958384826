import styles from "./PhoneNotifications.module.css";

import { getAppIconUrl } from "./Apps/common/AppIcons";
import Image from "components/common/Image";
import AppState from "state/AppState";
import { formatTime } from "utils/timeUtils";

import React from "react";
import { observer } from "mobx-react-lite";
import { forOwn } from "lodash";
import urljoin from "url-join";
import { Link } from "react-router-dom";

function PhoneNotifications({ rootUrl }) {
    const { storyState, isPhoneLocked } = AppState;

    if (!storyState || isPhoneLocked) {
        return null;
    }

    const notifs = prepareNotifications(storyState.notifications);

    return (
        <div className={styles.notifications}>
            {notifs.map((notif, i) => (
                <PhoneNotification key={i} rootUrl={rootUrl} {...notif} />
            ))}
        </div>
    );
}

export default observer(PhoneNotifications);

function PhoneNotification({ rootUrl, id, text, app, date }) {
    return (
        <Link
            to={urljoin(rootUrl, app.name)}
            onClick={() => AppState.clearNotification(id)}
        >
            <div className={styles.notification}>
                <div className={styles.notification__header}>
                    <Image
                        src={getAppIconUrl(app)}
                        className={styles.notification__icon}
                    />
                    <span className={styles.notification__app}>
                        {app.title}
                    </span>
                    <span className={styles.notification__time}>
                        {formatTime(date)}
                    </span>
                </div>
                <div className={styles.notification__text}>{text}</div>
            </div>
        </Link>
    );
}

function prepareNotifications(notifications) {
    const notifs = [];
    forOwn(notifications || {}, (notif, notifId) => {
        notifs.push({ id: notifId, ...notif });
    });
    return notifs;
}

import { SideMenuItem } from "../common/SideMenu";
import AppState from "../../state/AppState";
import UiState from "../../state/UiState";
import {
    becomeAWriterDialogReponseEvent,
    becomeAWriterEvent,
} from "../../analytics";
import {
    MIN_COMPLETED_MISSIONS_FOR_BECOME_A_WRITER,
    MIN_COMPLETED_MISSIONS_FOR_BECOME_A_BETA_TESTER,
    MIN_COMPLETED_MISSIONS_FOR_BECOME_A_CHARACTER,
    MIN_COMPLETED_MISSIONS_FOR_PLAYER_SURVEY,
    BECOME_A_CHARACTER_URL,
    PLAYER_SURVEY_URL,
    END_DATE_FOR_BECOME_A_CHARACTER_NEW_TAG,
} from "../../config";
import {
    Sliders as IconSliders,
    ExternalLink as IconExternalLink,
    Gift as IconGift,
    HelpCircle as IconHelpCircle,
    ClipboardCheck as IconClipboardCheck,
    Puzzle as IconPuzzle,
} from "lucide-react";
import ImageCoin from "../../images/coin.svg";

import * as Native from "@faintlines/native-bridge";

import React from "react";
import { observer } from "mobx-react-lite";

const PRIVACY_POLICY_URL = "https://faintlines.com/privacy";
const STUDIO_URL = "https://studio.peekaph.one";

const HAVE_NATIVE_OPEN_URL = Native.CAPABILITIES.openUrlInBrowser;

const HAVE_MAILTO_PARAMS = !Native.HAVE_ANDROID || Native.getAppVersion() >= 31;

export const SettingsMenuItem = observer(() => (
    <SideMenuItem
        title="Settings"
        icon={IconSliders}
        onClick={() => UiState.toggleSettingsModal(true)}
    />
));

export const SupportMenuItem = observer(() => {
    const { playerId } = AppState;

    const appVersion = Native.getAppVersion() || "";
    const platform = Native.HAVE_ANDROID ? "Android" : "Web";

    const subject = encodeURIComponent("Peek a Phone Support Request");
    const body = encodeURIComponent(`
\n\n
Please do not edit below this line
----------------------------------
i: ${playerId}
v: ${appVersion}
platform: ${platform}
UA: ${window.navigator.userAgent}
`);

    const mailtoParams = HAVE_MAILTO_PARAMS
        ? `?subject=${subject}&body=${body}`
        : "";
    const mailtoUrl = `mailto:support@faintlines.com${mailtoParams}`;

    return (
        <SideMenuItem title="Support" href={mailtoUrl} icon={IconHelpCircle} />
    );
});

export const BecomeAWriterMenuItem = observer(() => {
    if (
        AppState.completedStoriesCount <
        MIN_COMPLETED_MISSIONS_FOR_BECOME_A_WRITER
    ) {
        return null;
    }

    const yesHandler = () => {
        if (HAVE_NATIVE_OPEN_URL) {
            Native.openUrlInBrowser(STUDIO_URL);
        }
        becomeAWriterDialogReponseEvent("Yes");
    };

    const noHandler = () => {
        becomeAWriterDialogReponseEvent("No");
    };

    const clickHandler = () => {
        UiState.confirm({
            title: "Build a Mission",
            text: "Do you want to create your own Peek a Phone mission and share it with your friends?\n\nWe know you do, so we created **Peek a Phone Studio**. Check it out now - it's free!",
            yesText: "Go to Studio",
            yesHref: HAVE_NATIVE_OPEN_URL ? undefined : STUDIO_URL,
            onYes: yesHandler,
            noText: "Maybe later",
            onNo: noHandler,
        });
        becomeAWriterEvent();
    };

    return (
        <SideMenuItem
            title="Build a Mission"
            icon={IconPuzzle}
            onClick={clickHandler}
        />
    );
});

export const BecomeABetaTesterMenuItem = observer(() => {
    if (
        AppState.completedStoriesCount <
        MIN_COMPLETED_MISSIONS_FOR_BECOME_A_BETA_TESTER
    ) {
        return null;
    }

    return (
        <SideMenuItem
            title="Become a Beta Tester"
            icon="beaker"
            onClick={() => UiState.showSubscribeForBetaTestingModal()}
        />
    );
});

export const CoinsItem = observer(() => {
    if (!AppState.loggedIn || !AppState.config.coins) {
        return null;
    }

    return (
        <SideMenuItem
            title={
                <span>
                    {"You have "}
                    <b>{AppState.player.coins.toLocaleString()}</b>
                    {" coins"}
                </span>
            }
            image={ImageCoin}
        />
    );
});

export const SurveyMenuItem = observer(() => {
    if (
        AppState.completedStoriesCount <
        MIN_COMPLETED_MISSIONS_FOR_PLAYER_SURVEY
    ) {
        return null;
    }

    return (
        <SideMenuItem
            title="Take a Survey"
            href={PLAYER_SURVEY_URL}
            icon={IconClipboardCheck}
        />
    );
});

export const PrivacyPolicyMenuItem = observer(() => (
    <SideMenuItem
        title="Privacy Policy"
        href={PRIVACY_POLICY_URL}
        icon={IconExternalLink}
    />
));

export const FreeHintsMenuItem = observer(() => (
    <SideMenuItem
        title="Free Hints"
        icon={IconGift}
        onClick={() => UiState.toggleReferralModal(true)}
    />
));

export const BecomeACharacterMenuItem = observer(() => {
    if (
        AppState.completedStoriesCount <
        MIN_COMPLETED_MISSIONS_FOR_BECOME_A_CHARACTER
    ) {
        return null;
    }
    return (
        <SideMenuItem
            title="Become a Character"
            icon={IconStar}
            href={BECOME_A_CHARACTER_URL}
            newTag={END_DATE_FOR_BECOME_A_CHARACTER_NEW_TAG > Date.now()}
        />
    );
});

const IconStar = ({ className }) => (
    <svg
        x="0px"
        y="0px"
        viewBox="0 0 98 98"
        style={{
            enableBackground: "new 0 0 98 98",
            width: "1.9em",
            height: "1.9em",
            margin: "-1em calc(13px - 0.4em) -0.4em -0.4em",
        }}
        stroke="currentColor"
        fill="currentColor"
        className={className}
    >
        <g style={{ width: "5.8em" }}>
            <path d="M73.9,51.3l-16.3-2.4l-7.3-14.7c-0.4-0.7-1.3-1-2-0.6c-0.2,0.1-0.4,0.3-0.6,0.6l-7.3,14.7l-16.3,2.4   c-0.8,0.1-1.4,0.9-1.3,1.7c0,0.4,0.2,0.7,0.5,0.9l11.8,11.4l-2.8,16.2c-0.1,0.6,0.1,1.2,0.6,1.5c0.4,0.3,1,0.4,1.5,0.1L49,75.5   l14.6,7.6l0.6,0.2c0.3,0,0.7-0.1,0.9-0.3c0.5-0.3,0.7-0.9,0.6-1.5l-2.8-16.2l11.8-11.4c0.6-0.6,0.7-1.5,0.1-2.1   C74.6,51.5,74.3,51.3,73.9,51.3z" />
            <path d="M48.5,27.5c0.8,0,1.5-0.7,1.5-1.5V16c0-0.8-0.7-1.5-1.5-1.5S47,15.2,47,16v10C47,26.8,47.7,27.5,48.5,27.5z" />
            <path d="M78.9,25c-0.7-0.4-1.6-0.3-2.1,0.3l-6,8c-0.5,0.7-0.4,1.6,0.3,2.1c0.3,0.2,0.6,0.3,0.9,0.3   c0.5,0,0.9-0.2,1.2-0.6l6-8C79.7,26.4,79.6,25.5,78.9,25z" />
            <path d="M26,35.7c0.3,0,0.6-0.1,0.9-0.3c0.7-0.5,0.8-1.4,0.3-2.1l-6-8c-0.5-0.6-1.4-0.7-2.1-0.3   c-0.7,0.5-0.8,1.4-0.3,2.1l0,0l6,8C25.1,35.5,25.5,35.7,26,35.7z" />
        </g>
    </svg>
);

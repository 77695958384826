import { parseDate } from "utils/dateUtils";
import EmojiText from "components/utils/EmojiText";

import React from "react";

export default function DayDetails({ selectedDay, events }) {
    const dayEvents = events.filter((event) =>
        parseDate(event.date).isSame(selectedDay, "day")
    );
    const titleText = selectedDay.format("MMM D, YYYY");

    return (
        <div className="AppCalendar-dayDetails-container">
            <div className="AppCalendar-dayDetails-titleContainer">
                <div className="AppCalendar-dayDetails-title">
                    {`${titleText} \n\n`}
                </div>
            </div>

            <div className="AppCalendar-dayDetails-body-container">
                <Body events={dayEvents} />
            </div>
        </div>
    );
}

function Body({ events }) {
    if (events.length === 0) {
        return (
            <div className="AppCalendar-eventCell-body-noEvents">
                {"No events"}
            </div>
        );
    }
    return events.map((event, i) => <EventCell key={i} event={event} />);
}

function EventCell({ event }) {
    const time = event.time || parseDate(event.date).format("hh:mm a");
    const title = event.title || "[Untitled]";

    return (
        <div className="AppCalendar-eventCell-container">
            <div className="AppCalendar-eventCell-title">
                <span className="AppCalendar-eventCell-title-time">{time}</span>
                <EmojiText text={title} />
            </div>
            {event.body ? (
                <div className="AppCalendar-eventCell-body">
                    <EmojiText text={event.body} />
                </div>
            ) : null}
        </div>
    );
}

import styles from "./StudioWelcomeScreen.module.css";
import { EllipsisLoader } from "../Loaders";
import { FacebookLoginButton, GoogleLoginButton } from "../LoginButtons";
import UiState from "state/UiState";
import * as StudioApi from "server/studioApi";

import React, { useEffect, useState } from "react";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import update from "immutability-helper";
import classnames from "classnames";
import IconTrash from "react-feather/dist/icons/trash-2";
import urljoin from "url-join";

export default function StudioWelcomeScreen() {
    const [info, setInfo] = useState(null);
    const [permissionDenied, setPermissionDenied] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        StudioApi.getStories()
            .then(({ data }) => {
                if (data.success) {
                    setInfo(data);
                } else {
                    setPermissionDenied(true);
                }
            })
            .catch(() => setError(true));
    }, []);

    const handleDeleteStory = (storyId) => {
        UiState.confirm({
            title: "Delete mission?",
            text: "This cannot be undone.",
            yesText: "Delete",
            noText: "Cancel",
            onYes: () => {
                StudioApi.deleteStory(storyId);
                setInfo((oldInfo) =>
                    update(oldInfo, {
                        stories: (stories) =>
                            stories.filter((x) => x.id !== storyId),
                    })
                );
            },
        });
    };

    return (
        <div className={styles.welcomeScreen}>
            <div className={styles.welcomeScreen__title}>
                {"Peek a Phone "}
                <span className={styles.welcomeScreen__title__studio}>
                    {"Studio"}
                </span>
                <span className={styles.welcomeScreen__title__beta}>
                    {"Beta"}
                </span>
            </div>
            {info ? (
                <StudioStoryGrid
                    stories={info.stories}
                    onDelete={handleDeleteStory}
                />
            ) : permissionDenied ? (
                <div className={styles.welcomeScreen__denied}>
                    <p>
                        {
                            "Build your own Peek a Phone missions and share them with your friends!"
                        }
                    </p>
                    <p>{"To start, sign in or create an account."}</p>
                    <FacebookLoginButton />
                    <GoogleLoginButton />
                    <p className={styles.welcomeScreen__legal}>
                        {"By proceeding you agree with our "}
                        <a
                            href="https://faintlines.com/termsofuse/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="SignupPopup-terms-link"
                        >
                            {"Terms of Use"}
                        </a>
                        {" and "}
                        <a
                            href="https://faintlines.com/privacy/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="SignupPopup-terms-link"
                        >
                            {"Privacy Policy"}
                        </a>
                        {"."}
                    </p>
                </div>
            ) : error ? (
                "Failed to load missions. To try again, please reload this page."
            ) : (
                <div className={styles.welcomeScreen__loading}>
                    <EllipsisLoader color="black" />
                    <div className={styles.welcomeScreen__loading__text}>
                        {"Loading missions"}
                    </div>
                </div>
            )}
        </div>
    );
}

function StudioStoryGrid({ stories, onDelete }) {
    const { url } = useRouteMatch();
    const history = useHistory();

    function handleNewStory() {
        // TODO: loading...
        StudioApi.createStory().then(({ data }) =>
            history.push(urljoin(url, "edit", data.story.id))
        ); // TODO: catch
    }

    return (
        <div className={styles.storyGrid}>
            {stories.map((story) => (
                <StudioStoryGridItem
                    key={story.id}
                    story={story}
                    onDelete={onDelete}
                />
            ))}
            <div
                className={classnames(styles.storyGrid__item, styles.new)}
                onClick={handleNewStory}
            >
                <span className={styles.storyGrid__item__plus}>{"+"}</span>
                {"Create Mission"}
            </div>
        </div>
    );
}

function StudioStoryGridItem({ story, onDelete }) {
    const { url } = useRouteMatch();

    function handleDeleteClick(evt) {
        evt.stopPropagation();
        onDelete(story.id);
    }

    return (
        <div className={styles.storyGrid__item}>
            <Link
                to={urljoin(url, `edit/${story.id}`)}
                className={styles.storyGrid__item__contents}
            >
                <div className={styles.storyGrid__item__title}>
                    {story.name || "Untitled Mission"}
                </div>
                <div className={styles.storyGrid__item__created}>
                    {`Created ${story.created}`}
                </div>
                <div className={styles.storyGrid__item__created}>
                    {`Modified ${story.modified}`}
                </div>
            </Link>
            <div className={styles.storyGrid__item__menu}>
                <div
                    className={styles.storyGrid__item__menu__action}
                    onClick={handleDeleteClick}
                >
                    <IconTrash size="1em" />
                </div>
            </div>
        </div>
    );
}

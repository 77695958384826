import "./ContactInfo.css";
import AppState from "../../../../state/AppState";

import React from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";

export default function ContactInfo({ contact, isOpen, showFullInfo }) {
    return (
        <div
            className={classnames("ContactInfo-info", {
                hidden: !isOpen,
            })}
        >
            <ContactDetails contact={contact} showFullInfo={showFullInfo} />
            <div className="ContactInfo-icons">
                <CallButton contact={contact} />
            </div>
        </div>
    );
}

function ContactDetails({ contact, showFullInfo }) {
    return (
        <div className="ContactInfo-details">
            {!contact.number && !contact.email ? (
                <div className="ContactInfo-details-item">
                    {"No contact info"}
                </div>
            ) : null}
            {showFullInfo && contact.email ? (
                <div className="ContactInfo-details-item">{contact.email}</div>
            ) : null}
            {contact.number ? (
                <div className="ContactInfo-details-item">{contact.number}</div>
            ) : null}
        </div>
    );
}

export function CallButton({ contact }) {
    const history = useHistory();

    if (!contact.number || contact.number.length <= 6) {
        return null;
    }

    function clickHandler() {
        AppState.dialPhoneNumber(contact.number, history);
    }

    return (
        <div
            className="ContactInfo-icon call icon-phone"
            onClick={clickHandler}
        />
    );
}

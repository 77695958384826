import "./AppCalendar.css";
import DayDetails from "./DayDetails";
import CalendarHeader from "./CalendarHeader";
import DaysRuler from "./DaysRuler";
import Calendar from "./Calendar";
import YearModal from "./YearModal";
import { parseDate } from "utils/dateUtils";

import React, { useState } from "react";
import dayjs from "dayjs";

export default function AppCalendar({ events }) {
    const today = dayjs();
    const [selectedDay, setSelectedDay] = useState(today);
    const [selectedMonth, setSelectedMonth] = useState(today);
    const eventsDates = events.map((event) => parseDate(event.date));
    const [yearModalVisible, toggleYearModal] = useState(false);

    const daySelectionHandler = (day) => {
        if (day.month() !== selectedMonth.month()) {
            setSelectedMonth(day);
        }
        setSelectedDay(day);
    };

    const handleYearChange = (newDate) => {
        setSelectedDay(newDate);
        setSelectedMonth(newDate);
        toggleYearModal(false);
    };

    return (
        <div className="AppCalendar-container">
            <div className="AppCalendar-calendar-container">
                <CalendarHeader
                    selectedMonth={selectedMonth}
                    onMonthChange={setSelectedMonth}
                    onTitleClick={() => toggleYearModal((x) => !x)}
                />
                <DaysRuler />
                <Calendar
                    selectedDay={selectedDay}
                    selectedMonth={selectedMonth}
                    onDaySelection={daySelectionHandler}
                    eventsDates={eventsDates}
                />
            </div>

            <DayDetails events={events} selectedDay={selectedDay} />
            {yearModalVisible ? (
                <YearModal
                    selectedMonth={selectedMonth}
                    eventsDates={eventsDates}
                    onChange={handleYearChange}
                    onClose={() => toggleYearModal(false)}
                />
            ) : null}
        </div>
    );
}

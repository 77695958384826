import styles from "./PhoneWrapper.module.css";

import React from "react";
import classnames from "classnames";

export default function PhoneWrapper({ children, actions }) {
    return (
        <div
            className={classnames(
                styles.wrapper,
                isMobileDevice() ? styles.mobile : styles.desktop,
                actions ? styles.withActions : null
            )}
        >
            <div className={styles.content}>{children}</div>
            {actions ? <div className={styles.actions}>{actions}</div> : null}
        </div>
    );
}

function isMobileDevice() {
    return (
        typeof window.orientation !== "undefined" ||
        navigator.userAgent.indexOf("IEMobile") !== -1
    );
}

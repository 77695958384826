import { DEBUG, REWARDED_AD_UNIT_IDS } from "config";

import * as Native from "@faintlines/native-bridge";

import { makeAutoObservable } from "mobx";

const HAVE_ANDROID_SHOW_AD = Native.CAPABILITIES.showVideoAd;

class VideoAdManager {
    adsAvailable = HAVE_ANDROID_SHOW_AD || DEBUG;

    adLoading = false;

    constructor() {
        makeAutoObservable(this, {
            adsAvailable: false,
        });
    }

    /// / Actions

    showVideoAd(unitIdName) {
        if (this.adLoading) {
            return null;
        }

        const unitId = REWARDED_AD_UNIT_IDS[unitIdName];
        if (!unitId) {
            // TODO: return Promise.reject()
            return null;
        }

        this._earnedReward = false;
        this.toggleAdLoading(true);

        return new Promise((resolve, reject) => {
            if (!this.adsAvailable && !HAVE_ANDROID_SHOW_AD) {
                this.toggleAdLoading(false);
                reject();
                return;
            }

            this._resolve = resolve;
            this._reject = reject;

            Native.showVideoAd(unitId);
        });
    }

    handleAndroidEvent(event) {
        switch (event) {
            case "opened": // TODO: deprecated, remove
            case "loaded":
                this.adLoading = false;
                break;
            case "failure": // TODO: deprecated, remove
                this.adLoading = false;
                if (this._reject) {
                    this._reject();
                }
                break;
            case "success":
                this._earnedReward = true;
                this.adLoading = false;
                if (this._resolve) {
                    this._resolve(this._earnedReward);
                }
                break;
            case "closed": // TODO: deprecated, remove
                if (this._resolve) {
                    this._resolve(this._earnedReward);
                }
                break;
            default:
        }
    }

    toggleAdLoading(toggle) {
        this.adLoading = toggle;
    }
}

const videoAdManager = new VideoAdManager();

window.addEventListener("rewardedadloaded", () =>
    videoAdManager.handleAndroidEvent("loaded")
);
window.addEventListener("rewardedadsuccess", () =>
    videoAdManager.handleAndroidEvent("success")
);

// TODO: following events are deprecated, remove
window.addEventListener("rewardedadfailure", () =>
    videoAdManager.handleAndroidEvent("failure")
);
window.addEventListener("rewardedadopened", () =>
    videoAdManager.handleAndroidEvent("opened")
);
window.addEventListener("rewardedadclosed", () =>
    videoAdManager.handleAndroidEvent("closed")
);

export default videoAdManager;

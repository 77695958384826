import dayjs from "dayjs";
import { isString, isDate } from "lodash";

// TODO: to package (game-shell or stringUtils)

const TIME_FORMAT = "hh:mm A";

export function formatTime(time) {
    if (isString(time) || isDate(time)) {
        time = dayjs(time);
    }
    return time.format(TIME_FORMAT);
}

export function secondsToDaysHours(totalSeconds) {
    const days = Math.floor(totalSeconds / 86400);
    const hours = Math.floor((totalSeconds % 86400) / 3600);
    const daysStr = days ? `${days}d ` : "";
    return `${daysStr}${hours}h`;
}

import styles from "./AppDating.module.css";
import Image from "components/common/Image";
import AppState from "state/AppState";

import { ContactPhoto } from "@faintlines/phone-sim-common";

import React, { useEffect, useRef } from "react";
import {
    Switch,
    Route,
    Link,
    useParams,
    useLocation,
    Redirect,
} from "react-router-dom";
import urljoin from "url-join";
import { observer } from "mobx-react-lite";
import classnames from "classnames";
import { last } from "lodash";
import IconMessage from "react-feather/dist/icons/message-square";

function AppDating({ appTitle, messages, appUrl, appPath }) {
    return (
        <div className={styles.app}>
            <Header appTitle={appTitle} rootUrl={appUrl} />
            <Switch>
                <Route exact path={appPath}>
                    <MatchScreen
                        appUrl={appUrl}
                        offline={!AppState.isPhoneOnline}
                    />
                </Route>
                <Route exact path={urljoin(appPath, "messages")}>
                    <MessagesIndex messages={messages} appUrl={appUrl} />
                </Route>
                <Route exact path={urljoin(appPath, "messages/:threadId")}>
                    <MessagesThread messages={messages} />
                </Route>
                <Route>
                    <Redirect to={appUrl} />
                </Route>
            </Switch>
        </div>
    );
}

export default observer(AppDating);

function Header({ appTitle, rootUrl }) {
    const { pathname } = useLocation();
    const showMessages = !pathname.includes("messages");

    const title = pathname.includes("messages")
        ? "Messages"
        : appTitle || "MatchFinder";

    return (
        <div className={styles.header}>
            <div className={styles.header__title}>{title}</div>
            {showMessages ? (
                <Link
                    className={classnames(styles.header__button, styles.right)}
                    to={urljoin(rootUrl, "messages")}
                >
                    <IconMessage size="1em" />
                </Link>
            ) : null}
        </div>
    );
}

function MatchScreen({ offline }) {
    return (
        <div className={styles.matches}>
            {offline ? (
                <div className={styles.matches__offline}>
                    <div
                        className={classnames(
                            styles.matches__offline__icon,
                            "icon-no-sign"
                        )}
                    />
                    {"No internet"}
                </div>
            ) : (
                <div className={styles.matches__empty}>
                    <div className={styles.matches__empty__icon}>{":("}</div>
                    {"No matches nearby"}
                </div>
            )}
        </div>
    );
}

function MessagesIndex({ messages, appUrl }) {
    return (
        <div className={styles.messages}>
            {(messages || []).map((thread, threadId) => (
                <Link
                    className={styles.messages__contact}
                    key={threadId}
                    to={urljoin(appUrl, "messages", threadId.toString())}
                >
                    <div className={styles.messages__contact__iconWrapper}>
                        <ContactPhoto contact={thread.contact} />
                    </div>
                    <div className={styles.messages__contact__details}>
                        <div className={styles.messages__contact__name}>
                            {thread.contact.name}
                        </div>
                        <div className={styles.messages__contact__lastMessage}>
                            {last(thread.messages).text}
                            <span
                                className={
                                    styles.messages__contact__lastMessage__time
                                }
                            >
                                {last(thread.messages).time}
                            </span>
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    );
}

function MessagesThread({ messages }) {
    const { threadId } = useParams();
    const thread = messages[threadId];
    const scroller = useRef();

    useEffect(() => {
        scroller.current.scrollTop = scroller.current.scrollHeight;
    }, []); // TODO: not always?

    return (
        <div className={styles.thread}>
            <div className={styles.thread__title}>{thread.contact.name}</div>
            <div className={styles.thread__messagesWrapper} ref={scroller}>
                <div className={styles.thread__messages}>
                    {thread.messages.map((message, i) => (
                        <Message
                            key={i}
                            message={message}
                            contact={thread.contact}
                            showTime={
                                i === 0 ||
                                thread.messages[i - 1].direction !==
                                    message.direction
                            }
                            showProfile={
                                i === thread.messages.length - 1 ||
                                thread.messages[i + 1].direction !==
                                    message.direction
                            }
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

function Message({ message, contact, showTime, showProfile }) {
    const { direction } = message;

    return (
        <div
            className={classnames(
                styles.message,
                styles[direction],
                showProfile ? styles.withProfile : null
            )}
        >
            {showProfile && direction === "in" ? (
                <ContactPhoto
                    className={styles.message__contactPhoto}
                    contact={contact}
                    size={30}
                />
            ) : null}
            <div className={styles.message__content}>
                {showTime ? (
                    <div className={styles.message__time}>{message.time}</div>
                ) : null}
                <div
                    className={classnames(
                        styles.message__content__inner,
                        message.text ? styles.text : null,
                        message.image ? styles.image : null
                    )}
                >
                    {message.image ? (
                        <Image
                            className={styles.message__content__image}
                            src={message.image}
                            alt=""
                        />
                    ) : null}
                    {message.text}
                </div>
            </div>
        </div>
    );
}

import styles from "./YearModal.module.css";

import React, { useState } from "react";
import dayjs from "dayjs";
import classnames from "classnames";
import { range } from "lodash";
import {
    ChevronLeft as IconChevronLeft,
    ChevronRight as IconChevronRight,
} from "lucide-react";

const MONTH_NAMES_SHORT = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const YearModal = ({ selectedMonth, eventsDates, onChange, onClose }) => {
    const [showMonths, toggleMonths] = useState(true);
    const [year, setYear] = useState(selectedMonth.year());

    const startYear = year - (year % 12);
    const endYear = startYear + 12;
    const items = showMonths ? range(12) : range(startYear, startYear + 12);

    const title = showMonths ? year : `${startYear} - ${endYear - 1}`;
    const selected = showMonths ? selectedMonth.month() : year;

    const hasEvents = (item) =>
        showMonths
            ? monthHasEvents(year, item, eventsDates)
            : yearHasEvents(item, eventsDates);

    const handleClick = (item) => {
        if (showMonths) {
            onChange(dayjs(new Date(year, item, 1)));
        } else {
            setYear(item);
            toggleMonths(true);
        }
    };

    const handleNavClick = (direction) => {
        const advanceYears = showMonths ? 1 : 12;
        setYear((x) => x + advanceYears * direction);
    };

    return (
        <div className={styles.yearModalWrapper} onClick={onClose}>
            <div
                className={styles.yearModal}
                onClick={(evt) => evt.stopPropagation()}
            >
                <div
                    className={classnames(styles.yearModal__nav, styles.back)}
                    onClick={() => handleNavClick(-1)}
                >
                    <IconChevronLeft size="1em" />
                </div>
                <div
                    className={styles.yearModal__title}
                    onClick={() => toggleMonths((x) => !x)}
                >
                    {title}
                </div>
                <div
                    className={classnames(
                        styles.yearModal__nav,
                        styles.forward
                    )}
                    onClick={() => handleNavClick(1)}
                >
                    <IconChevronRight size="1em" />
                </div>
                <div className={styles.yearModal__items}>
                    {items.map((item) => (
                        <div
                            key={item}
                            className={classnames(
                                styles.yearModal__item,
                                item === selected ? styles.selected : null,
                                hasEvents(item) ? styles.events : null
                            )}
                            onClick={() => handleClick(item)}
                        >
                            {showMonths ? MONTH_NAMES_SHORT[item] : item}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default YearModal;

function monthHasEvents(year, month, eventsDates) {
    for (let i = 0; i < eventsDates.length; i += 1) {
        if (
            eventsDates[i].year() === year &&
            eventsDates[i].month() === month
        ) {
            return true;
        }
    }
    return false;
}

function yearHasEvents(year, eventsDates) {
    for (let i = 0; i < eventsDates.length; i += 1) {
        if (eventsDates[i].year() === year) {
            return true;
        }
    }
    return false;
}

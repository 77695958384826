import AppBrowser from "./AppBrowser";
import AppPhotos from "./AppPhotos";
import AppPhoneCalls from "./AppPhoneCalls";
import AppContacts from "./AppContacts";
import AppNotes from "./AppNotes";
import AppDocuments from "./AppDocuments";
import AppEmail from "./AppEmail";
import AppStreaming from "./AppStreaming";
import AppDating from "./AppDating";
import AppFolder from "./AppFolder";
import AppAuthenticator from "./AppAuthenticator";
import AppChat from "./AppChat";
import AppFindFriends from "./AppFindFriends";
import AppNews from "./AppNews";
import AppStory15Game from "./AppStory15Game";
import AppCalendar from "./AppCalendar/AppCalendar";
import AppSmartHome from "./AppSmartHome/AppSmartHome";
import AppPizza from "./AppPizza/AppPizza";
import AppDelivery from "./AppDelivery/AppDelivery";
import AppEmpty from "./AppEmpty";

import AppSocial from "@faintlines/phone-sim-app-social";
import "@faintlines/phone-sim-app-social/dist/index.css";
import AppBooks from "@faintlines/phone-sim-app-books";
import "@faintlines/phone-sim-app-books/dist/index.css";
import AppStaticText from "@faintlines/phone-sim-app-static-text";
import "@faintlines/phone-sim-app-static-text/dist/index.css";
import AppStaticImage from "@faintlines/phone-sim-app-static-image";
import "@faintlines/phone-sim-app-static-image/dist/index.css";
import AppMessages from "@faintlines/phone-sim-app-messages";
import "@faintlines/phone-sim-app-messages/dist/index.css";
import AppSettings from "@faintlines/phone-sim-app-settings";
import "@faintlines/phone-sim-app-settings/dist/index.css";
import AppDownloader from "@faintlines/phone-sim-app-downloader";
import "@faintlines/phone-sim-app-downloader/dist/index.css";

const Apps = {
    browser: AppBrowser,
    photos: AppPhotos,
    messages: AppMessages,
    phone: AppPhoneCalls,
    contacts: AppContacts,
    notes: AppNotes,
    documents: AppDocuments,
    email: AppEmail,
    streaming: AppStreaming,
    social: AppSocial,
    dating: AppDating,
    folder: AppFolder,
    authenticator: AppAuthenticator,
    chat: AppChat,
    findFriends: AppFindFriends,
    news: AppNews,
    story015game: AppStory15Game,
    calendar: AppCalendar,
    home: AppSmartHome,
    pizza: AppPizza,
    delivery: AppDelivery,
    staticText: AppStaticText,
    staticImage: AppStaticImage,
    empty: AppEmpty,
    books: AppBooks,
    settings: AppSettings,
    downloader: AppDownloader,
};

export default Apps;

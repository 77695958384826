import styles from "./AppDocuments.module.css";
import MarkdownContent from "../../utils/MarkdownContent";

import { pluralize, limitString } from "@faintlines/string-utils";

import React from "react";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import urljoin from "url-join";

export default function AppDocuments({
    documents,
    appTitle,
    itemTitle,
    appUrl,
    appPath,
}) {
    documents = documents || [];

    return (
        <div className={styles.app}>
            <Switch>
                <Route exact path={appPath}>
                    <Index
                        documents={documents}
                        appTitle={appTitle}
                        itemTitle={itemTitle}
                        appUrl={appUrl}
                    />
                </Route>
                <Route
                    exact
                    path={urljoin(appPath, "doc", ":docId")}
                    render={({ match }) => (
                        <Document
                            doc={documents[match.params.docId]}
                            appUrl={appUrl}
                        />
                    )}
                />
                <Route>
                    <Redirect to={appUrl} />
                </Route>
            </Switch>
        </div>
    );
}

function Index({ documents, appTitle, itemTitle, appUrl }) {
    return (
        <div className={styles.index__scroller}>
            <div className={styles.index}>
                <div className={styles.index__header}>
                    <div>
                        <div className={styles.index__title}>
                            {appTitle || "Documents"}
                        </div>
                        <div className={styles.index__subtitle}>
                            {`${documents.length} ${
                                itemTitle || "document"
                            }${pluralize(documents.length)}`}
                        </div>
                    </div>
                </div>
                <div className={styles.index__docs}>
                    {documents.map((doc, docId) => (
                        <Link
                            className={styles.index__doc__link}
                            key={docId}
                            to={urljoin(appUrl, "doc", docId.toString())}
                        >
                            <div className={styles.index__doc}>
                                <div className={styles.index__doc__contents}>
                                    {doc.title ? (
                                        <div
                                            className={styles.index__doc__title}
                                        >
                                            {doc.title}
                                        </div>
                                    ) : null}
                                    <MarkdownContent
                                        className={styles.index__doc__text}
                                        markdown={limitString(
                                            doc.text.split("\n")[0],
                                            50
                                        )}
                                    />
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
}

function Document({ doc, appUrl }) {
    const { title, text } = doc;

    return (
        <div className={styles.doc}>
            <div className={styles.doc__topBar}>
                <Link className={styles.doc__backButton} to={appUrl}>
                    <i className="icon-left-open-big" />
                </Link>
                <div className={styles.doc__title}>{title}</div>
            </div>
            <div className={styles.doc__content}>
                <MarkdownContent className={styles.doc__text} markdown={text} />
            </div>
        </div>
    );
}

import styles from "./Alert.module.css";
import MarkdownContent from "./utils/MarkdownContent";
import UiState from "../state/UiState";

import React from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";

function Alert() {
    const { alert } = UiState;

    if (!alert) {
        return null;
    }

    return (
        <div className={styles.alert}>
            <div
                className={styles.content}
                onClick={(evt) => evt.stopPropagation()}
            >
                <div className={styles.scroller}>
                    {alert.title ? (
                        <div className={styles.title}>{alert.title}</div>
                    ) : null}
                    <MarkdownContent
                        className={styles.text}
                        markdown={alert.text}
                    />
                    <div className={styles.buttons}>
                        {alert.buttons.map((btn, i) => (
                            <a
                                key={i}
                                className={classnames(
                                    styles.button,
                                    btn.secondary ? styles.secondary : null
                                )}
                                href={btn.href}
                                onClick={() => btn.event()}
                            >
                                {btn.text}
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(Alert);

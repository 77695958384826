import styles from "./Image.module.css";
import { selectImageSource } from "utils/browserUtils";

import React, { useState, useEffect, useRef, memo } from "react";
import classnames from "classnames";
import LRU from "lru-cache";
import { sum, isEqual } from "lodash";

export const loadedImagesCache = new LRU({
    // A reasonable amount of images to cover all UI elements + a single story.
    max: 150,
});

export default function Img({ src, ...props }) {
    return <ImgInner src={selectImageSource(src)} {...props} />;
}

const ImgInner = React.memo(
    ({ src, alt, className, showSpinner, onLoad, ...props }) => {
        const imageObject = useImage(src);

        if (imageObject !== null) {
            return (
                <img
                    className={classnames(styles.image, className)}
                    src={src}
                    alt={alt}
                    onLoad={onLoad}
                    {...props}
                />
            );
        }

        return showSpinner ? <Spinner /> : null;
    }
);

function ImagePreloaderInner({ images, onLoaded }) {
    const imageObjects = useRef();

    useEffect(() => {
        const numImages = (images || []).length;

        if (numImages === 0) {
            if (onLoaded) {
                onLoaded();
            }
            return;
        }

        const loadedImages = [];
        imageObjects.current = images.map((url, i) => {
            const img = new Image();
            img.addEventListener("load", () => {
                loadedImages[i] = 1;
                loadedImagesCache.set(img.src, true);

                if (sum(loadedImages) === numImages) {
                    if (onLoaded) {
                        onLoaded();
                    }
                }
            });
            img.src = selectImageSource(url);
            return img;
        });
    }, [images, onLoaded]);

    return null;
}

export const ImagePreloader = memo(ImagePreloaderInner, isEqual);

export function useImage(src) {
    const loadedDefault =
        src && (src.startsWith("data:image") || loadedImagesCache.get(src));
    const [loaded, setLoaded] = useState(loadedDefault);
    const imageRef = useRef(loadedDefault ? preloadedImageObject(src) : null);

    useEffect(() => {
        if (loaded) {
            return undefined;
        }

        imageRef.current = new Image();
        const image = imageRef.current;

        image.onload = () => {
            setLoaded(true);
            loadedImagesCache.set(src, true);
        };
        image.onerror = () => {
            setTimeout(() => {
                image.src = src;
            }, 500);
        };

        image.src = src;

        return () => {
            image.onload = null;
            image.onerror = null;
        };
    }, [src, loaded]);

    return loaded ? imageRef.current : null;
}

const Spinner = () => (
    <div className={styles.spinner}>
        <div className={styles.spinner__inner} />
        <div className={styles.spinner__inner} />
        <div className={styles.spinner__inner} />
        <div className={styles.spinner__inner} />
    </div>
);

function preloadedImageObject(src) {
    const image = new Image();
    image.src = src;
    return image;
}

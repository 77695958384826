import ChatAppState from "./ChatAppState";
import NewsAppState from "./NewsAppState";
import Story15GameAppState from "./Story15GameAppState";
import SmartHomeAppState from "./SmartHomeAppState";
import PizzaAppState from "./PizzaAppState";
import DeliveryAppState from "./DeliveryAppState";
import EmailAppState from "./EmailAppState";
import PhoneCallsAppState from "./PhoneCallsAppState";
import UiState from "./UiState";

import { AppStore as MessagesAppStore } from "@faintlines/phone-sim-app-messages";
import { AppStore as SettingsAppStore } from "@faintlines/phone-sim-app-settings";
import { AppStore as DownloaderAppStore } from "@faintlines/phone-sim-app-downloader";

import * as Server from "../server/api";
import { decodeStory, sortStoriesBy } from "../stories";
import { weightedChoice } from "utils/randomUtils";
import { isFakePhoneNumber } from "utils/stringUtils";
import { SPLIT_TEST_VALUES } from "utils/splitTesting";
import { pickToJs } from "utils/mobxUtils";
import { parseUtcTimestamp } from "utils/dateUtils";
import {
    hintUnlockEvent,
    storyStartedEvent,
    storyNoDataEvent,
    storyCompletedEvent,
    storySkippedEvent,
    storyUsedWalkthroughEvent,
    subscribeForNewMissionsEvent,
    subscribeForBetaTestingEvent,
    setCustomDimension,
    enteredInvalidReferralCodeEvent,
    appliedReferralCodeEvent,
    eventPasswordAttempt,
} from "analytics";
import {
    shouldShowSignupPopup,
    DEBUG,
    STORY_LOCK_TIME_MINUTES,
    TIME_LOCKED_STORY_INDEXES,
    MIN_BATTERY_FOR_INTRO_LEVELS,
    BATTERY_DECREASE_INTERVAL_MS,
    MIN_STORIES_COMPLETED_FOR_TROPHIES,
    TASK_COMPLETION_REWARD_WEIGHTS,
} from "config";

import { limitString } from "@faintlines/string-utils";
import * as Native from "@faintlines/native-bridge";

import { makeAutoObservable, reaction, toJS, runInAction } from "mobx";
import store from "store";
import {
    pick,
    filter,
    isEmpty,
    keys,
    mapValues,
    has,
    cloneDeep,
    isEqual,
    extend,
    values,
    isUndefined,
    findIndex,
    forEach,
    find,
} from "lodash";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";
import localforage from "localforage";

const SECONDS = 1000;
const STATE_SAVE_INTERVAL_MS = 2 * SECONDS;
const GET_PENDING_EVENTS_INTERVAL_MS = 10 * SECONDS;
const BATTERY_UPDATE_INTERVAL_MS = 60 * SECONDS;
const DEQUEUE_EVENTS_INTERVAL_MS = 1 * SECONDS;
let customOfferPopupShown = false;
let dailyRewardPopupShown = false;

const DEFAULT_STORY_STATE = {
    queuedEvents: [],
    firedEvents: [],

    confettiProps: {},
    musicTrack: "default",
    musicPaused: false,

    tutorialStep: 0,

    batteryLevel: null,
    phoneUnlocked: false,
    phoneOnline: false,
    unlockedApps: {},
    appVisibility: {},
    appStates: {},
    videoCall: null,
    notifications: {},
    failure: null,

    // a place for storing arbitrary data that will be deleted when exiting the story or
    // reloading the page. Set values using setSessionStorage.
    sessionStorage: {},

    requiredProgressMade: false,
    answerValue: "",
    checkingAnswer: false,
    answerError: null,

    hints: null,
    hintBalance: null,
    requestingHint: false,

    feedbackFormType: null,

    noteText: "",

    // This flag pauses the battery depletion process until a story event is triggered.
    allowBatteryDepletion: false,
};

const APP_STORES = {
    chat: ChatAppState,
    news: NewsAppState,
    story015game: Story15GameAppState,
    home: SmartHomeAppState,
    pizza: PizzaAppState,
    delivery: DeliveryAppState,
    email: EmailAppState,
    phone: PhoneCallsAppState,
    messages: MessagesAppStore,
    settings: SettingsAppStore,
    downloader: DownloaderAppStore,
};

class AppState {
    id = Math.random();

    constructor() {
        makeAutoObservable(this);

        this._tickers = [
            setInterval(
                () => this._sendStoryStateToServer(),
                STATE_SAVE_INTERVAL_MS
            ),
            setInterval(
                () => this._getStoryPendingEvents(),
                GET_PENDING_EVENTS_INTERVAL_MS
            ),
            setInterval(
                () => this._decreaseBatteryLevel(),
                BATTERY_DECREASE_INTERVAL_MS
            ),
            setInterval(
                () => this._globalBatteryRechargeCountdown(),
                1 * SECONDS
            ),
            setInterval(() => this._updateBatteryUnlimitedLeft(), 1 * SECONDS),
            setInterval(
                () => this._updateBatteryLevelFromServer(),
                BATTERY_UPDATE_INTERVAL_MS
            ),
            setInterval(() => this.dequeueEvents(), DEQUEUE_EVENTS_INTERVAL_MS),
        ];

        this._appStores = {};
        this._appStoresReactions = [];

        this._nextStorySettings = {}; // settings for loading the next story from server
        this._pendingStoryState = null;
        this._sendingStateToServer = false;
        this._eventTimeouts = [];

        reaction(
            () => [
                this.story,
                this.storyState &&
                    pickToJs(this.storyState, [
                        "firedEvents",
                        "queuedEvents",
                        "phoneUnlocked",
                        "phoneOnline",
                        "unlockedApps",
                        "appVisibility",
                        "appStates",
                        "noteText",
                        "batteryLevel",
                        "notifications",
                        "requiredProgressMade",
                        "allowBatteryDepletion",
                        "failure",
                    ]),
            ],
            ([story, storyState]) => {
                if (story && storyState) {
                    // TODO: why do we save the story state to localStorage?
                    // TODO: sign the story states
                    const savedStates = store.get("storyStates") || {};
                    savedStates[story.id] = storyState;
                    store.set("storyStates", savedStates);

                    this._pendingStoryState = [story.id, storyState, uuid()];
                }
            }
        );

        reaction(
            () => toJS(this.userSettings),
            (settings) => {
                if (typeof settings !== "object" || !settings.time) {
                    return;
                }
                store.set("userSettings", settings);
                if (this.playerInfoLoaded) {
                    Server.saveUserSettings(settings);
                }
            }
        );
    }

    story = null;
    storyLoadingError = false;
    storyState = null;
    shouldShowIntroNotification = true;
    shouldSendStateToServer = true;
    storyBriefId = null;

    // synced with server
    playerInfoLoaded = false;
    playerInfoLoadingFailed = false;
    stories = null;
    storyTree = null;
    config = null;
    player = {};
    storyStates = null;
    userSettings = {};
    offers = [];

    getAppStore(appName) {
        if (!this.storyState) {
            return null;
        }
        return this._appStores[appName] || null;
    }

    get playerId() {
        return this.player?.id;
    }

    get splitTests() {
        return this.config?.st || {};
    }

    // TODO: remove backwards compatibility for this.config?...

    get lobbyDesign() {
        return this.splitTests.lobby_design || this.config?.lobby_design;
    }

    get hintsIAP() {
        return this.splitTests.hints_iap || "default";
    }

    get multipleStoriesEnabled() {
        return !!(this.splitTests.multi_stories || this.config?.multi_stories);
    }

    get trophiesEnabled() {
        return !!(this.splitTests.trophies || this.config?.trophies);
    }

    get resetStoryProgressEnabled() {
        return this.splitTests.reset_story_progress;
    }

    get clipBriefTextEnabled() {
        return this.splitTests.clip_brief_text;
    }

    get freeBatteryCounterEnabled() {
        return this.splitTests.free_battery_counter;
    }

    get rewardedAdsEnabled() {
        return !this.splitTests.remove_rewarded_ads;
    }

    get taskCompletionRewardsEnabled() {
        return !!this.splitTests.task_completion_rewards;
    }

    get x1Enabled() {
        // see config.py for meaning
        return this.splitTests.x1;
    }

    get skipMissionPrice() {
        return this.splitTests.skip_mission_price || 1;
    }

    // TODO: optimize by not traversing all stories (save an id => index mapping)
    get storiesById() {
        const byId = {};
        (this.stories || []).forEach((x) => {
            byId[x.id] = x;
        });
        return byId;
    }

    get loggedIn() {
        return !!(this.player && this.player.loggedIn);
    }

    get playerEmail() {
        return (this.player && this.player.email) || null;
    }

    get playerCountry() {
        return (this.player && this.player.country) || null;
    }

    get playerReferralCode() {
        return (this.player && this.player.referralCode) || null;
    }

    get playerUsedReferralCode() {
        return !!(this.player && this.player.usedReferral);
    }

    get exploreMode() {
        return !!this.player?.categories;
    }

    get noAds() {
        return !!(this.player && this.player.noAds);
    }

    get completedStoriesCount() {
        return filter(this.stories, (x) => x.completed).length;
    }

    get storyLoadingFailed() {
        return this.storyLoadingError || this.playerInfoLoadingFailed;
    }

    get isPhoneLocked() {
        return !!(
            this.story &&
            this.storyState &&
            this.story.settings &&
            this.story.settings.lockScreen &&
            !this.storyState.phoneUnlocked
        );
    }

    get isPhoneOnline() {
        return !!(
            (this.story && this.story.settings && this.story.settings.online) ||
            (this.storyState && this.storyState.phoneOnline)
        );
    }

    get nextStoryId() {
        const isStoryCompletedOrSkipped = (storyId) => {
            const story = this.storiesById[storyId];
            return !!(story.completed || story.skipped);
        };

        if (!this.storyTree) {
            return null;
        }

        for (let i = 0; i < this.storyTree.length; i += 1) {
            const series = this.storyTree[i];
            if (!series.story_ids.every(isStoryCompletedOrSkipped)) {
                return series.id;
            }
        }
        return null;
    }

    get isNoDataMode() {
        return !!(this.story && this.story.no_data);
    }

    get batteryLevel() {
        if (this.globalBatteryEnabled) {
            return this.player.battery;
        }
        if (typeof this.storyState?.batteryLevel === "number") {
            return this.storyState.batteryLevel;
        }
        return 73; // a magical default
    }

    get globalBatteryEnabled() {
        return !!(
            this.config?.battery &&
            !this.story?.community &&
            !this.story?.template &&
            (Native.HAVE_NATIVE || DEBUG)
        );
    }

    get firstFreeBatteryAvailable() {
        return !!this.config?.battery_first_free;
    }

    get unlimitedBatterySecondsLeft() {
        return this.player?.batteryUnlimitedSec || 0;
    }

    get unlimitedBatteryActive() {
        return (
            this.unlimitedBatterySecondsLeft > 0 ||
            this.storyUnlimitedBatteryActive
        );
    }

    get storyUnlimitedBatteryActive() {
        return this.story && this.story.unlimited_battery;
    }

    get batteryDepletionEventsForStoryEnabled() {
        return !!this.story?.triggers_battery_depletion_event;
    }

    get wasBatteryDepletionEventFired() {
        return !!this.storyState?.allowBatteryDepletion;
    }

    get stuckBatteryOnOnePrecent() {
        return (
            this.batteryDepletionEventsForStoryEnabled &&
            this.batteryLevel === 1 &&
            !this.wasBatteryDepletionEventFired
        );
    }

    get shouldDecreaseGlobalBattery() {
        return !!(
            this.story &&
            this.storyState &&
            !this.story.community &&
            !this.story.template &&
            !this.story.unlimited_battery &&
            !(
                this.story.introduction &&
                this.batteryLevel <= MIN_BATTERY_FOR_INTRO_LEVELS
            ) &&
            !this.stuckBatteryOnOnePrecent &&
            !this.unlimitedBatteryActive &&
            !this.storyState?.videoCall &&
            !this.storyState?.failure &&
            !UiState.batteryModalVisible &&
            !UiState.successModalVisible &&
            !UiState.hintsModalVisible &&
            !UiState.settingsModalVisible &&
            !UiState.isAppPaused
        );
    }

    get shouldRefreshGlobalBattery() {
        return !!(
            this.player &&
            this.globalBatteryEnabled &&
            (UiState.batteryModalVisible || !this.story)
        );
    }

    get globalBatteryRechargeInfo() {
        return this.player?.batteryRecharge;
    }

    get trophiesUnlocked() {
        return this.completedStoriesCount >= MIN_STORIES_COMPLETED_FOR_TROPHIES;
    }

    get ecoIncentiveStatus() {
        return this.config?.eco_incentive;
    }

    get dailyRewardStatus() {
        const status = this.config?.daily_rewards;
        if (!status) {
            return {
                enabled: false,
            };
        }
        return {
            enabled: true,
            rewards: status.rewards,
            currentDay: status.current_day,
            available: status.is_available,
        };
    }

    handleLobbyLoaded() {
        // TODO: trigger from player info loaded
        this.showDailyRewardPopupIfNeeded();
    }

    getAppVisibility(appName, defaultVisibility = true) {
        if (!this.storyState) {
            return defaultVisibility;
        }
        const visibility = this.storyState.appVisibility[appName];
        return typeof visibility === "boolean" ? visibility : defaultVisibility;
    }

    getStorySeriesById(id) {
        if (!this.storyTree) {
            return null;
        }
        return find(this.storyTree, (x) => x.id === id);
    }

    getStorySeriesByStoryId(storyId) {
        if (!this.storyTree) {
            return null;
        }
        return (
            find(this.storyTree, (x) => x.story_ids.includes(storyId)) || null
        );
    }

    storyStarted(storyId) {
        return !!(this.storyStates && this.storyStates[storyId]);
    }

    storyHasProgress(storyId) {
        const state = this.storyStates && this.storyStates[storyId];
        if (!state) {
            return false;
        }

        if (
            state.phoneUnlocked ||
            state.phoneOnline ||
            state.firedEvents.length > 0 ||
            state.queuedEvents.length > 0 ||
            !isEmpty(state.unlockedApps)
        ) {
            return true;
        }

        const appNames = keys(state.appStates);
        for (let i = 0; i < appNames.length; i += 1) {
            const appName = appNames[i];
            const appStore = APP_STORES[appName];
            if (appStore && appStore.hasProgress(state.appStates[appName])) {
                return true;
            }
        }

        return false;
    }

    storyCompleted(storyId) {
        return this.storiesById[storyId]?.completed;
    }

    getStoryUnlockTimeSeconds(storyId) {
        const now = dayjs().unix();

        let unlockTimes = this.userSettings.unlockTimes || {};
        if (isUndefined(unlockTimes[storyId])) {
            const newUnlockTime = now + STORY_LOCK_TIME_MINUTES * 60;
            unlockTimes = { ...unlockTimes, [storyId]: newUnlockTime };
            this.updateUserSettings("unlockTimes", unlockTimes);
        }

        return Math.max(0, unlockTimes[storyId] - now);
    }

    shouldTimeLockStory(storyId) {
        if (this.noAds) {
            return false;
        }

        if (this.globalBatteryEnabled) {
            return false;
        }

        if (this.storyStates && this.storyStates[storyId]) {
            return false;
        }

        const storyIndex = findIndex(this.stories, (x) => x.id === storyId);
        if (!TIME_LOCKED_STORY_INDEXES.includes(storyIndex)) {
            return false;
        }

        return this.getStoryUnlockTimeSeconds(storyId) > 0;
    }

    configure({ shouldSendStateToServer, shouldShowIntroNotification }) {
        if (shouldSendStateToServer !== undefined) {
            this.shouldSendStateToServer = shouldSendStateToServer;
        }
        if (shouldShowIntroNotification !== undefined) {
            this.shouldShowIntroNotification = shouldShowIntroNotification;
        }
    }

    loadUserSettingsFromStorage() {
        const settings = store.get("userSettings");
        if (typeof settings === "object") {
            this.userSettings = settings;
        }
    }

    loadStateFromServer() {
        this.playerInfoLoadingFailed = false;
        Server.getPlayerInfo()
            .then(({ data }) => this.handlePlayerInfo(data))
            .catch((error) => this._handleLoadingError(error));
    }

    handlePlayerInfo(info) {
        this.config = info.config || {};
        this.player = {
            id: info.id,
            loggedIn: info.logged_in,
            email: info.email,
            coins: info.coins,
            referralCode: info.referral_code,
            usedReferral: info.used_referral_code,
            country: info.country,
            noAds: info.no_ads,
            battery: info.battery,
            batteryRecharge: info.battery_recharge,
            batteryUnlimitedSec: info.battery_unlimited_sec,
            categories: info.categories,
        };
        this.stories = sortStoriesBy(
            info.stories.map(decodeStory),
            (info.story_order || {})[SPLIT_TEST_VALUES.storyOrder]
        );
        this.storyTree = info.story_tree.map(decodeStory);

        if (
            typeof info.settings === "object" &&
            (!this.userSettings.time ||
                info.settings.time > this.userSettings.time)
        ) {
            this.userSettings = info.settings;
        }

        const states = info.storyStates || store.get("storyStates") || {};
        this.storyStates = mapValues(states, (state) => ({
            ...DEFAULT_STORY_STATE,
            ...state,
        }));

        this.updateOffers(info.config?.offers);

        // TODO: make each of these return a promise, then
        // chain the popups to appear one after the other.
        this.showIntroNotification(info.intro_popup);
        this.showCustomOfferPopupIfAny();

        forEach(info.dimensions, (value, dimension) => {
            setCustomDimension(dimension, value);
        });

        this.playerInfoLoaded = true;

        if (this.player.id) {
            Native.setUserId(this.player.id);
        }
    }

    _handleLoadingError() {
        this.playerInfoLoadingFailed = true;
    }

    updateUserSettings(key, value) {
        this.userSettings[key] = value;
        this.userSettings.time = new Date().getTime();
    }

    updateOffers(serverOffers) {
        this.offers = (serverOffers || []).map((offer) => ({
            offerId: offer.id,
            iapItemId: offer.product_id,
            endDate: parseUtcTimestamp(offer.expires),
        }));
    }

    updateEcoIncentiveStatus(ecoIncentiveData) {
        if (this.config && ecoIncentiveData) {
            this.config.eco_incentive = ecoIncentiveData;
        }
    }

    unlockStoryTimeLock(storyId) {
        const unlockTimes = { ...this.userSettings.unlockTimes, [storyId]: 0 };
        this.updateUserSettings("unlockTimes", unlockTimes);
    }

    setNextStorySettings({ resetState, noData }) {
        if (resetState !== undefined) {
            this._nextStorySettings.resetState = resetState;
        }
        if (noData !== undefined) {
            this._nextStorySettings.noData = noData;
        }
    }

    loadStory(storyId, options) {
        const { loadState } = options || {};

        this.story = null; // unload previous story, if any
        this.storyLoadingError = false;

        if (!this.playerInfoLoaded) {
            setTimeout(() => this.loadStory(storyId, options), 500);
            return;
        }

        Server.getStory(storyId, {
            returnState: loadState,
            ...this._nextStorySettings,
        })
            .then(({ data }) =>
                runInAction(() => {
                    if (typeof data.battery === "number") {
                        this.player.battery = data.battery;
                    }
                    if (loadState && data.state) {
                        this.storyStates[storyId] = {
                            ...DEFAULT_STORY_STATE,
                            ...data.state,
                        };
                    }
                    this.loadStoryFromData(decodeStory(data.story));
                })
            )
            .catch((error) => {
                if (process.env.NODE_ENV !== "production") {
                    console.error(error); // eslint-disable-line
                }
                runInAction(() => {
                    this.storyLoadingError = true;
                });
            });
    }

    retryLoadingStory(storyId) {
        if (this.playerInfoLoadingFailed) {
            this.loadStateFromServer();
        }
        this.loadStory(storyId);
    }

    loadStoryFromData(story, options) {
        options = options || {};
        const { resetState, noData } = this._nextStorySettings;

        // don't reset battery level
        const existingBatteryLevel = this.storyStates[story.id]?.batteryLevel;
        if (
            !has(this.storyStates, story.id) ||
            options.resetState ||
            resetState
        ) {
            this.storyStates[story.id] = cloneDeep(DEFAULT_STORY_STATE);
        }

        this.storyState = this.storyStates[story.id];
        this.storyState.batteryLevel =
            typeof existingBatteryLevel === "number"
                ? existingBatteryLevel
                : story.starting_battery_level;
        Object.assign(
            this.storyState,
            pick(DEFAULT_STORY_STATE, [
                "musicPaused",
                "answerValue",
                "answerError",
                "sessionStorage",
            ])
        );

        this._clearEventTimeouts();

        if (!options.keepAppStores) {
            this._disposeAppStores();
            this._initAppStores(story);
        }

        UiState.hideAlert();
        this._nextStorySettings = {};

        // this should be called last
        this.story = story;

        if (!options.noEvents) {
            storyStartedEvent(story.id);
            if (noData) {
                storyNoDataEvent(story.id);
            }
        }

        if (this.globalBatteryEnabled && this.batteryLevel === 0) {
            UiState.toggleBatteryModal(true);
        }
    }

    resetStoryState() {
        const batteryLevel = this.story?.starting_battery_level || 90;
        this.storyState = { ...DEFAULT_STORY_STATE, batteryLevel };
        if (this.story) {
            this._disposeAppStores();
            this._initAppStores(this.story);
        }
    }

    unloadStory() {
        UiState.hideSuccessModal();
        UiState.toggleHintsModal(false);
        this.story = null;
        this._disposeAppStores();
        this._clearEventTimeouts();
    }

    restartStory(history) {
        if (!this.story) {
            return;
        }
        const storyId = this.story.id;
        this.navigateToHomeScreen(history);
        this.unloadStory();
        this.setNextStorySettings({ resetState: true });
        this.loadStory(storyId);
    }

    playMusicTrack(trackName) {
        this.storyState.musicTrack = trackName;
    }

    fireConfetti(confettiProps) {
        if (this.storyStates) {
            this.storyState.confettiProps = confettiProps;
        }
    }

    clearConfetti() {
        if (this.storyStates) {
            this.storyState.confettiProps = {};
        }
    }

    pauseMusic() {
        this.storyState.musicPaused = true;
    }

    resumeMusic() {
        this.storyState.musicPaused = false;
    }

    tutorialNext() {
        if (this.storyState) {
            this.storyState.tutorialStep += 1;
        }
    }

    fireEvent(eventName, options) {
        const { delayMs, ignoreEventDelay } = options || {};

        if (!this.story || !this.story.events) {
            return;
        }

        if (delayMs) {
            this.storyState.queuedEvents.push({
                eventName,
                options: { ...options, delayMs: 0 },
                time: Date.now() + delayMs,
            });
            setTimeout(() => this.dequeueEvents(), delayMs);
            return;
        }

        const event = this.story.events[eventName];

        if (!event || this._wasEventFiredOrQueued(eventName)) {
            return;
        }

        if (
            event.unlessEvent &&
            this._wasEventFiredOrQueued(event.unlessEvent)
        ) {
            return;
        }

        if (event.delayMs && !ignoreEventDelay) {
            this.storyState.queuedEvents.push({
                eventName,
                options: { ...options, ignoreEventDelay: true },
                time: Date.now() + event.delayMs,
            });
            return;
        }

        if (event.fireEvent) {
            this.fireEvent(event.fireEvent.name, {
                delayMs: event.fireEvent.delayMs,
            });
        }

        if (event.completeStory) {
            this.completeStory({
                storyId: this.story.id,
                ...event.completeStory,
                notifyServer: true,
            });
        }

        if (event.failStory) {
            this.failStory(event.failStory);
        }

        if (event.fireConfetti) {
            this.fireConfetti({ ...event.fireConfetti });
        }

        if (event.playMusic) {
            this.playMusicTrack(event.playMusic);
        }

        if (event.sendChatHiddenMessage) {
            const { appName, chatId, messageText } =
                event.sendChatHiddenMessage;
            this._appStores[appName].sendHiddenMessage(chatId, messageText);
        }

        if (event.sendChatOutgoingMessage) {
            const { appName, chatId, messageText, typingDelay } =
                event.sendChatOutgoingMessage;
            this._appStores[appName].typeAndSendOutgoingMessage(
                chatId,
                messageText,
                typingDelay
            );
        }

        if (event.sendChatOutgoingImageMessage) {
            const { appName, chatId, imageUrl } =
                event.sendChatOutgoingImageMessage;
            this._appStores[appName].sendOutgoingImageMessage(chatId, imageUrl);
        }

        if (event.showNewsArticle) {
            const { appName, articleId } = event.showNewsArticle;
            this._appStores[appName].showArticle(articleId);
        }

        if (event.showEmail) {
            const { appName, threadId } = event.showEmail;
            this._appStores[appName].showThread(threadId);
        }

        if (event.incomingVideoCall) {
            this.triggerIncomingVideoCall(event.incomingVideoCall);
        }

        if (event.hideApps) {
            event.hideApps.forEach((appName) =>
                this.toggleAppVisibility(appName, false)
            );
        }

        if (event.revealApps) {
            event.revealApps.forEach((appName) =>
                this.toggleAppVisibility(appName, true)
            );
        }

        if (event.requiredProgressMade) {
            this.storyState.requiredProgressMade = true;
        }

        if (event.allowBatteryDepletion) {
            this.storyState.allowBatteryDepletion = true;
        }

        if (event.alert) {
            const { text, title, options: alertOptions } = event.alert;
            UiState.showAlert(text, title, alertOptions);
        }

        if (event.tutorial) {
            this.storyState.tutorialStep = 0;
            this.storyState.tutorial = event.tutorial;
        }

        (event.appEvents || []).forEach(
            ({ appName, eventName: appEventName, ...eventProps }) => {
                const appStore = this._appStores[appName];
                if (!appStore || !appStore[appEventName]) {
                    return;
                }
                appStore[appEventName].call(appStore, eventProps);
            }
        );

        this.storyState.firedEvents.push(eventName);
    }

    _wasEventFiredOrQueued(eventName) {
        return (
            this.storyState.firedEvents.includes(eventName) ||
            this.storyState.queuedEvents.includes(eventName)
        );
    }

    dequeueEvents() {
        if (!this.storyState || !this.storyState.queuedEvents) {
            return;
        }
        const now = Date.now();
        this.storyState.queuedEvents.forEach(({ eventName, options, time }) => {
            if (
                time <= now &&
                !this.storyState.firedEvents.includes(eventName)
            ) {
                this.fireEvent(eventName, options);
            }
        });
    }

    triggerIncomingVideoCall({ videoUrl, name, declineEvent, endEvent }) {
        if (this.storyState) {
            this.storyState.videoCall = {
                videoUrl,
                name,
                declineEvent,
                endEvent,
            };
        }
    }

    clearVideoCall() {
        if (this.storyState) {
            this.storyState.videoCall = null;
        }
    }

    unlockPhone() {
        this.storyState.phoneUnlocked = true;
        this.handleTaskCompletionEvent("unlock_phone");
    }

    togglePhoneOnline(isOnline) {
        this.storyState.phoneOnline = isOnline;
    }

    unlockApp(appName) {
        this.storyState.unlockedApps[appName] = true;
        this.handleTaskCompletionEvent(`unlock_app_${appName}`);
    }

    trackPasswordEvent(appName, password, isSucess) {
        eventPasswordAttempt(this.story.id, appName, password, isSucess);
    }

    toggleAppVisibility(appName, toggle) {
        this.storyState.appVisibility[appName] = toggle;
    }

    updateAnswerValue(newValue) {
        this.storyState.answerValue = newValue;
    }

    submitAnswer(storyId) {
        this.storyState.checkingAnswer = true;

        Server.checkAnswer({ storyId, answer: this.storyState.answerValue })
            .then(({ data }) => this._handleSubmittedAnswer(storyId, data))
            .catch(() =>
                this._handleSubmittedAnswer(storyId, {
                    text: "Failed checking answer, please try again.",
                })
            )
            .finally(() =>
                runInAction(() => {
                    this.storyState.checkingAnswer = false;
                })
            );
    }

    _handleSubmittedAnswer(storyId, responseData) {
        const {
            success,
            text,
            epilogue,
            image,
            wt,
            awards,
            state,
            trophy: encodedTrophy,
            offers,
            eco_incentive: ecoIncentive,
            story_tree: storyTree,
        } = responseData;

        if (success) {
            const trophy = encodedTrophy ? decodeStory(encodedTrophy) : null;
            this.completeStory({
                storyId,
                text,
                epilogue,
                image,
                trophy,
                awards,
                storyTree,
            });
            if (offers) {
                this.updateOffers(offers);
            }
            this.updateEcoIncentiveStatus(ecoIncentive, true);
            this.updateGlobalBatteryState({
                battery: state?.battery,
                batteryRecharge: state?.battery_recharge,
            });
        } else {
            if (wt) {
                setCustomDimension(10, "yes");
                storyUsedWalkthroughEvent(storyId);
            }
            this.storyState.answerError = text;
        }
    }

    completeStory({
        storyId,
        text,
        epilogue,
        image,
        trophy,
        awards,
        storyTree,
        notifyServer,
    }) {
        if (storyTree) {
            this.storyTree = storyTree.map(decodeStory);
        }
        extend(this.storyState, {
            answerError: null,
            feedbackFormType: weightedChoice([
                ["difficulty", 1],
                ["rating", 4],
            ]),
        });
        UiState.showSuccessModal({
            title: text,
            text: epilogue,
            image,
            trophy,
            awards,
        });
        this.markStoryAsCompleted(storyId);
        storyCompletedEvent(storyId);
        if (notifyServer) {
            Server.completeStory({ storyId });
        }
    }

    markStoryAsCompleted(storyId) {
        if (this.storiesById[storyId]) {
            this.storiesById[storyId].completed = true;
        }
        if (this.storyTree) {
            const series = this.getStorySeriesByStoryId(storyId);
            if (series) {
                series.completed = series.story_ids.every(
                    (id) => this.storiesById[id].completed
                );
            }
        }
    }

    markStoryAsSkipped(storyId) {
        this.storiesById[storyId].skipped = true;
        if (this.storyTree) {
            const series = this.getStorySeriesByStoryId(storyId);
            if (series) {
                series.skipped = series.story_ids.every(
                    (id) => this.storiesById[id].skipped
                );
            }
        }
    }

    failStory({ title, text, image }) {
        if (this.storyState) {
            this.storyState.failure = { title, text, image };
        }
    }

    handleMissionFinishedEvent() {
        const shown = this.showSignupPopupIfNeeded();
        if (!shown) {
            this.showCustomOfferPopupIfAny();
        }
    }

    handleTaskCompletionEvent(taskName) {
        if (!this.story) {
            return;
        }
        const taskId = `${this.story.id}__${taskName}`;
        localforage.getItem(taskId).then((value) => {
            if (!value) {
                this.grantTaskCompletionReward();
                localforage.setItem(taskId, "true");
            }
        });
    }

    grantTaskCompletionReward() {
        if (!this.taskCompletionRewardsEnabled) {
            return;
        }

        const [rewardType, rewardAmount] = weightedChoice(
            TASK_COMPLETION_REWARD_WEIGHTS
        );
        if (!rewardType) {
            return;
        }
        if (rewardType === "battery" && !this.globalBatteryEnabled) {
            return;
        }

        UiState.showReward(rewardType, rewardAmount);
        setTimeout(() => {
            this.applyReward(rewardType, rewardAmount);
        }, 3000);
    }

    applyReward(rewardType, rewardAmount) {
        switch (rewardType) {
            case "battery":
                this.setBatteryLevel(this.player.battery + rewardAmount);
                Server.increaseBatteryLevel("reward", rewardAmount);
                break;
            case "hints":
                // assuming rewardAmount == 1
                Server.increaseHintBalance();
                break;
            default:
                break;
        }
    }

    showSignupPopupIfNeeded() {
        if (this.loggedIn) {
            return false;
        }
        const shouldShow = shouldShowSignupPopup({
            completedStoriesCount: this.completedStoriesCount,
        });
        if (shouldShow) {
            UiState.toggleSignupPopup(true);
        }
        return shouldShow;
    }

    showCustomOfferPopupIfAny(force) {
        if (this.offers.length === 0) {
            return;
        }
        if (customOfferPopupShown && !force) {
            return;
        }
        UiState.showCustomOfferModal(this.offers[0]);
        customOfferPopupShown = true;
    }

    showDailyRewardPopupIfNeeded() {
        if (this.dailyRewardStatus.enabled && !dailyRewardPopupShown) {
            UiState.toggleDailyRewardModal(true);
            dailyRewardPopupShown = true;
        }
    }

    subscribeForUpdates(email, phone) {
        Server.subscribeForNewMissions(email, phone);
        UiState.hideSubscribeModal();
        UiState.showAlert("Thank you for subscribing!");
        subscribeForNewMissionsEvent();
    }

    subscribeForBetaTesting(email, phone) {
        Server.subscribeForBetaTesting(email, phone);
        UiState.hideSubscribeModal();
        UiState.showAlert("Thank you for subscribing!");
        subscribeForBetaTestingEvent();
    }

    activateReferralCode(code) {
        Server.activateReferralCode(code)
            .then(({ data }) =>
                runInAction(() => {
                    const { success, error } = data;
                    if (success) {
                        this.player.usedReferral = true;
                        this.reloadHintList(); // reload hint balance
                        appliedReferralCodeEvent();
                    } else {
                        UiState.setReferralModelError(error);
                        enteredInvalidReferralCodeEvent(error);
                    }
                })
            )
            .catch(() =>
                runInAction(() => {
                    const error = "UNEXPECTED";
                    UiState.setReferralModelError(error);
                    enteredInvalidReferralCodeEvent(error);
                })
            );
    }

    toggleHintsModal(toggle) {
        // TODO: hints should not be part of storyState (maybe UiState?)
        if (this.storyState) {
            this.storyState.hints = null;
            UiState.toggleHintsModal(toggle);
        }
    }

    reloadHintList() {
        if (!this.storyState) {
            return;
        }

        this.storyState.hints = null;
        this.storyState.hintBalance = null;
        const storyId = this.story.id;
        Server.listHints(storyId)
            .then(({ data }) => {
                const { hints, hint_balance: hintBalance } = data;
                this._handleHintList(storyId, hints, hintBalance);
            })
            .catch(() => this._handleHintList(storyId, "error"));
    }

    _handleHintList(storyId, hints, hintBalance) {
        if (!this.storyState || !this.story || this.story.id !== storyId) {
            return;
        }

        this.storyState.hints = hints;
        this.storyState.hintBalance = hintBalance;
    }

    unlockHint(hintId) {
        if (!this.story || !this.storyState || this.storyState.requestingHint) {
            return;
        }

        this.storyState.requestingHint = true;

        const storyId = this.story.id;
        Server.unlockHint(storyId, hintId)
            .then(({ data }) => {
                if (data.error) {
                    UiState.showHintUnlockError();
                } else {
                    this._handleHintList(
                        storyId,
                        data.hints,
                        data.hint_balance
                    );
                    hintUnlockEvent(storyId, hintId);
                }
            })
            .catch(() => UiState.showHintUnlockError())
            .finally(() => this.handleHintFinally());
    }

    handleHintFinally() {
        this.storyState.requestingHint = false;
    }

    skipStory(history) {
        if (!this.story || !this.storyState || this.storyState.requestingHint) {
            return;
        }

        this.storyState.requestingHint = true;

        const storyId = this.story.id;
        Server.skipStory(storyId)
            .then(({ data }) =>
                runInAction(() => {
                    if (data.error) {
                        UiState.showSkipStoryError();
                    } else {
                        this.markStoryAsSkipped(storyId);
                        this.navigateToWelcomeScreen(history);
                        storySkippedEvent(storyId);
                    }
                })
            )
            .catch(() => UiState.showSkipStoryError())
            .finally(() => this.handleHintFinally());
    }

    // TODO: remove and use the new method below
    increaseHintBalanceUnsafe() {
        return Server.increaseHintBalanceUnsafe().then(({ data }) =>
            this.handleIncreaseHintBalanceResponse(data.hint_balance)
        );
    }

    increaseHintBalance() {
        return Server.increaseHintBalance().then(({ data }) =>
            this.handleIncreaseHintBalanceResponse(data.hint_balance)
        );
        // TODO: handle error (.catch)
    }

    handleIncreaseHintBalanceResponse(newHintBalance) {
        if (this.storyState) {
            this.storyState.hintBalance = newHintBalance;
        }
    }

    removeAds() {
        if (this.player) {
            this.player.noAds = true;
        }
    }

    exitStory(history) {
        if (this.story) {
            UiState.confirmExitStory({
                onExit: () => {
                    // this.story can be null if user clicked "back" on the browser while
                    // the confirm dialog is open.
                    // TODO: prevent this from happening
                    this.exitStoryNoConfirm(history);
                },
            });
        }
    }

    exitStoryNoConfirm(history) {
        if (!this.story) {
            return;
        }

        let storyId = this.story.id;
        const series = this.getStorySeriesByStoryId(storyId);
        if (series) {
            storyId = series.id;
        }

        history.push(`/story-brief/${storyId}`);
    }

    updateGlobalBatteryState({ battery, batteryRecharge }) {
        if (!this.player) {
            return;
        }

        if (typeof battery === "number") {
            if (this.player.battery !== battery) {
                this.player.battery = battery;
            }
        }

        if (Array.isArray(batteryRecharge) && batteryRecharge.length === 2) {
            this.player.batteryRecharge = batteryRecharge;
        }
    }

    _updateBatteryLevelFromServer() {
        if (!this.shouldRefreshGlobalBattery) {
            return;
        }

        Server.getBatteryLevel().then((response) =>
            runInAction(() => {
                if (!response) {
                    return;
                }
                const { data } = response;
                if (!data) {
                    return;
                }
                this.updateGlobalBatteryState({
                    battery: data.battery,
                    batteryRecharge: data.battery_recharge,
                });
            })
        );
    }

    _globalBatteryRechargeCountdown() {
        if (!this.globalBatteryEnabled && !this.player?.batteryRecharge) {
            return;
        }

        let currentValue = this.player.batteryRecharge[0];
        if (currentValue === 0) {
            return;
        }

        currentValue -= 1;
        this.player.batteryRecharge[0] = currentValue;
        if (currentValue === 0) {
            this._updateBatteryLevelFromServer();
        }
    }

    _decreaseBatteryLevel() {
        if (this.globalBatteryEnabled) {
            if (this.shouldDecreaseGlobalBattery && this.player.battery > 0) {
                const newLevel = this.player.battery - 1;
                this.player.battery = newLevel;
                Server.updateBatteryLevel(newLevel);
                if (
                    newLevel === 0 ||
                    this.config.battery_modal_levels.includes(newLevel)
                ) {
                    UiState.toggleBatteryModal(true);
                }
            }
        } else if (this.story && this.storyState) {
            // TODO: get decrease rate from story
            const newLevel = Math.max(this.storyState.batteryLevel - 1, 10);
            if (newLevel !== this.storyState.batteryLevel) {
                this.storyState.batteryLevel = newLevel;
            }
        }
    }

    _updateBatteryUnlimitedLeft() {
        if (this.player?.batteryUnlimitedSec) {
            const newValue = this.player.batteryUnlimitedSec - 1;
            this.player.batteryUnlimitedSec = newValue;
            if (newValue === 0) {
                this._updateBatteryLevelFromServer();
            }
        }
    }

    _sendStoryStateToServer() {
        if (
            !this.shouldSendStateToServer ||
            this._sendingStateToServer ||
            this._pendingStoryState === null
        ) {
            return;
        }

        const [storyId, storyState, stateUuid] = this._pendingStoryState;
        this._sendingStateToServer = true;
        Server.updateStoryState(storyId, storyState).finally(() => {
            this._sendingStateToServer = false;

            // even on failure, stop trying to avoid an infinite loop
            const pendingUuid = (this._pendingStoryState || [])[2];
            if (pendingUuid === stateUuid) {
                // if state changed since last sent, don't reset
                this._pendingStoryState = null;
            }
        });
    }

    _getStoryPendingEvents() {
        if (this.story && this.story.server_events) {
            Server.listStoryPendingEvents(this.story.id).then((response) => {
                (response?.data?.events || []).forEach((evt) =>
                    this.fireEvent(evt.id)
                );
            });
        }
    }

    setSessionStorage(key, value) {
        if (this.storyState) {
            this.storyState.sessionStorage[key] = value;
        }
    }

    updateNoteText(text) {
        if (this.storyState) {
            this.storyState.noteText = text;
        }
    }

    showIntroNotification(type) {
        if (
            !this.shouldShowIntroNotification ||
            this.userSettings.viewdNotificationAlert
        ) {
            return;
        }

        UiState.toggleIntroNotification(true, type);
    }

    pushNotification(app, notificationId, { text }) {
        if (!this.storyState) {
            return;
        }

        const id = `${app.name}_${notificationId}`;
        this.storyState.notifications[id] = {
            id,
            date: new Date(),
            app: pick(app, ["title", "type", "name", "iconUrl"]),
            text: limitString(text, 80),
        };
    }

    clearNotification(fullNotificationId) {
        if (this.storyState) {
            delete this.storyState.notifications[fullNotificationId];
        }
    }

    dialPhoneNumber(number, history) {
        const { dialerApp } = this.story.settings;

        if (dialerApp) {
            const dialerStore = this.getAppStore(dialerApp);
            if (dialerStore) {
                dialerStore.setCurrentScreen("dialer");
                dialerStore.setDialerNumber(number);
            }
            this.navigateToApp(history, dialerApp);
            return;
        }

        if (isFakePhoneNumber(number) || this.isNoDataMode) {
            UiState.showAlert("You can't call this number right now.");
            return;
        }

        const callConfirmProps = {};
        if (Native.CAPABILITIES.dialPhoneNumber) {
            callConfirmProps.onYes = () =>
                Native.dialPhoneNumber(number, () => {
                    UiState.showAlert(
                        `Could not make the phone call. Please use a phone to dial **${number}**`
                    );
                });
        } else {
            callConfirmProps.yesHref = `tel:${number}`;
        }

        UiState.confirm({
            text: "This phone is offline. Want to call from your phone? Don't worry, this is part of the game 😎. Data rates may apply.",
            yesText: "Make the call",
            noText: "No",
            ...callConfirmProps,
        });
    }

    navigateToWelcomeScreen(history) {
        history.push("/");
    }

    navigateToNextStoryBrief(history) {
        const { nextStoryId } = this;
        if (nextStoryId) {
            history.push(`/story-brief/${nextStoryId}`);
        }
    }

    navigateToHomeScreen(history) {
        history.push(`/story/${this.story.id}`);
    }

    navigateToApp(history, appName) {
        history.push(`/story/${this.story.id}/${appName}`);
    }

    increaseBatteryLevel(type) {
        return new Promise((resolve, reject) => {
            Server.increaseBatteryLevel(type)
                .then(({ data }) =>
                    runInAction(() => {
                        this.setBatteryLevel(data.battery);
                        if (type === "first_free") {
                            this.config.battery_first_free = false;
                        }
                        resolve();
                    })
                )
                .catch(() => {
                    // TODO: better error
                    UiState.showAlert(
                        "An unexpected error has occurred. Please try again or contact support."
                    );
                    reject();
                });
        });
    }

    setBatteryLevel(batteryLevel) {
        this.player.battery = batteryLevel;
    }

    setBatteryUnlimitedSec(batteryUnlimitedSec) {
        this.player.batteryUnlimitedSec = batteryUnlimitedSec;
    }

    useUnlimitedBatteryForCurrentStory() {
        if (!this.story) {
            return;
        }

        const storyId = this.story.id;

        Server.activateStoryUnlimitedBattery(storyId)
            .then(() => {
                if (this.story.id === storyId) {
                    this.story.unlimited_battery = true;
                }
            })
            .catch(() => {
                UiState.showAlert(
                    "An unexpected error has occurred. Please restart the mission."
                );
            });
    }

    createEcoImpact() {
        return new Promise((resolve, reject) => {
            Server.createEcoImpact()
                .then(({ data }) => {
                    if (data.created) {
                        this.updateEcoIncentiveStatus(data.eco_incentive);
                    }
                    resolve(!!data.created);
                })
                .catch(() => {
                    reject();
                });
        });
    }

    claimDailyReward() {
        return new Promise((resolve, reject) => {
            Server.claimDailyReward()
                .then(({ data }) => {
                    if (data.applied) {
                        if (data.battery) {
                            this.updateGlobalBatteryState({
                                battery: data.battery,
                                batteryRecharge: data.battery_recharge,
                            });
                        }
                        // TODO: other reward types
                    }
                    if (data.applied) {
                        resolve(data);
                    } else {
                        reject();
                    }
                })
                .catch(() => {
                    reject();
                });
        });
    }

    deleteAccount(history) {
        return new Promise((resolve, reject) => {
            Server.deleteAccount()
                .then(() => {
                    this.navigateToWelcomeScreen(history);
                    window.location.reload();
                    resolve();
                })
                .catch(() => {
                    UiState.showAlert(
                        "Could not delete account. Please try again or contact us at [support@faintlines.com](mailto:support@faintlines.com)"
                    );
                    reject();
                });
        });
    }

    _initAppStores(story) {
        story.apps.forEach((app) => {
            const AppStoreClass = APP_STORES[app.type || app.name];
            if (AppStoreClass) {
                const existingAppState = toJS(
                    this.storyState.appStates[app.name]
                );
                const appStore = new AppStoreClass({
                    storyId: story.id,
                    existingState: existingAppState,
                    onEvent: this.fireEvent.bind(this),
                    app,
                    mainState: this,
                });
                this._appStores[app.name] = appStore;

                this._appStoresReactions.push(
                    reaction(
                        () => appStore.asJson,
                        (newState) => this._updateAppState(app.name, newState)
                    )
                );
            }
        });
    }

    _updateAppState(appName, newState) {
        const currentState = toJS(this.storyState.appStates[appName]);
        if (!isEqual(currentState, newState)) {
            this.storyState.appStates[appName] = newState;
        }
    }

    _disposeAppStores() {
        values(this._appStores).forEach((appStore) => appStore.dispose());
        this._appStores = {};

        this._appStoresReactions.forEach((disposer) => disposer());
        this._appStoresReactions = [];
    }

    _clearEventTimeouts() {
        this._eventTimeouts.forEach(clearTimeout);
        this._eventTimeouts = [];
    }
}

const appState = new AppState();
export default appState;

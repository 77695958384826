import "./FloatingNote.css";

import AppState from "../../state/AppState";
import UiState from "../../state/UiState";

import React from "react";
import { observer } from "mobx-react-lite";
import Draggable from "react-draggable";

const SIZE = 200;

function FloatingNote({ screenWidth, screenHeight }) {
    const { storyState } = AppState;

    if (!UiState.noteVisible || !storyState) {
        return null;
    }

    return (
        <Draggable handle=".FloatingNote-handle">
            <div
                className="FloatingNote"
                style={{
                    top: screenHeight / 5,
                    left: (screenWidth - SIZE) / 2,
                    width: SIZE,
                    height: SIZE,
                }}
            >
                <div className="FloatingNote-handle">
                    <div className="FloatingNote-move icon-move" />
                    {"Note"}
                </div>
                <textarea
                    className="FloatingNote-textbox data-hj-allow"
                    placeholder="You can write anything you want here."
                    value={storyState.noteText}
                    spellCheck="false"
                    maxLength={1000}
                    onChange={(evt) =>
                        AppState.updateNoteText(evt.target.value)
                    }
                />
                <div
                    className="FloatingNote-close icon-cancel"
                    onClick={() => UiState.toggleNote(false)}
                />
            </div>
        </Draggable>
    );
}

export default observer(FloatingNote);

import { WeakPRNG } from "utils/randomUtils";
import AREA_CODES from "utils/areaCodes";

import { entries, isArray, isObjectLike } from "lodash";

export const HOTJAR_ALLOW_CLASS_NAME = "data-hj-allow";

export function traverse(obj, fn) {
    traverseInternal(obj, fn, []);
}

function traverseInternal(obj, fn, scope) {
    let objEntries = null;

    if (isObjectLike(obj)) {
        objEntries = entries(obj);
    } else if (isArray(obj)) {
        objEntries = obj.map((x, i) => [i, x]);
    } else {
        return;
    }

    objEntries.forEach(([key, value]) => {
        fn.apply(this, [obj, key, value, scope]);
        if (isObjectLike(value) || isArray(value)) {
            traverseInternal(value, fn, scope.concat(key));
        }
    });
}

export class PhoneNumberGenerator {
    constructor() {
        this.random = WeakPRNG(818181818);
        this._numbers = {};
        this._used = {};
    }

    contactNumberToString({ id, type, number }) {
        switch (type) {
            case "none":
                return null;
            case "auto":
                return this.randomPhoneNumber(id, type);
            case "autoAreaCode":
                return this.randomPhoneNumber(id, type, number);
            case "custom":
                return number;
            default:
                return null;
        }
    }

    randomPhoneNumber(id, type, areaCode) {
        const key = `${id}_${type}__${areaCode}`;
        if (!this._numbers[key]) {
            if (!areaCode) {
                areaCode = AREA_CODES[this.randInt(0, AREA_CODES.length - 1)];
            }
            const suffix = String(this.randInt(0, 100)).padStart(2, "0");
            this._numbers[key] = `${areaCode}-555-01${suffix}`;
        }
        return this._numbers[key];
    }

    randInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(this.random() * (max - min + 1)) + min;
    }
}

import { makeAutoObservable, set } from "mobx";
import { pickToJs } from "utils/mobxUtils";

import { isEmpty } from "lodash";

export default class SmartHomeAppState {
    constructor({ existingState }) {
        makeAutoObservable(this);

        if (SmartHomeAppState.hasProgress(existingState)) {
            set(this, existingState);
        }
    }

    dispose() {}

    unlocked = false;
    deviceState = {};

    static hasProgress(state) {
        return state && (state.unlocked || !isEmpty(state.deviceState));
    }

    /// / Computed

    get asJson() {
        return pickToJs(this, ["deviceState", "unlocked"]);
    }

    /// / Actions

    unlock() {
        this.unlocked = true;
    }

    setDeviceState(deviceId, state) {
        this.deviceState[deviceId] = state;
    }
}

import { useEffect } from "react";
import { v4 as uuid } from "uuid";

const AudoIdHiddenWidget = ({ value, onChange }) => {
    useEffect(() => {
        if (!value) {
            setTimeout(() => onChange(uuid()), 100);
        }
    }, [value, onChange]);

    return null;
};

export default AudoIdHiddenWidget;

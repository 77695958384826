import "./MediaSlider.css";
import GestureDetector from "./GestureDetector";

import React, { useState, useCallback, useRef } from "react";
import classnames from "classnames";

export default function MediaSlider({
    items,
    defaultIndex,
    disabled,
    onSlide,
}) {
    const numItems = items.length;

    const [index, setIndex] = useState(defaultIndex);
    const [deltaX, setDeltaX] = useState(0);
    const [dragging, setDragging] = useState(false);

    const indexRef = useRef(defaultIndex);
    const sliderRef = useRef();

    const translateX = `${(index / numItems) * -100}%`;

    const slideTo = useCallback(
        (newIndex) => {
            indexRef.current = newIndex;
            setIndex(newIndex);
            if (onSlide) {
                onSlide(newIndex);
            }
        },
        [setIndex, onSlide]
    );

    const panMoveHandler = useCallback(
        (evt) => {
            if (!disabled) {
                setDeltaX(evt.deltaX);
            }
        },
        [disabled]
    );

    const panStartHandler = useCallback(() => {
        if (!disabled) {
            setDragging(true);
        }
    }, [disabled]);

    const panEndHandler = useCallback(
        (evt) => {
            if (disabled) {
                return;
            }
            const currentIndex = indexRef.current;
            setDragging(false);
            const currentWidth = sliderRef.current.parentElement.clientWidth;

            if (
                (evt.deltaX > currentWidth * 0.4 || evt.velocityX > 0.5) &&
                currentIndex > 0
            ) {
                slideTo(currentIndex - 1);
            } else if (
                (evt.deltaX < -currentWidth * 0.4 || evt.velocityX < -0.5) &&
                currentIndex < numItems - 1
            ) {
                slideTo(currentIndex + 1);
            }
            setDeltaX(0);
        },
        [disabled, slideTo, numItems]
    );

    return (
        <GestureDetector
            className="MediaSlider"
            onPanMove={panMoveHandler}
            onPanStart={panStartHandler}
            onPanEnd={panEndHandler}
            panThreshold={0}
        >
            <div
                ref={sliderRef}
                className={classnames("MediaSlider-inner", { dragging })}
                style={{
                    width: `${numItems * 100}%`,
                    transform: `translateX(calc(${translateX} + ${deltaX}px))`,
                }}
            >
                {items.map((item, i) => (
                    <Item
                        key={i}
                        index={i}
                        item={item}
                        numItems={numItems}
                        currentIndex={index}
                    />
                ))}
            </div>
        </GestureDetector>
    );
}

function Item({ item, index, currentIndex, numItems }) {
    if (index < currentIndex - 1 || index > currentIndex + 1) {
        return null;
    }

    return (
        <div
            className="MediaSlider-item"
            style={{
                width: `${100 / numItems}%`,
                left: `${(index / numItems) * 100}%`,
            }}
        >
            {item}
        </div>
    );
}

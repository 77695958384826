import styles from "./StudioEditor.module.css";
import { useStudioStory } from "../StudioContext";
import AppsEditor from "./AppsEditor";
import ContactsEditor from "./ContactsEditor";
import MissionObjectiveEditor from "./MissionObjectiveEditor";
import SettingsEditor from "./SettingsEditor";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

const StudioEditor = observer(({ onStoryUpdate }) => {
    const story = useStudioStory();
    const [selectedTab, selectTab] = useState(0);

    if (!story) {
        return null;
    }

    const editorProps = { onStoryUpdate };

    return (
        <div className={styles.editor}>
            <Tabs
                className={styles.editor__tabs}
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(evt, newValue) => selectTab(newValue)}
                variant="fullWidth"
            >
                <Tab label="Apps" />
                <Tab label="Contacts" />
                <Tab label="Mission Objectives" />
                <Tab label="Settings" />
            </Tabs>
            <div className={styles.editor__content}>
                {selectedTab === 0 ? (
                    <AppsEditor {...editorProps} />
                ) : selectedTab === 1 ? (
                    <ContactsEditor {...editorProps} />
                ) : selectedTab === 2 ? (
                    <MissionObjectiveEditor {...editorProps} />
                ) : selectedTab === 3 ? (
                    <SettingsEditor {...editorProps} />
                ) : null}
            </div>
        </div>
    );
});

export default StudioEditor;

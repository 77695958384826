import { HAS_WEBP } from "../../../../utils/browserUtils";

import {
    IconPng as AppSocialIconPng,
    IconWebp as AppSocialIconWebp,
} from "@faintlines/phone-sim-app-social";
import {
    IconPng as AppBooksIconPng,
    IconWebp as AppBooksIconWebp,
} from "@faintlines/phone-sim-app-books";
import { IconPng as AppSettingsIconPng } from "@faintlines/phone-sim-app-settings";
import {
    IconPng as AppDownloaderIconPng,
    IconWebp as AppDownloaderIconWebp,
} from "@faintlines/phone-sim-app-downloader";

/* eslint-disable global-require */
export const APP_ICONS = {
    browser: require("images/icons/browser.png").default,
    photos: require("images/icons/photos.png").default,
    messages: require("images/icons/messages.png").default,
    phone: require("images/icons/phone.png").default,
    contacts: require("images/icons/contacts.png").default,
    notes: require("images/icons/notes.png").default,
    email: require("images/icons/email.png").default,
    streaming: require("images/icons/streaming.png").default,
    calculator: require("images/icons/calculator.png").default,
    authenticator: require("images/icons/authenticator.png").default,
    chat: require("images/icons/chat.png").default,
    findFriends: require("images/icons/findFriends.png").default,
    news: require("images/icons/news.png").default,
    vault: HAS_WEBP
        ? require("images/icons/vault.webp").default
        : require("images/icons/vault.png").default,
    pizza: HAS_WEBP
        ? require("images/icons/pizza.webp").default
        : require("images/icons/pizza.png").default,
    home: require("images/icons/home.png").default,
    calendar: require("images/icons/calendar.png").default,
    converter: require("images/icons/converter.png").default,
    social: HAS_WEBP ? AppSocialIconWebp : AppSocialIconPng,
    folder: require("images/icons/folder.png").default,
    dating: require("images/icons/dating.png").default,
    documents: require("images/icons/documents.png").default,
    delivery: HAS_WEBP
        ? require("images/icons/delivery.webp").default
        : require("images/icons/delivery.png").default,
    books: HAS_WEBP ? AppBooksIconWebp : AppBooksIconPng,
    settings: AppSettingsIconPng,
    downloader: HAS_WEBP ? AppDownloaderIconWebp : AppDownloaderIconPng,
};
/* eslint-enable */

export function getAppIconUrl(app) {
    return app.iconUrl || APP_ICONS[app.icon || app.type || app.name];
}

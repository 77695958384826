import styles from "./StudioPreview.module.css";
import { useStudioStory } from "./StudioContext";
import PhoneWrapper from "../Phone/PhoneWrapper";
import { PhoneView } from "../Phone/Phone";
import PhoneScreen from "../Phone/PhoneScreen";
import { AnswerFormView } from "../Phone/AnswerForm";
import { SideMenu } from "../common/SideMenu";
import UiState from "state/UiState";
import * as StudioApi from "server/studioApi";

import Button from "@material-ui/core/Button";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { MemoryRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

const StudioPreview = observer(({ story, onResetProgress }) => (
    <div className={styles.preview}>
        <MemoryRouter>
            <PhoneWrapper
                actions={<PreviewActions onResetProgress={onResetProgress} />}
            >
                {story ? (
                    <ErrorBoundary FallbackComponent={StudioPreviewError}>
                        <StudioPhone story={story} />
                    </ErrorBoundary>
                ) : (
                    "Loading..."
                )}
            </PhoneWrapper>
        </MemoryRouter>
    </div>
));

export default StudioPreview;

function StudioPhone({ story, onExit }) {
    const [menuOpen, toggleMenu] = useState(false);

    const closeMenu = () => toggleMenu(false);

    const handleMenuClick = (evt) => {
        evt.stopPropagation();
        toggleMenu(true);
    };

    return (
        <PhoneView menuOpen={menuOpen}>
            <StudioSideMenu visible={menuOpen} onClose={closeMenu} />
            <PhoneScreen
                story={story}
                menuOpen={menuOpen}
                onClick={closeMenu}
                onMenuClick={handleMenuClick}
                onExit={onExit}
            />
        </PhoneView>
    );
}

const StudioSideMenu = observer(({ visible }) => {
    const story = useStudioStory();

    const [value, setValue] = useState("");
    const [error, setError] = useState(null);
    const [submitting, toggleSubmitting] = useState(false);

    const handleSubmit = () => {
        toggleSubmitting(true);
        setError(null);

        StudioApi.checkAnswer(value, story.answers)
            .then(({ data }) => {
                if (data.success) {
                    UiState.showAlert("Success!");
                } else {
                    setError(data.text);
                }
            })
            .catch(() => {
                setError(
                    "Failed checking answer. Please check the mission settings and try again."
                );
            })
            .finally(() => toggleSubmitting(false));
    };

    return (
        <SideMenu>
            <AnswerFormView
                title={story.answerTitle}
                question={story.answerQuestion}
                error={error}
                placeholder={story.answerPlaceholder}
                value={value}
                disabled={!visible}
                submitting={submitting}
                onChange={setValue}
                onSubmit={handleSubmit}
            />
        </SideMenu>
    );
});

function StudioPreviewError() {
    return (
        <div>
            {"Failed rendering phone. Please check mission configuration"}
        </div>
    );
}

function PreviewActions({ onResetProgress }) {
    return (
        <div className={styles.preview__actions}>
            <Button color="primary" onClick={onResetProgress}>
                {"Reset progress"}
            </Button>
        </div>
    );
}

import {
    ENABLE_GOOGLE_ANALYTICS,
    GOOGLE_ANALYTICS_ID,
    ANDROID_APP_VERSION,
} from "./config";
import { SPLIT_TEST_VALUES } from "./utils/splitTesting";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

export function initAnalytics() {
    if (!ENABLE_GOOGLE_ANALYTICS) {
        return;
    }
    ReactGA.initialize(GOOGLE_ANALYTICS_ID);
    ReactGA.set({
        dimension6: ANDROID_APP_VERSION,
        dimension1: SPLIT_TEST_VALUES.storyOrder,
        dimension4: SPLIT_TEST_VALUES.storeItems,
        dimension7: SPLIT_TEST_VALUES.storyTimeLock,
        dimension8: SPLIT_TEST_VALUES.noFeedback,
    });
    pageView(window.location);
}

export function setCustomDimension(dimensionIndex, value) {
    if (!ENABLE_GOOGLE_ANALYTICS) {
        // eslint-disable-next-line
        console.info("Analytics custom dimension", { dimensionIndex, value });
        return;
    }

    const setObj = {};
    setObj[`dimension${dimensionIndex}`] = value;
    ReactGA.set(setObj);
}

export function pageView(location) {
    if (ENABLE_GOOGLE_ANALYTICS) {
        ReactGA.pageview(location.pathname + location.search);
    }
}

export const PageViewReporter = () => {
    const location = useLocation();
    useEffect(() => {
        pageView(location);
    }, [location]);
    return null;
};

export function introNotificationClosedEvent() {
    event({
        category: "Onboarding",
        action: "Closed Intro Notification",
    });
}

export function storyStartedEvent(storyId) {
    event({
        category: "Story Progression",
        action: "Started Story",
        label: storyId,
    });
}

export function ReadStoryBriefEvent(storyId, readMoreClicked) {
    event({
        category: "Story Progression",
        action: "Read Story Brief",
        label: storyId,
        value: readMoreClicked,
    });
}

export function storyNoDataEvent(storyId) {
    event({
        category: "Story Progression",
        action: "Started Story Without Data",
        label: storyId,
    });
}

export function storyCompletedEvent(storyId) {
    event({
        category: "Story Progression",
        action: "Completed Story",
        label: storyId,
    });
}

export function storySkippedEvent(storyId) {
    event({
        category: "Story Progression",
        action: "Skipped Story",
        label: storyId,
    });
}

export function storyUnlockWatchAd(reason) {
    event({
        category: "Story Progression",
        action: "Unlocked Story",
        label: reason,
    });
}

export function storyUnlockRemoveAdsEvent() {
    event({
        category: "Story Progression",
        action: "Remove Ads",
    });
}

export function batteryChargeFirstFreeEvent(storyId) {
    event({
        category: "Battery",
        action: "Claimed First Free",
        label: storyId,
    });
}

export function batteryChargeWatchAd(reason) {
    event({
        category: "Battery",
        action: "Watched Ad",
        label: reason,
    });
}

export function storyUsedWalkthroughEvent(storyId) {
    event({
        category: "Story Progression",
        action: "Used Walkthrough",
        label: storyId,
    });
}

export function eventPasswordAttempt(storyId, appName, password, isSuccess) {
    event({
        category: "Story Progression",
        action: `Password Attempt: ${storyId} ${appName}`,
        label: password,
        value: isSuccess ? 1 : 0,
    });
}

export function storyDifficultyFeedbackEvent(storyId, rating) {
    event({
        category: "Story Completed Feedback",
        action: "Rated Difficulty",
        label: storyId,
        value: rating,
    });
}

export function storyLoveFeedbackEvent(storyId, rating) {
    event({
        category: "Story Completed Feedback",
        action: "Rated Love",
        label: storyId,
        value: rating,
    });
}

export function storyAppShareEvent(storyId, service) {
    event({
        category: "Story Completed Feedback",
        action: `Shared App on ${service}`,
        label: storyId,
    });
}

export function storyFollowInstagramEvent(storyId) {
    event({
        category: "Story Completed Feedback",
        action: "Followed on instagram",
        label: storyId,
    });
}

export function storyRateInStoreEvent(storyId) {
    event({
        category: "Story Completed Feedback",
        action: "Rated in Store",
        label: storyId,
    });
}

export function storyRateInStoreErrorEvent(errorMsg) {
    event({
        category: "Story Completed Feedback",
        action: "Rated in Store Error",
        label: errorMsg,
    });
}

export function storySkipRateInStoreEvent(storyId) {
    event({
        category: "Story Completed Feedback",
        action: "Skipped Rate in Store",
        label: storyId,
    });
}

export function storyTextualFeedbackEvent(storyId) {
    event({
        category: "Story Completed Feedback",
        action: "Sent textual feedback",
        label: storyId,
    });
}

export function hintUnlockEvent(storyId) {
    event({
        category: "Hints",
        action: "Unlocked a Hint",
        label: storyId,
    });
}

export function hintShopBuyItemEvent(itemName, numHints) {
    event({
        category: "Hints",
        action: "Bought Hint Shop Item",
        label: itemName,
        value: numHints,
    });
}

export function hintFeedbackEvent(storyId, hintId, isHelpful, x1Enabled) {
    // for the meaning of x1, please see config.py
    const actionSuffix = x1Enabled ? " X1" : "";
    event({
        category: "Hints",
        action: `Hint Feedback${actionSuffix}`,
        label: `${storyId}, ${hintId}`,
        value: isHelpful ? 1 : 0,
    });
}

export function becomeAWriterEvent() {
    event({
        category: "Become a Writer",
        action: "Clicked menu button",
    });
}

export function becomeAWriterDialogReponseEvent(responseStr) {
    event({
        category: "Become a Writer",
        action: `Answered dialog with ${responseStr}`,
    });
}

export function subscribeForNewMissionsEvent() {
    event({
        category: "Retention",
        action: "Subscribed for new missions",
    });
}

export function subscribeForBetaTestingEvent() {
    event({
        category: "Retention",
        action: "Subscribed for beta testing",
    });
}

export function clickedOnBetaTestingAdEvent(origin) {
    event({
        category: "Beta Testing",
        action: "Clicked on beta testing ad",
        label: origin,
    });
}

export function copiedReferralCodeEvent() {
    event({
        category: "Referral",
        action: "Copied Referral Code",
    });
}

export function enteredInvalidReferralCodeEvent(errorCode) {
    event({
        category: "Referral",
        action: "Entered Invalid Referral Code",
        label: errorCode,
    });
}

export function appliedReferralCodeEvent() {
    event({
        category: "Referral",
        action: "Applied Referral Code",
    });
}

export function communityStoryClickDownloadGame(target) {
    event({
        category: "Community",
        action: "Clicked Download Game",
        label: target,
    });
}

export function apiSilentFailure(endpointLabel) {
    event({
        category: "Backend API Call",
        action: "Failed Silently",
        label: endpointLabel,
        nonInteraction: true,
    });
}

export function onesignalSetUidFailureEvent() {
    event({
        category: "OneSignal",
        action: "Failed Setting User Id",
        nonInteraction: true,
    });
}

export function apiCallTiming(endpointLabel, durationMs) {
    timing({
        category: "Backend API Call",
        variable: "call",
        value: durationMs,
        label: endpointLabel,
        noDebug: true,
    });
}

function event({ category, action, label, value, nonInteraction }) {
    if (!ENABLE_GOOGLE_ANALYTICS) {
        // eslint-disable-next-line
        console.info("Analytics event", { category, action, label, value });
    } else {
        try {
            ReactGA.event({
                category,
                action,
                label,
                value,
                nonInteraction,
            });
        } catch (err) {
            // nothing
        }
    }
}

function timing({ category, variable, value, label }) {
    if (!ENABLE_GOOGLE_ANALYTICS) {
        // console.info("Analytics timing", { category, variable, value, label });
    } else {
        try {
            ReactGA.timing({
                category,
                variable,
                value,
                label,
            });
        } catch (err) {
            // nothing
        }
    }
}

import styles from "./Clickable.module.css";
import React, { useRef } from "react";
import classnames from "classnames";

const MIN_CLICK_INTERVAL_MS = 80; // should be longer than the duration between onTouchEnd and onClick

/**
 * A component for treating touch events as clicks, for two reasons:
 * 1. Reduce the delay until onClick is fired on touch devices.
 * 2. Prevent onClick misfire on some cases, for example after a swipe.
 */
export default function Clickable({ onClick, className, children, ...props }) {
    const lastClickTime = useRef(0);

    // https://developer.mozilla.org/en-US/docs/Web/API/Touch_events/Supporting_both_TouchEvent_and_MouseEvent#event_order
    // event fire order:
    // - touchstart
    // - touchend
    // - (mouse events)
    // - click

    function touchStartHandler(evt) {
        evt.preventDefault();
        onClick(evt);
        lastClickTime.current = nowMs();
    }

    function touchEndHandler(evt) {
        // Prevent the following click event
        evt.preventDefault();

        // In case the user holds the element
        lastClickTime.current = nowMs();
    }

    function clickHandler(evt) {
        evt.preventDefault();

        // if there was a recent touch, ignore this click event
        const now = nowMs();
        if (now - lastClickTime.current > MIN_CLICK_INTERVAL_MS) {
            lastClickTime.current = now;
            onClick(evt);
        }
    }

    return (
        <div
            className={classnames(styles.clickable, className)}
            onClick={onClick ? clickHandler : undefined}
            onTouchStart={onClick ? touchStartHandler : undefined}
            onTouchEnd={onClick ? touchEndHandler : undefined}
            {...props}
        >
            {children}
        </div>
    );
}

function nowMs() {
    return performance && performance.now ? performance.now() : Date.now();
}

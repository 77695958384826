import styles from "./AppPizza.module.css";
import PizzaButton from "./PizzaButton";
import ExternalLink from "../../../utils/ExternalLink";
import PinKeyboard from "../../../common/PinKeyboard";
import Image, { ImagePreloader } from "components/common/Image";
import AppState from "state/AppState";

import ImageLogoPng from "./logo.png";
import ImageLogoWebp from "./logo.webp";
import ImageDeliveryPng from "./delivery.png";
import ImageDeliveryWebp from "./delivery.webp";
import ImageDrone from "./icon-drone.png";
import ImageNoDronePng from "./icon-no-drone.png";
import ImageNoDroneWebp from "./icon-no-drone.webp";

import { useAppStore } from "@faintlines/phone-sim-common";

import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";

const ImageLogo = [ImageLogoPng, ImageLogoWebp];
const ImageDelivery = [ImageDeliveryPng, ImageDeliveryWebp];
const ImageNoDrone = [ImageNoDronePng, ImageNoDroneWebp];

function AppPizza({
    map,
    pin,
    deliveryTime,
    targetMapX,
    targetMapY,
    crashEvent,
    trackEvent,
    appName,
}) {
    const State = useAppStore();
    const status = State.orderStatus;

    const handleOrder = () => {
        State.setOrderStatus("ordered");
    };
    const handleTrack = () => {
        State.setOrderStatus("tracker");
        if (trackEvent) {
            AppState.fireEvent(trackEvent);
        }
    };

    const handleCrash = () => {
        if (crashEvent) {
            AppState.fireEvent(crashEvent, { delayMs: 5000 });
        }
    };

    return (
        <div className={styles.app}>
            <div className={styles.app__contents}>
                {status === null ? (
                    <Home pin={pin} onOrder={handleOrder} appName={appName} />
                ) : status === "ordered" ? (
                    <Ordered onTrack={handleTrack} />
                ) : status === "tracker" ? (
                    <Tracker
                        map={map}
                        deliveryTime={deliveryTime}
                        targetMapX={targetMapX}
                        targetMapY={targetMapY}
                        onCrash={handleCrash}
                    />
                ) : null}
            </div>
            <ImagePreloader
                images={[ImageLogo, ImageDelivery, ImageDrone, ImageNoDrone]}
            />
        </div>
    );
}

export default observer(AppPizza);

function Home({ pin, onOrder, appName }) {
    const [showKeyboard, toggleKeyboard] = useState(false);

    const orderClickHandler = () => {
        if (pin) {
            toggleKeyboard(true);
        } else {
            onOrder();
        }
    };

    return (
        <div className={styles.home}>
            <Title>{"Fastest Pizza on The Planet!"}</Title>
            <SubTitle>
                {
                    "Our order-predicting AI delivers fresh pizza in less than 3 minutes!"
                }
            </SubTitle>
            <Image className={styles.home__hero} src={ImageLogo} />
            <PizzaButton text="One Click Order!" onClick={orderClickHandler} />
            <div
                className={classnames(styles.home__keyboardWrapper, {
                    visible: showKeyboard,
                })}
            >
                <PinKeyboard
                    title="Enter Your PIN"
                    password={pin}
                    darkMode
                    onUnlock={onOrder}
                    onCancel={() => toggleKeyboard(false)}
                    appName={appName}
                />
            </div>
        </div>
    );
}

function Ordered({ onTrack }) {
    return (
        <div className={styles.ordered}>
            <Title>{"Hurray! Your Pizza Is on the Way"}</Title>
            <SubTitle>
                {
                    "Your hot pizza just got out of the oven (yes, we're that fast), and our magical drone is on its way to your place."
                }
            </SubTitle>
            <Image className={styles.ordered__hero} src={ImageDelivery} />
            <PizzaButton text="Track My Pizza!" onClick={onTrack} />
        </div>
    );
}

const Tracker = observer(
    ({ map, deliveryTime, targetMapX, targetMapY, onCrash }) => {
        const [progress, setProgress] = useState(0);
        const [crashed, setCrashed] = useState(false);
        const State = useAppStore();

        const { deliveryStartTime } = State;

        useEffect(() => {
            const interval = setInterval(() => {
                const now = Math.round(Date.now() / 1000);
                const remainingTime = deliveryTime - (now - deliveryStartTime);

                if (remainingTime <= 12) {
                    setCrashed(true);
                    onCrash();
                } else {
                    setProgress(1 - remainingTime / deliveryTime);
                }
            }, 1000);
            return () => clearInterval(interval);
        }, [deliveryStartTime, deliveryTime, onCrash]);

        const translateX = 300 * (1 - progress);
        const translateY = 100 * (1 - progress);

        const eta = Math.round((1 - progress) * deliveryTime);

        return (
            <div className={styles.tracker}>
                <Title>{"Pizza Tracker"}</Title>
                <SubTitle>
                    {crashed
                        ? "Oh no! We lost communication with the drone!! Please wait while we figure out what happened."
                        : progress === 0
                        ? "checking..."
                        : `Time to destination: ${eta} seconds`}
                </SubTitle>
                <div
                    className={styles.tracker__map}
                    style={{ backgroundImage: `url(${map})` }}
                >
                    {crashed ? (
                        <Image
                            className={styles.tracker__no_drone}
                            src={ImageNoDrone}
                        />
                    ) : (
                        <Image
                            className={styles.tracker__drone}
                            style={{
                                top: `${targetMapY}%`,
                                left: `${targetMapX}%`,
                                transform: `translate(${translateX}px, ${translateY}px)`,
                            }}
                            src={ImageDrone}
                        />
                    )}
                    <div className={styles.tracker__map_copyright}>
                        {"© "}
                        <ExternalLink
                            href="https://www.openstreetmap.org/copyright"
                            className={styles.tracker__map_copyright_link}
                        >
                            {"OpenStreetMap"}
                        </ExternalLink>

                        {" contributors"}
                    </div>
                </div>
            </div>
        );
    }
);

function Title({ children }) {
    return <div className={styles.title}>{children}</div>;
}

function SubTitle({ children }) {
    return <div className={styles.subtitle}>{children}</div>;
}

import { apiCall } from "./api";

export function getStories() {
    return apiCall("studio", "get", {
        timingLabel: "getStories",
    });
}

export function createStory() {
    return apiCall("studio/create", "post", {
        timingLabel: "createStory",
    });
}

export function loadStory(storyId) {
    return apiCall(`studio/story/${storyId}`, "get", {
        timingLabel: "loadStory",
    });
}

export function updateStory(storyId, data) {
    return apiCall(`studio/story/${storyId}`, "put", {
        data: { data },
        timingLabel: "updateStory",
    });
}

export function deleteStory(storyId) {
    return apiCall(`studio/story/${storyId}`, "delete", {
        timingLabel: "deleteStory",
    });
}

export function publishStory(storyId, data) {
    return apiCall(`studio/story/${storyId}/publish`, "post", {
        data: { data },
        timingLabel: "publishStory",
    });
}

export function uploadImage(storyId, file) {
    const formData = new FormData();
    formData.append("file", file);

    return apiCall(`studio/story/${storyId}/upload-image`, "post", {
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        timeoutMs: 60 * 1000,
        timingLabel: "uploadImage",
    });
}

export function checkAnswer(answer, answers) {
    return apiCall("studio/check-answer", "post", {
        data: { answer, answers },
        timingLabel: "checkAnswer",
    });
}

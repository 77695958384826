import styles from "./BatteryIconSmall.module.css";
import { ChargeIcon } from "./icons";

import classnames from "classnames";
import React, { useEffect, useState } from "react";

export default function BatteryIconSmall({
    batteryLevel,
    showPercent,
    charging,
    unlimited,
    dark,
    fullColors,
    lowThreshold,
    mediumThreshold,
    onClick,
    id,
}) {
    const animate = useBatteryAnimation(batteryLevel, 3);

    const barClass =
        batteryLevel <= lowThreshold
            ? "low"
            : batteryLevel <= mediumThreshold
            ? "medium"
            : "high";

    const barWidthPercent = Math.min(batteryLevel, 100);

    return (
        <div
            id={id}
            className={classnames(
                styles.battery,
                dark ? styles.dark : null,
                charging ? styles.charging : null,
                unlimited ? styles.unlimited : null,
                fullColors ? styles.fullColors : null,
                animate ? styles.animate : null
            )}
            onClick={onClick}
        >
            {showPercent ? (
                <span className={styles.percent}>{`${batteryLevel}%`}</span>
            ) : null}
            <span className={styles.icon}>
                <span
                    className={classnames(styles.bar, styles[barClass])}
                    style={{ width: `${barWidthPercent}%` }}
                />
                {charging || unlimited ? (
                    <ChargeIcon
                        className={styles.chargingIcon}
                        pathClassName={styles.chargingIcon__path}
                    />
                ) : null}
            </span>
        </div>
    );
}
BatteryIconSmall.defaultProps = {
    lowThreshold: 20,
    mediumThreshold: 40,
    showPercent: true,
};

function useBatteryAnimation(batteryLevel, minIncreaseForAnimation) {
    const [previousLevel, setPreviousLevel] = useState(batteryLevel);
    const animate = batteryLevel > previousLevel;

    useEffect(() => {
        let timeout;
        if (batteryLevel >= previousLevel + minIncreaseForAnimation) {
            timeout = setTimeout(() => {
                setPreviousLevel(batteryLevel);
            }, 1000);
        } else if (batteryLevel !== previousLevel) {
            setPreviousLevel(batteryLevel);
        }
        return () => clearTimeout(timeout);
    }, [batteryLevel, previousLevel, minIncreaseForAnimation]);

    return animate;
}

import { ENABLE_SENTRY } from "../config";

import * as Sentry from "@sentry/react";

export function reportExceptionIfError(err) {
    if (err instanceof Error) {
        reportException(err);
    }
}

export function reportException(err) {
    if (ENABLE_SENTRY) {
        Sentry.captureException(err);
    } else {
        console.warn("reportException", err); // eslint-disable-line
    }
}

import "./AppAuthenticator.css";

import React, { useEffect, useState, useRef } from "react";

window.buffer = require("@otplib/preset-browser/buffer");
const otp = require("@otplib/preset-browser").authenticator;

const REFRESH_RATE_SEC = 30;

export default function AppAuthenticator({ entries }) {
    const [codes, setCodes] = useState(getCodes(entries));
    const [timeLeft, setTimeLeft] = useState(0);
    const timeLeftRef = useRef(REFRESH_RATE_SEC);

    useEffect(() => {
        timeLeftRef.current = REFRESH_RATE_SEC;

        const interval = setInterval(() => {
            timeLeftRef.current -= 1;
            if (timeLeftRef.current === 0) {
                setCodes(getCodes(entries));
                timeLeftRef.current = 30;
            }
            setTimeLeft(timeLeftRef.current);
        }, 1000);
        return () => clearInterval(interval);
    }, [entries]);

    return (
        <div className="AppAuthenticator">
            <div className="AppAuthenticator-title">{"Authenticator"}</div>
            <div className="AppAuthenticator-entries">
                {entries.map((entry, i) => (
                    <div key={i} className="AppAuthenticator-entry">
                        <div className="AppAuthenticator-entry-code">
                            {codes[i].match(/.{1,3}/g).join(" ")}
                        </div>
                        <div className="AppAuthenticator-entry-name">
                            {entry.name}
                        </div>
                        <div className="AppAuthenticator-entry-time">
                            {timeLeft}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

function getCodes(entries) {
    otp.options = {
        step: 30,
        digits: 6,
        window: 10, // TODO: needed here or just on validation?
    };
    return entries.map((entry) => otp.generate(entry.secret));
}

import axiosTiming from "./axiosTiming";
import axiosHeaderSession, { clearHeaderSession } from "./axiosHeaderSession";
import axiosErrorReporting, { isTimeoutError } from "./axiosErrorReporting";
import shouldRetry from "./retryCondition";
import { apiCallTiming, apiSilentFailure } from "../analytics";
import { reportException } from "utils/errorReporting";
import { HAS_WEBP } from "utils/browserUtils";
import { DEBUG } from "../config";

import { HAVE_IOS } from "@faintlines/native-bridge";

import axios from "axios";
import axiosRetry from "axios-retry";
import urljoin from "url-join";
import { v4 as uuid } from "uuid";

const HOSTNAME_TO_BASE_URL = {
    // staging
    "staging.peekaph.one": "/",
    "peek-a-phone-staging.web.app":
        "https://staging-dot-peek-a-phone.appspot.com/",
    "staging-dot-peek-a-phone.appspot.com": "/",
    "staging.studio.peekaph.one": "https://staging.peekaph.one/",
    //
    // production
    "peekaph.one": "/",
    "peek-a-phone.appspot.com": "/",
    "studio.peekaph.one": "https://peekaph.one/",
};
if (DEBUG) {
    HOSTNAME_TO_BASE_URL[
        window.location.hostname
    ] = `http://${window.location.hostname}:9080/`;
}
const BASE_URL = HAVE_IOS
    ? "https://peekaph.one/"
    : HOSTNAME_TO_BASE_URL[window.location.hostname] || "https://peekaph.one/";

const API_URL = urljoin(BASE_URL, "/api");
export const LOGIN_WITH_FACEBOOK_URL = urljoin(BASE_URL, "/auth/fb/login");
export const LOGIN_WITH_FACEBOOK_TOKEN_URL = urljoin(
    BASE_URL,
    "/auth/fb/login-with-token"
);
export const LOGIN_WITH_GOOGLE_URL = urljoin(BASE_URL, "/auth/google/login");
export const LOGIN_WITH_GOOGLE_CODE_URL = urljoin(
    BASE_URL,
    "/auth/google/login-with-code"
);
export const LOGIN_WITH_GOOGLE_TOKEN_URL = urljoin(
    BASE_URL,
    "/auth/google/login-with-token"
);
export const LOGOUT_URL = urljoin(BASE_URL, "/auth/logout");

const REQUEST_TIMEOUT_MS = 10 * 1000;
const SESSION_ID_HEADER_NAME = "X-Session-Id";
const SESSION_ID_STORE_KEY = "pap-session";

const client = axios.create({
    headers: {
        "X-Webp-Support": HAS_WEBP ? "1" : "0",
        "X-Timezone-Offset": new Date().getTimezoneOffset(),
    },
});
axiosErrorReporting(client, {
    // do not report timeouts
    onError: (error) => {
        if (error && !isTimeoutError(error)) {
            reportException(error);
        }
    },
});
if (HAVE_IOS) {
    // On iOS, the server is on a different origin, so cookies are not supported.
    // This middleware transmits the session id over a header. It's less secure,
    // but it works.
    axiosHeaderSession(client, SESSION_ID_HEADER_NAME, SESSION_ID_STORE_KEY);
}
axiosRetry(client, {
    retries: 5,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: shouldRetry,
    shouldResetTimeout: true,
});
axiosTiming(client, {
    onRequestFinished: ({ request, timeMs }) =>
        apiCallTiming(request.config.timingLabel, timeMs),
});

export function checkAnswer({ storyId, answer }) {
    return apiCall("player/answer", "put", {
        data: {
            story_id: storyId,
            answer,
        },
        timingLabel: "checkAnswer",
    });
}

export function completeStory({ storyId }) {
    return apiCall(`player/story/${storyId}/complete`, "put", {
        timingLabel: "completeStory",
    });
}

export function sendFeedback({
    storyId,
    difficultyScore,
    feedbackText,
    ratingScore,
}) {
    return apiCall("feedback", "post", {
        data: {
            story_id: storyId,
            difficulty_score: difficultyScore,
            free_text: feedbackText,
            rating_score: ratingScore,
        },
        timingLabel: "sendFeedback",
        failSilently: true,
    });
}

export function getPlayerInfo() {
    return apiCall("player/info", "get", { timingLabel: "getPlayerInfo" });
}

export function saveUserSettings(userSettings) {
    return apiCall("player/settings", "put", {
        data: { settings: userSettings },
        timingLabel: "saveUserSettings",
        failSilently: true,
    });
}

export function getStory(storyId, options) {
    const { resetState, noData, returnState } = options || {};

    const params = {
        reset: resetState ? 1 : 0,
        "return-state": returnState ? 1 : 0,
    };
    if (typeof noData === "boolean") {
        params["no-data"] = noData ? 1 : 0;
    }

    return apiCall(`player/story/${storyId}`, "get", {
        params,
        timingLabel: "getStory",
    });
}

export function updateStoryState(storyId, state) {
    return apiCall(`player/story/${storyId}/state`, "put", {
        data: { state },
        timingLabel: "updateStoryState",
        failSilently: true,
    });
}

export function getBatteryLevel() {
    return apiCall("player/battery", "get", {
        timingLabel: "getBatteryLevel",
        failSilently: true,
    });
}

export function updateBatteryLevel(batteryLevel) {
    return apiCall("player/battery", "put", {
        data: { batteryLevel },
        timingLabel: "updateBatteryLevel",
        failSilently: true,
    });
}

export function increaseBatteryLevel(increaseType, by) {
    return apiCall(`player/battery/${increaseType}`, "put", {
        params: { by },
        timingLabel: "increaseBatteryLevel",
    });
}

export function activateStoryUnlimitedBattery(storyId) {
    return apiCall(`player/story/${storyId}/battery`, "put", {
        timingLabel: "increaseBatteryLevel",
    });
}

export function getTrophies() {
    return apiCall("player/trophies", "get", {
        timingLabel: "getTrophies",
    });
}

export function listStoryPendingEvents(storyId) {
    return apiCall(`player/story/${storyId}/events`, "get", {
        noRetry: true,
        timingLabel: "listStoryPendingEvents",
        failSilently: true,
    });
}

export function listHints(storyId) {
    return apiCall(`player/hint/${storyId}`, "get", {
        timingLabel: "listHints",
    });
}

export function unlockHint(storyId, hintId) {
    return apiCall(`player/hint/${storyId}/${hintId}`, "put", {
        timingLabel: "unlockHint",
    });
}

export function skipStory(storyId) {
    return apiCall(`player/story/${storyId}/skip`, "put", {
        timingLabel: "skipStory",
    });
}

export function applyPurchasedItem(packageName, productId, purchaseToken) {
    return apiCall("player/play-store-apply-item", "put", {
        data: {
            package_name: packageName,
            product_id: productId,
            token: purchaseToken,
            store_id: HAVE_IOS ? "ios" : "android",
        },
        timingLabel: "applyPurchasedItem",
    });
}

// TODO: remove and use the new method
export function increaseHintBalanceUnsafe() {
    return apiCall("player/hint/add", "post", {
        timingLabel: "increaseHintBalanceUnsafe",
    });
}

export function increaseHintBalance() {
    return apiCall(`player/hint/add/${uuid()}`, "put", {
        timingLabel: "increaseHintBalance",
    });
}

export function activateReferralCode(code) {
    return apiCall("player/referral", "put", {
        data: { code },
        timingLabel: "useReferralCode",
    });
}

export function createEcoImpact() {
    return apiCall("player/create-eco-impact", "put", {
        data: {},
        timingLabel: "createEcoImpact",
    });
}
export function claimDailyReward() {
    return apiCall("player/claim-daily-reward", "put", {
        timingLabel: "createEcoImpact",
    });
}

export function subscribe(subscription, email, phone) {
    return apiCall("player/subscribe", "put", {
        data: { email, phone, for: subscription },
        timingLabel: "subscribe",
        failSilently: true,
    });
}

export function subscribeForNewMissions(email, phone) {
    return subscribe("new_missions", email, phone);
}

export function subscribeForBetaTesting(email, phone) {
    return subscribe("beta_testing", email, phone);
}

export function loginWithAppleToken(token) {
    return apiCall("auth/apple/login-with-token", "put", {
        data: { token },
        timingLabel: "loginWithAppleToken",
    });
}

export function loginWithFacebookToken(token) {
    return apiCall("auth/fb/login-with-token", "put", {
        data: { token },
        timingLabel: "loginWithFacebookToken",
    });
}

export function loginWithGoogleToken(token, source) {
    return apiCall("auth/google/login-with-token", "put", {
        data: { token, source },
        timingLabel: "loginWithGoogleToken",
    });
}

export function deleteAccount() {
    return apiCall("player/account", "delete", {
        timingLabel: "deleteAccount",
    });
}

export function logout() {
    if (HAVE_IOS) {
        clearHeaderSession(SESSION_ID_STORE_KEY);
        window.location.reload();
    } else {
        const nextUrl = encodeURIComponent(window.location.href);
        window.location.href = `${LOGOUT_URL}?next=${nextUrl}`;
    }
}

export function chatAppGetInitialChats(storyId, appName) {
    return apiCall(`app/chat/${storyId}`, "get", {
        params: { app: appName },
        timingLabel: "chatAppGetInitialChats",
    });
}

export function chatAppPostMessage(
    storyId,
    appName,
    chatId,
    stateId,
    messageText
) {
    return apiCall("app/chat/message", "put", {
        params: { app: appName },
        data: {
            story_id: storyId,
            chat_id: chatId,
            chat_state_id: stateId,
            message: messageText,
        },
        timingLabel: "chatAppPostMessage",
    });
}

export function apiCall(url, method, options) {
    const {
        params,
        data,
        noRetry,
        timingLabel,
        extra,
        failSilently,
        timeoutMs,
    } = options || {};

    const extraOptions = extra || {};
    if (noRetry) {
        extraOptions["axios-retry"] = {
            retries: 0,
        };
    }

    let promise = client.request({
        url: urljoin(API_URL, url),
        method,
        timeout: timeoutMs || REQUEST_TIMEOUT_MS,
        withCredentials: true,
        params,
        data,
        timingLabel,
        ...extraOptions,
    });

    if (failSilently) {
        promise = promise.catch(() => apiSilentFailure(timingLabel));
    }

    return promise;
}

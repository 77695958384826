import styles from "./Modal.module.css";
import Button from "./Button";

import React, { useState, useEffect } from "react";
import classnames from "classnames";

export default function Modal({
    zIndex,
    visible,
    noCloseOnClickOutside,
    onClose,
    padding,
    backgroundColor,
    entranceEffect,
    children,
}) {
    const entered = useEntraceEffect(entranceEffect, visible);

    if (!visible) {
        return null;
    }

    return (
        <div
            className={classnames(
                styles.modal,
                entranceEffect ? styles.entranceEffect : null,
                entered ? styles.entered : null
            )}
            style={{ zIndex }}
            onClick={noCloseOnClickOutside ? null : onClose}
        >
            <div
                className={styles.contents}
                style={{ padding, backgroundColor }}
                onClick={(evt) => evt.stopPropagation()}
            >
                {onClose ? (
                    <div
                        className={classnames(styles.close, "icon icon-cancel")}
                        onClick={onClose}
                    />
                ) : null}
                {children}
            </div>
        </div>
    );
}
Modal.defaultProps = { padding: 30 };

export function ModalTitle({ fontSize, children }) {
    return (
        <div className={styles.title} style={{ fontSize }}>
            {children}
        </div>
    );
}

export function ModalText({ children, align, style, onClick }) {
    return (
        <div
            className={classnames(styles.text, styles[align])}
            style={style}
            onClick={onClick}
        >
            {children}
        </div>
    );
}

export const ModalTextbox = React.forwardRef((props, ref) => (
    <input ref={ref} className={styles.textbox} {...props} />
));

export function ModalButton(props) {
    return <Button className={styles.button} {...props} />;
}

function useEntraceEffect(entranceEffect, visible) {
    const [entered, setEntered] = useState(false);

    useEffect(() => {
        if (!entranceEffect) {
            return undefined;
        }

        if (!visible && entered) {
            setEntered(false);
            return undefined;
        }

        if (!entered) {
            const timeout = setTimeout(() => setEntered(true), 10);
            return () => clearTimeout(timeout);
        }

        return undefined;
    }, [entranceEffect, visible, entered]);

    return entered;
}

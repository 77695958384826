import StudioSchemaForm from "../StudioSchemaForm";
import { useStudioStory } from "../StudioContext";
import SETTINGS_SCHEMA, { SETTINGS_UI_SCHEMA } from "../schemas/settingsSchema";
import EditorTab from "../common/EditorTab";

import { observer } from "mobx-react-lite";
import React from "react";
import { toPairs, fromPairs, pick } from "lodash";

const SettingsEditor = observer(({ onStoryUpdate }) => {
    const story = useStudioStory();

    const handleChange = (formData) => {
        onStoryUpdate(
            fromPairs(
                toPairs(formData).map(([key, value]) => [key, { $set: value }])
            )
        );
    };

    const data = pick(story, ["name", "brief", "image", "settings"]);

    return (
        <EditorTab scrollable>
            <StudioSchemaForm
                schema={SETTINGS_SCHEMA}
                uiSchema={SETTINGS_UI_SCHEMA}
                data={data}
                onChange={handleChange}
            />
        </EditorTab>
    );
});

export default SettingsEditor;

import styles from "./ZoomableImage.module.css";
import Image from "./Image";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import React from "react";

export default function ZoomableImage({
    disabled,
    disablePan,
    noCenter,
    limitToBounds,
    resetOnDoubleClick,
    onZoomStart,
    onZoomEnd,
    onLoad,
    ...imageProps
}) {
    const zoomStopHandler = (ref) => {
        const isZoomedIn = ref?.state?.scale > 1;
        onZoomEnd(isZoomedIn);
    };

    return (
        <TransformWrapper
            disabled={disabled}
            centerOnInit={!noCenter}
            limitToBounds={limitToBounds}
            panning={{ disabled: disablePan }}
            doubleClick={
                resetOnDoubleClick ? { mode: "reset" } : { disabled: true }
            }
            onZoomStart={onZoomStart}
            onZoomStop={onZoomEnd ? zoomStopHandler : null}
        >
            <TransformComponent
                wrapperClass={styles.wrapper}
                contentClass={styles.content}
            >
                <Image {...imageProps} />
            </TransformComponent>
        </TransformWrapper>
    );
}
ZoomableImage.defaultProps = {
    limitToBounds: true,
};

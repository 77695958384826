import React, { forwardRef } from "react";
import { Link } from "react-router-dom";

const OptionalLink = forwardRef(({ to, disabled, children, ...props }, ref) => {
    if (disabled) {
        return (
            <div ref={ref} {...props}>
                {children}
            </div>
        );
    }

    return (
        <Link ref={ref} to={to} {...props}>
            {children}
        </Link>
    );
});

export default OptionalLink;

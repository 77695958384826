import * as Native from "@faintlines/native-bridge";

import React from "react";

export default function ExternalLink({ href, onClick, children, ...props }) {
    function clickHandler(evt) {
        if (onClick) {
            onClick(evt);
        }

        if (Native.CAPABILITIES.openUrlInBrowser) {
            evt.preventDefault();
            Native.openUrlInBrowser(href);
        }
    }

    return (
        <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            onClick={clickHandler}
            {...props}
        >
            {children}
        </a>
    );
}

import {
    MULTI_STORIES_COUNT,
    MIN_STORIES_COMPLETED_FOR_MULTI_STORY,
} from "config";

import dayjs from "dayjs";
import { find, findIndex, sum } from "lodash";

export function decodeStory(encodedStory) {
    /* eslint-disable no-underscore-dangle */
    return JSON.parse(encodedStory, (key, val) => {
        if (typeof val === "object" && val && val.__type) {
            switch (val.__type) {
                case "DT":
                    return dayjs(val.__str);
                default:
                    return val;
            }
        }
        return val;
    });
    /* eslint-enable */
}

export function sortStoriesBy(stories, storyOrder) {
    if (!storyOrder) {
        return stories;
    }

    const sorted = [];
    storyOrder.forEach((storyId) => {
        const story = find(stories, (x) => x.id === storyId);
        if (story) {
            sorted.push(story);
        }
    });
    return sorted;
}

export function getLockedStoryIds(stories, storiesById) {
    const lastFinishedStory = getLastFinishedStoryIndex(stories);
    const lockedStories = {};

    if (lastFinishedStory >= MIN_STORIES_COMPLETED_FOR_MULTI_STORY - 1) {
        return getLockedStoryIdsForMultipleStories(stories, storiesById);
    }

    for (let i = 0; i < stories.length; i += 1) {
        if (
            i > lastFinishedStory + 1 &&
            !isPartlyCompletedSeries(stories[i], storiesById)
        ) {
            lockedStories[stories[i].id] = true;
        }
    }

    return lockedStories;
}

export function getLockedStoryIdsForMultipleStories(stories, storiesById) {
    const lockedStories = {};
    let remaining = MULTI_STORIES_COUNT;

    for (let i = 0; i < stories.length; i += 1) {
        const story = stories[i];
        if (wasStoryCompleted(story)) {
            continue;
        }
        if (remaining <= 0 && !isPartlyCompletedSeries(story, storiesById)) {
            lockedStories[story.id] = true;
            continue;
        }
        remaining -= 1;
    }

    return lockedStories;
}

export function getLastFinishedStoryIndex(stories) {
    for (let i = stories.length - 1; i >= 0; i -= 1) {
        if (wasStoryCompleted(stories[i])) {
            return i;
        }
    }
    return -1;
}

export function getNextStoryIndex(stories) {
    return findIndex(stories, (story) => story && !wasStoryCompleted(story));
}

export function isStoryPartOfTheFirstSeries(storyId, stories, storiesById) {
    const sorted = sortStories(stories, storiesById);
    const firstSeries = find(sorted, isSeries);
    return !!(firstSeries && firstSeries.story_ids.includes(storyId));
}

export function sortStories(stories, storiesById) {
    const completed = [];
    const partlyCompleted = [];
    const uncompleted = [];

    stories.forEach((story) => {
        if (wasStoryCompleted(story)) {
            completed.push(story);
        } else if (isPartlyCompletedSeries(story, storiesById)) {
            partlyCompleted.push(story);
        } else {
            uncompleted.push(story);
        }
    });
    return [...completed, ...partlyCompleted, ...uncompleted];
}

export function wasStoryCompleted(story) {
    return !!(story && (story.completed || story.skipped));
}

export function countCompletedStoriesInSeries(series, storiesById) {
    if (!series) {
        return 0;
    }
    if (series.completed) {
        return series.story_ids.length;
    }
    return sum(
        series.story_ids.map((id) => +wasStoryCompleted(storiesById[id]))
    );
}

export function isPartlyCompletedSeries(story, storiesById) {
    if (story?.completed) {
        return true;
    }
    return !!(
        story &&
        story.story_ids.some((id) => wasStoryCompleted(storiesById[id]))
    );
}

export function isSeries(story) {
    return !!(story && story.story_ids && story.story_ids.length > 1);
}

import "./AppPhotos.css";
import MediaSlider from "../../utils/MediaSlider";
import Image from "../../common/Image";
import ZoomableImage from "../../common/ZoomableImage";
import { IconPinchToZoom } from "../../common/icons";
import { usePhoneSessionStorage } from "state/stateHooks";

import VideoPlayer from "@faintlines/video-player";

import React, { useState, useEffect, useCallback } from "react";
import { Switch, Route, Link, withRouter, Redirect } from "react-router-dom";
import urljoin from "url-join";
import useMeasure from "react-use/lib/useMeasure";
import { observer } from "mobx-react-lite";
import { ChevronLeft as IconChevronLeft } from "lucide-react";
import classnames from "classnames";

export default function AppPhotos({
    sections,
    zoomTutorial,
    appUrl,
    appPath,
    onEvent,
}) {
    return (
        <div className="AppPhotos">
            <Switch>
                <Route exact path={appPath}>
                    {sections.length === 0 ? (
                        <NoPhotos />
                    ) : (
                        <PhotosIndex sections={sections} appUrl={appUrl} />
                    )}
                </Route>
                <Route
                    exact
                    path={urljoin(
                        appPath,
                        "photo",
                        ":defaultSectionId/:defaultPhotoId"
                    )}
                    render={(props) => (
                        <PhotosDetails
                            key={`${props.match.params.sectionId}_${props.match.params.photoId}`}
                            appUrl={appUrl}
                            sections={sections}
                            zoomTutorial={zoomTutorial}
                            onEvent={onEvent}
                        />
                    )}
                />
                <Route>
                    <Redirect to={appUrl} />
                </Route>
            </Switch>
        </div>
    );
}

function PhotosIndex({ sections, appUrl }) {
    return (
        <div className="AppPhotos-index-scroller">
            <div className="AppPhotos-index">
                {sections.map((section, sectionId) => (
                    <div className="AppPhotos-section" key={sectionId}>
                        <div className="AppPhotos-section-name">
                            {section.name}
                        </div>
                        <div className="AppPhotos-photogrid">
                            {section.photos.map((photo, photoId) => (
                                <Link
                                    key={photoId}
                                    id={photo.id}
                                    to={urljoin(
                                        appUrl,
                                        "photo",
                                        `${sectionId}/${photoId}`
                                    )}
                                >
                                    <PhotoGridThumbnail photo={photo} />
                                </Link>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

function NoPhotos() {
    return (
        <div className="AppPhotos-no-photos">
            <div>
                <div className="AppPhotos-no-photos-title">
                    {"No Photos or Videos"}
                </div>
            </div>
        </div>
    );
}

function PhotoGridThumbnail({ photo }) {
    const { video, url, thumbnail } = photo;
    const src = video ? thumbnail : url;

    return (
        <div className="AppPhotos-photogrid-thumb">
            <Image src={src} className="AppPhotos-photogrid-thumb-image" />
            {video ? (
                <i className="AppPhotos-photogrid-thumb-play icon icon-play" />
            ) : null}
        </div>
    );
}

function sectionsToList(sections) {
    const lst = [];
    sections.forEach((section, sectionId) => {
        section.photos.forEach((photo, photoId) => {
            lst.push(`${sectionId}_${photoId}`);
        });
    });
    return lst;
}

const PhotosDetails = withRouter(
    ({ sections, appUrl, match, history, zoomTutorial, onEvent }) => {
        const { defaultSectionId, defaultPhotoId } = match.params;
        const photos = sectionsToList(sections);
        const defaultIndex = photos.indexOf(
            `${defaultSectionId}_${defaultPhotoId}`
        );
        const [index, setIndex] = useState(defaultIndex);
        const [isZoomed, setIsZoomed] = useState(false);

        const [screenRef, { height }] = useMeasure();

        const slideHandler = (newIndex) => {
            const [sectionId, photoId] = photos[newIndex].split("_");
            history.replace(urljoin(appUrl, "photo", sectionId, photoId));
            setIndex(newIndex);
        };

        const zoomEndHandler = useCallback((isZoomedNew) => {
            setIsZoomed(isZoomedNew);
        }, []);

        return (
            <div className="AppPhotos-photo-details" ref={screenRef}>
                <div className="AppPhotos-photo-details-header">
                    <Link
                        className="AppPhotos-photo-details-header-back-btn"
                        to={appUrl}
                    >
                        <IconChevronLeft size="1em" />
                    </Link>
                </div>
                <MediaSlider
                    items={photos.map((photo, i) => {
                        const [sectionId, photoId] = photo.split("_");
                        return (
                            <Media
                                key={photo}
                                photo={sections[sectionId].photos[photoId]}
                                maxHeight={height}
                                isActive={index === i}
                                zoomTutorial={zoomTutorial}
                                onZoomEnd={zoomEndHandler}
                                onEvent={onEvent}
                            />
                        );
                    })}
                    disabled={isZoomed}
                    defaultIndex={defaultIndex}
                    onSlide={slideHandler}
                />
            </div>
        );
    }
);

function Media({
    photo,
    maxHeight,
    isActive,
    zoomTutorial,
    onZoomEnd,
    onEvent,
}) {
    const { url, viewEvent, zoomEvent } = photo;

    useEffect(() => {
        if (isActive && viewEvent && onEvent) {
            onEvent(viewEvent);
        }
    }, [isActive, viewEvent, onEvent]);

    const zoomEndHandler = useCallback(
        (isZoomedNow) => {
            if (isActive && isZoomedNow && zoomEvent && onEvent) {
                onEvent(zoomEvent);
            }
            if (onZoomEnd) {
                onZoomEnd(isZoomedNow);
            }
        },
        [isActive, zoomEvent, onEvent, onZoomEnd]
    );

    return (
        <div className="AppPhotos-photo-details-photo-wrapper">
            {photo.video ? (
                <Video src={url} isActive={isActive} />
            ) : (
                <Photo
                    src={url}
                    isActive={isActive}
                    maxHeight={maxHeight}
                    zoomTutorial={zoomTutorial}
                    onZoomEnd={zoomEndHandler}
                />
            )}
            {photo.date ? (
                <div className="AppPhotos-photo-details-photo-info">
                    {photo.date}
                </div>
            ) : null}
        </div>
    );
}

const Photo = observer(
    ({ src, maxHeight, isActive, zoomTutorial, onZoomEnd }) => {
        const [isZoomed, setIsZoomed] = useState(false);

        const [zoomTutorialVisible, toggleZoomTutorial] =
            usePhoneSessionStorage(
                "photos_zoom_tutorial",
                zoomTutorial || false
            );

        const zoomEndHandler = (isZoomedNow) => {
            if (isZoomedNow !== isZoomed) {
                setIsZoomed(isZoomedNow);
                if (onZoomEnd) {
                    onZoomEnd(isZoomedNow);
                }
            }
        };

        // css max-height: 100% doesn't work on certain cases, for example when parent elements also have
        // max-size: 100%. To overcome this, we specify max-height in pixels (and object-fit: contain).
        return (
            <>
                <ZoomableImage
                    key={src}
                    disabled={!isActive}
                    disablePan={!isZoomed}
                    onZoomStart={
                        zoomTutorialVisible
                            ? () => toggleZoomTutorial(false)
                            : null
                    }
                    onZoomEnd={zoomEndHandler}
                    className="AppPhotos-photo-details-photo"
                    src={src}
                    style={{ maxHeight }}
                    showSpinner
                />
                {zoomTutorial ? (
                    <ZoomTutorial visible={zoomTutorialVisible} />
                ) : null}
            </>
        );
    }
);

function Video({ src, isActive }) {
    return (
        <VideoPlayer
            url={src}
            className="AppPhotos-photo-details-video"
            width="100%"
            height="100%"
            autoPlay={isActive}
            autoRetry={isActive}
            controls
            controlsList="nodownload nofullscreen noremoteplayback noplaybackrate"
            preload="auto"
        />
    );
}

const ZoomTutorial = ({ visible }) => (
    <div
        className={classnames("AppPhotos-photo-details-photo-zoom-tutorial", {
            visible,
        })}
    >
        <div
            id="photos-pinch-to-zoom-tutorial"
            className="AppPhotos-photo-details-photo-zoom-tutorial-container"
        >
            <IconPinchToZoom />
            <div>{"Pinch to zoom"}</div>
        </div>
    </div>
);

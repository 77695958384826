import styles from "./EmojiText.module.css";

import {
    replaceEmojisToArray,
    unicodeToCodepoint,
} from "../../utils/stringUtils";

import React, { memo } from "react";

const EmojiText = memo(({ text }) => {
    if (!text) {
        return null;
    }
    const array = replaceEmojisToArray(text, emojiToImage);
    return <>{array}</>;
});

export default EmojiText;

export function getEmojiImageUrl(codepoint) {
    return `https://twemoji.maxcdn.com/v/13.1.0/72x72/${codepoint}.png`;
    // return `https://twemoji.maxcdn.com/v/13.1.0/svg/${codepoint}.svg`;
}

function emojiToImage(match, i) {
    const codepoint = unicodeToCodepoint(match);
    return (
        <img
            key={i}
            alt={match}
            src={getEmojiImageUrl(codepoint)}
            className={styles.emoji}
        />
    );
}

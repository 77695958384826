export default function axiosTiming(axios, options) {
    const { onRequestFinished } = options || {};

    if (!window.performance) {
        return;
    }

    try {
        axios.interceptors.request.use((req) => {
            req.meta = req.meta || {};
            req.meta.requestStartedAt = getTime();
            return req;
        });

        axios.interceptors.response.use(
            (req) => {
                fireEvent(req, onRequestFinished);
                return req;
            },
            // Handle 4xx & 5xx responses
            (req) => {
                fireEvent(req, onRequestFinished);
                throw req;
            }
        );
    } catch (err) {
        // nothing
    }
}

function fireEvent(req, onRequestFinished) {
    if (!onRequestFinished) {
        return;
    }

    try {
        onRequestFinished({
            request: req,
            timeMs: getTime() - req.config.meta.requestStartedAt,
        });
    } catch (err) {
        // nothing
    }
}

function getTime() {
    return Math.round(window.performance.now());
}

import "./AppStreaming.css";

import { pluralize } from "@faintlines/string-utils";

import React, { useState, useEffect, useRef } from "react";
import { Switch, Route, Link, useParams, Redirect } from "react-router-dom";
import urljoin from "url-join";
import { forEach } from "lodash";

export default function AppStreaming({ sections, titles, appUrl, appPath }) {
    const commonParams = { sections, titles, appUrl };

    return (
        <div className="AppStreaming">
            <Switch>
                <Route exact path={appPath}>
                    <Index {...commonParams} />
                </Route>
                <Route
                    exact
                    path={urljoin(appPath, "title", ":sectionId", ":titleId")}
                >
                    <Title {...commonParams} />
                </Route>
                <Route>
                    <Redirect to={appUrl} />
                </Route>
            </Switch>
        </div>
    );
}

function Index({ sections, titles, appUrl }) {
    return (
        <div className="AppStreaming-index-sections">
            {sections.map((section, i) => (
                <IndexSection
                    key={i}
                    sectionId={i}
                    section={section}
                    titles={titles}
                    appUrl={appUrl}
                />
            ))}
        </div>
    );
}

function IndexSection({ section, sectionId, titles, appUrl }) {
    const sectionTitles = section.titles.map((x) => titles[x]);

    const screenRef = useRef();
    const [screenWidth, setScreenWidth] = useState(null);

    useEffect(() => {
        const currentWidth = screenRef.current?.clientWidth;
        if (currentWidth && currentWidth !== screenWidth) {
            setScreenWidth(currentWidth);
        }
    }, [screenRef, screenWidth]);

    return (
        <div className="AppStreaming-section" ref={screenRef}>
            <div className="AppStreaming-section-name">{section.name}</div>
            <div className="AppStreaming-section-titles">
                {sectionTitles.map((title, titleId) => (
                    <Link
                        className="AppStreaming-section-title"
                        style={{
                            backgroundImage: `url(${title.image})`,
                            ...calcTileDimensions(screenWidth),
                        }}
                        key={titleId}
                        to={urljoin(
                            appUrl,
                            "title",
                            sectionId.toString(),
                            titleId.toString()
                        )}
                    >
                        <div className="AppStreaming-section-title-name">
                            {title.title}
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

function Title({ sections, titles, appUrl }) {
    const { titleId, sectionId } = useParams();
    const title = titles[sections[sectionId].titles[titleId]];
    const { friends, seasons, runtime } = title;

    let friendsStr = "";

    if (friends) {
        forEach(friends, (friend, i) => {
            if (i === friends.length - 1 && i !== 0) friendsStr += " and ";
            else if (i > 0) friendsStr += ", ";
            friendsStr += friend;
        });
    }

    return (
        <div className="AppStreaming-title">
            <div className="AppStreaming-title-top-bar">
                <Link className="AppStreaming-title-back-button" to={appUrl}>
                    <i className="icon-left-open-big" />
                </Link>
            </div>
            <div
                className="AppStreaming-title-bg"
                style={{ backgroundImage: `url(${title.image})` }}
            >
                <div className="AppStreaming-title-name">{title.title}</div>
            </div>
            <div className="AppStreaming-title-details">
                <div className="AppStreaming-title-details-info">
                    {title.year ? (
                        <span className="AppStreaming-title-details-info-detail">
                            {title.year}
                        </span>
                    ) : null}
                    {seasons ? (
                        <span className="AppStreaming-title-details-info-detail">
                            {`${seasons} season${pluralize(seasons)}`}
                        </span>
                    ) : null}
                    {runtime ? (
                        <span className="AppStreaming-title-details-info-detail">
                            {`${runtime} minutes`}
                        </span>
                    ) : null}
                    <span className="AppStreaming-title-details-info-detail hd">
                        {"HD"}
                    </span>
                </div>
                {friends && friends.length > 0 ? (
                    <div className="AppStreaming-title-friends">
                        {`Your friend${pluralize(
                            friends.length
                        )} ${friendsStr} also like this title.`}
                    </div>
                ) : null}
                <div className="AppStreaming-title-details-offline">
                    <div className="AppStreaming-title-details-offline-title">
                        {"No Internet Connection"}
                    </div>
                    <div className="AppStreaming-title-details-offline-text">
                        {"Check your network settings and try again."}
                    </div>
                </div>
            </div>
        </div>
    );
}

function calcTileDimensions(screenWidth) {
    if (!screenWidth) {
        return {};
    }

    const tilesPerRow = screenWidth < 340 ? 2.8 : 3.8;
    const width = screenWidth / tilesPerRow;
    const height = width * 1.5;
    return { width, height };
}

import styles from "./AppDelivery.module.css";
import MarkdownContent from "components/utils/MarkdownContent";
import Image, { ImagePreloader } from "components/common/Image";
import PinKeyboard from "components/common/PinKeyboard";
import AppState from "state/AppState";

import IconDronePng from "./icon-drone.png";
import IconDroneWebp from "./icon-drone.webp";
import IconDroneFailedPng from "./icon-drone-failed.png";
import IconDroneFailedWebp from "./icon-drone-failed.webp";
import IconDelivered from "./icon-delivered.png";

import { useAppStore } from "@faintlines/phone-sim-common";
import { pluralize, limitString } from "@faintlines/string-utils";

import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import urljoin from "url-join";
import { find } from "lodash";
import classNames from "classnames";

const DEFAULT_TITLE = "Deliveries";

const IconDrone = [IconDronePng, IconDroneWebp];
const IconDroneFailed = [IconDroneFailedPng, IconDroneFailedWebp];

function AppDelivery({
    appTitle,
    appName,
    deliveries,
    statusTexts,
    appUrl,
    appPath,
}) {
    return (
        <div className={styles.app}>
            <Switch>
                <Route exact path={appPath}>
                    <Index
                        deliveries={deliveries}
                        statusTexts={statusTexts}
                        appTitle={appTitle}
                        appUrl={appUrl}
                    />
                </Route>
                <Route
                    exact
                    path={urljoin(appPath, ":deliveryId")}
                    render={({ match }) => (
                        <Delivery
                            delivery={find(
                                deliveries,
                                (x) => x.id === match.params.deliveryId
                            )}
                            appUrl={appUrl}
                            appName={appName}
                        />
                    )}
                />
                <Route>
                    <Redirect to={appUrl} />
                </Route>
            </Switch>
            <ImagePreloader
                images={[
                    IconDronePng,
                    IconDroneWebp,
                    IconDroneFailedPng,
                    IconDroneFailedWebp,
                    IconDelivered,
                ]}
            />
        </div>
    );
}
AppDelivery.defaultProps = {
    statusTexts: {
        ordered: "Preparing to ship",
        tracker: "In transit",
        finished: "Finished",
        default: "Pending",
    },
};

export default observer(AppDelivery);

function Index({ deliveries, statusTexts, appTitle, appUrl }) {
    return (
        <div className={styles.index}>
            <div className={styles.index__header}>
                <Title>{appTitle || DEFAULT_TITLE}</Title>
                <SubTitle
                    text={`${deliveries.length} ${pluralize(
                        deliveries.length,
                        "deliveries",
                        "delivery"
                    )}`}
                />
            </div>
            <div className={styles.index__items}>
                {deliveries.map((delivery) => (
                    <IndexItem
                        key={delivery.id}
                        delivery={delivery}
                        statusTexts={statusTexts}
                        appUrl={appUrl}
                    />
                ))}
            </div>
        </div>
    );
}

const IndexItem = observer(({ delivery, statusTexts, appUrl }) => {
    const store = useAppStore();
    const { title, subtitle } = delivery;
    const status = store.getDeliveryStatus(delivery);
    const statusText = statusTexts[status || "default"];

    return (
        <Link
            className={styles.indexItem}
            to={urljoin(appUrl, delivery.id.toString())}
        >
            <div className={styles.indexItem__texts}>
                <div className={styles.indexItem__title}>{title}</div>
                {subtitle ? (
                    <div className={styles.indexItem__subtitle}>
                        {limitString(subtitle.split("\n")[0], 30)}
                    </div>
                ) : null}
                <div className={styles.indexItem__status}>
                    {`Status: ${statusText}`}
                </div>
            </div>
        </Link>
    );
});

const Delivery = observer(({ delivery, appName }) => {
    const store = useAppStore();
    const status = store.getDeliveryStatus(delivery);
    const { finishEvent, finishEventDelay } = delivery;

    const handleDispatch = () => {
        store.setDeliveryStatus(delivery, "ordered");
    };

    const handleTrack = () => {
        store.setDeliveryStatus(delivery, "tracker");
    };

    const handleFinish = () => {
        store.setDeliveryStatus(delivery, "finished");
        if (finishEvent) {
            AppState.fireEvent(finishEvent, finishEventDelay);
        }
    };

    return (
        <div className={styles.app}>
            <div className={styles.app__contents}>
                <DeliveryContent
                    delivery={delivery}
                    status={status}
                    onDispatch={handleDispatch}
                    onTrack={handleTrack}
                    onFinish={handleFinish}
                    appName={appName}
                />
            </div>
        </div>
    );
});

const DeliveryContent = ({
    delivery,
    status,
    onDispatch,
    onTrack,
    onFinish,
    appName,
}) => {
    switch (status) {
        case "finished":
        case "tracker":
            return (
                <Tracker
                    delivery={delivery}
                    onFinish={onFinish}
                    status={status}
                />
            );
        case "ordered":
            return <Ordered delivery={delivery} onTrack={onTrack} />;
        default:
            return (
                <DeliveryHome
                    delivery={delivery}
                    onDispatch={onDispatch}
                    appName={appName}
                />
            );
    }
};

const DeliveryHome = ({ delivery, onDispatch, appName }) => {
    const { title, subtitle, image, pin } = delivery;
    const [showKeyboard, toggleKeyboard] = useState(false);

    const orderClickHandler = () => {
        if (pin) {
            toggleKeyboard(true);
        } else {
            onDispatch();
        }
    };

    return (
        <div className={styles.home}>
            <Title>{title}</Title>
            <SubTitle text={subtitle} />
            {image ? (
                <img className={styles.home__hero} src={image} alt="" />
            ) : null}
            <Button text="Dispatch Delivery!" onClick={orderClickHandler} />
            <div
                className={classNames(styles.home__keyboardWrapper, {
                    visible: showKeyboard,
                })}
            >
                <PinKeyboard
                    title="Enter Your PIN"
                    password={pin || ""}
                    darkMode
                    onUnlock={onDispatch}
                    onCancel={() => toggleKeyboard(false)}
                    appName={`${appName}|${title}`}
                />
            </div>
        </div>
    );
};

function Ordered({ delivery, onTrack }) {
    return (
        <div className={styles.ordered}>
            <Title>
                {delivery.inTransitTitle ||
                    "Hurray! Your Delivery is on the Way"}
            </Title>
            <SubTitle
                text={
                    delivery.inTransitSubtitle ||
                    "Our magical drone is on its way to your place."
                }
            />
            {delivery.inTransitImage ? (
                <img
                    className={styles.ordered__hero}
                    src={delivery.inTransitImage}
                    alt=""
                />
            ) : null}
            <Button text="Track My Delivery" onClick={onTrack} />
        </div>
    );
}

const Tracker = observer(({ delivery, status, onFinish }) => {
    const {
        mapUrl,
        deliveryTimeSec,
        finishTimeSec,
        targetMapX,
        targetMapY,
        finishedText,
        result,
    } = delivery;
    const [progress, setProgress] = useState(0);
    const [finished, setFinished] = useState(status === "finished");
    const store = useAppStore();
    const deliveryStartTime = store.getDeliveryStartTime(delivery);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = Math.round(Date.now() / 1000);
            const remainingTime = deliveryTimeSec - (now - deliveryStartTime);

            if (remainingTime <= (finishTimeSec || deliveryTimeSec)) {
                setFinished(true);
                onFinish();
            } else {
                setProgress(1 - remainingTime / deliveryTimeSec);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [deliveryStartTime, deliveryTimeSec, finishTimeSec, onFinish]);

    const translateX = 300 * (1 - progress);
    const translateY = 100 * (1 - progress);

    const eta = Math.round((1 - progress) * deliveryTimeSec);

    return (
        <div className={styles.tracker}>
            <Title>{"Delivery Tracker"}</Title>
            <SubTitle
                text={
                    finished
                        ? finishedText
                        : progress === 0
                        ? "checking..."
                        : `Time to destination: ${eta} seconds`
                }
            />
            <div
                className={styles.tracker__map}
                style={{ backgroundImage: `url(${mapUrl})` }}
            >
                {finished ? (
                    <Image
                        className={styles.tracker__finished}
                        src={
                            result === "crash" ? IconDroneFailed : IconDelivered
                        }
                    />
                ) : (
                    <Image
                        className={styles.tracker__drone}
                        style={{
                            top: `${targetMapY}%`,
                            left: `${targetMapX}%`,
                            transform: `translate(${translateX}px, ${translateY}px)`,
                        }}
                        src={IconDrone}
                    />
                )}
            </div>
        </div>
    );
});

function Title({ children }) {
    return <div className={styles.title}>{children}</div>;
}

function SubTitle({ text }) {
    return <MarkdownContent className={styles.subtitle} markdown={text} />;
}

function Button({ text, onClick }) {
    return (
        <div className={styles.button} onClick={onClick}>
            <div className={styles.inner}>{text}</div>
        </div>
    );
}

import { HOTJAR_ALLOW_CLASS_NAME } from "../utils/StudioUtils";

import React from "react";
import TextField from "@material-ui/core/TextField";

// Copied from https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/material-ui/src/TextareaWidget/TextareaWidget.tsx
// to add HotJar classname
const TextareaWidget = ({
    id,
    placeholder,
    value,
    required,
    disabled,
    autofocus,
    label,
    readonly,
    onBlur,
    onFocus,
    onChange,
    options,
    schema,
    rawErrors = [],
}) => {
    const handleChange = ({ target: { value: newValue } }) => {
        onChange(newValue === "" ? options.emptyValue : newValue);
    };
    const handleBlur = ({ target: { value: newValue } }) => {
        onBlur(id, newValue);
    };
    const handleFocus = ({ target: { value: newValue } }) => {
        onFocus(id, newValue);
    };

    return (
        <TextField
            id={id}
            label={label || schema.title}
            placeholder={placeholder}
            disabled={disabled || readonly}
            value={value}
            required={required}
            autoFocus={autofocus}
            multiline
            rows={options.rows || 5}
            error={rawErrors.length > 0}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            inputProps={{ className: HOTJAR_ALLOW_CLASS_NAME }}
        />
    );
};

export default TextareaWidget;

import AppState from "./AppState";
import UiState from "./UiState";
import * as Server from "../server/api";
import { hintShopBuyItemEvent, storyUnlockRemoveAdsEvent } from "../analytics";
import { STORE_ITEMS_DEFAULT_PRICE, DEBUG } from "config";

import * as Native from "@faintlines/native-bridge";

import { makeAutoObservable } from "mobx";

const HANDLE_PENDING_PURCHASES_INTERVAL_MS = 60 * 1000;
const FETCH_SKUS_INTERVAL_MS = 5 * 60 * 1000;
const PLATFORM_NAME = Native.PLATFORM_NAME
    ? Native.PLATFORM_NAME.toLowerCase()
    : "android";
const STORE_ITEMS_DEFAULT_PRICE_FOR_PLATFORM =
    STORE_ITEMS_DEFAULT_PRICE[PLATFORM_NAME];
const HAVE_NATIVE_IN_APP_PURCHASE = Native.CAPABILITIES.buyStoreItem;

class InAppPurchaseState {
    iapAvailable = HAVE_NATIVE_IN_APP_PURCHASE || DEBUG;

    prices = {};

    constructor() {
        makeAutoObservable(this, {
            iapAvailable: false,
        });

        this._tickers = [
            Native.HAVE_ANDROID
                ? setInterval(
                      () => this.handleAndroidPendingPurchases(),
                      HANDLE_PENDING_PURCHASES_INTERVAL_MS
                  )
                : // IOS will trigger handle_purchased_item when the transaction is completted
                  null,
            setInterval(() => this.fetchStoreSKUs(), FETCH_SKUS_INTERVAL_MS),
        ];

        this._handlingPurchases = false;
    }

    getItemPrice(itemId) {
        return (
            this.prices[itemId] ||
            STORE_ITEMS_DEFAULT_PRICE_FOR_PLATFORM[itemId]
        );
    }

    buyItem(itemId) {
        Native.buyStoreItem(itemId);
    }

    fetchStoreSKUs() {
        try {
            // this is an async function. results are received via the billing_sku_prices event
            Native.fetchStoreSKUs(
                Object.keys(STORE_ITEMS_DEFAULT_PRICE_FOR_PLATFORM).join("|")
            );
        } catch (e) {
            // nothing
        }
    }

    handlePrices(prices) {
        this.prices = prices || {};
    }

    handlePurchesedItem(packageName, sku, token) {
        return Server.applyPurchasedItem(packageName, sku, token).then(
            ({ data }) => {
                if (data.hint_balance) {
                    AppState.handleIncreaseHintBalanceResponse(
                        data.hint_balance
                    );

                    if (data.applied) {
                        hintShopBuyItemEvent("In-App Purchase", data.hints);
                    }
                }
                if (data.remove_ads) {
                    AppState.removeAds();
                    storyUnlockRemoveAdsEvent();
                }
                if (data.battery) {
                    AppState.setBatteryLevel(data.battery);
                }
                if (data.battery_unlimited_sec) {
                    AppState.setBatteryUnlimitedSec(data.battery_unlimited_sec);
                }
                if (data.story_battery_balance > 0) {
                    AppState.useUnlimitedBatteryForCurrentStory();
                }

                Native.ackStoreItem(token);
                UiState.hideCustomOfferModal();
            }
        );
    }

    handleAndroidPendingPurchases() {
        if (!Native.HAVE_ANDROID || !Native.CAPABILITIES.listPurchases) {
            return;
        }

        // Throttle this function to avoid race conditions
        if (this._handlingPurchases) {
            setTimeout(() => this.handleAndroidPendingPurchases(), 5000);
            return;
        }
        this._handlingPurchases = true;
        setTimeout(() => {
            this._handlingPurchases = false;
        }, 10000);

        const purchases = Native.listPurchases();
        if (!purchases) {
            return;
        }

        purchases.split("\n").forEach((purchaseStr) => {
            if (purchaseStr.trim() === "") {
                return;
            }
            const [sku, token, packageName] = purchaseStr.split("\t");
            this.handlePurchesedItem(packageName, sku, token);
            // TODO: handle errors (.catch)
        });
    }
}

const state = new InAppPurchaseState();

window.addEventListener("billing_pendingpurchases", () => {
    state.handleAndroidPendingPurchases();
});

window.addEventListener("billing_sku_prices", (evt) => {
    state.handlePrices(evt.detail);
});

window.addEventListener("handle_purchased_item", (evt) => {
    const { sku, token, packageName } = evt.detail;
    state.handlePurchesedItem(packageName, sku, token);
});

export default state;

import "dayjs/locale/en-gb";

export function buildCalendar(value) {
    const first = value.locale("en-gb").startOf("month").startOf("week");
    const last = value.locale("en-gb").endOf("month").endOf("week");
    const calendar = [];

    let day = first.clone().subtract(1, "day");
    while (day.isBefore(last, "day")) {
        const week = [];
        let i;
        for (i = 0; i < 7; i += 1) {
            day = day.add(1, "day");
            week.push(day);
        }
        calendar.push(week);
    }
    return calendar;
}

export function dateExistsInArray(array, day) {
    for (let i = 0; i < array.length; i += 1) {
        if (array[i].isSame(day, "day")) {
            return true;
        }
    }
    return false;
}

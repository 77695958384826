const missionObjectiveSchema = {
    type: "object",
    properties: {
        answerTitle: { type: "string", title: "Mission objective title" },
        answerQuestion: { type: "string", title: "Mission objective subtitle" },
        answerPlaceholder: {
            type: "string",
            title: "Mission objective placeholder",
        },
        answers: {
            type: "array",
            title: "Answers",
            default: [],
            items: {
                type: "object",
                properties: {
                    text: { type: "string", title: "Answer text", default: "" },
                    isCorrect: {
                        type: "boolean",
                        title: "Is this a correct answer?",
                        default: true,
                    },
                    response: {
                        type: "string",
                        title: "Response text (optional)",
                        default: "",
                    },
                    allowTypos: {
                        type: "boolean",
                        title: "Allow minor typos",
                        default: true,
                    },
                },
            },
        },
    },
};
export default missionObjectiveSchema;

export const MISSION_OBJECTIVE_UI_SCHEMA = {
    answers: {
        items: {
            allowTypos: {
                "ui:help": "Turn off to only accept exact answers.",
            },
        },
    },
};

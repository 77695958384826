import "./images/fontello/css/fontello-embedded.css";
import "normalize.css";
import "@faintlines/game-shell/dist/index.css";
import "./index.css";
import "./colors.css";
import AppState from "./state/AppState";
import InAppPurchaseState from "./state/InAppPurchaseState";
import { initAnalytics } from "./analytics";
import * as serviceWorker from "./serviceWorker";
import "./events";
import { ENABLE_SENTRY } from "./config";
import MarkdownContent from "./components/utils/MarkdownContent";

// import VideoAdManager before configuring mobx so it won't throw on default values
// TODO: remove after upgrading to mobx v6
import "./state/VideoAdManager";

import { MarkdownContentContext } from "@faintlines/markdown";

import { configure } from "mobx";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import promiseFinally from "promise.prototype.finally";
import smoothscroll from "smoothscroll-polyfill";
import dayjsAdvancedFormat from "dayjs/plugin/advancedFormat";
import dayjs from "dayjs";

// Google Fonts
import "@fontsource/bungee/400.css";
import "@fontsource/roboto-condensed/400.css";
import "@fontsource/roboto-condensed/700.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/400-italic.css";
import "@fontsource/roboto/700.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/700.css";

let App = null;

// IMPORTANT: in order for tree shaking to work, REACT_APP_STUDIO *must* be defined.
// See: https://github.com/facebook/create-react-app/issues/9674#issuecomment-696256850
if (process.env.REACT_APP_STUDIO === "1") {
    App = require("./StudioApp").default; // eslint-disable-line
} else {
    App = require("./App").default; // eslint-disable-line
}

// Needed for Chrome (+ Android WebView) < 63
// https://www.chromestatus.com/feature/5704570235781120
promiseFinally.shim();

// Safari does not support passing an options object to scrollIntoView
smoothscroll.polyfill();

if (ENABLE_SENTRY) {
    Sentry.init({
        dsn: "https://c5324de699de49e9aba207e45823b494@o415344.ingest.sentry.io/5306286",
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.0001,
    });
}

// Needed for Chrome (+ Android WebView) < 64 and Safari < 13.1
if (!window.ResizeObserver) {
    import("resize-observer-polyfill")
        .then((importedModule) => {
            window.ResizeObserver = importedModule.default;
        })
        .catch(() => {});
}

initAnalytics();

configure({
    enforceActions: "always",
    computedRequiresReaction: true,
    // observableRequiresReaction: true,
    // reactionRequiresObservable: true,
});

dayjs.extend(dayjsAdvancedFormat);

AppState.loadUserSettingsFromStorage();
AppState.loadStateFromServer();

const AppWrapper = () => (
    <MarkdownContentContext.Provider value={MarkdownContent}>
        <App />
    </MarkdownContentContext.Provider>
);

ReactDOM.render(<AppWrapper />, document.getElementById("root"));

InAppPurchaseState.handleAndroidPendingPurchases();
InAppPurchaseState.fetchStoreSKUs();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import AudioNewsIn from "../audio/news-in.mp3";
import { pickToJs } from "utils/mobxUtils";

import { makeAutoObservable, set } from "mobx";
import { Howl } from "howler";
import { isEmpty, keys, find } from "lodash";

export default class NewsAppState {
    constructor({ existingState, app, mainState }) {
        makeAutoObservable(this);

        if (NewsAppState.hasProgress(existingState)) {
            set(this, existingState);
        } else {
            (app.data.items || []).forEach((item) => {
                if (item.visible && !item.read) {
                    this.unreadArticles[item.id] = true;
                }
            });
        }

        this._app = app;
        this._mainState = mainState;

        this._soundNewsIn = new Howl({
            src: [AudioNewsIn],
            volume: 0.5,
        });
    }

    dispose() {}

    visibleArticles = {}; // articleId => boolean
    unreadArticles = {};
    anyArticleRead = false;

    static hasProgress(state) {
        return (
            state && (state.anyArticleRead || !isEmpty(state.visibleArticles))
        );
    }

    /// / Computed

    get asJson() {
        return pickToJs(this, [
            "visibleArticles",
            "unreadArticles",
            "anyArticleRead",
        ]);
    }

    get notificationCounter() {
        return keys(this.unreadArticles).length;
    }

    /// / Actions

    showArticle(articleId) {
        if (!this.visibleArticles[articleId]) {
            this.visibleArticles[articleId] = true;
            this.unreadArticles[articleId] = true;
            this._soundNewsIn.play();
            this._pushNewArticleNotification(articleId);
        }
    }

    markArticleAsRead(articleId) {
        if (this.unreadArticles[articleId]) {
            delete this.unreadArticles[articleId];
            this.anyArticleRead = true;
        }
    }

    /// / Private functions

    _pushNewArticleNotification(articleId) {
        const article = find(this._app.data.items, (x) => x.id === articleId);
        if (!article) {
            // should not get here
            return;
        }

        this._mainState.pushNotification(this._app, articleId, {
            text: article.title,
        });
    }
}

import { HOTJAR_ALLOW_CLASS_NAME } from "../utils/StudioUtils";

import { Widgets } from "@rjsf/material-ui";
import React from "react";
import classnames from "classnames";

const TextWidget = ({ inputProps, ...props }) => (
    <Widgets.TextWidget
        inputProps={{
            ...inputProps,
            className: classnames(
                inputProps?.className,
                HOTJAR_ALLOW_CLASS_NAME
            ),
        }}
        {...props}
    />
);

export default TextWidget;

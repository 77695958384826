import "./PhoneFooter.css";
import { vibrateShort } from "../../utils/browserUtils";

import { rsplit } from "@faintlines/string-utils";

import { Link, withRouter } from "react-router-dom";
import React from "react";
import classnames from "classnames";

function PhoneFooter({ rootUrl, location, onMenuClick, onExit }) {
    const { pathname } = location;
    const isHome = pathname === rootUrl;

    return (
        <div className={classnames("PhoneFooter", { home: isHome })}>
            {isHome ? (
                <div
                    id="footer-button-back"
                    className="PhoneFooter-button exit"
                    onClick={onExit}
                    onTouchStart={() => vibrateShort()}
                >
                    <i className="icon-left-open-big" />
                </div>
            ) : (
                <Link
                    id="footer-button-back"
                    className="PhoneFooter-button exit"
                    to={rsplit(pathname, "/", 1)[0]}
                    onTouchStart={() => vibrateShort()}
                >
                    <i className="PhoneFooter-button-icon icon-left-open-big" />
                </Link>
            )}
            <Link
                id="footer-button-home"
                to={rootUrl}
                onTouchStart={() => vibrateShort()}
            >
                <div className="PhoneFooter-home-button" />
            </Link>
            <div
                id="footer-button-answer"
                className="PhoneFooter-button answer"
                onClick={onMenuClick}
            >
                <i className="PhoneFooter-button-icon icon-flag-checkered" />
            </div>
        </div>
    );
}

export default withRouter(PhoneFooter);

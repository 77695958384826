import ConversationEditor from "../editors/ConversationEditor";

import React from "react";

export const TextConversationSingeContactField = ({ formData, onChange }) => (
    <ConversationEditor value={formData} maxContacts={1} onChange={onChange} />
);

export const TextConversationMultipleContactsField = ({
    formData,
    onChange,
}) => (
    <ConversationEditor value={formData} maxContacts={10} onChange={onChange} />
);

import styles from "./DotPattern.module.css";
import Clickable from "../utils/Clickable";
import GestureDetector from "../utils/GestureDetector";
import { vibrateShort } from "utils/browserUtils";

import { stringsInclude } from "@faintlines/string-utils";

import React, { useState, useEffect } from "react";
import classnames from "classnames";

const VALUES = [1, 2, 3, 4, 5, 6, 7, 8, 9, "a", "b", "c", "d", "e", "f", "g"];

export default function DotPatternHoverable({
    title,
    pattern, // encoding the sequence of the chosen toggles: e.g 741569
    size,
    className,
    darkMode,
    onCancel,
    onUnlock,
}) {
    const [sequence, setSequence] = useState([]);
    const [startSequence, setStartSequence] = useState(false);
    const [animated, setAnimated] = useState(false);

    const dotIdentifier = "dot";

    function clearSequence() {
        setStartSequence(false);
        setSequence([]);
    }

    useEffect(clearSequence, []);

    const handlePanEnd = () => {
        // TODO: Add AppState.trackPasswordEvent - currently handlePanEnd is evoked 2 times at the end of pattern
        if (stringsInclude(pattern, sequence.join(""))) {
            onUnlock();
        } else {
            setAnimated(true);
            setTimeout(() => {
                setAnimated(false);
                clearSequence();
            }, 300);
        }
    };

    const handlePanMove = (e) => {
        const elementMouseIsOver = document.elementFromPoint(
            e.center.x,
            e.center.y
        );
        if (
            elementMouseIsOver &&
            elementMouseIsOver.hasAttribute(dotIdentifier)
        ) {
            addToSequence(
                Number(elementMouseIsOver.getAttribute(dotIdentifier))
            );
        }
    };

    const addToSequence = (index) => {
        if (!index || !startSequence || sequence.includes(index)) {
            return;
        }
        setSequence([...sequence, index]);
        vibrateShort();
    };

    const handlePointerDown = (index) => {
        setStartSequence(true);
        setSequence([index]);
    };

    return (
        <GestureDetector
            onPanStart={() => setStartSequence(true)}
            onPanMove={(e) => handlePanMove(e)}
            onPanEnd={handlePanEnd}
            className={classnames(styles.root, className, { darkMode })}
        >
            {title ? <div className={styles.title}>{title}</div> : null}
            <div
                className={classnames(
                    styles.keyboard,
                    animated ? styles.animated : null
                )}
                style={{ gridTemplateColumns: `repeat(${size}, 1fr)` }}
            >
                {[...VALUES].splice(0, size * size).map((value) => (
                    <div
                        key={value}
                        className={classnames(styles.key, {
                            darkMode,
                            on: sequence.includes(value),
                        })}
                        onPointerEnter={() => addToSequence(value)}
                        // PointerDown and PointerUp is required to handle the
                        // first pattern selection (before pan is recognized)
                        onPointerDown={() => handlePointerDown(value)}
                        onPointerUp={() => handlePanEnd()}
                        {...{ [dotIdentifier]: value }} // used to identify the dots elements
                    />
                ))}
            </div>
            {onCancel ? (
                <Clickable className={styles.cancel} onClick={onCancel}>
                    {"Cancel"}
                </Clickable>
            ) : null}
        </GestureDetector>
    );
}
DotPatternHoverable.defaultProps = {
    size: 3,
};

import "./Chat.css";
import ChatMessage from "./ChatMessage";

import { useChatTheme } from "./ChatThemeContext";
import ZoomableImage from "../../../common/ZoomableImage";
import { parseDate } from "utils/dateUtils";

import {
    useAppStore,
    useScroller,
    useScrollerPosition,
    ScrollToTopButton,
} from "@faintlines/phone-sim-common";
import { formatDate } from "@faintlines/string-utils";

import React, { useRef, useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import classnames from "classnames";
import dayjs from "dayjs";

const DATE_FORMAT = "MM/DD/YYYY";

export default function Chat({ appUrl }) {
    return (
        <div className="Chat">
            <ChatContents appUrl={appUrl} />
        </div>
    );
}

const ChatContents = observer(({ appUrl }) => {
    const store = useAppStore();
    const { activeChat } = store;
    const [image, setImage] = useState(null);

    if (!activeChat) {
        return <Redirect to={appUrl} />;
    }

    const { title, picture, messages, typing, connected } = activeChat;
    const status = typing ? "Typing..." : connected ? "Connected" : null;

    const imageClickHandler = (src, text) => {
        setImage({ src, text });
    };

    return (
        <>
            <ChatHeader
                appUrl={appUrl}
                picture={picture}
                title={title}
                status={status}
            />
            <ChatMessages
                messages={messages}
                onImageClick={imageClickHandler}
            />
            <ChatFooter />
            {image ? (
                <ImageView
                    src={image.src}
                    text={image.text}
                    onClose={() => setImage(null)}
                />
            ) : null}
        </>
    );
});

function ChatHeader({ appUrl, picture, title, status }) {
    return (
        <div className="ChatHeader">
            <Link
                to={appUrl}
                className="ChatHeader-back icon icon-left-open-big"
            />
            {picture ? (
                <div
                    className="ChatHeader-picture"
                    style={{ backgroundImage: `url(${picture})` }}
                />
            ) : (
                <div className="ChatHeader-icon icon-user" />
            )}

            <div className="ChatHeader-details">
                <div className="ChatHeader-title">{title}</div>
                {status ? (
                    <div className="ChatHeader-status">{status}</div>
                ) : null}
            </div>
        </div>
    );
}

const ChatMessages = observer(({ messages, onImageClick }) => {
    const [scroller, scrollerChildrenStyle, scrollToBottom, scrollToTop] =
        useScroller();
    const [isAtBottom] = useScrollerPosition(scroller);
    useEffect(() => scrollToBottom(), [messages.length, scrollToBottom]);

    const theme = useChatTheme();
    const { backgroundImage, backgroundSize } = theme || {};
    return (
        <div
            className="ChatMessagesWrapper"
            ref={scroller}
            style={{
                backgroundImage: backgroundImage
                    ? `url(${backgroundImage})`
                    : null,
                backgroundSize,
            }}
        >
            <div
                className="ChatMessages"
                style={{
                    ...scrollerChildrenStyle,
                }}
            >
                {messages.map((message, i) => (
                    <React.Fragment key={i}>
                        <ChatMessagesDate
                            message={message}
                            previousMessage={i > 0 ? messages[i - 1] : null}
                        />
                        <ChatMessage
                            message={message}
                            previousMessage={i > 0 ? messages[i - 1] : null}
                            onImagesLoaded={scrollToBottom}
                            onImageClick={onImageClick}
                        />
                    </React.Fragment>
                ))}
                <ScrollToTopButton
                    color="white"
                    backgroundColor="rgba(0,0,0,0.5)"
                    visible={isAtBottom}
                    onClick={() => scrollToTop(500)}
                />
            </div>
        </div>
    );
});

export const ChatMessagesDate = ({ message, previousMessage }) => {
    const messageDate = formatMessageDate(message);
    const previousMessageDate = formatMessageDate(previousMessage);

    if (messageDate === previousMessageDate) {
        return null;
    }

    return <div className="ChatMessagesDate">{messageDate}</div>;
};

const ChatFooter = observer(() => {
    const store = useAppStore();
    const textbox = useRef(null);

    const { activePendingMessage, canSendMessage, typeCharacterEventName } =
        store;

    useEffect(() => {
        const typeHandler = () => {
            const input = textbox.current;
            input.scrollLeft = input.scrollWidth;
        };

        window.addEventListener(typeCharacterEventName, typeHandler);

        return () =>
            window.removeEventListener(typeCharacterEventName, typeHandler);
    }, [typeCharacterEventName]);

    function handleSendClick() {
        if (!canSendMessage) {
            return;
        }

        store.sendMessage();
        textbox.current.focus();
    }

    return (
        <div className="ChatFooter">
            <input
                ref={textbox}
                className="ChatFooter-input data-hj-allow"
                type="text"
                placeholder="Type a message"
                value={activePendingMessage}
                onChange={(evt) =>
                    store.setActivePendingMessage(evt.target.value)
                }
                onKeyPress={(evt) => evt.key === "Enter" && store.sendMessage()}
            />
            <div
                className={classnames(
                    "ChatFooter-send-button icon icon-paper-plane",
                    { disabled: !canSendMessage }
                )}
                onClick={handleSendClick}
            />
        </div>
    );
});

function ImageView({ src, onClose }) {
    return (
        <div className="ChatImageView">
            <div
                className="ChatImageView-close icon-cancel"
                onClick={onClose}
            />
            <ZoomableImage
                src={src}
                className="AppFolder-file-image"
                showSpinner
            />
        </div>
    );
}

export function formatMessageDate(message) {
    if (!message?.time) {
        return "";
    }

    let { time } = message;
    if (typeof time === "string") {
        time = parseDate(time);
    }

    const now = dayjs();
    if (time.isSame(now, "day")) {
        return "TODAY";
    }

    const yesterday = now.subtract(1, "day");
    if (time.isSame(yesterday, "day")) {
        return "YESTERDAY";
    }

    return formatDate(time, DATE_FORMAT);
}

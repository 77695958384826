import axiosRetry from "axios-retry";

const SAFE_HTTP_METHODS = ["get", "head", "options"];
const IDEMPOTENT_HTTP_METHODS = SAFE_HTTP_METHODS.concat(["put", "delete"]);

function isIdempotentRequestError(error) {
    if (!error.config) {
        // Cannot determine if the request can be retried
        return false;
    }

    return IDEMPOTENT_HTTP_METHODS.indexOf(error.config.method) !== -1;
}

function isTimedOutIdempotentRequestError(error) {
    return error.code === "ECONNABORTED" && isIdempotentRequestError(error);
}

export default function shouldRetry(error) {
    // isNetworkOrIdempotentRequestError + retry on timed out idempotent requests
    return (
        axiosRetry.isNetworkOrIdempotentRequestError(error) ||
        isTimedOutIdempotentRequestError(error)
    );
}

import styles from "./ShutDown.module.css";
import AppState from "state/AppState";
import useEntered from "hooks/useEntered";

import React from "react";
import classnames from "classnames";
import { observer } from "mobx-react-lite";

function ShutDown() {
    const entered = useEntered();

    return (
        <div
            className={classnames(
                styles.screen,
                entered ? styles.entered : null
            )}
        >
            <div className={styles.inner} />
        </div>
    );
}

const ShutDownController = observer(() => {
    if (
        !AppState.globalBatteryEnabled ||
        AppState.batteryLevel !== 0 ||
        AppState.unlimitedBatteryActive
    ) {
        return null;
    }
    return <ShutDown />;
});

export default ShutDownController;

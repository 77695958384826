import { useState, useEffect } from "react";

export default function useEntered(condition) {
    const conditionMet = typeof condition === "undefined" || !!condition;
    const [entered, toggleEntered] = useState(false);

    useEffect(() => {
        let timeout;
        if (conditionMet && !entered) {
            timeout = setTimeout(() => toggleEntered(true), 20);
        }
        return () => clearTimeout(timeout);
    }, [conditionMet, entered]);

    return entered;
}

import styles from "./EditorTab.module.css";

import React from "react";
import classnames from "classnames";

const EditorTab = ({ scrollable, children }) => (
    <div
        className={classnames(
            styles.tab,
            scrollable ? styles.scrollable : null
        )}
    >
        {children}
    </div>
);

export default EditorTab;

import dayjs from "dayjs";

const MINUTES_IN_DAY = 24 * 60;

export function formatDate(date, format) {
    /* eslint-disable no-underscore-dangle */
    if (typeof date === "string") {
        return date;
    }

    if (typeof date === "object" && date !== null && date.__type === "RDT") {
        date = dayjs() // eslint-disable-line no-param-reassign
            .subtract(date.amount || 0, date.units || "minutes")
            .hour(0)
            .minute(0)
            .add(date.time || 0, "minutes");
    }

    if (typeof date?.format === "function") {
        return date.format(format);
    }

    return "";
    /* eslint-enable no-underscore-dangle */
}

export function parseDate(date) {
    /* eslint-disable no-underscore-dangle */
    if (typeof date === "object" && date !== null && date.__type === "RDT") {
        return dayjs().subtract(date.amount || 0, date.units || "minutes");
    }
    return dayjs(date);
    /* eslint-enable no-underscore-dangle */
}

export function parseUtcTimestamp(utcTimestamp) {
    return dayjs
        .unix(utcTimestamp)
        .subtract(getTimezoneOffsetMinutes(), "minute");
}

export function getTimezoneOffsetMinutes() {
    return new Date().getTimezoneOffset();
}

export function relativeDate(amount, units) {
    return { __type: "RDT", amount, units };
}

export class RelativeDateTime {
    constructor(date, time) {
        // eslint-disable-next-line no-underscore-dangle
        if (typeof date === "object" && date.__type === "RDT") {
            Object.assign(this, date);
        } else {
            // date is daysBack
            Object.assign(this, relativeDate(date || 0, "days"));
        }
        this.time = time || 0;
    }

    hour() {
        return Math.floor(this.time / 60);
    }

    minute() {
        return this.time % 60;
    }

    formatTime(format) {
        return dayjs().hour(this.hour()).minute(this.minute()).format(format);
    }

    setTime(hour, minute) {
        return this.clone({ time: hour * 60 + (minute || 0) });
    }

    isSameDate(other) {
        return this.amount === other.amount;
    }

    add(amount, units) {
        switch (units) {
            case "minutes":
                return this.addMinutes(amount);
            case "hours":
                return this.addMinutes(amount * 60);
            case "days":
                return this.addMinutes(amount * 60 * 24);
            case "weeks":
                return this.addMinutes(amount * 60 * 24 * 7);
            default:
                return null;
        }
    }

    addMinutes(minutes) {
        let nextTime = this.time + minutes;
        let nextDate = this.amount;

        if (nextTime >= MINUTES_IN_DAY) {
            nextDate -= Math.floor(nextTime / MINUTES_IN_DAY);
            nextTime %= MINUTES_IN_DAY;
        }

        return new RelativeDateTime(nextDate, nextTime);
    }

    toRelativeDate() {
        return relativeDate(this.amount, this.units);
    }

    clone(overrides) {
        return Object.assign(new RelativeDateTime(), this, overrides || {});
    }
}

import React, { useRef, useEffect } from "react";
import Hammer from "hammerjs";

export default function GestureDetector({
    children,
    className,
    onSwipe,
    onSwipeLeft,
    onSwipeRight,
    onPanMove,
    onPanStart,
    onPanEnd,
    panThreshold,
    ...props
}) {
    const root = useRef();
    const manager = useRef();

    useEffect(() => {
        manager.current = new Hammer.Manager(root.current);

        return () => {
            manager.current.destroy();
        };
    }, []);

    useEffect(() => {
        // TODO: consider support for removing recognizers.

        const managerInst = manager.current;

        if (
            (onSwipe || onSwipeLeft || onSwipeRight) &&
            !managerInst.get("swipe")
        ) {
            managerInst.add(new Hammer.Swipe());
        }

        if ((onPanMove || onPanStart || onPanEnd) && !managerInst.get("pan")) {
            const panOptions = {};
            if (panThreshold !== undefined) {
                panOptions.threshold = panThreshold;
            }
            managerInst.add(new Hammer.Pan(panOptions));
        }
    }, [
        onSwipe,
        onSwipeLeft,
        onSwipeRight,
        onPanMove,
        onPanStart,
        onPanEnd,
        panThreshold,
    ]);

    useEffect(() => {
        // console.log("useEffect onSwipe");
        if (onSwipe) {
            manager.current.on("swipe", onSwipe);
        }
        return () => manager.current.off("swipe");
    }, [onSwipe]);

    useEffect(() => {
        // console.log("useEffect onSwipeLeft");
        if (onSwipeLeft) {
            manager.current.on("swipeleft", onSwipeLeft);
        }
        return () => manager.current.off("swipeleft");
    }, [onSwipeLeft]);

    useEffect(() => {
        // console.log("useEffect onSwipeRight");
        if (onSwipeRight) {
            manager.current.on("swiperight", onSwipeRight);
        }
        return () => manager.current.off("swiperight");
    }, [onSwipeRight]);

    useEffect(() => {
        // console.log("useEffect onPanStart");
        if (onPanStart) {
            manager.current.on("panstart", onPanStart);
        }
        return () => manager.current.off("panstart");
    }, [onPanStart]);

    useEffect(() => {
        // console.log("useEffect onPanMove");
        if (onPanMove) {
            manager.current.on("panmove", onPanMove);
        }
        return () => manager.current.off("panmove");
    }, [onPanMove]);

    useEffect(() => {
        // console.log("useEffect onPanEnd");
        if (onPanEnd) {
            manager.current.on("panend", onPanEnd);
        }
        return () => manager.current.off("panend");
    }, [onPanEnd]);

    return (
        <div className={className} ref={root} {...props}>
            {children}
        </div>
    );
}

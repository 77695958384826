import { makeAutoObservable, set } from "mobx";
import { pickToJs } from "utils/mobxUtils";

export default class PhoneCallsAppState {
    constructor({ existingState, app }) {
        makeAutoObservable(this);

        if (existingState) {
            set(this, existingState);
        } else {
            this.currentScreen = app?.data?.startScreen || "history";
        }
    }

    dispose() {}

    currentScreen = "history";
    dialerNumber = "";

    static hasProgress() {
        return false;
    }

    /// / Computed

    get asJson() {
        return pickToJs(this, ["currentScreen", "dialerNumber"]);
    }

    /// / Actions

    setCurrentScreen(currentScreen) {
        this.currentScreen = currentScreen;
    }

    setDialerNumber(number) {
        this.dialerNumber = number;
    }
}

import styles from "./VideoCall.module.css";
import AppState from "state/AppState";
import { reportExceptionIfError } from "utils/errorReporting";
import AudioRingtone from "audio/video-call-ringtone.mp3";

import VideoPlayer from "@faintlines/video-player";

import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Howl } from "howler";
import useMeasure from "react-use/lib/useMeasure";
import classnames from "classnames";

const RING_MAX_TIME_SEC = 60;

export default observer(() => {
    const { videoCall } = AppState.storyState;

    if (!videoCall) {
        return null;
    }

    const { name, videoUrl, declineEvent, endEvent } = videoCall;

    const finishHandler = (declined) => {
        AppState.clearVideoCall();

        const event = declined ? declineEvent : endEvent;
        if (event) {
            AppState.fireEvent(event);
        }
    };

    return (
        <VideoCallView
            name={name}
            videoUrl={videoUrl}
            videoCall={videoCall}
            onFinish={finishHandler}
        />
    );
});

function VideoCallView({ name, videoUrl, onFinish }) {
    const [status, setStatus] = useState(null);

    const finishHandler = (declined) => {
        if (onFinish) {
            onFinish(declined);
        }
    };

    return (
        <div className={styles.videoCall}>
            {status === "active" ? (
                <ActiveVideoCall
                    videoUrl={videoUrl}
                    onEnded={() => finishHandler(false)}
                />
            ) : (
                <IncomingCall
                    name={name}
                    onAnswer={() => setStatus("active")}
                    onDecline={() => finishHandler(true)}
                />
            )}
        </div>
    );
}

function IncomingCall({ name, onAnswer, onDecline }) {
    useEffect(() => {
        const ringtone = new Howl({
            src: [AudioRingtone],
            autoplay: true,
            loop: true,
            volume: 0.5,
        });

        const timeout = setTimeout(() => {
            if (onDecline) {
                onDecline();
            }
        }, RING_MAX_TIME_SEC * 1000);

        return () => {
            ringtone.stop();
            clearTimeout(timeout);
        };
    }, [onDecline]);

    return (
        <div className={styles.incoming}>
            <div className={styles.incoming__name}>{name}</div>
            <div className={styles.incoming__subtitle}>
                {"Incoming video call"}
            </div>
            <div className={styles.incoming__buttons}>
                <IncomingButton type="answer" onClick={onAnswer} />
                <IncomingButton type="decline" onClick={onDecline} />
            </div>
        </div>
    );
}

function IncomingButton({ type, onClick }) {
    return (
        <div
            className={classnames(styles.incoming__button, styles[type])}
            onClick={onClick}
        >
            <i
                className={classnames(
                    styles.incoming__button__icon,
                    "icon-phone"
                )}
            />
        </div>
    );
}

function ActiveVideoCall({ videoUrl, onEnded }) {
    const [started, setStarted] = useState(false);

    return (
        <>
            <Video
                videoUrl={videoUrl}
                onEnded={onEnded}
                onStarted={() => setStarted(true)}
            />
            <div className={styles.endButton} onClick={onEnded}>
                <i
                    className={classnames(styles.endButton__icon, "icon-phone")}
                />
            </div>
            {started ? null : (
                <div className={styles.video__loading}>{"Connecting..."}</div>
            )}
        </>
    );
}

function Video({ videoUrl, onStarted, onEnded }) {
    const [screenRef, { width, height }] = useMeasure();
    const haveMeasure = !!(width && height);
    const videoAspectRatio = 9 / 16;

    let videoWidth;
    let videoHeight;
    if (haveMeasure) {
        const screenAR = width / height;
        if (screenAR > videoAspectRatio) {
            videoWidth = width;
            videoHeight = width * (1 / videoAspectRatio);
        } else {
            videoHeight = height;
            videoWidth = height * videoAspectRatio;
        }
    }

    return (
        <div className={styles.videoWrapper} ref={screenRef}>
            {haveMeasure ? (
                <VideoPlayer
                    url={videoUrl}
                    width={videoWidth}
                    height={videoHeight}
                    className={styles.video__player}
                    playsInline
                    autoPlay
                    autoRetry
                    preload="auto"
                    onEnded={onEnded}
                    onCanPlay={onStarted}
                    onError={(err) => reportExceptionIfError(err)}
                />
            ) : null}
        </div>
    );
}

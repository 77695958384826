import { makeAutoObservable, set } from "mobx";
import { pickToJs } from "utils/mobxUtils";

export default class Story15GameAppState {
    constructor({ existingState }) {
        makeAutoObservable(this);

        if (Story15GameAppState.hasProgress(existingState)) {
            set(this, existingState);
        }
    }

    dispose() {}

    currentLevel = null;

    static hasProgress(state) {
        return state && !!state.currentLevel;
    }

    /// / Computed

    get asJson() {
        return pickToJs(this, ["currentLevel"]);
    }

    /// / Actions

    setLevel(newLevel) {
        this.currentLevel = newLevel;
    }
}

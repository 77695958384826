import { pickToJs } from "utils/mobxUtils";
import AudioEmailIn from "../audio/email-in.mp3";

import { makeAutoObservable, set } from "mobx";
import { isEmpty, find } from "lodash";
import { Howl } from "howler";
import dayjs from "dayjs";

export default class EmailAppState {
    constructor({ existingState, app, mainState }) {
        makeAutoObservable(this);

        if (EmailAppState.hasProgress(existingState)) {
            set(this, existingState);
        }

        this._app = app;
        this._mainState = mainState;

        this._soundIncomingEmail = new Howl({
            src: [AudioEmailIn],
            volume: 0.5,
        });
    }

    dispose() {}

    visibleThreads = {}; // threadId => datetime
    downloadedAttachments = {}; // attachmentId => boolean
    unlockedAttachments = {}; // attachmentId => boolean

    static hasProgress(state) {
        return (
            state &&
            (!isEmpty(state.visibleThreads) ||
                !isEmpty(state.downloadedAttachments) ||
                !isEmpty(state.unlockedAttachments))
        );
    }

    get asJson() {
        return pickToJs(this, [
            "visibleThreads",
            "downloadedAttachments",
            "unlockedAttachments",
        ]);
    }

    showThread(threadId) {
        if (!this.visibleThreads[threadId]) {
            this.visibleThreads[threadId] = dayjs().format("YYYY-MM-DD h:mm A");
            this._soundIncomingEmail.play();
            this._pushNewThreadNotification(threadId);
        }
    }

    downloadAttachment(attachmentId) {
        this.downloadedAttachments[attachmentId] = true;
    }

    unlockAttachment(attachmentId) {
        this.unlockedAttachments[attachmentId] = true;
    }

    _pushNewThreadNotification(threadId) {
        const thread = find(this._app.data.threads, (x) => x.id === threadId);
        if (!thread) {
            // should not get here
            return;
        }

        this._mainState.pushNotification(this._app, threadId, {
            text: thread.subject,
        });
    }
}

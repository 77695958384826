import styles from "./CalculatorLocker.module.css";
import { usePhoneSessionStorage } from "state/stateHooks";

import { stringsInclude } from "@faintlines/string-utils";

import React from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";

function CalculatorLocker({ password, appName, onUnlock }) {
    const [value, setValue] = usePhoneSessionStorage(
        `calculator__${appName}`,
        ""
    );

    function op(newValue) {
        setValue(newValue);

        if (stringsInclude(password, newValue)) {
            onUnlock();
        }
    }

    function calculate() {
        const expression = value
            .replace(/×/g, "*")
            .replace(/÷/g, "/")
            .replace(/(^|[^\d])0+(\d+)/g, "$1$2");
        try {
            const newValue = eval(expression); // eslint-disable-line no-eval
            if (Number.isFinite(newValue)) {
                const newValueStr = newValue.toString();
                setValue(newValueStr);
                if (stringsInclude(password, newValueStr)) {
                    onUnlock();
                }
            } else {
                setValue("");
            }
        } catch (e) {
            // console.log(expression);
            // console.log(e);
            // TODO: reflect error
        }
    }

    return (
        <div className={styles.calculator}>
            <div className={styles.calculator__value}>
                <textarea
                    type="text"
                    className={classnames(
                        styles.calculator__value__inner,
                        "data-hj-allow"
                    )}
                    value={value}
                    readOnly
                />
            </div>
            <div className={styles.calculator__keyboard}>
                <CalcKey label="C" evt={() => op("")} cls="c" />
                <CalcKey label="(" evt={() => op(`${value}(`)} cls="op" />
                <CalcKey label=")" evt={() => op(`${value})`)} cls="op" />
                <CalcKey label="÷" evt={() => op(`${value}÷`)} cls="op" />

                <CalcKey label="7" evt={() => op(`${value}7`)} />
                <CalcKey label="8" evt={() => op(`${value}8`)} />
                <CalcKey label="9" evt={() => op(`${value}9`)} />
                <CalcKey label="×" evt={() => op(`${value}×`)} cls="op" />

                <CalcKey label="4" evt={() => op(`${value}4`)} />
                <CalcKey label="5" evt={() => op(`${value}5`)} />
                <CalcKey label="6" evt={() => op(`${value}6`)} />
                <CalcKey label="-" evt={() => op(`${value}-`)} cls="op" />

                <CalcKey label="1" evt={() => op(`${value}1`)} />
                <CalcKey label="2" evt={() => op(`${value}2`)} />
                <CalcKey label="3" evt={() => op(`${value}3`)} />
                <CalcKey label="+" evt={() => op(`${value}+`)} cls="op" />

                <CalcKey
                    label="⌫"
                    evt={() => op(value.slice(0, -1))}
                    cls="op"
                />
                <CalcKey label="0" evt={() => op(`${value}0`)} />
                <CalcKey label="." evt={() => op(`${value}.`)} />
                <CalcKey label="=" evt={() => calculate()} cls="eq" />
            </div>
        </div>
    );
}

export default observer(CalculatorLocker);

function CalcKey({ label, evt, cls }) {
    return (
        <div
            className={classnames(styles.calculator__key, cls)}
            data-label={label}
            onClick={evt}
        >
            {label}
        </div>
    );
}

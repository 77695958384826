import "./AppChat.css";
import ChatWindow from "./ChatWindow";
import { ChatThemeContext } from "./ChatThemeContext";

import { useAppStore } from "@faintlines/phone-sim-common";

import { Route } from "react-router-dom";
import React, { useEffect } from "react";
import urljoin from "url-join";
import { observer } from "mobx-react-lite";

function AppChat({ title, theme, appUrl, appPath }) {
    const State = useAppStore();

    useEffect(() => {
        State.toggleIsAppActive(true);
        return () => State.toggleIsAppActive(false);
    }, [State]);

    return (
        <ChatThemeContext.Provider value={theme || {}}>
            <div className="AppChat">
                <Route
                    exact
                    path={[appPath, urljoin(appPath, ":chatId")]}
                    render={({ match }) => (
                        <ChatWindow
                            appUrl={appUrl}
                            appTitle={title}
                            theme={theme}
                            chatId={match.params.chatId}
                        />
                    )}
                />
            </div>
        </ChatThemeContext.Provider>
    );
}

export default observer(AppChat);

import store from "store";
import React, { useEffect } from "react";

const STORE_KEY = "jc81";

export default function Hotjar({ hjid, hjsv, fraction }) {
    if (process.env.NODE_ENV !== "production") {
        return null;
    }

    if (!shouldActivate(fraction)) {
        return null;
    }

    return <HotjarInternal hjid={hjid} hjsv={hjsv} />;
}
Hotjar.defaultProps = {
    fraction: 1,
};

const HotjarInternal = ({ hjid, hjsv }) => {
    /* eslint-disable */
    useEffect(() => {
        (function (h, o, t, j, a, r) {
            h.hj =
                h.hj ||
                function () {
                    (h.hj.q = h.hj.q || []).push(arguments);
                };
            h._hjSettings = { hjid, hjsv };
            a = o.getElementsByTagName("head")[0];
            r = o.createElement("script");
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
        })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    }, [hjid, hjsv]);
    /* eslint-enable */

    return null;
};

function shouldActivate(fraction) {
    const existing = store.get(STORE_KEY);
    if (existing !== undefined) {
        return existing;
    }
    const activate = Math.random() <= fraction;
    store.set(STORE_KEY, activate);
    return activate;
}

import styles from "./PasswordScreenLocker.module.css";
import AppState from "state/AppState";
import PasswordInput from "components/utils/PasswordInput";
import Clickable from "components/utils/Clickable";
import { vibrateShort } from "utils/browserUtils";

import { stringsInclude } from "@faintlines/string-utils";

import classnames from "classnames";
import React, { useState } from "react";

export default function PasswordScreenLocker({
    title,
    password,
    onUnlock,
    onCancel,
}) {
    const [value, setValue] = useState("");
    const [animated, setAnimated] = useState(false);

    const onSubmit = () => {
        if (stringsInclude(password, value) && onUnlock) {
            onUnlock();
            AppState.trackPasswordEvent("lockScreenPassword", value, true);
        } else {
            vibrateShort();
            setAnimated(true);
            setTimeout(() => {
                setAnimated(false);
                setValue("");
            }, 300);
            AppState.trackPasswordEvent("lockScreenPassword", value, false);
        }
    };

    return (
        <div className={styles.root}>
            {title ? <div className={styles.title}>{title}</div> : null}
            <div className={styles.inputContainer}>
                <PasswordInput
                    className={classnames(
                        styles.textbox,
                        animated ? styles.animated : null
                    )}
                    formClassName={styles.textboxForm}
                    value={value}
                    onChange={(evt) => setValue(evt.target.value)}
                />
                <Clickable onClick={onSubmit}>{"Unlock"}</Clickable>
            </div>
            <Clickable className={styles.cancel} onClick={onCancel}>
                {"Cancel"}
            </Clickable>
        </div>
    );
}

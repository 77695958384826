import styles from "./AnswerForm.module.css";
import { EllipsisLoader } from "../Loaders";
import Button from "../common/Button";
import AppState from "state/AppState";
import UiState from "state/UiState";

import React from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";

function AnswerForm({ story, visible }) {
    const {
        id,
        answerTitle,
        answerQuestion,
        answerPlaceholder,
        requiredProgress,
        requiredProgressText,
    } = story;

    const handleFormSubmit = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        AppState.submitAnswer(id);
    };

    const { answerError, answerValue, checkingAnswer, requiredProgressMade } =
        AppState.storyState;
    const isProgressRequired = requiredProgress && !requiredProgressMade;

    return (
        <AnswerFormView
            title={answerTitle}
            question={answerQuestion}
            error={answerError}
            placeholder={answerPlaceholder}
            value={answerValue}
            disabled={!visible}
            isProgressRequired={isProgressRequired}
            requiredProgressText={requiredProgressText}
            submitting={checkingAnswer}
            onChange={(value) => AppState.updateAnswerValue(value)}
            onSubmit={handleFormSubmit}
        />
    );
}

export default observer(AnswerForm);

export const AnswerFormView = observer(
    ({
        title,
        question,
        error,
        placeholder,
        value,
        disabled,
        isProgressRequired,
        requiredProgressText,
        submitting,
        onChange,
        onSubmit,
    }) => (
        <div className={styles.container}>
            <Title
                isProgressRequired={isProgressRequired}
                requiredProgressText={requiredProgressText}
                title={title}
                question={question}
            />
            {error ? <div className={styles.error}>{error}</div> : null}
            <form onSubmit={onSubmit} action="#">
                <input
                    className={classnames(styles.textbox, "data-hj-allow")}
                    value={value || ""}
                    onChange={(evt) => onChange(evt.target.value)}
                    placeholder={isProgressRequired ? "" : placeholder}
                    type="text"
                    data-lpignore="true"
                    disabled={
                        isProgressRequired ||
                        disabled ||
                        UiState.successModalVisible
                    }
                />
                <Button
                    className={classnames(
                        styles.submitButton,
                        submitting ? styles.loading : null
                    )}
                    primary={!isProgressRequired}
                    displayFont
                    disabled={!value}
                    onClick={submitting ? null : onSubmit}
                >
                    {"Check Answer"}
                    {submitting ? (
                        <EllipsisLoader
                            className={styles.submitButton__loader}
                        />
                    ) : null}
                </Button>
            </form>
        </div>
    )
);

const Title = ({
    isProgressRequired,
    requiredProgressText,
    title,
    question,
}) => {
    if (isProgressRequired) {
        return (
            <div className={styles.requiredProgressText}>
                {requiredProgressText ||
                    "To submit an answer, first make some progress"}
            </div>
        );
    }

    return (
        <>
            <div className={styles.title}>{title || "Think You Got It?"}</div>
            {question ? (
                <div className={styles.question}>{question}</div>
            ) : null}
        </>
    );
};

import "./AppFindFriends.css";
import Image from "components/common/Image";
import EmojiText from "components/utils/EmojiText";

import React, { useState } from "react";
import classnames from "classnames";

export default function AppFindFriends({ entries, appTitle }) {
    const [selected, setSelected] = useState(0);

    return (
        <div className="AppFindFriends">
            <div className="AppFindFriends-title">
                {appTitle || "Find My Friends"}
            </div>
            <FindFriendsMap entry={entries[selected]} />
            <FindFriendsList
                entries={entries}
                selected={selected}
                onSelected={(index) => setSelected(index)}
            />
        </div>
    );
}

function FindFriendsMap({ entry }) {
    const src = entry ? entry.map : null;

    return (
        <div
            className="AppFindFriends-map"
            style={{ backgroundImage: `url(${src})` }}
        />
    );
}

function FindFriendsList({ entries, selected, onSelected }) {
    return (
        <div className="AppFindFriends-list">
            {(entries || []).map((entry, i) => (
                <FindFriendsEntry
                    key={i}
                    index={i}
                    entry={entry}
                    isSelected={i === selected}
                    onSelected={onSelected}
                />
            ))}
        </div>
    );
}

function FindFriendsEntry({ entry, index, isSelected, onSelected }) {
    const { image, name, location, time } = entry;
    const detailsStr = [location, time].filter((x) => !!x).join(" - ");

    return (
        <div className="AppFindFriends-entry" onClick={() => onSelected(index)}>
            <Image className="AppFindFriends-entry-img" src={image} />
            <div className="AppFindFriends-entry-details">
                <div
                    className={classnames("AppFindFriends-entry-name", {
                        selected: isSelected,
                    })}
                >
                    <EmojiText text={name} />
                </div>
                {detailsStr ? (
                    <div className="AppFindFriends-entry-location">
                        {detailsStr}
                    </div>
                ) : null}
            </div>
        </div>
    );
}

import AppState from "../../state/AppState";

import React, { useState, useEffect } from "react";
import { Howl } from "howler";
import classnames from "classnames";

const FADE_OUT_DURATION_MS = 2000;

export default function SoundPlayer({
    src,
    volume,
    className,
    pauseMusic,
    children,
}) {
    // TODO: convert _state to useRef and test it
    /* eslint-disable no-underscore-dangle */
    const _state = { _sound: null, _playing: false };
    const [playing, setPlaying] = useState(false);

    function onStop() {
        if (pauseMusic) {
            AppState.resumeMusic();
        }
    }

    useEffect(() => {
        if (_state._playing !== playing) {
            if (!playing && _state.sound) {
                stopSound(_state._sound);
                onStop();
                _state._sound = null;
            }

            if (playing) {
                if (!_state._sound) {
                    _state._sound = new Howl({
                        src: [src],
                        volume: volume || 1,
                        onend: () => {
                            onStop();
                            setPlaying(false);
                        },
                    });
                }

                _state._sound.play();
                if (pauseMusic) {
                    AppState.pauseMusic();
                }
            }

            _state._playing = playing;
        }

        return () => {
            if (_state._sound) {
                stopSound(_state._sound);
                onStop();
            }
        };
    });
    /* eslint-enable no-underscore-dangle */

    function clickHandler(evt) {
        evt.stopPropagation();
        setPlaying(!playing);
    }

    return (
        <div
            className={classnames("SoundPlayer", className, { playing })}
            onClick={clickHandler}
        >
            {children}
        </div>
    );
}

function stopSound(sound, fadeOutDuration) {
    if (fadeOutDuration) {
        sound.fade(sound.volume(), 0, FADE_OUT_DURATION_MS);
        setTimeout(() => sound.stop(), FADE_OUT_DURATION_MS);
    } else {
        sound.stop();
    }
}

import { random } from "lodash";

export function randomChoice(array) {
    return array[random(array.length - 1)];
}

export function weightedChoice(array, randomFunc) {
    // array: [["a", 1], ["b", 2]]

    const total = array.reduce((sum, x) => sum + x[1], 0);
    const threshold = (randomFunc || Math.random)() * total;

    let upto = 0;
    for (let i = 0; i < array.length; i += 1) {
        const [choice, weight] = array[i];
        upto += weight;
        if (upto >= threshold) {
            return choice;
        }
    }

    // should not get here
    return undefined;
}

export function WeakPRNG(seed) {
    let current = seed;
    return () => {
        const x = Math.sin(current) * 10000;
        current += 1;
        return x - Math.floor(x);
    };
}

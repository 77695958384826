import styles from "./ImageUploader.module.css";

import { useStudioStory } from "../StudioContext";
import * as StudioApi from "../../../server/studioApi";

import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import classnames from "classnames";

export default function ImageUploader({ label, value, options, onChange }) {
    const { size } = options;
    const story = useStudioStory();
    const [uploading, setUploading] = useState(false);
    const dropEnabled = !uploading && !value;

    const handleDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                setUploading(true);
                StudioApi.uploadImage(story.id, file)
                    .then(({ data }) => {
                        onChange(data.url);
                    })
                    .finally(() => setUploading(false));
            });
        },
        [onChange, story.id]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: "image/*",
        maxFiles: 1,
        onDrop: handleDrop,
        disabled: !dropEnabled,
    });

    function handleDelete(evt) {
        evt.stopPropagation();
        onChange("");
    }

    return (
        <div className={styles.root}>
            {label ? (
                <div
                    className={classnames(
                        styles.label,
                        "MuiFormLabel-root MuiInputLabel-root MuiInputLabel-shrink"
                    )}
                >
                    {label}
                </div>
            ) : null}
            <div
                className={styles.dropzone}
                {...getRootProps()}
                style={{ width: size, height: size }}
            >
                <input {...getInputProps()} />
                {uploading ? (
                    <div className={styles.uploading}>{"Uploading..."}</div>
                ) : null}
                {value ? (
                    <>
                        <img className={styles.thumbnail} src={value} alt="" />
                        <div className={styles.delete} onClick={handleDelete}>
                            <i
                                className={classnames(
                                    styles.delete__icon,
                                    "icon-cancel"
                                )}
                            />
                        </div>
                    </>
                ) : null}
                {dropEnabled ? (
                    <p>
                        {isDragActive
                            ? "Drop the image here ..."
                            : "Drop an image here, or click to select a file"}
                    </p>
                ) : null}
            </div>
        </div>
    );
}

ImageUploader.defaultProps = {
    options: {
        size: 120,
    },
};

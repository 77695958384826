import styles from "./DraggableList.module.css";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import React from "react";
import classnames from "classnames";

export default function DraggableList({ items, direction, onReorder }) {
    const handleDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const reorderedIds = Array.from(items.map((x) => x.id));
        const [removed] = reorderedIds.splice(result.source.index, 1);
        reorderedIds.splice(result.destination.index, 0, removed);

        if (onReorder) {
            onReorder(reorderedIds);
        }
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable" direction={direction}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        className={classnames(
                            styles.list,
                            snapshot.isDraggingOver
                                ? styles.draggingOver
                                : null,
                            styles[direction]
                        )}
                        {...provided.droppableProps}
                    >
                        {items.map((item, index) => (
                            <DraggableListItem
                                key={item.id}
                                item={item}
                                index={index}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}
DraggableList.defaultProps = {
    direction: "horizontal",
};

const DraggableListItem = ({ item, index }) => (
    <Draggable draggableId={item.id} index={index}>
        {(provided, snapshot) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={classnames(
                    styles.draggable,
                    snapshot.isDragging ? styles.dragging : null
                )}
                style={provided.draggableProps.style}
            >
                {item.content}
            </div>
        )}
    </Draggable>
);

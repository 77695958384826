import styles from "./PhoneHeader.module.css";
import BatteryIconSmallController from "../common/BatteryIconSmallController";
import AppState from "state/AppState";
import UiState from "state/UiState";

import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import classnames from "classnames";
import { Slash as IconSlash, Wifi as IconWifi } from "lucide-react";

export default function PhoneHeader() {
    return (
        <div className={styles.header}>
            <Time className={styles.header__left} />
            <div className={styles.header__right}>
                <SignalIcon />
                <BatteryIconController />
            </div>
        </div>
    );
}

function Time({ className }) {
    const [time, setTime] = useState(getTime());
    useEffect(() => {
        const interval = setInterval(() => setTime(getTime()), 1000);
        return () => clearInterval(interval);
    }, []);
    return <div className={className}>{time}</div>;
}

const SignalIcon = observer(() => {
    const { isPhoneOnline } = AppState;
    const id = "header-signal-icon"; // used by the tutorial
    return isPhoneOnline ? (
        <IconWifi
            id={id}
            className={classnames(styles.icon, styles.wifi)}
            size="1em"
        />
    ) : (
        <IconSlash
            id={id}
            className={classnames(styles.icon, styles.noSignal)}
            size="1em"
        />
    );
});

const BatteryIconController = observer(() => {
    const { globalBatteryEnabled } = AppState;
    const handleClick = () => {
        if (globalBatteryEnabled) {
            UiState.toggleBatteryModal(true);
        }
    };

    // id prop is used by the tutorial
    return (
        <BatteryIconSmallController
            id="header-battery-icon"
            onClick={handleClick}
        />
    );
});

function getTime() {
    return dayjs().format("h:mm A");
}

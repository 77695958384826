import styles from "./AppSmartHome.module.css";
import OptionalLink from "../../../utils/OptionalLink";
import PinKeyboard from "../../../common/PinKeyboard";
import IconCamera from "./icon-camera.svg";
import IconLight from "./icon-light.svg";
import IconSocket from "./icon-socket.svg";
import IconDoorLock from "./icon-door-lock.svg";
import { IS_SAFARI } from "utils/browserUtils";
import { reportExceptionIfError } from "utils/errorReporting";

import VideoPlayer from "@faintlines/video-player";
import { useAppStore, MediaViewer } from "@faintlines/phone-sim-common";

import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import urljoin from "url-join";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { observer } from "mobx-react-lite";
import { find } from "lodash";
import classnames from "classnames";

const ICONS = {
    camera: IconCamera,
    light: IconLight,
    socket: IconSocket,
    doorlock: IconDoorLock,
};

const DEVICE_TYPES_WITH_PAGE = {
    camera: true,
};

function AppSmartHome({
    devices,
    locked,
    pin,
    manual,
    manualReadEvent,
    appUrl,
    appPath,
    appName,
    unlockEvent,
    onEvent,
}) {
    const State = useAppStore();
    const [showKeypad, toggleKeypad] = useState(false);

    const changeHandler = (deviceId, newState) => {
        if (!locked || State.unlocked) {
            State.setDeviceState(deviceId, newState);
        } else {
            toggleKeypad(true);
        }
    };

    const unlockHandler = () => {
        State.unlock();
        if (unlockEvent && onEvent) {
            onEvent(unlockEvent);
        }
        toggleKeypad(false);
    };

    return (
        <div className={styles.app}>
            <Switch>
                <Route exact path={appPath}>
                    <Index
                        devices={devices}
                        manual={manual}
                        manualReadEvent={manualReadEvent}
                        appUrl={appUrl}
                        onChange={changeHandler}
                        onEvent={onEvent}
                    />
                </Route>
                <Route
                    exact
                    path={urljoin(appPath, ":deviceId")}
                    render={({ match }) => (
                        <DevicePage
                            device={find(
                                devices,
                                (x) => x.id === match.params.deviceId
                            )}
                        />
                    )}
                />
                <Route>
                    <Redirect to={appUrl} />
                </Route>
            </Switch>
            {locked ? (
                <Keypad
                    pin={pin}
                    visible={showKeypad}
                    onUnlock={unlockHandler}
                    onCancel={() => toggleKeypad(false)}
                    appName={appName}
                />
            ) : null}
        </div>
    );
}

export default observer(AppSmartHome);

function Keypad({ pin, visible, onUnlock, onCancel, appName }) {
    return (
        <div
            className={classnames(styles.keypad, {
                visible,
            })}
        >
            <PinKeyboard
                title="To control your devices, enter your PIN"
                darkMode
                password={pin}
                onUnlock={onUnlock}
                onCancel={onCancel}
                appName={appName}
            />
        </div>
    );
}

function Index({
    devices,
    manual,
    manualReadEvent,
    appUrl,
    onChange,
    onEvent,
}) {
    const [showManual, toggleManual] = useState(false);

    useEffect(() => {
        if (manual && showManual && onEvent && manualReadEvent) {
            onEvent(manualReadEvent);
        }
    }, [manual, showManual, onEvent, manualReadEvent]);

    return (
        <div className={styles.index}>
            <Title>{"Smart Home"}</Title>
            <div className={styles.index__contents}>
                <div className={styles.index__devices}>
                    {devices.map((device, i) => (
                        <DeviceTile
                            key={i}
                            device={device}
                            index={i}
                            appUrl={appUrl}
                            onChange={onChange}
                        />
                    ))}
                </div>
                {manual ? (
                    <HelpLink onClick={() => toggleManual(true)} />
                ) : null}
            </div>
            {manual && showManual ? (
                <MediaViewer
                    image={manual}
                    onClose={() => toggleManual(false)}
                />
            ) : null}
        </div>
    );
}

function HelpLink({ onClick }) {
    return (
        <div className={styles.index__help} onClick={onClick}>
            {"Need help?"}
            <br />
            <span className={styles.index__help__link}>
                {"Click to read the manual"}
            </span>
        </div>
    );
}

function Title({ children }) {
    return <div className={styles.title}>{children}</div>;
}

function DeviceTile({ device, appUrl, onChange }) {
    const { id, name, type, icon } = device;

    return (
        <OptionalLink
            className={styles.deviceTile}
            to={urljoin(appUrl, id)}
            disabled={!DEVICE_TYPES_WITH_PAGE[type]}
        >
            <img src={ICONS[icon]} className={styles.deviceTile__icon} alt="" />
            <div className={styles.deviceTile__name}>{name}</div>
            <DeviceTileAction device={device} onChange={onChange} />
        </OptionalLink>
    );
}

function DeviceTileAction({ device, onChange }) {
    const { type } = device;

    if (type === "toggle") {
        return <ToggleDeviceTileAction device={device} onChange={onChange} />;
    }
    return null;
}

const ToggleDeviceTileAction = observer(({ device, onChange }) => {
    const { id } = device;
    const State = useAppStore();
    const toggleState = State.deviceState[id];
    const on =
        toggleState === undefined ? device.default || false : toggleState;

    return (
        <>
            <DeviceTileStatus>
                {on ? device.onText || "On" : device.offText || "Off"}
            </DeviceTileStatus>
            <Toggle
                className={styles.deviceTile__toggle}
                checked={on}
                icons={false}
                onChange={(e) => onChange(id, e.target.checked)}
            />
        </>
    );
});

function DeviceTileStatus({ children }) {
    return <div className={styles.deviceTile__status}>{children}</div>;
}

function DevicePage({ device }) {
    return (
        <div className={styles.device}>
            <Title>{device.name}</Title>
            <DevicePageContents device={device} />
        </div>
    );
}

function DevicePageContents({ device }) {
    const { type } = device;
    if (type === "camera") {
        return <Camera device={device} />;
    }
    return null;
}

const Camera = observer(({ device }) => {
    const { needsLight, videoSource, videoAspectRatio } = device;
    const [videoLoaded, setVideoLoaded] = useState(false);
    const store = useAppStore();
    const lightsOff = needsLight && !store.deviceState[needsLight];

    return (
        <>
            <div
                className={styles.camera}
                style={{
                    paddingTop: `${aspectRatioToPercent(videoAspectRatio)}%`,
                }}
            >
                <div
                    className={classnames(styles.camera__wrapper, {
                        lightsOff,
                    })}
                >
                    <TransformWrapper
                        limitToBounds
                        doubleClick={{ disabled: true }}
                    >
                        <TransformComponent
                            wrapperClass={styles.camera__zoomableWrapper}
                            contentClass={styles.camera__zoomableContent}
                        >
                            <VideoPlayer
                                url={videoSource}
                                style={{ aspectRatio: videoAspectRatio }}
                                width="100%"
                                height="auto"
                                className={styles.camera__video}
                                preload="auto"
                                autoPlay
                                autoRetry
                                loop
                                muted
                                onPlay={() => setVideoLoaded(true)}
                                onError={(err) => reportExceptionIfError(err)}
                            />
                        </TransformComponent>
                    </TransformWrapper>
                    {videoLoaded ? null : (
                        <div className={styles.camera__connecting}>
                            {"Connecting..."}
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.camera__instructions}>
                {IS_SAFARI ? "Slide two fingers to zoom" : "Pinch to zoom"}
            </div>
        </>
    );
});

function aspectRatioToPercent(aspectRatio) {
    try {
        const [width, height] = aspectRatio.split("/");
        return (100.0 * parseInt(height, 10)) / parseInt(width, 10);
    } catch (e) {
        return 56.25; // a default of 16/9;
    }
}

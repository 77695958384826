import styles from "./ConversationEditor.module.css";
import ContactSelector from "../widgets/ContactSelector";
import RelativeDateSelector from "../fields/RelativeDateSelector";
import TimePicker from "../fields/TimePicker";
import { HOTJAR_ALLOW_CLASS_NAME } from "../utils/StudioUtils";

import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import update from "immutability-helper";

// TODO: faintlines package
// TODO: syntax highlighting

export default function ConversationEditor({ value, maxContacts, onChange }) {
    const { conversationText, startDate, startTime, contacts } = value || {};
    const [commandsVisible, toggleCommands] = useState(false);

    const handleContactChange = (cntId, index) => {
        index = index || 0;

        let updateSpec = null;
        if (Array.isArray(contacts)) {
            updateSpec = {
                contacts: {
                    [index]: { $set: cntId },
                },
            };
        } else {
            const cntArray = [];
            cntArray[index] = cntId;
            updateSpec = { contacts: { $set: cntArray } };
        }

        onChange(update(value, updateSpec));
    };

    const handleDateChange = (date) => {
        onChange(update(value, { startDate: { $set: date } }));
    };

    const handleTimeChange = (time) => {
        onChange(update(value, { startTime: { $set: time } }));
    };

    return (
        <div>
            {maxContacts === 1 ? (
                <ContactSelector
                    label="Contact"
                    value={contacts ? contacts[0] : "[null]"}
                    onChange={handleContactChange}
                />
            ) : null}
            <RelativeDateSelector
                className={styles.control}
                formData={startDate}
                onChange={handleDateChange}
                schema={{ title: "Start date" }}
                daysOnly
            />
            <TimePicker
                className={styles.control}
                value={startTime}
                onChange={handleTimeChange}
                label="Start time"
            />
            <TextField
                value={conversationText || ""}
                multiline
                fullWidth
                variant="outlined"
                label="Conversation contents"
                InputLabelProps={{
                    shrink: true,
                    className: styles.control__textbox,
                }}
                inputProps={{ className: HOTJAR_ALLOW_CLASS_NAME }}
                placeholder={getPlaceholderText(maxContacts)}
                className={styles.control}
                onChange={(evt) =>
                    onChange(
                        update(value, {
                            conversationText: { $set: evt.target.value },
                        })
                    )
                }
            />
            <div
                className={styles.commandsToggle}
                onClick={() => toggleCommands(!commandsVisible)}
            >
                {`${commandsVisible ? "Hide" : "Show"} available commands`}
            </div>
            {commandsVisible ? (
                <div className={styles.commands}>{getSyntax(maxContacts)}</div>
            ) : null}
        </div>
    );
}

function getPlaceholderText() {
    return `
[time 19:40]
in: Hi honey
in: When are you coming home today?

[skip 2 minutes]
out: I just left work. On my way...

[skip 50 minutes]
in: Where are you?

[skip 10 minutes]
in: Daniel???
`.trim();
}

function getSyntax() {
    return (
        <>
            <SyntaxCommand
                command="in: text"
                description="An incoming message."
            />
            <SyntaxCommand
                command="out: text"
                description="An outgoing message."
            />
            <SyntaxCommand command="[time 15:30]" description="Set the time." />
            <SyntaxCommand
                command="[skip 5 minutes/hours/days]"
                description="Skip time."
            />
        </>
    );
}

function SyntaxCommand({ command, description }) {
    return (
        <div className={styles.commands__command}>
            <span className={styles.command__command__title}>{command}</span>
            <span
                className={styles.command__command__description}
            >{` - ${description}`}</span>
        </div>
    );
}

// import SyncLoader from "react-spinners/SyncLoader";
import PulseLoader from "react-spinners/PulseLoader";

import React from "react";

function fromReactSpinner(SpinnerComponent) {
    return ({ loading, color, style, size }) => (
        <SpinnerComponent
            color={color}
            loading={loading}
            cssOverride={style}
            size={size}
        />
    );
}

// export const BouncingEllipsisSpinner = fromReactSpinner(SyncLoader);
export const PulseSpinner = fromReactSpinner(PulseLoader);

import store from "store";

// An Axios middleware for transmitting the session id over a HTTP header.
// Use when cookies are unavailable, for example, on iOS when the server
// is on a different origin than the client.

export default function axiosHeaderSession(axios, headerName, storeKey) {
    const headerNameLower = headerName.toLowerCase();

    axios.interceptors.request.use((config) => {
        config.headers[headerName] = store.get(storeKey) || "?";
        return config;
    });

    axios.interceptors.response.use((response) => {
        if (response.headers[headerNameLower]) {
            store.set(storeKey, response.headers[headerNameLower]);
        }
        return response;
    });
}

export function clearHeaderSession(storeKey) {
    store.remove(storeKey);
}

import AppState from "state/AppState";

import * as Native from "@faintlines/native-bridge";

import { find } from "lodash";

export const HAS_WEBP = hasWebpImageFormatSupport();
export const IS_SAFARI = /^((?!chrome|android).)*safari/i.test(
    navigator.userAgent
);
export const IS_ANDROID =
    navigator.userAgent.toLowerCase().indexOf("android") > -1;

export function vibrateShort() {
    vibrate(10);
}

export function vibrate(durationMs) {
    if (AppState.userSettings?.disableVibrate) {
        return;
    }

    if (Native.CAPABILITIES.vibrate) {
        try {
            Native.vibrate(durationMs);
        } catch (e) {
            // nothing
        }
        return;
    }
    if (window.navigator?.vibrate) {
        window.navigator.vibrate(durationMs);
    }
}

export function selectImageSource(src, hasWebp = HAS_WEBP) {
    if (Array.isArray(src)) {
        const webpSrc = find(src, isWebpImageUrl);
        const nonWebpSrc = find(src, (x) => !isWebpImageUrl(x));
        return webpSrc && hasWebp ? webpSrc : nonWebpSrc;
    }
    return src;
}

export function isWebpImageUrl(url) {
    return (
        typeof url === "string" &&
        (url.includes(".webp") || url.startsWith("data:image/webp"))
    );
}

export function isImageUrl(url) {
    return !!(
        typeof url === "string" &&
        (url.split("?")[0].match(/\.(jpe?g|png|svg|webp)$/) ||
            url.startsWith("data:image"))
    );
}

export function filterImageUrls(urls) {
    return urls.map((x) => selectImageSource(x)).filter(isImageUrl);
}

function hasWebpImageFormatSupport() {
    const elem = document.createElement("canvas");

    if (elem.getContext && elem.getContext("2d")) {
        // was able or not to get WebP representation
        return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
    }
    // very old browser like IE 8, canvas not supported
    return false;
}

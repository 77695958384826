import React from "react";
import dayjs from "dayjs";
import classnames from "classnames";

export default function CalendarCell({
    dayValue,
    onClick,
    isSelected,
    hasEvents,
    outOfMonth,
}) {
    const textStyle = classnames("AppCalendar-cell-text", {
        today: dayjs().isSame(dayValue, "day"),
        selected: isSelected,
    });

    const container = classnames("AppCalendar-cell-container", {
        outOfMonth,
    });

    return outOfMonth ? (
        <div className={container} />
    ) : (
        <div className={container} onClick={onClick}>
            <div className={textStyle}>{dayValue.date().toString()}</div>
            {hasEvents ? <EventsIndication /> : null}
        </div>
    );
}

function EventsIndication() {
    return <span className="AppCalendar-cell-eventsIndication" />;
}

import styles from "./DotPattern.module.css";
import Clickable from "../utils/Clickable";
import { vibrateShort } from "../../utils/browserUtils";

import React, { useState } from "react";
import classnames from "classnames";

export default function DotPatternKeyboard({
    title,
    pattern, // e.g. 010010001
    className,
    darkMode,
    onCancel,
    onUnlock,
}) {
    const [toggles, setToggles] = useState(Array(9).fill(false));

    function toggleValue(index) {
        const newToggles = [...toggles];
        newToggles[index] = !newToggles[index];
        setToggles(newToggles);
        vibrateShort();

        if (togglesToStr(newToggles) === pattern) {
            onUnlock();
        }
    }

    return (
        <div className={classnames(styles.root, className, { darkMode })}>
            {title ? <div className={styles.title}>{title}</div> : null}
            <div className={styles.keyboard}>
                {toggles.map((value, i) => (
                    <Clickable
                        key={i}
                        className={classnames(styles.key, {
                            darkMode,
                            on: !!value,
                        })}
                        onClick={() => toggleValue(i)}
                    />
                ))}
            </div>
            {onCancel ? (
                <Clickable className={styles.cancel} onClick={onCancel}>
                    {"Cancel"}
                </Clickable>
            ) : null}
        </div>
    );
}

function togglesToStr(toggles) {
    return toggles.map((x) => (x ? "1" : "0")).join("");
}

import styles from "./StudioAppSelector.module.css";
import { useStudioStory } from "./StudioContext";
import { MAX_APPS, APP_TYPES } from "./StudioConstants";
import DraggableList from "./common/DraggableList";
import { getAppIconUrl } from "../Phone/Apps/common/AppIcons";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { observer } from "mobx-react-lite";
import React from "react";
import classnames from "classnames";
import { v4 as uuid } from "uuid";
import IconTrash from "react-feather/dist/icons/trash-2";
import { find } from "lodash";

const AppSelector = observer(
    ({ selectedApp, onSelected, onDelete, onStoryUpdate }) => {
        const story = useStudioStory();
        const apps = story.apps || [];

        const handleReorder = (reorderedIds) => {
            const reorderedApps = reorderedIds.map((appName) =>
                find(apps, (x) => x.name === appName)
            );
            onStoryUpdate({ apps: { $set: reorderedApps } });
        };

        return (
            <div className={styles.appSelector}>
                <DraggableList
                    direction="horizontal"
                    items={apps.map((app, i) => ({
                        id: app.name,
                        content: (
                            <App
                                key={i}
                                app={app}
                                isSelected={selectedApp === app.name}
                                onSelected={onSelected}
                                onDelete={onDelete}
                            />
                        ),
                    }))}
                    onReorder={handleReorder}
                />
                {apps.length < MAX_APPS ? (
                    <NewAppButton
                        onStoryUpdate={onStoryUpdate}
                        onAppCreated={(app) => onSelected(app.name)}
                    />
                ) : null}
            </div>
        );
    }
);

export default AppSelector;

const App = observer(({ app, isSelected, onSelected, onDelete }) => (
    <div
        className={classnames(
            styles.appSelector__app,
            isSelected ? styles.selected : null
        )}
        onClick={() => onSelected(app.name)}
    >
        <img
            className={styles.appSelector__app__icon}
            src={getAppIconUrl(app)}
            alt={app.name}
        />
        <div className={styles.appSelector__app__title}>{app.title}</div>
        <div
            className={styles.appSelector__app__delete}
            onClick={() => onDelete(app.name)}
        >
            <IconTrash size="1em" />
        </div>
    </div>
));

const NewAppButton = observer(({ onStoryUpdate, onAppCreated }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    function addNewApp(app) {
        // TODO: nicer name?
        const newApp = {
            type: app.type,
            name: `${app.type}-${uuid()}`,
            title: app.title,
        };
        onStoryUpdate({
            apps: {
                $push: [newApp],
            },
        });
        setTimeout(() => onAppCreated(newApp), 100);
        setAnchorEl(null);
    }

    return (
        <div>
            <div
                className={styles.appSelector__app}
                onClick={(evt) => setAnchorEl(evt.currentTarget)}
            >
                <div className={styles.appSelector__addButton__icon}>{"+"}</div>
            </div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                {APP_TYPES.map((app) => (
                    <MenuItem key={app.type} onClick={() => addNewApp(app)}>
                        {app.title}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
});

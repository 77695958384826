import styles from "./AppPhoneCalls.module.css";
import ContactInfo from "./common/ContactInfo";
import Clickable from "../../utils/Clickable";
import SoundPlayer from "../../utils/SoundPlayer";
import EmojiText from "components/utils/EmojiText";
import { normalizePhoneNumber } from "utils/stringUtils";
import { formatDate } from "utils/dateUtils";
import AppState from "state/AppState";
import AudioDialTone from "audio/dial-tone.mp3";

import { useAppStore, ContactPhoto } from "@faintlines/phone-sim-common";

import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import { observer } from "mobx-react-lite";
import { range, find } from "lodash";
import { Howl } from "howler";

export const DIAL_TONE_DURATION_MS = 2000;

function AppPhoneCalls({ calls, dialer, contacts }) {
    const State = useAppStore();
    const { currentScreen } = State;
    const showFooter = !!dialer;
    const [dialing, setDialing] = useState(null);

    const dialHandler = (number) => {
        const normalized = normalizePhoneNumber(number);
        const data = find(
            dialer,
            (_, num) => normalizePhoneNumber(num) === normalized
        );
        setDialing({
            contact: findContact(contacts, number),
            number,
            audioUrl: data?.audioUrl || "noAnswer",
            callEndedEvent: data?.callEndedEvent,
        });
    };

    const callEndedHandler = () => {
        if (dialing.callEndedEvent) {
            AppState.fireEvent(dialing.callEndedEvent);
        }
        setDialing(null);
    };

    return (
        <div className={styles.root}>
            <Header />
            {currentScreen === "history" ? (
                <CallHistory calls={calls || []} />
            ) : currentScreen === "dialer" ? (
                <Dialer contacts={contacts} onDial={dialHandler} />
            ) : null}
            {showFooter ? (
                <Footer
                    items={[
                        { name: "dialer", title: "Dial Pad" },
                        { name: "history", title: "Recents" },
                    ]}
                    activeItem={currentScreen}
                    onNavigate={(to) => State.setCurrentScreen(to)}
                />
            ) : null}
            {dialing ? (
                <ActiveCall
                    number={dialing.number}
                    contact={dialing.contact}
                    audioUrl={dialing.audioUrl}
                    onEnded={callEndedHandler}
                />
            ) : null}
        </div>
    );
}

export default observer(AppPhoneCalls);

function Header() {
    return (
        <div className={styles.header}>
            <div>
                <div className={styles.header__title}>{"Phone"}</div>
            </div>
        </div>
    );
}

function Footer({ items, activeItem, onNavigate }) {
    return (
        <div className={styles.footer}>
            {items.map(({ name, title }) => (
                <div
                    key={name}
                    className={classnames(
                        styles.footer__item,
                        activeItem === name ? styles.active : null
                    )}
                    onClick={() => onNavigate(name)}
                >
                    {title}
                </div>
            ))}
        </div>
    );
}

function ActiveCall({ number, contact, audioUrl, onEnded }) {
    const [ending, setEnding] = useState(false);
    const [status, setStatus] = useState("Dialing...");
    const audioRef = useRef();
    const dialToneRef = useRef(
        new Howl({
            src: [AudioDialTone],
            loop: true,
            volume: 0.8,
        })
    );

    const noAnswer = audioUrl === "noAnswer";

    useEffect(() => {
        if (!ending) {
            return undefined;
        }

        if (dialToneRef.current) {
            dialToneRef.current.stop();
        }
        if (audioRef.current) {
            audioRef.current.stop();
        }
        const timeout = setTimeout(onEnded, DIAL_TONE_DURATION_MS);
        return () => clearTimeout(timeout);
    }, [ending, onEnded]);

    useEffect(() => {
        dialToneRef.current.play();

        if (!noAnswer) {
            audioRef.current = new Howl({
                src: [audioUrl],
                volume: 1,
                onend: () => {
                    setStatus("Call ended");
                    setEnding(true);
                },
            });
        }

        const timeout = setTimeout(() => {
            dialToneRef.current.stop();
            if (noAnswer) {
                setStatus("No answer");
                setEnding(true);
            } else {
                audioRef.current.play();
                setStatus("Call in progress");
            }
        }, 7000);

        return () => {
            clearTimeout(timeout);
            if (audioRef.current) {
                audioRef.current.stop();
            }
        };
    }, [noAnswer, audioUrl]);

    const endHandler = () => {
        if (!ending) {
            setStatus("Call ended");
            setEnding(true);
        }
    };

    return (
        <div className={styles.activeCall}>
            <div className={styles.activeCall__status}>{status}</div>
            <div className={styles.activeCall__title}>
                <EmojiText text={contact?.name || number} />
            </div>
            <CallButton type="end" onClick={endHandler} />
        </div>
    );
}

const Dialer = observer(({ contacts, onDial }) => {
    const State = useAppStore();
    const { dialerNumber } = State;
    const contact = findContact(contacts, dialerNumber);

    const addDigit = (digit) => {
        State.setDialerNumber(dialerNumber + digit);
    };

    const removeDigit = () => {
        if (dialerNumber.length > 0) {
            State.setDialerNumber(dialerNumber.slice(0, -1));
        }
    };

    const dialClickHandler = () => {
        if (dialerNumber.length > 0) {
            onDial(dialerNumber);
        }
    };

    return (
        <div className={styles.dialer}>
            <div className={styles.dialer__number}>
                {dialerNumber}
                <div className={styles.dialer__name}>
                    <EmojiText text={contact?.name || ""} />
                </div>
            </div>
            <div className={styles.dialer__pad}>
                <div className={styles.dialer__pad__grid}>
                    {range(1, 10).map((i) => (
                        <Clickable
                            key={i}
                            className={classnames(
                                styles.dialer__pad__key,
                                `i${i}`
                            )}
                            onClick={() => addDigit(i.toString())}
                        >
                            {i}
                        </Clickable>
                    ))}
                    <Clickable
                        className={classnames(
                            styles.dialer__pad__key,
                            styles.erase
                        )}
                        onClick={removeDigit}
                    >
                        {"⌫"}
                    </Clickable>
                    <Clickable
                        className={classnames(styles.dialer__pad__key, "i0")}
                        onClick={() => addDigit("0")}
                    >
                        {"0"}
                    </Clickable>
                    <Clickable
                        className={classnames(styles.dialer__pad__key, "iplus")}
                        onClick={() => addDigit("+")}
                    >
                        {"+"}
                    </Clickable>
                </div>
            </div>
            <CallButton type="start" onClick={dialClickHandler} />
        </div>
    );
});

function CallHistory({ calls }) {
    const [openCall, setOpenCall] = useState(null);

    const handleToggle = (callId) => {
        setOpenCall(callId === openCall ? null : callId);
    };

    return (
        <div className={styles.history}>
            {!calls || calls.length === 0 ? (
                <div className={styles.history__empty}>{"No recent calls"}</div>
            ) : null}
            {calls.map((segment, segmentId) => (
                <div className={styles.history__segment} key={segmentId}>
                    <div className={styles.history__segment__title}>
                        {formatDate(segment.date, "MMMM D, YYYY")}
                    </div>
                    <div className={styles.history__segment__calls}>
                        {segment.calls.map((call, callId) => (
                            <Call
                                key={callId}
                                call={call}
                                callId={`${segmentId}_${callId}`}
                                isOpen={openCall === `${segmentId}_${callId}`}
                                onToggle={handleToggle}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

function Call({ call, callId, isOpen, onToggle }) {
    const { contact } = call;

    return (
        <div className={styles.call}>
            <div className={styles.call__data} onClick={() => onToggle(callId)}>
                <div className={styles.call__iconWrapper}>
                    <ContactPhoto contact={contact} />
                </div>
                <div className={styles.call__details}>
                    <div className={styles.call__name}>
                        <EmojiText text={contact.name || contact.number} />
                        {call.recording ? (
                            <CallRecording audioSrc={call.recording} />
                        ) : null}
                    </div>
                    <div className={styles.call__type}>
                        <i className="icon-phone" />
                        <i
                            className={classnames(
                                styles.call__direction,
                                call.direction === "in"
                                    ? "icon-left"
                                    : "icon-right"
                            )}
                        />
                    </div>
                    <div className={styles.call__time}>{call.time}</div>
                </div>
            </div>
            <ContactInfo contact={contact} isOpen={isOpen} />
        </div>
    );
}

function CallRecording({ audioSrc }) {
    return (
        <SoundPlayer
            src={audioSrc}
            pauseMusic
            className={styles.call__recording}
        >
            <i
                className={classnames(
                    styles.call__recording__icon,
                    "icon-play"
                )}
            />
            <i
                className={classnames(
                    styles.call__recording__icon,
                    "icon-stop"
                )}
            />
        </SoundPlayer>
    );
}

function CallButton({ type, onClick }) {
    return (
        <div
            className={classnames(
                styles.callButton,
                styles[type],
                "icon-phone"
            )}
            onClick={onClick}
        />
    );
}

function findContact(contacts, number) {
    if (!Array.isArray(contacts) || contacts.length === 0) {
        return null;
    }
    const normalized = normalizePhoneNumber(number);
    return find(
        contacts,
        (cnt) => cnt.number && normalizePhoneNumber(cnt.number) === normalized
    );
}

const settingsSchema = {
    type: "object",
    properties: {
        name: { type: "string", title: "Mission name" },
        image: { type: "string", title: "Mission poster" },
        brief: { type: "string", title: "Mission brief" },
        settings: {
            type: "object",
            title: "",
            properties: {
                online: {
                    type: "boolean",
                    title: "Phone is online",
                    default: false,
                },
                home: {
                    type: "object",
                    title: "Home Screen",
                    properties: {
                        background: {
                            type: "string",
                            title: "Background image",
                        },
                    },
                },
                lockScreen: {
                    type: "object",
                    title: "Lock Screen",
                    properties: {
                        unlockMethod: {
                            title: "Unlock method",
                            enum: [
                                "none",
                                "code",
                                "password",
                                "dotPattern",
                                "dotHoverPattern",
                            ],
                            default: "none",
                        },
                        code: { type: "string", title: "Code" },
                        background: {
                            type: "string",
                            default: "",
                            title: "Background image",
                        },
                        showDate: {
                            type: "boolean",
                            default: true,
                            title: "Display date and time",
                        },
                    },
                },
            },
        },
    },
};
export default settingsSchema;

export const SETTINGS_UI_SCHEMA = {
    brief: { "ui:widget": "textarea" },
    image: { "ui:widget": "ImageUploader" },
    settings: {
        home: { background: { "ui:widget": "ImageUploader" } },
        lockScreen: { background: { "ui:widget": "ImageUploader" } },
    },
};

import "./AppNotes.css";
import MarkdownContent from "../../utils/MarkdownContent";

import { pluralize, limitString } from "@faintlines/string-utils";

import React from "react";
import { Switch, Route, Link, useParams, Redirect } from "react-router-dom";
import urljoin from "url-join";

export default function AppNotes({
    notes,
    appTitle,
    itemTitle,
    appUrl,
    appPath,
}) {
    notes = notes || [];

    return (
        <div className="AppNotes">
            <Switch>
                <Route exact path={appPath}>
                    <NotesIndex
                        notes={notes}
                        appTitle={appTitle}
                        itemTitle={itemTitle}
                        appUrl={appUrl}
                    />
                </Route>
                <Route exact path={urljoin(appPath, "note", ":noteId")}>
                    <Note appUrl={appUrl} notes={notes} />
                </Route>
                <Route>
                    <Redirect to={appUrl} />
                </Route>
            </Switch>
        </div>
    );
}

function NotesIndex({ notes, appTitle, itemTitle, appUrl }) {
    return (
        <div className="AppNotes-index-scroller">
            <div className="AppNotes-index">
                <div className="AppNotes-index-header">
                    <div>
                        <div className="AppNotes-index-title">
                            {appTitle || "Notes"}
                        </div>
                        <div className="AppNotes-index-subtitle">
                            {`${notes.length} ${itemTitle || "note"}${pluralize(
                                notes.length
                            )}`}
                        </div>
                    </div>
                </div>
                <div className="AppNotes-index-notes">
                    {notes.map((note, noteId) => (
                        <Link
                            className="AppNotes-index-note-link"
                            key={noteId}
                            to={urljoin(appUrl, "note", noteId.toString())}
                        >
                            <div className="AppNotes-index-note">
                                <div className="AppNotes-index-note-contents">
                                    {note.title ? (
                                        <div className="AppNotes-index-note-title">
                                            {note.title}
                                        </div>
                                    ) : null}
                                    <MarkdownContent
                                        className="AppNotes-index-note-text"
                                        markdown={
                                            note.excerpt ||
                                            limitString(
                                                (note.text || "").split(
                                                    "\n"
                                                )[0],
                                                50
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
}

function Note({ notes, appUrl }) {
    const { noteId } = useParams();
    const note = notes[noteId];
    const { title, text } = note;

    return (
        <div className="AppNotes-note">
            <div className="AppNotes-note-top-bar">
                <Link className="AppNotes-note-back-button" to={appUrl}>
                    <i className="icon-left-open-big" />
                </Link>
                <div className="AppNotes-note-title">{title}</div>
            </div>
            <div className="AppNotes-note-content">
                <MarkdownContent
                    className="AppNotes-note-text"
                    markdown={text}
                />
            </div>
        </div>
    );
}

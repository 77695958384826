import { makeAutoObservable, set } from "mobx";
import { pickToJs } from "utils/mobxUtils";
import { isEmpty } from "lodash";

export default class DeliveryAppState {
    constructor({ existingState }) {
        makeAutoObservable(this);

        if (DeliveryAppState.hasProgress(existingState)) {
            set(this, existingState);
        }
    }

    dispose() {}

    deliveryStatus = {}; // deliveryId: status
    deliveryStartTime = {}; // deliveryId: timestamp

    static hasProgress(state) {
        return (
            state &&
            (!isEmpty(state.deliveryStatus) ||
                !isEmpty(state.deliveryStartTime))
        );
    }

    /// / Computed

    get asJson() {
        return pickToJs(this, ["deliveryStatus", "deliveryStartTime"]);
    }

    getDeliveryStatus(delivery) {
        return this.deliveryStatus[delivery.id] || delivery.status;
    }

    getDeliveryStartTime(delivery) {
        return this.deliveryStartTime[delivery.id];
    }

    setDeliveryStatus(delivery, newStatus) {
        this.deliveryStatus[delivery.id] = newStatus;
        if (newStatus === "tracker") {
            this.deliveryStartTime[delivery.id] = Math.round(Date.now() / 1000);
        }
    }
}

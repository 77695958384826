import styles from "./AppBrowser.module.css";
import MarkdownContent from "../../utils/MarkdownContent";
import Image from "../../common/Image";

import { replaceCaseInsensitive } from "@faintlines/string-utils";

import React, { useState } from "react";
import { filter, find } from "lodash";
import classnames from "classnames";

const MAX_HISTORY = 5;

export default function AppBrowser({
    searchHistory,
    cachedPages,
    searchHistoryOpenEvent,
    onEvent,
}) {
    searchHistory = searchHistory || [];

    const [typing, setTyping] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const cachedPage = find(cachedPages || [], (x) => x.query === searchQuery);

    const handleSearchHistoryItemClick = (query) => {
        setTyping(false);
        setSearchQuery(query);
    };

    const handleSearchBarFocus = () => {
        if (!typing) {
            setTyping(true);
            setSearchQuery("");
            if (searchHistoryOpenEvent && onEvent) {
                onEvent(searchHistoryOpenEvent);
            }
        }
    };

    return (
        <div className={styles.browser}>
            <SearchBar
                searchQuery={searchQuery}
                onFocus={handleSearchBarFocus}
                onChange={setSearchQuery}
            />
            {typing ? (
                <HistoryBar
                    searchHistory={searchHistory}
                    searchQuery={searchQuery}
                    cachedPages={cachedPages}
                    onQueryChange={handleSearchHistoryItemClick}
                />
            ) : cachedPage ? (
                <CachedPage src={cachedPage.src} />
            ) : (
                <OfflinePage />
            )}
        </div>
    );
}

function SearchBar({ searchQuery, onFocus, onChange }) {
    return (
        <div className={styles.search}>
            <input
                type="text"
                className={classnames(styles.search__input, "data-hj-allow")}
                placeholder="Search or type a URL"
                value={searchQuery}
                onFocus={onFocus}
                onChange={(evt) => onChange(evt.target.value)}
            />
        </div>
    );
}

function HistoryBar({
    searchHistory,
    searchQuery,
    cachedPages,
    onQueryChange,
}) {
    const [filteredHistory, moreHistoryResults] = filterHistory(
        searchHistory,
        searchQuery
    );
    const searchQueryTrimmed = searchQuery.trim();
    const queriesWithCachedPages = (cachedPages || []).map((x) => x.query);

    return (
        <div className={styles.history}>
            {filteredHistory.map((query, i) => (
                <div
                    key={i}
                    id={`browser-search-history-${i}`}
                    className={styles.history__item}
                    onClick={() => onQueryChange(query)}
                >
                    <i
                        id={`browser-search-history-${i}-icon`}
                        className={classnames(
                            styles.history__item__icon,
                            "icon-clock"
                        )}
                    />
                    {searchQueryTrimmed === "" ? (
                        <div className={styles.history__item__text}>
                            <p>{query}</p>
                        </div>
                    ) : (
                        <MarkdownContent
                            className={styles.history__item__text}
                            markdown={replaceCaseInsensitive(
                                query,
                                searchQueryTrimmed,
                                "**$&**"
                            )}
                        />
                    )}
                    {queriesWithCachedPages.includes(query) ? (
                        <span className={styles.history__item__cached}>
                            {"cached"}
                        </span>
                    ) : null}
                </div>
            ))}
            {moreHistoryResults ? (
                <div className={styles.history__more}>
                    {"More search history available, type to filter"}
                </div>
            ) : null}
            {searchQueryTrimmed !== "" && filteredHistory.length === 0 ? (
                <div className={styles.history__more}>
                    {"No search history for "}
                    <b>{searchQueryTrimmed}</b>
                </div>
            ) : null}
        </div>
    );
}

function OfflinePage() {
    return (
        <div className={styles.offline}>
            <h2 className={styles.offline__title}>{"You are offline."}</h2>
            <div className={styles.offline__text}>
                {"Click on the search bar to view your recent searches."}
            </div>
        </div>
    );
}

function CachedPage({ src }) {
    return (
        <div className={styles.cached}>
            <div className={styles.cached__note}>{"Cached version"}</div>
            <Image src={src} className={styles.cached__image} />
        </div>
    );
}

function filterHistory(searchHistory, searchQuery) {
    if (searchQuery) {
        searchQuery = searchQuery.toLowerCase();
        searchHistory = filter(searchHistory, (x) =>
            x.toLowerCase().includes(searchQuery)
        );
    }

    return [
        searchHistory.slice(0, MAX_HISTORY),
        searchHistory.length > MAX_HISTORY,
    ];
}

import "./HomeScreen.css";
import { getAppIconUrl } from "./Apps/common/AppIcons";
import Image from "components/common/Image";
import AppState from "state/AppState";
import { selectImageSource } from "utils/browserUtils";

import { Link } from "react-router-dom";
import React from "react";
import urljoin from "url-join";
import { filter } from "lodash";
import { observer } from "mobx-react-lite";

function HomeScreen({ apps, backgroundImage, appsPosition, rootUrl }) {
    apps = filter(apps, (app) =>
        AppState.getAppVisibility(app.name, app.homeScreen !== false)
    );

    return (
        <div
            className={`HomeScreen HomeScreen-position-${appsPosition}`}
            style={{
                backgroundImage: `url(${selectImageSource(backgroundImage)})`,
            }}
        >
            <div className="HomeScreen-apps">
                {apps.map((app) => (
                    <AppIcon key={app.name} app={app} rootUrl={rootUrl} />
                ))}
            </div>
        </div>
    );
}
HomeScreen.defaultProps = {
    appsPosition: "top",
};

export default observer(HomeScreen);

const AppIcon = observer(({ app, rootUrl }) => {
    const notifs = AppState.getAppStore(app.name)?.notificationCounter || 0;

    return (
        <Link to={urljoin(rootUrl, app.name)}>
            <div className="HomeScreen-app" id={`app-icon-${app.name}`}>
                <div className="HomeScreen-app-icon-wrapper">
                    {notifs ? (
                        <div className="HomeScreen-app-notif-counter">
                            {notifs}
                        </div>
                    ) : null}
                    <Image
                        src={getAppIconUrl(app)}
                        className="HomeScreen-app-icon"
                        alt={app.name}
                    />
                </div>
                <div className="HomeScreen-app-name">{app.title}</div>
            </div>
        </Link>
    );
});

import React from "react";

export default function CalendarHeader({
    selectedMonth,
    onMonthChange,
    onTitleClick,
}) {
    const month = selectedMonth.format("MMMM");
    const year = selectedMonth.format("YYYY");

    const nextMonth = selectedMonth.clone().add(1, "month");
    const prevMonth = selectedMonth.clone().subtract(1, "month");

    return (
        <div className="AppCalendar-header">
            <NavButton icon="«" onClick={() => onMonthChange(prevMonth)} />
            <div
                className="AppCalendar-header-title"
                onClick={onTitleClick}
            >{`${month} ${year}`}</div>
            <NavButton icon="»" onClick={() => onMonthChange(nextMonth)} />
        </div>
    );
}

function NavButton({ icon, onClick }) {
    return (
        <div className="AppCalendar-navButton" onClick={onClick}>
            <div className="AppCalendar-navButton-icon">{icon}</div>
        </div>
    );
}

import "./StorySideMenu.css";
import AnswerForm from "./AnswerForm";
import { SideMenu, SideMenuItem, SideMenuItems } from "../common/SideMenu";
import { IconLightBulb } from "../common/icons";
import {
    CoinsItem,
    BecomeABetaTesterMenuItem,
    BecomeAWriterMenuItem,
    SettingsMenuItem,
    SupportMenuItem,
    SurveyMenuItem,
} from "../shell/MenuItems";
import AppState from "state/AppState";
import UiState from "state/UiState";

import React from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import {
    ArrowLeft as IconArrowLeft,
    StickyNote as IconStickyNote,
} from "lucide-react";

const StorySideMenu = observer(({ story, visible, onClose }) => (
    <SideMenu smallHeight={620}>
        <AnswerForm story={story} visible={visible} />
        <SideMenuItems>
            <CoinsItem />
            <HintsMenuItem />
            <BriefMenuItem story={story} />
            <NoteMenuItem story={story} onClick={onClose} />
            <ExitStoryMenuItem />
        </SideMenuItems>
        <SideMenuItems bottom>
            <SurveyMenuItem />
            <SupportMenuItem />
            <SettingsMenuItem />
            <BecomeABetaTesterMenuItem />
            <BecomeAWriterMenuItem />
        </SideMenuItems>
    </SideMenu>
));

export default StorySideMenu;

const BriefMenuItem = observer(({ story }) => {
    const briefStr = getStoryBriefStr(story);

    if (!briefStr) {
        return null;
    }

    return (
        <SideMenuItem
            title="Mission Brief"
            icon="feather"
            onClick={() => UiState.showAlert(briefStr, story.name)}
        />
    );
});

const HintsMenuItem = observer(() => (
    <SideMenuItem
        title="I Need a Hint!"
        icon={IconLightBulb}
        onClick={() => AppState.toggleHintsModal(true)}
    />
));

const ExitStoryMenuItem = observer(() => {
    const history = useHistory();
    if (AppState.completedStoriesCount < 1) {
        return null;
    }

    return (
        <SideMenuItem
            title="Exit Mission"
            icon={IconArrowLeft}
            onClick={() => AppState.exitStory(history)}
        />
    );
});

const NoteMenuItem = observer(({ story, onClick }) => {
    if (story.no_note) {
        return null;
    }

    return (
        <SideMenuItem
            title="Show Note"
            icon={IconStickyNote}
            onClick={() => {
                UiState.toggleNote(true);
                if (onClick) {
                    onClick();
                }
            }}
        />
    );
});

export function getStoryBriefStr(story) {
    const { brief } = story || {};
    if (!brief) {
        return null;
    }
    if (typeof brief === "string") {
        return brief;
    }
    if (Array.isArray(brief)) {
        return brief.join("\n\n");
    }
    return null;
}

import styles from "./PinKeyboard.module.css";
import AppState from "state/AppState";
import Clickable from "../utils/Clickable";
import { vibrateShort } from "../../utils/browserUtils";

import React, { useState } from "react";
import classnames from "classnames";
import { range } from "lodash";

export default function PinKeyboard({
    title,
    hint,
    password,
    className,
    darkMode,
    onCancel,
    onUnlock,
    appName,
}) {
    const [value, setValue] = useState("");
    const [animated, setAnimated] = useState(false);

    let passwords = Array.isArray(password) ? password : [password];
    passwords = passwords.map((x) => x.toString());
    const numDigits = passwords[0].length;

    const updateValue = (evt, newValue) => {
        evt.stopPropagation();

        if (newValue.length > numDigits) {
            return;
        }

        setValue(newValue);
        vibrateShort();

        if (passwords.includes(newValue)) {
            if (onUnlock) {
                onUnlock();
            }
            AppState.trackPasswordEvent(appName, newValue, true);
            setValue("");
        } else if (newValue.length === numDigits) {
            setAnimated(true);
            setTimeout(() => {
                setAnimated(false);
                setValue("");
            }, 300);
            AppState.trackPasswordEvent(appName, newValue, false);
        }
    };

    return (
        <div className={classnames(styles.root, className, { darkMode })}>
            {title ? <div className={styles.title}>{title}</div> : null}
            {hint ? <div className={styles.hint}>{hint}</div> : null}
            <div className={classnames(styles.value, { animated })}>
                {range(numDigits).map((i) => (
                    <div
                        key={i}
                        className={classnames(styles.valueDigit, {
                            full: !!value[i],
                        })}
                    />
                ))}
            </div>
            <div className={styles.keyboard}>
                {range(1, 10).map((i) => (
                    <Clickable
                        key={i}
                        className={classnames(styles.key, `i${i}`)}
                        onClick={(evt) =>
                            updateValue(evt, value + i.toString())
                        }
                    >
                        {i}
                    </Clickable>
                ))}
                <Clickable
                    className={classnames(styles.key, styles.erase)}
                    onClick={(evt) => updateValue(evt, value.slice(0, -1))}
                >
                    {"⌫"}
                </Clickable>
                <Clickable
                    className={classnames(styles.key, "i0")}
                    onClick={(evt) => updateValue(evt, `${value}0`)}
                >
                    {"0"}
                </Clickable>
            </div>
            {onCancel ? (
                <Clickable className={styles.cancel} onClick={onCancel}>
                    {"Cancel"}
                </Clickable>
            ) : null}
        </div>
    );
}

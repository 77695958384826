import AppState from "./AppState";

import { useCallback, useState } from "react";
import { toJS, runInAction } from "mobx";

export function usePhoneSessionStorage(key, defaultValue) {
    let storage = AppState.storyState?.sessionStorage || {};
    if (storage[key] === undefined && defaultValue !== undefined) {
        // For some reason, calling setSessionStorage without runInAction results in the following warning:
        // "Since strict-mode is enabled, changing (observed) observable values without using an action is not allowed".
        // TODO: investigate why
        runInAction(() => AppState.setSessionStorage(key, defaultValue));
        storage = AppState.storyState.sessionStorage;
    }
    const [value, setLocalValue] = useState(toJS(storage[key]));
    const setValue = useCallback(
        (newValue) => {
            AppState.setSessionStorage(key, newValue);
            setLocalValue(newValue);
        },
        [key]
    );
    return [value, setValue];
}

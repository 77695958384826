export default function axiosErrorReporting(axios, options) {
    const { onError } = options || {};

    try {
        axios.interceptors.response.use(
            (response) => response, // do nothing on success
            // Handle 4xx & 5xx responses
            (error) => {
                try {
                    if (onError) {
                        onError(error);
                    }
                } catch (err) {
                    // nothing
                }

                throw error;
            }
        );
    } catch (err) {
        // nothing
    }
}

export function isTimeoutError(error) {
    return error && error.code === "ECONNABORTED";
}

import "./Loaders.css";

import React from "react";
import classnames from "classnames";

// TODO: rename to EllipsisSpinner and move to common/spinners

export function EllipsisLoader({ color, className }) {
    const innerStyle = { backgroundColor: color };

    return (
        <div className={classnames("loader EllipsisLoader", className)}>
            <div className="EllipsisLoader-inner" style={innerStyle} />
            <div className="EllipsisLoader-inner" style={innerStyle} />
            <div className="EllipsisLoader-inner" style={innerStyle} />
            <div className="EllipsisLoader-inner" style={innerStyle} />
        </div>
    );
}

import "./AppStory15Game.css";
import ZoomableImage from "../../../common/ZoomableImage";
import AppState from "../../../../state/AppState";
import Image from "components/common/Image";
import useEntered from "hooks/useEntered";

import VideoPlayer from "@faintlines/video-player";
import { useAppStore } from "@faintlines/phone-sim-common";

import { observer } from "mobx-react-lite";
import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import { Howl } from "howler";

// TODO: lazy loading for this app

function AppStory15Game({ resources, secret }) {
    const [levelDone, setLevelDone] = useState(false);

    const successSoundRef = useRef(null);
    const failureSoundRef = useRef(null);

    useEffect(() => {
        successSoundRef.current = new Howl({
            src: [resources.game_success],
            volume: 0.7,
        });
        failureSoundRef.current = new Howl({
            src: [resources.game_failure],
            volume: 0.7,
        });

        return () => {
            successSoundRef.current.unload();
            failureSoundRef.current.unload();
        };
    }, []); // eslint-disable-line

    const State = useAppStore();

    const LevelComponent =
        [
            null, // (no level zero)
            Level1, // LOL
            Level2, // 87149
            Level5, // The Doors
            Level6, // Maze
            Level3, // k 16
            Level4, // 201
            Level7, // Hypnotic
        ][State.currentLevel] || GameOver;

    const handleNext = () => {
        if (State.currentLevel) {
            successSoundRef.current.play();
            setLevelDone(true);
            AppState.fireEvent(`finishedLevel${State.currentLevel}`);
            setTimeout(() => {
                State.setLevel(State.currentLevel + 1);
                setLevelDone(false);
            }, 3000);
        } else {
            State.setLevel(1);
        }
    };

    const handleFailedAttempt = () => {
        failureSoundRef.current.play();
    };

    return (
        <div className="AppStory15Game AppStory15Game-screen">
            <LevelComponent
                resources={resources}
                secret={secret}
                onNext={handleNext}
                onFailedAttempt={handleFailedAttempt}
            />
            <LevelIntro
                key={State.currentLevel}
                levelNumber={State.currentLevel}
            />
            {levelDone ? <LevelDone /> : null}
        </div>
    );
}

export default observer(AppStory15Game);

function LevelIntro({ levelNumber }) {
    const [exiting, setExiting] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => setExiting(true), 2000);
        return () => clearTimeout(timeout);
    }, []);

    if (!levelNumber) {
        return null;
    }

    return (
        <div
            className={classnames(
                "AppStory15Game-screen vertical-center AppStory15Game-level-intro",
                { exiting }
            )}
        >
            <div className="AppStory15Game-level-intro-text">{`Level ${levelNumber}`}</div>
        </div>
    );
}

function LevelDone() {
    const entered = useEntered();

    return (
        <div
            className={classnames(
                "AppStory15Game-screen vertical-center AppStory15Game-level-done",
                { entered }
            )}
        >
            <div className="AppStory15Game-level-done-text">{"Well Done"}</div>
        </div>
    );
}

function Level1({ resources, onNext, onFailedAttempt }) {
    return (
        <div className="AppStory15Game-screen vertical-center AppStory15Game-level1">
            <Image
                className="AppStory15Game-level1-image"
                src={resources.game_level1}
            />
            <AnswerField
                answers={["lol"]}
                onSuccess={onNext}
                onFail={onFailedAttempt}
            />
        </div>
    );
}

function Level2({ resources, onNext, onFailedAttempt }) {
    return (
        <div className="AppStory15Game-screen vertical-center AppStory15Game-level2">
            <Image
                className="AppStory15Game-image-main float"
                src={resources.game_level2}
            />
            <AnswerField
                answers={["87149"]}
                onSuccess={onNext}
                onFail={onFailedAttempt}
            />
        </div>
    );
}

function Level3({ resources, onNext, onFailedAttempt }) {
    return (
        <div className="AppStory15Game-screen vertical-center AppStory15Game-level3">
            <ZoomableImage
                className="AppStory15Game-level3-image"
                src={resources.game_level3}
                showSpinner
            />
            <AnswerField
                answers={["k 16", "k16", "16k", "16 k"]}
                onSuccess={onNext}
                onFail={onFailedAttempt}
            />
        </div>
    );
}

function Level4({ resources, onNext, onFailedAttempt }) {
    return (
        <div className="AppStory15Game-screen vertical-center AppStory15Game-level4">
            <ZoomableImage
                className="AppStory15Game-level4-image"
                src={resources.game_level4}
                showSpinner
            />
            <AnswerField
                answers={[
                    "201",
                    "one boston place",
                    "one boston",
                    "1 boston",
                    "1 boston place",
                    "201 washington",
                ]}
                onSuccess={onNext}
                onFail={onFailedAttempt}
            />
        </div>
    );
}

function Level5({ resources, onNext, onFailedAttempt }) {
    return (
        <div className="AppStory15Game-screen vertical-center AppStory15Game-level5">
            <Image
                className="AppStory15Game-image-main float"
                src={resources.game_level5}
            />
            <AnswerField
                answers={["the doors", "doors", "jim morrison", "morrison"]}
                onSuccess={onNext}
                onFail={onFailedAttempt}
            />
        </div>
    );
}

function Level6({ resources, onNext, onFailedAttempt }) {
    return (
        <div className="AppStory15Game-screen vertical-center AppStory15Game-level6">
            <Image
                className="AppStory15Game-image-main spin"
                src={resources.game_level6}
            />
            <AnswerField
                answers={["rotator"]}
                onSuccess={onNext}
                onFail={onFailedAttempt}
            />
        </div>
    );
}

function Level7({ resources, secret, onNext, onFailedAttempt }) {
    const [started, setStarted] = useState(false);

    return (
        <div className="AppStory15Game-screen vertical-center AppStory15Game-level7">
            {started ? (
                <>
                    <div className="AppStory15Game-level7-image">
                        <VideoPlayer
                            className="AppStory15Game-level7-video"
                            url={resources.game_level7}
                            width="100%"
                            height="auto"
                            preload="auto"
                            autoPlay
                            autoRetry
                            muted
                            loop
                        />
                    </div>
                    <AnswerField
                        answers={[]}
                        onSuccess={onNext}
                        onFail={onFailedAttempt}
                    />
                </>
            ) : (
                <div className="AppStory15Game-level7-intro">
                    <p>{"Welcome to level 7 - the final level."}</p>
                    <p>
                        {
                            "Everything up to here was just an audition for finding unique people like yourself."
                        }
                    </p>
                    <p>
                        {
                            "Completing this level will win you a serious reward, plus an opportunity to work on our secret project."
                        }
                    </p>
                    <p>
                        {
                            "To solve level 7, you must use your subconscious by going into a deep sleep. The Game will get you there, but if you fail to complete the level, someone else will have to wake you up using a very particular word. Without this word, you might never wake up!"
                        }
                    </p>
                    <p>
                        {`The word is ${secret}. You can write it down if you want. However, if you genuinely believe in yourself, don't!`}
                    </p>
                    <div
                        className="AppStory15Game-level7-start-button"
                        onClick={() => setStarted(true)}
                    >
                        {"Go to sleep"}
                    </div>
                </div>
            )}
        </div>
    );
}

function GameOver({ resources, onNext }) {
    return (
        <div
            className="AppStory15Game-screen AppStory15Game-game-over"
            style={{ backgroundImage: `url(${resources.game_over})` }}
        >
            <div className="AppStory15Game-game-over-restart" onClick={onNext}>
                {"Restart"}
            </div>
        </div>
    );
}

function AnswerField({ answers, onSuccess, placeholder, onFail }) {
    const [value, setValue] = useState("");
    const [errorAnimation, setErrorAnimation] = useState(false);

    function clickHandler() {
        if (answers.includes(value.toLowerCase().trim())) {
            if (onSuccess) {
                onSuccess();
            }
            return;
        }

        if (onFail) {
            onFail();
        }
        setErrorAnimation(true);
        setTimeout(() => setErrorAnimation(false), 500);
    }

    return (
        <div
            className={classnames("AppStory15Game-answer-field", {
                errorAnimation,
            })}
        >
            <input
                className="AppStory15Game-answer-field-textbox"
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
            />
            <div
                className={classnames("AppStory15Game-answer-field-submit", {
                    visible: value !== "",
                })}
                onClick={clickHandler}
            >
                <i className="icon-play" />
            </div>
        </div>
    );
}

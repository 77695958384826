import { buildCalendar, dateExistsInArray } from "./calendarUtil";
import CalendarCell from "./CalendarCell";

import React from "react";

export default function Calendar({
    selectedDay,
    selectedMonth,
    onDaySelection,
    eventsDates,
}) {
    const calendar = buildCalendar(selectedMonth);

    return (
        <div className="AppCalendar-calendar">
            {calendar.map((week, i) => (
                <WeekRow
                    key={i}
                    week={week}
                    selectedDay={selectedDay}
                    eventsDates={eventsDates}
                    onDaySelection={onDaySelection}
                    currentMonth={selectedMonth}
                />
            ))}
        </div>
    );
}

function WeekRow({
    week,
    selectedDay,
    onDaySelection,
    eventsDates,
    currentMonth,
}) {
    return (
        <div className="AppCalendar-week">
            {week.map((day, i) => (
                <CalendarCell
                    key={i}
                    dayValue={day}
                    isSelected={selectedDay.isSame(day, "day")}
                    outOfMonth={!currentMonth.isSame(day, "month")}
                    hasEvents={dateExistsInArray(eventsDates, day)}
                    onClick={() => onDaySelection(day)}
                />
            ))}
        </div>
    );
}

import styles from "./AppLocker.module.css";
import CalculatorLocker from "./CalculatorLocker";
import WordleLocker from "./WordleLocker";
import PasswordInput from "../../../utils/PasswordInput";
import PinKeyboard from "../../../common/PinKeyboard";
import DotPatternKeyboard from "../../../common/DotPatternKeyboard";
import DotPatternHoverable from "../../../common/DotPatternHoverable";
import LoginScreen from "../../LoginScreen";
import AppState from "state/AppState";
import { vibrateShort } from "utils/browserUtils";
import Image from "components/common/Image";

import { pluralize, stringsInclude } from "@faintlines/string-utils";
import TilePuzzle from "@faintlines/tile-puzzle";

import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";

function AppLocker({
    appName,
    appTitle,
    appIcon,
    lockType,
    password,
    passwordHint,
    lockerProps,
    unlockEvent,
    unlockEventDelay,
}) {
    if (!password) return null;

    function onUnlock() {
        AppState.unlockApp(appName);
        if (unlockEvent) {
            AppState.fireEvent(unlockEvent, { delayMs: unlockEventDelay });
        }
    }

    return (
        <div
            className={classnames(styles.locker, {
                unlocked: AppState.storyState.unlockedApps[appName],
            })}
            onClick={(evt) => evt.stopPropagation()}
            onTouchStart={(evt) => evt.stopPropagation()}
        >
            <Locker
                {...{
                    lockType: lockType || "code",
                    appName,
                    appTitle,
                    appIcon,
                    passwordHint,
                    password,
                    onUnlock,
                }}
                {...lockerProps}
            />
        </div>
    );
}

export default observer(AppLocker);

function Locker({ lockType, allowTypos, ...props }) {
    switch (lockType) {
        case "pin":
        case "code":
            return <PinLocker {...props} />;
        case "calculator":
            return <CalculatorLocker {...props} />;
        case "password":
            return <PasswordLocker allowTypos={allowTypos} {...props} />;
        case "dotPattern":
            return <DotPatternLocker {...props} />;
        case "dotHoverPattern":
            return <DotPatternHoverableLocker {...props} />;
        case "userPassword":
            return (
                <UsernamePasswordLocker allowTypos={allowTypos} {...props} />
            );
        case "emailPassword":
            return (
                <UsernamePasswordLocker
                    usernameText="Email"
                    usernameType="email"
                    allowTypos={allowTypos}
                    {...props}
                />
            );
        case "converter":
            return <ConverterLocker {...props} />;
        case "tilePuzzle":
            return <TilePuzzleLocker {...props} />;
        case "wordle":
            return <WordleLocker {...props} />;
        default:
            return null;
    }
}
Locker.defaultProps = {
    allowTypos: true,
};

function PinLocker({ appTitle, passwordHint, password, onUnlock, appName }) {
    return (
        <PinKeyboard
            title={
                appTitle === ""
                    ? "This App is Password Protected"
                    : `App ${appTitle} is Password Protected`
            }
            hint={passwordHint}
            password={password}
            onUnlock={onUnlock}
            appName={appName}
        />
    );
}

function DotPatternLocker({ appTitle, passwordHint, password, onUnlock }) {
    return (
        <DotPatternKeyboard
            title={
                appTitle === ""
                    ? "This App is Protected"
                    : `App ${appTitle} is Protected`
            }
            hint={passwordHint}
            pattern={password}
            onUnlock={onUnlock}
        />
    );
}

function DotPatternHoverableLocker({
    appTitle,
    passwordHint,
    password,
    size,
    onUnlock,
    appName,
}) {
    return (
        <DotPatternHoverable
            title={
                appTitle === ""
                    ? "This App is Protected"
                    : `App ${appTitle} is Protected`
            }
            hint={passwordHint}
            pattern={password}
            size={size}
            onUnlock={onUnlock}
            appName={appName}
        />
    );
}

function PasswordLocker({
    appTitle,
    passwordHint,
    password,
    allowTypos,
    onUnlock,
    appName,
}) {
    const [value, setValue] = useState("");
    const [animated, setAnimated] = useState(false);

    function onSubmit() {
        if (stringsInclude(password, value, { allowTypos })) {
            onUnlock();
            AppState.trackPasswordEvent(appName, value, true);
        } else {
            vibrateShort();
            setAnimated(true);
            setTimeout(() => {
                setAnimated(false);
            }, 300);
            AppState.trackPasswordEvent(appName, value, false);
        }
    }

    return (
        <div className={styles.password}>
            <div className={styles.password__title}>
                {`App ${appTitle} is Password Protected`}
            </div>
            {passwordHint ? (
                <div
                    className={styles.password__hint}
                >{`Hint: ${passwordHint}`}</div>
            ) : null}
            <PasswordInput
                className={classnames(styles.password__textbox, { animated })}
                formClassName={styles.password__textbox_form}
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
            />
            <div className={styles.password__submit} onClick={onSubmit}>
                {"Unlock"}
            </div>
        </div>
    );
}

function UsernamePasswordLocker({
    appTitle,
    appName,
    appIcon,
    password,
    allowTypos,
    onUnlock,
    ...props
}) {
    const [username, pass] = password.split("|");

    return (
        <LoginScreen
            username={username}
            className={styles.usernamePassword}
            password={pass}
            allowTypos={allowTypos}
            backgroundColor="#e2e2e2"
            appName={appName}
            onLogin={onUnlock}
            {...props}
        >
            <Image
                src={appIcon}
                alt={appTitle}
                className={styles.usernamePassword__logo}
            />
        </LoginScreen>
    );
}

function ConverterLocker({
    password,
    fromUnit,
    toUnit,
    conversionFactor,
    onUnlock,
}) {
    const [value, setValue] = useState("");

    const valueFloat = parseFloat(value);
    const result = Number.isNaN(valueFloat)
        ? null
        : conversionFactor * valueFloat;

    function handleChange(evt) {
        const newValue = evt.target.value;
        setValue(newValue);
        if (newValue === password) {
            onUnlock();
        }
    }

    return (
        <div className={styles.converter}>
            <div className={styles.converter__headline}>
                {`${fromUnit}s to ${toUnit}s Converter`}
            </div>
            <input
                type="number"
                className={styles.converter__input}
                placeholder={`${fromUnit}s`}
                onChange={handleChange}
            />
            {result && (
                <div className={styles.converter__result}>
                    {`${fromUnit}${pluralize(
                        valueFloat,
                        "s equal",
                        " equals"
                    )}`}
                    <div className={styles.converter__result__value}>
                        {parseFloat(result.toFixed(8))}
                    </div>
                    {`${toUnit}${pluralize(result)}`}
                </div>
            )}
        </div>
    );
}

function TilePuzzleLocker({
    appTitle,
    width,
    height,
    rows,
    cols,
    hole,
    image,
    onUnlock,
}) {
    return (
        <div className={styles.tilePuzzle}>
            <div className={styles.tilePuzzle__title}>
                {`To continue to ${appTitle}, prove you are a human by solving the puzzle`}
            </div>
            <TilePuzzle
                width={width}
                height={height}
                rows={rows}
                cols={cols}
                hole={hole}
                image={image}
                onSolved={onUnlock}
            />
        </div>
    );
}

import "./ChatWindow.css";
import ChatList from "./ChatList";
import Chat from "./Chat";
import { ImagePreloader } from "components/common/Image";
import ImageChatBackground from "images/apps/chat/bg.jpg";

import { useAppStore } from "@faintlines/phone-sim-common";

import React, { useEffect } from "react";
import classnames from "classnames";
import { observer } from "mobx-react-lite";

function ChatWindow({ appTitle, appUrl, chatId }) {
    const store = useAppStore();

    useEffect(() => {
        if (chatId) {
            store.setActiveChat(chatId);
        } else {
            store.unsetActiveChat();
        }
        return () => store.unsetActiveChat();
    }, [store, chatId]);

    return (
        <div
            className={classnames("ChatWindow mobile", {
                chat: !!chatId,
            })}
        >
            <div className="ChatWindow-left">
                <ChatList appTitle={appTitle} appUrl={appUrl} />
            </div>
            <div className="ChatWindow-right">
                <Chat appUrl={appUrl} />
            </div>
            <ImagePreloader images={[ImageChatBackground]} />
        </div>
    );
}

export default observer(ChatWindow);

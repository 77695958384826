import { relativeDate } from "utils/dateUtils";
import { HOTJAR_ALLOW_CLASS_NAME } from "../utils/StudioUtils";

import React from "react";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export default function RelativeDateSelector({
    daysOnly,
    daysWeeksOnly,
    formData,
    onChange,
    schema,
    className,
}) {
    const defaultUnit = daysOnly ? "days" : "minutes";
    const amount = formData?.amount || 0;
    const units = formData?.units || defaultUnit;

    const handleAmountChange = ({ target }) => {
        const { value } = target;
        const parseIntValue = parseInt(value, 10);
        if (Number.isNaN(parseIntValue)) {
            return;
        }
        onChange(relativeDate(parseIntValue, units));
    };

    const handleUnitsChange = (evt) => {
        onChange(relativeDate(amount, evt.target.value));
    };

    return (
        <div
            style={{ display: "flex", alignItems: "flex-end" }}
            className={className}
        >
            <TextField
                type="number"
                label={schema?.title}
                value={amount || 0}
                inputProps={{ className: HOTJAR_ALLOW_CLASS_NAME }}
                onChange={handleAmountChange}
            />
            <Select value={units} onChange={handleUnitsChange}>
                {daysOnly || daysWeeksOnly ? null : (
                    <MenuItem value="minutes">{"Minutes ago"}</MenuItem>
                )}
                {daysOnly || daysWeeksOnly ? null : (
                    <MenuItem value="hours">{"Hours ago"}</MenuItem>
                )}
                <MenuItem value="days">{"Days ago"}</MenuItem>
                {daysOnly ? null : (
                    <MenuItem value="weeks">{"Weeks ago"}</MenuItem>
                )}
            </Select>
        </div>
    );
}

import { ConversationParser } from "./utils/conversationUtils";
import { PhoneNumberGenerator } from "./utils/StudioUtils";

import update from "immutability-helper";
import { find, fromPairs } from "lodash";

const phoneNumbers = new PhoneNumberGenerator();

const noop = (data) => data;

function phoneAppConverter(data, story) {
    if (data.calls) {
        data = update(data, {
            calls: (x) =>
                x.map((call) =>
                    update(call, {
                        calls: (y) =>
                            y.map((call2) =>
                                update(call2, {
                                    contact: (cntId) =>
                                        getContactById(story, cntId),
                                })
                            ),
                    })
                ),
        });
    }
    return data;
}

function contactsAppConverter(data, story) {
    // TODO: allow customization
    return {
        contacts: [
            {
                name: "All Contacts",
                contacts: (story.contacts || [])
                    .filter((x) => !x.unlisted)
                    .map(convertContact),
            },
        ],
    };
}

function messagesAppConverter(data, story) {
    const conversations = (data.conversations || []).map((conv) => ({
        contact:
            conv.contacts?.length > 0
                ? getContactById(story, conv.contacts[0])
                : {},
        threads: textToMessages(conv.conversationText || "", {
            startDate: conv.startDate,
            startTime: conv.startTime,
            threads: true,
            timeField: "time",
            directionField: "type",
            textField: "text",
        }),
    }));
    return { conversations };
}

function streamingAppConverter(data) {
    const titlesById = fromPairs(
        (data.titles || []).map((item) => [
            item.id,
            update(item, { friends: commaSeparatedStringToArray }),
        ])
    );

    return update(data, {
        titles: { $set: titlesById },
        sections: (x) =>
            (x || []).map((section) =>
                update(section, {
                    titles: (titles) =>
                        commaSeparatedStringToArray(titles).filter(
                            (y) => !!titlesById[y]
                        ),
                })
            ),
    });
}

function socialAppConverter(data, story) {
    const messages = (data.messages || []).map((conv) => ({
        name: conv.name,
        photo: conv.photo,
        contacts: getContactsByIds(story, conv.conv?.contacts),
        messages: textToMessages(conv.conv?.conversationText || "", {
            startDate: conv.conv?.startDate,
            startTime: conv.startTime,
            fromIndexField: "fromIndex",
            textField: "text",
            timeField: "time",
            dateField: "date",
        }),
    }));

    // TODO: notifications
    return { messages, notifications: [] };
}

function browserAppConverter(data) {
    const history = (data.history || []).filter((x) => !!x.query);

    return {
        searchHistory: history.map((x) => x.query),
        cachedPages: history.filter((x) => !!x.src),
    };
}

const Converters = {
    phone: phoneAppConverter,
    messages: messagesAppConverter,
    contacts: contactsAppConverter,
    streaming: streamingAppConverter,
    social: socialAppConverter,
    browser: browserAppConverter,
    email: noop,
    photos: noop,
    notes: noop,
    folder: noop,
    findFriends: noop,
    news: noop,
    calendar: noop,
};
export default Converters;

function getContactsByIds(story, cntIdArray) {
    return (cntIdArray || []).map((cntId) => getContactById(story, cntId));
}

function getContactById(story, cntId) {
    const contact = find(story.contacts, { id: cntId });
    return contact ? convertContact(contact) : {};
}

function convertContact(contact) {
    if (contact.number) {
        contact = update(contact, {
            number: (num) =>
                phoneNumbers.contactNumberToString({ id: contact.id, ...num }),
        });
    }
    return contact;
}

function commaSeparatedStringToArray(str) {
    if (!str) {
        return [];
    }
    return str
        .split(",")
        .map((x) => x.trim())
        .filter((x) => x !== "");
}

function textToMessages(text, config) {
    const parser = new ConversationParser(config);
    return parser.parse(text);
}

import StudioSchemaForm from "../StudioSchemaForm";
import { useStudioStory } from "../StudioContext";
import MISSION_OBJECTIVE_SCHEMA, {
    MISSION_OBJECTIVE_UI_SCHEMA,
} from "../schemas/missionObjectiveSchema";
import EditorTab from "../common/EditorTab";

import { observer } from "mobx-react-lite";
import React from "react";
import { toPairs, fromPairs, pick } from "lodash";

const MissionObjectiveEditor = observer(({ onStoryUpdate }) => {
    const story = useStudioStory();

    const handleChange = (formData) => {
        onStoryUpdate(
            fromPairs(
                toPairs(formData).map(([key, value]) => [key, { $set: value }])
            )
        );
    };

    const data = pick(story, [
        "answerTitle",
        "answerQuestion",
        "answerPlaceholder",
        "answers",
    ]);

    return (
        <EditorTab scrollable>
            <StudioSchemaForm
                schema={MISSION_OBJECTIVE_SCHEMA}
                uiSchema={MISSION_OBJECTIVE_UI_SCHEMA}
                data={data}
                onChange={handleChange}
            />
        </EditorTab>
    );
});

export default MissionObjectiveEditor;

export const MAX_APPS = 16;

export const APP_TYPES = [
    { type: "phone", title: "Phone" },
    { type: "contacts", title: "Contacts" },
    { type: "photos", title: "Photos" },
    { type: "messages", title: "Messages" },
    { type: "browser", title: "Browser" },
    { type: "email", title: "Email" },
    { type: "calendar", title: "Calendar" },
    { type: "notes", title: "Notes" },
    { type: "streaming", title: "Streaming" },
    { type: "social", title: "Social" },
    { type: "folder", title: "Folder" },
    { type: "findFriends", title: "Find Friends" },
    { type: "news", title: "News" },
];

export const LOCKERS = {
    pin: {
        label: "PIN",
        helperText: "Digits only",
        hasHint: true,
    },
    password: {
        label: "Password",
        helperText: null,
        hasHint: true,
    },
    userPassword: {
        label: "User & password",
        helperText: "Format: username|password",
    },
    calculator: {
        label: "Secret value",
        helperText: "Digits and/or calculator signs",
    },
    converter: {
        label: "Secret value",
        helperText: "Digits only",
    },
    dotPattern: {
        label: "Pattern",
        helperText: "Exactly 9 zeros and ones",
        hasHint: true,
    },
    dotHoverPattern: {
        label: "Pattern",
        helperText: "Digits only",
    },
};

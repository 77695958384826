import React from "react";
import {
    TimePicker as MuiTimePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import dayjs from "dayjs";

export default function TimePicker({ value, label, className, onChange }) {
    const hour = Math.floor((value || 0) / 60);
    const minute = Math.floor((value || 0) % 60);
    const selectedDate = dayjs(`2000-01-01T${hour}:${minute}`);

    const handleDateChange = (date) => {
        onChange(date.hour() * 60 + date.minute());
    };

    return (
        <MuiPickersUtilsProvider utils={DayjsUtils}>
            <MuiTimePicker
                margin="normal"
                className={className}
                label={label}
                value={selectedDate}
                onChange={handleDateChange}
                mask="__:__ _M"
                format="HH:mm a"
            />
        </MuiPickersUtilsProvider>
    );
}

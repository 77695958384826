import { RelativeDateTime } from "utils/dateUtils";

import { isInteger } from "lodash";

const WORDS_PER_MINUTE = 38;
const WORDS_PER_SECOND = WORDS_PER_MINUTE / 60;

const CONSTANT_RESPONSE_DELAY_SEC = 20;

const MESSAGE_RE = /^(?<from>[a-zA-Z\d-]+)\s*:\s*(?<text>.*)$/;

// TODO: change regex to specific commands
const COMMAND_RE = /^\[(?<command>[a-zA-Z]+)\s*(?<params>.*)\s*\]$/;

export class ConversationParser {
    constructor(config) {
        this.config = config || {};

        this.currentDate = new RelativeDateTime(
            config.startDate?.amount || 0,
            config.startTime || 0
        );
        this.responseDelay = 0;
        this.messages = [];
        this.threads = [createThread(this.currentDate, this.messages)];
        this.firstMessage = true;
    }

    parse(text) {
        const parts = text.split("\n");
        for (let i = 0; i < parts.length; i += 1) {
            const line = parts[i].trim();
            if (line === "") {
                continue;
            }
            if (this._parseMessage(line)) {
                continue;
            }
            this._parseCommand(line);
        }

        return this.config.threads ? this.threads : this.messages;
    }

    _parseMessage(line) {
        const match = line.match(MESSAGE_RE);
        if (!match) {
            return false;
        }

        const { text } = match.groups;
        let { from } = match.groups;
        from = from.toLowerCase();

        if (!this.firstMessage) {
            this.responseDelay +=
                (CONSTANT_RESPONSE_DELAY_SEC + typingDelay(text)) / 60;
            if (this.responseDelay >= 1) {
                this._setCurrentDate(
                    this.currentDate.addMinutes(Math.floor(this.responseDelay))
                );
                this.responseDelay %= 1;
            }
        }
        this.firstMessage = false;

        const message = {
            [this.config.textField || "text"]: text,
        };
        if (this.config.directionField) {
            message[this.config.directionField] = from;
        }
        if (this.config.fromIndexField) {
            message[this.config.fromIndexField] = convertFromToIndex(from);
        }
        if (this.config.dateField) {
            message[this.config.dateField] = this.currentDate.toRelativeDate();
        }
        if (this.config.timeField) {
            message[this.config.timeField] =
                this.currentDate.formatTime("h:mm A");
        }

        this.messages.push(message);
        return true;
    }

    _parseCommand(line) {
        const match = line.match(COMMAND_RE);
        if (!match) {
            return false;
        }

        const { command, params } = match.groups;

        switch (command) {
            case "time":
                {
                    const [hour, minute] = params
                        .trim()
                        .split(":")
                        .map((x) => parseInt(x, 10));
                    this.currentDate = this.currentDate.setTime(hour, minute);
                }
                break;

            case "skip":
                {
                    const [amount, units] = params
                        .split(" ")
                        .map((x) => x.trim());
                    const newDate = this.currentDate.add(
                        parseInt(amount, 10),
                        units
                    );
                    if (!newDate) {
                        return false;
                    }
                    this._setCurrentDate(newDate);
                }
                break;

            default:
                return false;
        }

        return true;
    }

    _setCurrentDate(newDate) {
        if (this.config.threads && !newDate.isSameDate(this.currentDate)) {
            this.messages = [];
            this.threads.push(createThread(newDate, this.messages));
        }
        this.currentDate = newDate;
    }
}

function convertFromToIndex(from) {
    if (from === "out") {
        return -1;
    }
    if (from === "in") {
        return 0;
    }
    const fromInt = parseInt(from, 10);
    return isInteger(fromInt) ? fromInt - 1 : -1;
}

export function typingDelay(text) {
    return parseInt(countWords(text) / WORDS_PER_SECOND, 10);
}

export function countWords(text) {
    return text
        .trim()
        .split(/\s+/)
        .filter((x) => x !== "").length;
}

function createThread(relativeDateTime, messages) {
    return {
        date: relativeDateTime.toRelativeDate(),
        messages,
    };
}

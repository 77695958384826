import "./AppContacts.css";
import ContactInfo from "./common/ContactInfo";
import EmojiText from "../../utils/EmojiText";

import { ContactPhoto } from "@faintlines/phone-sim-common";

import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import { sortBy } from "lodash";

export default function AppContacts({ contacts, appPath }) {
    return (
        <div className="AppContacts">
            <Switch>
                <Route exact path={appPath}>
                    <ContactList contacts={contacts} />
                </Route>
            </Switch>
        </div>
    );
}

function ContactList({ contacts }) {
    const [openContact, setOpenContact] = useState(null);

    const handleContactToggle = (contactId) => {
        setOpenContact(openContact === contactId ? null : contactId);
    };

    return (
        <div className="AppContacts-list-scroller">
            <div className="AppContacts-list">
                <div className="AppContacts-list-header">
                    <div>
                        <div className="AppContacts-list-title">
                            {"Contacts"}
                        </div>
                    </div>
                </div>
                <div className="AppContacts-list-segments">
                    {contacts.map((segment, segmentId) => (
                        <div
                            className="AppContacts-list-segment"
                            key={segmentId}
                        >
                            <div className="AppContacts-list-segment-title">
                                {segment.name}
                            </div>
                            <div className="AppContacts-list-segment-contacts">
                                {sortContacts(
                                    segment.contacts,
                                    segment.sort
                                ).map((contact, contactId) => (
                                    <Contact
                                        key={contactId}
                                        contact={contact}
                                        id={`${segmentId}_${contactId}`}
                                        isOpen={
                                            openContact ===
                                            `${segmentId}_${contactId}`
                                        }
                                        onToggle={handleContactToggle}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

function Contact({ contact, id, isOpen, onToggle }) {
    return (
        <div className="AppContacts-list-contact">
            <div
                className="AppContacts-list-contact-data"
                onClick={() => onToggle(id)}
            >
                <div className="AppContacts-list-contact-icon-wrapper">
                    <ContactPhoto contact={contact} />
                </div>
                <div className="AppContacts-list-contact-details">
                    <div className="AppContacts-list-contact-name">
                        <EmojiText text={contact.name} />
                    </div>
                </div>
                <div className="AppContacts-list-contact-expand-icon icon-dot-3" />
            </div>
            <ContactInfo contact={contact} isOpen={isOpen} showFullInfo />
        </div>
    );
}

function sortContacts(contacts, shouldSort) {
    if (shouldSort === false) {
        // default is true
        return contacts;
    }
    return sortBy(contacts, "name");
}

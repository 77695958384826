// TODO:
// home: AppSmartHome,

const appSchemas = {
    photos: {
        type: "object",
        properties: {
            sections: {
                type: "array",
                title: "Sections",
                default: [],
                items: {
                    type: "object",
                    properties: {
                        name: {
                            type: "string",
                            title: "Section title",
                        },
                        photos: {
                            type: "array",
                            title: "Photos",
                            default: [],
                            items: {
                                type: "object",
                                properties: {
                                    url: { type: "string", title: "Photo" },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
    photosUi: {
        sections: {
            items: {
                photos: {
                    items: {
                        url: { "ui:widget": "ImageUploader" },
                    },
                },
            },
        },
    },
    messages: {
        type: "object",
        properties: {
            conversations: {
                type: "array",
                title: "Conversations",
                default: [],
                items: {
                    type: "object",
                },
            },
        },
    },
    messagesUi: {
        conversations: {
            items: {
                "ui:field": "TextConversationSingeContactField",
            },
        },
    },
    browser: {
        type: "object",
        properties: {
            history: {
                title: "Search History",
                type: "array",
                default: [],
                items: {
                    type: "object",
                    properties: {
                        query: { type: "string", title: "Query string" },
                        src: {
                            type: "string",
                            title: "Cached page image (optional)",
                        },
                    },
                },
            },
        },
    },
    browserUi: {
        history: {
            items: {
                src: { "ui:widget": "ImageUploader" },
            },
        },
    },
    phone: {
        type: "object",
        properties: {
            // startScreen: {"title": "Start Screen", "enum": ["dialer", "history"], default: "history"},
            // TODO: dialer
            calls: {
                type: "array",
                title: "Call Log",
                items: {
                    type: "object",
                    properties: {
                        date: { type: "object" },
                        calls: {
                            type: "array",
                            items: {
                                type: "object",
                                properties: {
                                    contact: { type: "string" },
                                    time: {
                                        type: "string",
                                        default: "10:00 AM",
                                    }, // TODO: time selector
                                    direction: {
                                        enum: ["in", "out"],
                                        default: "in",
                                    },
                                },
                            },
                            default: [],
                        },
                    },
                },
            },
        },
    },
    phoneUi: {
        calls: {
            items: {
                date: { "ui:field": "RelativeDateSelector" },
                calls: {
                    items: {
                        contact: {
                            "ui:widget": "ContactSelector",
                        },
                    },
                },
            },
        },
    },
    contacts: {
        type: "object",
        properties: {},
    },
    notes: {
        type: "object",
        properties: {
            appTitle: { type: "string", title: "App title" },
            itemTitle: { type: "string", title: "Item title" },
            notes: {
                type: "array",
                title: "Notes",
                items: {
                    type: "object",
                    properties: {
                        title: { type: "string", title: "Title", default: "" },
                        text: {
                            type: "string",
                            title: "Text (markdown)",
                            default: "",
                        },
                    },
                },
            },
        },
    },
    notesUi: {
        appTitle: { "ui:placeholder": "Notes" },
        itemTitle: { "ui:placeholder": "note" },
        notes: { items: { text: { "ui:widget": "textarea" } } },
    },
    email: {
        type: "object",
        properties: {
            threads: {
                type: "array",
                title: "Email Threads",
                items: {
                    type: "object",
                    properties: {
                        subject: {
                            type: "string",
                            default: "",
                            title: "Subject",
                        },
                        emails: {
                            type: "array",
                            title: "Emails",
                            default: [],
                            items: {
                                type: "object",
                                properties: {
                                    from: {
                                        type: "object",
                                        title: "Sender",
                                        properties: {
                                            name: {
                                                title: "Name",
                                                type: "string",
                                                default: "",
                                            },
                                            email: {
                                                type: "string",
                                                title: "Email address",
                                                default: "",
                                                format: "email",
                                            },
                                        },
                                    },
                                    to: {
                                        type: "array",
                                        title: "Recipients",
                                        items: {
                                            type: "object",
                                            properties: {
                                                name: {
                                                    title: "Name",
                                                    type: "string",
                                                    default: "",
                                                },
                                                email: {
                                                    type: "string",
                                                    title: "Email address",
                                                    default: "",
                                                    format: "email",
                                                },
                                            },
                                        },
                                        default: [],
                                    },
                                    date: {
                                        type: "object",
                                        title: "Date sent",
                                    },
                                    body: {
                                        type: "string",
                                        default: "",
                                        title: "Email body",
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
    emailUi: {
        threads: {
            items: {
                emails: {
                    items: {
                        body: { "ui:widget": "textarea" },
                        date: { "ui:field": "RelativeDateTimeWeeksSelector" },
                    },
                },
            },
        },
    },
    streaming: {
        type: "object",
        properties: {
            titles: {
                type: "array",
                title: "Titles",
                items: {
                    type: "object",
                    properties: {
                        id: { type: "string" }, // TODO: auto generate
                        title: { type: "string", title: "Title" },
                        image: { type: "string", title: "Poster image" },
                        seasons: {
                            type: "integer",
                            title: "Number of seasons",
                        },
                        year: { type: "integer", title: "Release year" },
                        friends: {
                            type: "string",
                            title: "Friends who love this title (comma separated)",
                        },
                    },
                },
            },
            sections: {
                type: "array",
                title: "Sections",
                default: [],
                items: {
                    type: "object",
                    properties: {
                        name: { type: "string" },
                        titles: {
                            type: "string",
                            title: "Title ids (comma separated)",
                        },
                    },
                },
            },
        },
    },
    streamingUi: {
        titles: { items: { image: { "ui:widget": "ImageUploader" } } },
    },
    social: {
        type: "object",
        properties: {
            messages: {
                type: "array",
                title: "Conversations",
                default: [],
                items: {
                    type: "object",
                    properties: {
                        name: {
                            type: "string",
                            title: "Conversation name (optional)",
                        },
                        photo: {
                            type: "string",
                            title: "Conversation photo (optional)",
                        },
                        conv: {
                            type: "object",
                        },
                    },
                },
            },
        },
    },
    socialUi: {
        messages: {
            items: {
                photo: {
                    "ui:widget": "ImageUploader",
                },
                conv: {
                    "ui:field": "TextConversationSingeContactField", // TODO: TextConversationMultipleContactsField
                },
            },
        },
    },
    folder: {
        type: "object",
        properties: {
            folderName: { type: "string", title: "Folder name" },
            bgImage: { type: "string", title: "Background image (optional)" },
            layout: {
                enum: ["grid", "list"],
                default: "grid",
                title: "Layout",
            },
            items: {
                type: "array",
                default: [],
                title: "Files",
                items: {
                    type: "object",
                    properties: {
                        title: { type: "string", title: "Title" },
                        type: {
                            enum: ["file"],
                            default: "file",
                            title: "Item type",
                        },
                        icon: {
                            enum: ["document", "photo"],
                            default: "document",
                            title: "Icon",
                        },
                        image: { type: "string", title: "Contents (image)" },
                    },
                },
            },
        },
    },
    folderUi: {
        bgImage: { "ui:widget": "ImageUploader" },
        items: {
            items: {
                type: { "ui:widget": "hidden" },
                image: { "ui:widget": "ImageUploader" },
            },
        },
    },
    findFriends: {
        type: "object",
        properties: {
            entries: {
                type: "array",
                default: [],
                title: "Friends",
                items: {
                    type: "object",
                    properties: {
                        name: { type: "string", title: "Name" },
                        location: { type: "string", title: "Location" },
                        time: { type: "string", title: "Time last seen" },
                        image: { type: "string", title: "Friend image" },
                        map: { type: "string", title: "Location map image" },
                    },
                },
            },
        },
    },
    findFriendsUi: {
        entries: {
            items: {
                image: { "ui:widget": "ImageUploader" },
                map: { "ui:widget": "ImageUploader" },
            },
        },
    },
    news: {
        type: "object",
        properties: {
            title: { type: "string", title: "Title" },
            items: {
                type: "array",
                title: "Articles",
                default: [],
                items: {
                    type: "object",
                    properties: {
                        id: { type: "string", title: "Id (required)" },
                        title: { type: "string", title: "Title" },
                        subtitle: { type: "string", title: "Subtitle" },
                        image: { type: "string", title: "Image" },
                        contents: {
                            type: "string",
                            title: "Body",
                            default: "",
                        },
                        visible: {
                            type: "boolean",
                            title: "Visible by default",
                            default: true,
                        },
                        read: {
                            type: "boolean",
                            title: "Read by default",
                            default: true,
                        },
                    },
                    required: ["id"],
                },
            },
        },
    },
    newsUi: {
        items: {
            items: {
                contents: { "ui:widget": "textarea" },
                image: { "ui:widget": "ImageUploader" },
            },
        },
    },
    calendar: {
        type: "object",
        properties: {
            events: {
                type: "array",
                title: "Events",
                default: [],
                items: {
                    type: "object",
                    properties: {
                        date: { type: "object", title: "Event date" },
                        time: {
                            type: "string",
                            default: "10:00 AM",
                            title: "Event time",
                        }, // TODO: time selector
                        title: { type: "string", title: "Event title" },
                        body: { type: "string", title: "Event Description" },
                    },
                },
            },
        },
    },
    calendarUi: {
        events: {
            items: {
                date: { "ui:field": "RelativeDateSelector" },
            },
        },
    },
};

export default appSchemas;

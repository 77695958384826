import emojiRegexFactory from "emoji-regex/RGI_Emoji";
import replaceToArray from "string-replace-to-array";

export const emojiRegex = emojiRegexFactory();

// https://github.com/twitter/twemoji/blob/gh-pages/2/twemoji.js#L232
const UFE0Fg = /\uFE0F/g;

// \u200D is a zero-width joiner character
// https://github.com/twitter/twemoji/blob/gh-pages/2/twemoji.js#L235
const U200D = String.fromCharCode(0x200d);

export function isEmoji(string) {
    if (!string) {
        return false;
    }
    const match = string.match(emojiRegex);
    return match !== null && match.length > 0 && match[0] === string;
}

export function escapeLunrQueryString(queryString) {
    if (typeof queryString !== "string") {
        return queryString;
    }
    return queryString.replace(/[-~^:+]/g, "\\$&");
}

export function normalizePhoneNumber(number) {
    if (!number) {
        return "";
    }
    if (number.startsWith("+1")) {
        number = number.substring(2);
    }
    number = number.replace(/[^\d]/g, "");
    // Remove US country code
    if (number.length === 11 && number.startsWith("1")) {
        number = number.substring(1);
    }
    return number;
}

export function isFakePhoneNumber(phoneNumber) {
    // TODO: get this info from the backend
    return (
        !!phoneNumber &&
        (phoneNumber.includes("555") || phoneNumber.includes("7010"))
    );
}

export function replaceEmojisToArray(text, replacement) {
    return replaceToArray(text, emojiRegex, replacement);
}

export function replaceEmojis(text, replacement) {
    if (!text) {
        return text;
    }
    return text.replace(emojiRegex, replacement);
}

export function unicodeToCodepoint(input) {
    return toCodePoint(
        input.indexOf(U200D) < 0 ? input.replace(UFE0Fg, "") : input
    );
}

function toCodePoint(input, separator = "-") {
    const codePoints = Array.from(input).map((codePoint) =>
        codePoint.codePointAt(0).toString(16)
    );
    return codePoints.join(separator);
}

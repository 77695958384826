import styles from "./ConfettiBlast.module.css";
import Confetti from "components/common/Confetti";
import AppState from "state/AppState";

import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import useMeasure from "react-use/lib/useMeasure";
import { isEmpty } from "lodash";

const blastSizeToNumberOfPieces = {
    small: 100,
    medium: 300,
    big: 1000,
    huge: 4000,
};

const calculateBlastLocationToConfettiSource = (width, height) => ({
    top: { x: 0, y: 0, w: width, h: 0 },
    twothirds: {
        x: width / 2 - 25,
        y: (height * 2) / 3 - 25,
        w: 50,
        h: 50,
    },
    bottom: { x: width / 2 - 25, y: height, w: 50, h: 0 },
});

const BlastLocationToVelocityY = {
    top: 10,
    twothirds: 20,
    bottom: 50,
};

export default observer(() => {
    const { confettiProps } = AppState.storyState;
    const [rootElem, { width, height }] = useMeasure();
    const [confettiOn, setConfettiOn] = useState(false);

    useEffect(() => {
        if (confettiProps && !isEmpty(confettiProps)) {
            setConfettiOn(true);
        }
    }, [confettiProps]);

    if (!confettiProps || isEmpty(confettiProps) || !confettiOn) {
        return null;
    }

    const {
        blastSize = "small",
        gravity = 0.15,
        blastSource = "top",
    } = confettiProps;

    const numberOfPieces = blastSizeToNumberOfPieces[blastSize];
    const confettiSource = calculateBlastLocationToConfettiSource(
        width,
        height
    )[blastSource];
    const initialVelocityY = BlastLocationToVelocityY[blastSource];

    return (
        <div className={styles.confettiWrap} ref={rootElem}>
            <Confetti
                style={{ pointerEvents: "none" }}
                width={width}
                height={height}
                numberOfPieces={numberOfPieces}
                recycle={false}
                gravity={gravity}
                confettiSource={confettiSource}
                initialVelocityY={initialVelocityY}
                onConfettiComplete={(confetti) => {
                    setConfettiOn(false);
                    AppState.clearConfetti();
                    confetti.reset();
                }}
            />
        </div>
    );
});

import { onesignalSetUidFailureEvent } from "./analytics";

import { Howler } from "howler";

// TODO: unify event definition

window.appPauseEvent = new CustomEvent("apppause");
window.appResumeEvent = new CustomEvent("appresume");

window.addEventListener("apppause", () => Howler.mute(true));
window.addEventListener("appresume", () => Howler.mute(false));

document.addEventListener("contextmenu", (evt) => evt.preventDefault());

// document.addEventListener("visibilitychange", () =>
// 	Howler.mute(document.visibilityState !== "visible")
// );

window.addEventListener("onesignalSetUidFail", () =>
    onesignalSetUidFailureEvent()
);

import styles from "./Button.module.css";
import { PulseSpinner } from "./Spinners";

import React from "react";
import classnames from "classnames";

export default function Button({
    children,
    disabled,
    primary,
    secondary,
    minor,
    small,
    fullWidth,
    displayFont,
    shine,
    loading,
    className,
    onClick,
}) {
    return (
        <div
            className={classnames(
                styles.button,
                className,
                disabled ? styles.disabled : null,
                primary ? styles.primary : null,
                secondary ? styles.secondary : null,
                minor ? styles.minor : null,
                small ? styles.small : null,
                fullWidth ? styles.fullWidth : null,
                displayFont ? styles.displayFont : null,
                shine && !loading ? styles.shine : null,
                loading ? styles.loading : null
            )}
            onClick={onClick}
        >
            {loading ? (
                <div className={styles.spinnerWrapper}>
                    <PulseSpinner
                        size={small ? 8 : 12}
                        color="rgba(0,0,0,0.7)"
                    />
                </div>
            ) : null}
            <div className={styles.content}>{children}</div>
        </div>
    );
}

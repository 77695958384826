import styles from "./PizzaButton.module.css";

import React from "react";

export default function PizzaButton({ text, onClick }) {
    return (
        <div className={styles.button} onClick={onClick}>
            <div className={styles.inner}>{text}</div>
        </div>
    );
}

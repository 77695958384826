import { makeAutoObservable, set } from "mobx";
import { pickToJs } from "utils/mobxUtils";

export default class PizzaAppState {
    constructor({ existingState }) {
        makeAutoObservable(this);

        if (PizzaAppState.hasProgress(existingState)) {
            set(this, existingState);
        }
    }

    dispose() {}

    orderStatus = null;
    deliveryStartTime = null;

    static hasProgress(state) {
        return state && !!state.orderStatus;
    }

    /// / Computed

    get asJson() {
        return pickToJs(this, ["orderStatus", "deliveryStartTime"]);
    }

    /// / Actions

    setOrderStatus(newStatus) {
        this.orderStatus = newStatus;
        if (newStatus === "tracker") {
            this.deliveryStartTime = Math.round(Date.now() / 1000);
        }
    }
}

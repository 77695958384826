import * as Native from "@faintlines/native-bridge";

import ImageStarterPack1Popup from "images/offers/starter-pack-1.png";
import ImageStarterPack1PopupWebp from "images/offers/starter-pack-1.webp";
import ImageStarterPack1Badge from "images/offers/starter-pack-1-badge.png";
import ImageStarterPack1BadgeWebp from "images/offers/starter-pack-1-badge.webp";

import ImageHalloween1Badge from "images/offers/halloween-1-badge.png";
import ImageHalloween1BadgeWebp from "images/offers/halloween-1-badge.webp";
import ImageHalloween1Popup from "images/offers/halloween-1-lq.png";
import ImageHalloween1PopupWebp from "images/offers/halloween-1.webp";

export const DEBUG = process.env.NODE_ENV !== "production";
export const ENABLE_SENTRY = !DEBUG;
export const ENABLE_GOOGLE_ANALYTICS = !DEBUG;

export const ANDROID_APP_VERSION = Native.HAVE_ANDROID
    ? Native.getAppVersion()
    : null;

const TEST_REWARDED_AD_UNIT_ID = Native.HAVE_IOS
    ? "ca-app-pub-3940256099942544/1712485313" // https://developers.google.com/admob/ios/rewarded
    : "ca-app-pub-3940256099942544/5224354917"; // https://developers.google.com/admob/android/rewarded-ads

export const REWARDED_AD_UNIT_IDS = DEBUG
    ? {
          freeHint: TEST_REWARDED_AD_UNIT_ID,
          missionUnlock: TEST_REWARDED_AD_UNIT_ID,
          batteryCharge: TEST_REWARDED_AD_UNIT_ID,
      }
    : Native.HAVE_ANDROID
    ? {
          freeHint:
              ANDROID_APP_VERSION < 26
                  ? "ca-app-pub-8668678357062343/1966932027"
                  : "ca-app-pub-2504769489122277/7276582887",
          missionUnlock: "ca-app-pub-2504769489122277/5909436335",
          batteryCharge: "ca-app-pub-2504769489122277/1684670110",
      }
    : Native.HAVE_IOS
    ? {
          freeHint: "ca-app-pub-2504769489122277/3084753717",
          missionUnlock: "ca-app-pub-2504769489122277/5392187001",
          batteryCharge: "ca-app-pub-2504769489122277/5583758690",
      }
    : {};

export const GOOGLE_ANALYTICS_ID = "UA-2216047-21";

export const LIVE_CHAT_LICENSE_ID = 12777708;
export const LIVE_CHAT_CLIENT_ID = "1d534f998e7fb2168ad73fde1ff5f9b3";

export const STUDIO_CLIENT_URL = "https://studio.peekaph.one/";
export const INSTAGRAM_PAGE_URL = "https://www.instagram.com/peekaphone/";
export const PLAYER_SURVEY_URL = "https://go.peekaphonegame.com/survey";
export const BECOME_A_CHARACTER_URL =
    "https://go.peekaphonegame.com/become-a-character";

export const MIN_STORIES_COMPLETED_FOR_MULTI_STORY = 9;
export const MULTI_STORIES_COUNT = 3;

export const MIN_STORIES_COMPLETED_FOR_TROPHIES = 3;

export const STORY_LOCK_TIME_MINUTES = 30;

export const STORE_ITEMS_DEFAULT_PRICE = {
    android: {
        hints1a: "$1.49",
        hints2a: "$2.49",
        hints3a: "$3.99",
        hints4a: "$6.99",
        hints5a: "$12.99",
        //
        hints1_h2: "$1.49",
        hints2_h2: "$2.49",
        hints3_h2: "$3.99",
        hints4_h2: "$6.99",
        hints5_h2: "$12.99",
        hints6_h2: "$24.99",
        hints7_h2: "$44.99",
        //
        noads1: "$1.49",
        //
        // old battery products
        battery1: "$0.99",
        unlimited_battery_24: "$2.49",
        unlimited_battery_7_days: "$6.99",
        //
        // new battery products
        unlimited_battery_30min: "$1.49",
        unlimited_battery_4: "$2.49",
        unlimited_battery_4a: "$4.99",
        story_batteries_10: "$6.99",
        story_batteries_20: "$12.99",
        //
        // battery percentage product
        battery_percent_100: "$1.49",
        battery_percent_250: "$2.99",
        battery_percent_500: "$4.99",
        battery_percent_1000: "$8.99",
        battery_percent_2000: "$15.99",
        //
        // bundles
        promo_july_4th: "$1.99",
        starter_pack_1: "$1.99",
        halloween_1: "$3.49",
    },
    ios: {
        hints1a: "$1.99",
        hints2a: "$2.99",
        hints3a: "$4.99",
        hints4a: "$8.99",
        hints5a: "$15.99",
        noads1: "$1.99",
        //
        // old battery products
        battery1: "$1.99",
        unlimited_battery_24: "$4.99",
        unlimited_battery_7_days: "$7.99",
        //
        // new battery products
        unlimited_battery_30min: "$1.99",
        unlimited_battery_4: "$2.99",
        unlimited_battery_4a: "$4.99",
        story_batteries_10: "$6.99",
        story_batteries_20: "$12.99",
        //
        // battery percentage product
        battery_percent_100: "$1.99",
        battery_percent_250: "$2.99",
        battery_percent_500: "$4.99",
        battery_percent_1000: "$8.99",
        battery_percent_2000: "$15.99",
    },
};

export const CUSTOM_OFFERS_AVAILABLE =
    Native.CAPABILITIES.buyStoreItem || DEBUG;

export const CUSTOM_OFFERS = CUSTOM_OFFERS_AVAILABLE
    ? {
          starter_pack_1: {
              badgeImageUrl: [
                  ImageStarterPack1Badge,
                  ImageStarterPack1BadgeWebp,
              ],
              popupImage: [ImageStarterPack1Popup, ImageStarterPack1PopupWebp],
              popupBuyButtonStyle: {
                  width: "28%",
                  height: "10%",
                  bottom: "17%",
                  left: "36%",
                  color: "#2a302e",
                  fontFamily: "var(--font-family-display)",
              },
              popupCloseButtonStyle: {
                  width: "10%",
                  height: "10%",
                  top: "2%",
                  right: "2%",
              },
              badgeCountdownStyle: {
                  color: "#fff",
                  fontSize: "11px",
                  bottom: "12.5%",
                  textShadow: "0 0 6px #c02200",
              },
          },
          halloween_1: {
              badgeImageUrl: [ImageHalloween1Badge, ImageHalloween1BadgeWebp],
              popupImage: [ImageHalloween1Popup, ImageHalloween1PopupWebp],
              popupBuyButtonStyle: {
                  width: "34%",
                  height: "10%",
                  bottom: "13.7%",
                  left: "33%",
                  color: "#074E27",
                  textShadow: "0px 2px 0px #A1FBC4",
                  fontFamily: "var(--font-family-display)",
              },
              popupCloseButtonStyle: {
                  width: "10%",
                  height: "8%",
                  top: "0",
                  right: "0",
              },
              popupCountdownStyle: {
                  bottom: "8%",
              },
              badgeCountdownStyle: {
                  color: "#fff",
                  fontSize: "11px",
                  bottom: "12.5%",
                  textShadow: "0 0 6px #c02200",
              },
          },
      }
    : {};

// Feedback form config
export const MIN_RATING_FOR_RATE_IN_STORE = 4;
export const MAX_RATING_FOR_TEXTUAL_FEEDBACK = 3;
export const MIN_COMPLETED_MISSIONS_FOR_RATE_IN_STORE = 3;

export const MIN_RATING_FOR_SHARING = 4;
export const MIN_COMPLETED_MISSIONS_FOR_SHARING = 3;

export const MIN_COMPLETED_MISSIONS_FOR_BECOME_A_WRITER = 4;
export const MIN_COMPLETED_MISSIONS_FOR_BECOME_A_BETA_TESTER = 999;
export const MIN_COMPLETED_MISSIONS_FOR_BECOME_A_CHARACTER = 6;
export const END_DATE_FOR_BECOME_A_CHARACTER_NEW_TAG = new Date(
    "August 26, 2022"
).getTime();
export const MIN_COMPLETED_MISSIONS_FOR_PLAYER_SURVEY = 4;

// export const TIME_LOCKED_STORY_INDEXES = [7, 9, 10, 12, 13, 15, 16, 17];
export const TIME_LOCKED_STORY_INDEXES = [];

export const BATTERY_DRAIN_TIME_MINUTES = 30;
export const BATTERY_DRAIN_TIME_SECONDS = BATTERY_DRAIN_TIME_MINUTES * 60;
export const BATTERY_DECREASE_INTERVAL_MS = Math.ceil(
    (BATTERY_DRAIN_TIME_SECONDS / 100) * 1000
);
export const BATTERY_LOW_THRESHOLD = 30;
export const MIN_BATTERY_FOR_INTRO_LEVELS = 85;
export const MAX_DAILY_CHARGE_ADS = 5;

export const TASK_COMPLETION_REWARD_WEIGHTS = [
    [[null, 0], 40],
    [["battery", 3], 30],
    [["battery", 5], 15],
    [["battery", 7], 8],
    [["battery", 10], 4],
    [["hints", 1], 3],
];

// eslint-disable-next-line
export function shouldShowSignupPopup({ completedStoriesCount }) {
    return false;
    // return completedStoriesCount > 2 && completedStoriesCount % 2 === 1;
}

export function shouldShowBetaTestAd({ completedStoriesCount, playerCountry }) {
    return completedStoriesCount >= 12 && playerCountry === "us";
}
